import { deviceDetect, isAndroid as isAndroidBase, isIOS, isIOS13, isMacOs, isWindows } from 'react-device-detect';

export const isOS = isIOS || isIOS13;
export const isAndroid = isAndroidBase;

export const getPlayMarketUrl = (androidId: string) => `http://play.google.com/store/apps/details?id=${androidId}`;
export const getAppStoreUrl = (appleId: number) => `itms-apps://apple.com/app/${appleId}`;

export enum Platform {
  Linux = 'LINUX',
  Pc = 'PC',
  Mac = 'MAC',
  Ios = 'IOS',
  Android = 'ANDROID',
  Web = 'WEB',
  Other = 'OTHER',
}
export const getPlatform = () => {
  if (isIOS) return Platform.Ios;
  if (isAndroid) return Platform.Android;
  if (isMacOs) return Platform.Mac;
  if (isWindows) return Platform.Pc;
  return Platform.Other;
};

export const getDevice = () => {
  const { osName, osVersion } = deviceDetect(navigator.userAgent);
  return `${osName || ''}${osVersion ? ` ${osVersion}` : ''}`;
};
