import clsx from 'clsx';

type AlertVariants = 'info' | 'error' | 'warning';

const alertVariantClasses: Record<AlertVariants, string> = {
  info: 'bg-blue-100 border-blue-500',
  error: 'bg-red-100 border-red-500',
  warning: 'bg-yellow-50 border-yellow-500',
};

const alertVariantText: Record<AlertVariants, string> = {
  info: 'text-blue-600',
  error: 'text-red-500',
  warning: 'text-yellow-600',
};

type AlertProps = {
  className?: string;
  heading: string;
  content: string;
  variant?: AlertVariants;
};

export function Alert({ className, heading, content, variant = 'error' }: AlertProps) {
  return (
    <div
      className={clsx('rounded border-t-4  px-4 py-3 shadow-md', alertVariantClasses[variant], className)}
      role="alert"
    >
      <div className="flex">
        <div className="py-1">
          <svg
            className={clsx('mr-4 h-6 w-6 fill-current', alertVariantText[variant])}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
          </svg>
        </div>
        <div>
          <p className={clsx('font-bold', alertVariantText[variant])}>{heading}</p>
          <p className={clsx('text-sm', alertVariantText[variant])}>{content}</p>
        </div>
      </div>
    </div>
  );
}
