import 'cleave.js/dist/addons/cleave-phone.us';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@/components/Common/Button';
import { SelectInput } from '@/components/Common/SelectInput';
import { SelectItem, allCountryList, allStatesWithCountry, getOptionByValue } from '@/utils/select-options.utils';
import { Input } from '@/components/Common/Input';
import { MaskedInput } from '@/components/Common/MaskedInput';
import { AddressFormData, schema } from './AddressForm.schema';
import { useMe } from '@/hooks/useMe';
import { useEffect, useState } from 'react';
import { useAddressForm } from './AddressForm.hooks';

export type AddressFormProps = {
  className?: string;
};

export function AddressForm({ className }: AddressFormProps) {
  const me = useMe();

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
		trigger
  } = useForm<AddressFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      address1: me?.shippingInfo?.address1,
      address2: me?.shippingInfo?.address2,
      phone: me?.shippingInfo?.phone || '',
      country: me?.shippingInfo?.country,
      city: me?.shippingInfo?.city,
      state: me?.shippingInfo?.state,
      zipCode: me?.shippingInfo?.zipCode,
    },
    mode: 'all',
  });

  const country = useWatch({ control, name: 'country' });

  const [countries, setCountries] = useState<SelectItem[]>([]);
  const [states, setStates] = useState<SelectItem[]>([]);

  useEffect(() => {
    (async function () {
      setCountries(await allCountryList());
    })();
  }, []);

  useEffect(() => {
    (async function () {
      setStates(await allStatesWithCountry(country));
    })();
  }, [country]);

  const onSubmit = useAddressForm();

  return (
    <form noValidate className={className} onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col space-y-4">
        <Controller
          control={control}
          name="address1"
          render={({ field }) => (
            <Input
              error={errors.address1?.message}
              label="Address"
              placeholder="Your Address"
              required
              type="text"
              {...field}
            />
          )}
          rules={{ required: true }}
        />
        <Controller
          control={control}
          name="address2"
          render={({ field }) => (
            <Input
              error={errors.address2?.message}
              label="Address 2"
              placeholder="Your Second Address"
              type="text"
              {...field}
            />
          )}
          rules={{ required: true }}
        />
        <Controller
          control={control}
          name="phone"
          render={({ field }) => (
            <MaskedInput
              error={errors.phone?.message}
              label="Phone"
              options={{ phone: true, phoneRegionCode: country }}
              placeholder="Your Phone"
              required
              {...field}
              value={field.value || ''}
            />
          )}
          rules={{ required: true }}
        />
        <Controller
          control={control}
          name="country"
          render={({ field }) => (
            <SelectInput
              label="Country"
              options={countries}
              placeholder="Your Country"
              required
              value={getOptionByValue(field.value, countries) || null}
              onChange={(v) => {
								field.onChange(v);
								trigger('phone')
							}}
            />
          )}
          rules={{ required: true }}
        />
        <Controller
          control={control}
          name="state"
          render={({ field }) => (
            <SelectInput
              label="State"
              options={states}
              placeholder=""
              required
              value={getOptionByValue(field.value || '', states) || null}
              onChange={(v) => field.onChange(v)}
            />
          )}
          rules={{ required: true }}
        />
        <Controller
          control={control}
          name="city"
          render={({ field }) => (
            <Input error={errors.city?.message} label="City" placeholder="Your City" required type="text" {...field} />
          )}
          rules={{ required: true }}
        />
        <Controller
          control={control}
          name="zipCode"
          render={({ field }) => (
            <Input error={errors.zipCode?.message} label="Zip" placeholder="Zip" required {...field} />
          )}
          rules={{ required: true }}
        />
      </div>
      <Button disabled={!isValid} type="submit" className="mt-12 w-full">
        Save
      </Button>
    </form>
  );
}
