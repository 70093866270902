type Props = {
    className?: string;
    onClick?: () => void;
};

export function LightBulb(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.13947 15.6629C6.95197 14.3973 5.47876 12.0312 5.47876 9.32143C5.47876 5.27902 8.75778 2 12.8002 2C16.8426 2 20.1216 5.27902 20.1216 9.32143C20.1216 12.0312 18.6484 14.3973 16.4609 15.6629V18.25C16.4609 18.6451 16.1417 18.9643 15.7466 18.9643H9.85376C9.45867 18.9643 9.13947 18.6451 9.13947 18.25V15.6629ZM10.1216 20.3929H15.4788C15.577 20.3929 15.6573 20.4732 15.6573 20.5714V21.2857C15.6573 21.6808 15.3381 22 14.943 22H10.6573C10.2622 22 9.94305 21.6808 9.94305 21.2857V20.5714C9.94305 20.4732 10.0234 20.3929 10.1216 20.3929Z"
                fill="currentColor"
            />
        </svg>
    );
}
