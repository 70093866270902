type Props = {
    className?: string;
    onClick?: () => void;
};

export function ChevronRight(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.69881 5.27655L4.86545 0.31466C4.46008 -0.101736 3.79922 -0.105088 3.38989 0.305944C2.9799 0.717646 2.9766 1.38884 3.38197 1.80457L7.4858 6.01815L3.33707 10.1868C2.92774 10.5985 2.92378 11.2697 3.32981 11.6854C3.73452 12.1018 4.39538 12.1052 4.80471 11.6935L9.68495 6.78992C9.79058 6.68465 9.86848 6.56127 9.91998 6.42985C10.0764 6.04698 10.0038 5.58968 9.69881 5.27655Z"
                fill="currentColor"
            />
        </svg>
    );
}
