import { Button } from '@/components/Common/Button';
import { Icon } from '@/components/Common/Icon';
import { appRoutes } from '@/helpers/routes.helper';
import { useMe } from '@/hooks/useMe';
import { Link } from 'react-router-dom';

export function AboutPage() {
  const me = useMe();

  return (
    <>
      <div className="-mx-2 -mb-4 flex h-full w-screen flex-col overflow-hidden bg-dark-gray">
        <div className="flex h-full w-full flex-1 flex-row overflow-hidden">
          <div
            className={`relative hidden w-1/3 min-w-fit shrink-0 flex-col overflow-y-auto bg-transparent md:flex md:bg-darker-gray`}
          >
            <img alt="" className="absolute left-0 top-0 hidden w-full md:block" src="/backgrounds/WaveSmall.png" />
            <div className="hidden md:block">
              <div className="z-10 flex h-full flex-col items-center justify-between px-0">
                <Icon name="ScutiLogo" className="z-10 mt-4 hidden h-[125px]  p-6 lg:block" />
                <img src="/images/ScutiSmartphone.png" alt="" className="w-80 " />
                <div className="mx-4 mb-4 text-sm font-semibold text-light-gray lg:mx-0">
                  © {new Date().getFullYear()} Scuti. All Rights Reserved.
                </div>
              </div>
            </div>
          </div>
          <div className={`mt-8 h-full w-full overflow-y-auto px-4 md:w-2/3 lg:px-16`}>
            <div className="relative flex min-h-full flex-col">
              <img
                alt=""
                className="absolute -left-4 -top-8 z-10 block w-[80%] lg:hidden"
                src="/backgrounds/WaveSmall.png"
              />
              <div className="z-20 block">
                <Icon name="ScutiLogo" className="mt-16 block h-[100px] p-6 sm:mt-0 lg:hidden" />
              </div>
              <h2 className="mt-16 font-semibold lg:mt-4">About Scuti</h2>
              <p className=" mb-3 mt-4 text-sm">
                SCUTI is proud to be the go-to destination for gamers to shop for top brands while playing your favorite
                games. Whether you're looking for gaming gear or awesome apparel, SCUTI has you covered.
              </p>
              <p className="mb-3 mt-4 text-white">The best part?</p>
              <p className=" mb-3 mt-2 text-sm">
                With every purchase, you earn SCUTI Rewards. These rewards can be exchanged for your game's currency,
                allowing you to put them towards your next in-game upgrade, or you can use them to buy more products in
                our marketplace.
              </p>
              <p className=" mb-3 mt-2 text-sm">
                You also earn FREE SCUTI Rewards every day you visit SCUTI! You can use your Rewards across any game
                where you see the SCUTI marketplace.
              </p>
              <p className=" mb-3 mt-2 text-sm">
                To start earning SCUTI Rewards just <Link to={appRoutes.registration}>create an account</Link>
              </p>
              <div className="mx-7 mb-3 text-sm text-light-gray">
                <ul className="mt-2 list-disc flex-col space-y-1 p-0">
                  <li>Receive up to 5% back in rewards with every purchase</li>
                  <li>All items ship direct to you from each brand</li>
                </ul>
              </div>
              <img
                alt=""
                className="absolute -bottom-12 -right-4 block w-[80%] lg:hidden"
                src="/backgrounds/WaveBig.png"
              />

              {!me && (
                <>
                  <p className="mb-6  mt-4 text-sm">
                    Click the button below to create an account today and start earning rewards from any Scuti-partnered
                    video games you play.
                  </p>
                  <div className="mt-4 w-full pb-12 lg:w-96">
                    <Button href={appRoutes.registration} variant="primary" className="w-full">
                      Register Now!
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
