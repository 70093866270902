import { Blurred } from '@/components/Common/Blurred';
import { Icon } from '@/components/Common/Icon';
import { ProgressBar } from '@/components/Common/ProgressBar';
import useAnimation from '@/hooks/useAnimation';

export type ConvertScutiFormProgressProps = {
  coinImage?: string | null;
};

export function ConvertScutiFormProgress({ coinImage }: ConvertScutiFormProgressProps) {
  const loading = useAnimation('linear', 2000);

  const image = (size: string) => {
    return coinImage ? (
      <img alt="" className={size} src={coinImage || ''} />
    ) : (
      <Icon name="Coin" className={`text-white ${size}`} />
    );
  };

  return (
    <Blurred>
      <div className="flex flex-col space-y-8">
        <div className="relative flex justify-center">
          <div className="absolute top-2 flex w-full flex-row items-center justify-center space-x-20 opacity-30">
            <img alt="" className="h-16 w-16" src="/images/ScutiCoin.png" />
            {image('w-20 h-20')}
          </div>
          <div className="flex flex-row items-center space-x-10">
            <img alt="" className="h-20 w-20" src="/images/ScutiCoin.png" />
            <Icon name="Exchange" className="h-12 w-12 rotate-90 text-white" />
            {image('w-24 h-24')}
          </div>
        </div>
        <ProgressBar className="w-full max-w-lg" value={loading * 100} />
        <h2 className="text-center">Applying Your Scuti$ to your game</h2>
        <p className="text-paragraph-sm text-secondary-400 text-center">
          Your transaction is in process, this action might take a few seconds...
        </p>
      </div>
    </Blurred>
  );
}
