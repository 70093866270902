import { Button } from '@/components/Common/Button';
import { Icon } from '@/components/Common/Icon';
import { formatScore } from '@/utils/number.utils';
import { useRewards } from './RewardsPage.hooks';
import { useEffect } from 'react';
import { appRoutes } from '@/helpers/routes.helper';
import { useNavigate } from 'react-router-dom';
export type CongratulationsProps = {
  className?: string;
};

export function Congratulations({ className }: CongratulationsProps) {
  const navigate = useNavigate();
  const { handleClaim, totalReward } = useRewards();

  useEffect(() => {
    if (totalReward === 0) {
      navigate(appRoutes.store);
    }
  }, [totalReward, navigate]);
  return (
    <div
      className={`bg-dark-grey flex h-full w-full flex-col items-center justify-center text-center ${className} overflow-y-auto`}
    >
      <div className="bg-dark-grey/50 z-20 flex h-full flex-1 flex-col items-end md:h-fit md:w-1/2 md:flex-initial md:rounded-3xl md:border md:border-darker-gray md:px-8 md:py-2 md:shadow-xl md:backdrop-blur-2xl lg:w-1/3">
        <div className="mt-32 flex w-full flex-col items-center justify-center md:mt-10">
          <h1 className="font-bold">Congratulations!</h1>
          <hr className="mt-4 inline-flex w-10 border-b-4 border-light-blue" />
          <div className="flex flex-col items-center">
            <div className="relative z-10 mx-auto flex w-72 flex-row justify-center bg-transparent">
              <img alt="" className="absolute top-8 h-56 w-56" src="/images/ScutiCoins.png" />
            </div>
            <div className="-z-10 flex h-60 w-full flex-col items-center justify-end overflow-hidden md:h-52">
              <h6 className="font-size-auto w-full bg-gradient-to-b from-gray to-transparent bg-clip-text font-extrabold text-transparent opacity-20">
                REWARDS
              </h6>
            </div>
            <div className="flex w-fit flex-col items-center">
              <h2 className="mb-4 font-semibold md:mt-6 md:pt-12">
                You&apos;ve Earned {formatScore(totalReward)} Scuti$
              </h2>
              <ul className="mx-8 mb-10 mt-6 flex flex-col space-y-2">
                <li className="flex flex-row items-center space-x-2">
                  <Icon name="Star" className="h-6 w-6 text-blue" />
                  <p className="text-left text-sm font-semibold text-white">Start Discovering Great Offers</p>
                </li>
                <li className="flex flex-row items-center space-x-2">
                  <Icon name="Star" className="h-6 w-6 text-blue" />
                  <p className="text-left text-sm font-semibold text-white">Earn more Scuti$ Every Day</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="mb-12 flex w-full flex-grow flex-col justify-end px-4 md:mb-8">
          <Button onClick={handleClaim} className="w-full">
            Claim!
          </Button>
        </div>
      </div>
    </div>
  );
}
