import { ReactNode } from 'react';

type WelcomeLayoutProps =
  | {
      split?: false;
      children: ReactNode;
    }
  | {
      split: true;
      left?: ReactNode;
      right?: ReactNode;
    };

export function WelcomeLayout(props: WelcomeLayoutProps) {
  return (
    <div className="relative h-full w-screen">
      {props.split && (
        <>
          <div className="relative flex h-full w-full md:hidden xl:flex">
            <div className="relative z-10 h-full w-full overflow-auto bg-dark-gray md:w-1/2 md:bg-darker-gray">
              {props.left}
            </div>
            <div
              className="bottom-0 left-0 right-0 top-0 hidden overflow-auto bg-cover bg-center md:block md:w-1/2"
              style={{ backgroundImage: 'url(/backgrounds/Welcome.jpg)' }}
            >
              {props.right}
            </div>
            <img
              alt=""
              className="absolute bottom-0 right-0 hidden animate-pulse md:block"
              src="/backgrounds/WaveBig.png"
            />
          </div>
          <div className="hidden h-full w-full overflow-auto md:block xl:hidden">
            <div
              className="absolute bottom-0 left-0 right-0 top-0 hidden bg-cover bg-center md:block"
              style={{ backgroundImage: 'url(/backgrounds/Welcome.jpg)' }}
            />
            {props.left}
          </div>
        </>
      )}
      {!props.split && (
        <div className="relative h-full w-screen">
          <div
            className="absolute bottom-0 left-0 right-0 top-0 overflow-auto bg-cover bg-center"
            style={{ backgroundImage: 'url(/backgrounds/Welcome.jpg)' }}
          />
          {props.children}
        </div>
      )}
    </div>
  );
}
