import { Icon } from './Icon';

type QuantitySelectorProps = {
  className?: string;
  label?: string;
  value: number;
  max: number;
  step?: number;
  onChange: (value: number) => void;
};

export function QuantitySelector({ className, label, value = 1, max, step = 1, onChange }: QuantitySelectorProps) {
  return (
    <div className={`flex flex-col space-y-4 ${className}`}>
      {label && <label className="text-sm font-semibold text-white">{label}:</label>}
      <div className="flex flex-row items-center justify-between space-x-2 rounded-full border-2 border-blue px-4 py-3">
        <button disabled={value <= 1} onClick={() => onChange(Math.max(value - step, step))}>
          <Icon className={`h-3 w-3 ${value <= 1 ? 'text-secondary-200' : 'text-white'}`} name="Minus" />
        </button>
        <p className="selector w-9 text-center text-white">{value}</p>
        <button disabled={value >= max} onClick={() => onChange(Math.min(value + step, max))}>
          <Icon className={`h-3 w-3 ${value >= max ? 'text-secondary-200' : 'text-white'}`} name="Plus" />
        </button>
      </div>
    </div>
  );
}
