type Props = {
    className?: string;
    onClick?: () => void;
};

export function Cart(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.4444 20.4258C16.4444 21.689 17.4378 22.7143 18.6667 22.7143C19.8933 22.7143 20.8889 21.689 20.8889 20.4258C20.8889 19.1603 19.8933 18.1373 18.6667 18.1373C17.4378 18.1373 16.4444 19.1603 16.4444 20.4258ZM5.33333 20.4258C5.33333 21.689 6.32778 22.7143 7.55556 22.7143C8.78111 22.7143 9.77778 21.689 9.77778 20.4258C9.77778 19.1603 8.78111 18.1373 7.55556 18.1373C6.32778 18.1373 5.33333 19.1603 5.33333 20.4258ZM9.27444 14.0692L21.5722 10.4511C21.8067 10.3813 22 10.1204 22 9.86754V3.57419H6.44444V1.7434C6.44444 1.49167 6.24333 1.28571 6.00111 1.28571H2.44333C2.2 1.28571 2 1.49167 2 1.7434V3.57419H4.22222L6.34444 13.8232L6.44444 14.9022V16.7891C6.44444 17.0397 6.64444 17.2468 6.88889 17.2468H21.5556C21.8 17.2468 22 17.0385 22 16.7891V15.0166H9.50222C8.22444 15.0166 8.19778 14.3862 9.27444 14.0692Z"
                fill="currentColor"
            />
        </svg>
    );
}
