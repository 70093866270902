type Props = {
    className?: string;
    onClick?: () => void;
};

export function Play(props: Props) {
    return (
        <svg {...props} fill="none" viewBox="0 0 80 77" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M39.9876 0.000789003C41.9228 -0.0248202 43.8147 0.573589 45.3832 1.70739C46.9517 2.84118 48.1133 4.45005 48.6959 6.29566L51.5759 15.1346L53.5539 21.1807C53.6626 21.5104 53.8727 21.7974 54.1542 22.0007C54.4357 22.204 54.7742 22.3132 55.1214 22.3128H70.8213C72.7565 22.2872 74.6484 22.8856 76.2169 24.0194C77.7854 25.1532 78.947 26.762 79.5296 28.6076C80.1468 30.4362 80.1569 32.4153 79.5584 34.2501C78.9599 36.0849 77.7847 37.6773 76.208 38.7902L63.1455 48.2325C62.9651 48.3625 62.8307 48.5463 62.7614 48.7575C62.6921 48.9688 62.6915 49.1965 62.7598 49.4081L65.9757 59.2734L66.38 60.5174L67.7485 64.7161C68.3677 66.5445 68.3789 68.5242 67.7802 70.3594C67.1815 72.1947 66.0052 73.7871 64.4269 74.8986C62.868 76.043 60.9864 76.6636 59.0526 76.6714C57.1167 76.6645 55.2329 76.0438 53.6721 74.8986L44.2546 68.0564C43.0169 67.1601 41.5281 66.6769 40 66.6755C38.4717 66.6756 36.9825 67.1589 35.7454 68.0564L26.3279 74.8986C24.7667 76.0431 22.8832 76.6637 20.9474 76.6714C19.0136 76.6636 17.132 76.043 15.5731 74.8986C13.9948 73.7871 12.8185 72.1947 12.2198 70.3594C11.6211 68.5242 11.6323 66.5445 12.2515 64.7161L13.62 60.5174L14.0181 59.2734L17.2402 49.4081C17.3056 49.1963 17.3036 48.9695 17.2345 48.7589C17.1654 48.5483 17.0326 48.3644 16.8545 48.2325L3.79202 38.7902C2.21527 37.6773 1.04014 36.0849 0.441622 34.2501C-0.1569 32.4153 -0.146803 30.4362 0.470419 28.6076C1.05212 26.7615 2.21348 25.1521 3.78217 24.0181C5.35086 22.8842 7.24331 22.2862 9.17874 22.3128H24.8724C25.2196 22.3132 25.5581 22.204 25.8396 22.0007C26.121 21.7974 26.3311 21.5104 26.4399 21.1807L28.4179 15.1346L31.2979 6.29566C31.8796 4.45314 33.0383 2.84651 34.603 1.713C36.1678 0.579499 38.0555 -0.0207899 39.9876 0.000789003Z"
                fill="#1D35EE"
            />
            <path
                d="M30 51.2253V34.0374C30 30.8764 33.4938 28.9644 36.1562 30.6683L50.0174 39.5395C52.5181 41.1399 52.4651 44.811 49.9192 46.3385L36.058 54.6552C33.3919 56.2549 30 54.3344 30 51.2253Z"
                fill="white"
            />
        </svg>
    );
}
