type ErrorWithMessage = {
  message: string;
};

type GraphQLError = {
  extensions?: {
    code: string;
    response: {
      statusCode: number;
      message: string;
      errors: string;
    };
  };
};

export class ServerError extends Error {
  constructor(private errors: GraphQLError[]) {
    super('Error: ');
    Object.setPrototypeOf(this, ServerError.prototype);
  }

  getErrorMessage() {
    return this.errors[0].extensions?.response.message || 'Server error';
  }
}

export const isErrorWithMessage = (error: unknown): error is ErrorWithMessage => {
  return (
    typeof error === 'object' &&
    error !== null &&
    (('message' in error && typeof (error as Record<string, unknown>).message === 'string') ||
      ('message' in error && typeof (error as Record<string, unknown>).message === 'object'))
  );
};

export const toErrorWithMessage = (maybeError: unknown): ErrorWithMessage => {
  if (isErrorWithMessage(maybeError)) return maybeError;

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError));
  }
};

export const getErrorMessage = (error: unknown) => {
  // if (error instanceof ServerError) return error.getErrorMessage();
  return toErrorWithMessage(error).message;
};
