import { Slider } from '@/components/Common/Slider';
import { Image } from '@/components/Common/Image';
import { useConvertScuti } from './ConvertScutiForm.hooks';
import { Button } from '@/components/Common/Button';
import { Icon } from '@/components/Common/Icon';
import { formatScore } from '@/utils/number.utils';
import { ConvertScutiFormProgress } from './ConvertScutiFormProgress';
import { ConvertScutiFormSuccess } from './ConvertScutiFormSuccess';

export type ConvertScutiFormProps = {
  className?: string;
};

export function ConvertScutiForm({ className }: ConvertScutiFormProps) {
  const {
    coinImage,
    coinName,
    usedScutis,
    scutiBalance,
    gameCoinsValue,
    scutisPerOneGameCoin,
    transactionStatus,
    onChange,
    onExchange,
  } = useConvertScuti();

  return (
    <div className={`flex h-full flex-col justify-between  ${className}`}>
      <div className="flex w-full flex-col items-center  justify-center md:m-auto md:max-w-lg">
        <img alt="" className="h-40" src="/images/ScutiCoin.png" />
        <p className="text-paragraph-lg mt-4 text-lg text-white">Balance: {scutiBalance - usedScutis}</p>
        <div className="mt-16 text-2xl font-semibold ">{usedScutis}</div>
        <div className="mt-9 w-full">
          <Slider
            value={usedScutis}
            max={scutiBalance}
            step={scutisPerOneGameCoin}
            withButtons
            onChange={(value) => (typeof value === 'number' ? onChange(value) : onChange(value[0]))}
            autoFocus
          />
        </div>
        <p className="mt-8 text-xs text-light-gray">Select number of Scuti$ to use</p>
      </div>
      <div className="flex w-full flex-col justify-center space-y-8 md:m-auto md:max-w-lg">
        <p className="text-light-gray">Estimate: </p>
        <div>
          <div className="flex w-full items-center justify-between">
            <div className="flex w-full items-center">
              <Image src={coinImage} className="mr-2 h-6" />
              <div className="text-lg">{coinName}</div>
            </div>
            <div className="text-2xl">{formatScore(gameCoinsValue)}</div>
          </div>
        </div>
        <Button className="w-full" onClick={onExchange} disabled={gameCoinsValue < 1}>
          Convert <Icon name="Exchange" className="ml-2 h-4" />
        </Button>
      </div>
      {transactionStatus === 'inProgress' && <ConvertScutiFormProgress coinImage={coinImage} />}
      {transactionStatus === 'Success' && (
        <ConvertScutiFormSuccess coinName={coinName} convertedCoins={gameCoinsValue} />
      )}
    </div>
  );
}
