import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Input } from '@/components/Common/Input';
import { Button } from '@/components/Common/Button';
import { ChangePasswordFormData, schema } from './ChangePasswordForm.schema';
import { useChangePassword } from './ChangePasswordForm.hooks';

interface LoginFormProps {
  className?: string;
}

export function ChangePasswordForm({ className }: LoginFormProps) {
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<ChangePasswordFormData>({
    resolver: yupResolver(schema),
    defaultValues: { currentPassword: '', newPassword: '', newPasswordConfirmation: '' },
    context: {
      isValid: true,
    },
    mode: 'all',
  });

  const onSubmit = useChangePassword();

  return (
    <form noValidate className={className} onSubmit={handleSubmit((data) => onSubmit(data))}>
      <div className="flex flex-col space-y-4">
        <Controller
          control={control}
          name="currentPassword"
          render={({ field }) => (
            <Input
              error={errors.currentPassword?.message}
              label="Current Password"
              placeholder="Your Current Password"
              required
              type="password"
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="newPassword"
          render={({ field }) => (
            <Input
              error={errors.newPassword?.message}
              label="New Password"
              placeholder="Your New Password"
              required
              type="password"
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="newPasswordConfirmation"
          render={({ field }) => (
            <Input
              error={errors.newPasswordConfirmation?.message}
              label="New Password Confirmation"
              placeholder="Confirm New Password"
              required
              type="password"
              {...field}
            />
          )}
        />
      </div>
      <Button disabled={!isValid} type="submit" className="mt-12 w-full">
        Save
      </Button>
    </form>
  );
}
