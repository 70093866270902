import { isThisMonth, isThisWeek, isThisYear, isToday, isYesterday } from 'date-fns';

export const formatDateLevel = (date: string) => {
  return new Date(date).toLocaleDateString('en-US', {
    weekday: undefined,
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

export const getPeriodLabel = (strDate: string) => {
  const date = new Date(strDate);
  if (isToday(date)) return 'Today';
  if (isYesterday(date)) return 'Yesterday';
  if (isThisWeek(date)) return 'Earlier this week';
  if (isThisMonth(date)) return 'Earlier this month';
  if (isThisYear(date)) return 'This Year';
  return 'Older';
};
