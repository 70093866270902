import * as yup from 'yup';

export const schema = yup
  .object({
    email: yup.string().email('Email is incorrect').required('Field is required'),
    password: yup.string().required('Field is required'),
  })
  .required();

export type LoginFormData = yup.InferType<typeof schema>;
