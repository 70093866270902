type Props = {
    className?: string;
    onClick?: () => void;
};

export function ChevronUp(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.79046 2.81501L0.828576 7.64838C0.41218 8.05374 0.408827 8.71461 0.819859 9.12394C1.23156 9.53392 1.90276 9.53722 2.31848 9.13186L6.53206 5.02802L10.7007 9.17675C11.1124 9.58608 11.7836 9.59004 12.1993 9.18402C12.6157 8.77931 12.6191 8.11844 12.2074 7.70912L7.30384 2.82888C7.19857 2.72324 7.07519 2.64534 6.94377 2.59384C6.5609 2.43738 6.1036 2.51 5.79046 2.81501Z"
                fill="currentColor"
            />
        </svg>
    );
}
