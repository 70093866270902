import { memo, useEffect, useState } from 'react';
import { Icon } from './Icon';
import { getEnv } from '@/utils/sentry.utils';
import { useMe } from '@/hooks/useMe';
import { tourStore } from '@/store/tour.store';

const env = getEnv();
let TAG = 'ads/wallet-dev.html';

if (env === 'production') {
  TAG = 'ads/wallet.html';
}

const VIDEO_TIMEOUT = 35000;
const SKIP_TIMEOUT = 17000;

export const AdModal = memo(() => {
  const me = useMe();
  const { forceRun } = tourStore.state;
  // const tourStatus = forceRun || (!!me && !me?.welcomeTourCompleted);
  const tourStatus = false;
  const [show, setShow] = useState(!tourStatus);
  const [showClose, setShowClose] = useState(false);

  useEffect(() => {
    let timeout: number;

    if (show) {
      timeout = window.setTimeout(() => {
        setShow(false);
      }, VIDEO_TIMEOUT);
    }
    return () => clearTimeout(timeout);
  }, [show]);

  useEffect(() => {
    let timeout: number;

    if (!showClose) {
      timeout = window.setTimeout(() => {
        setShowClose(true);
      }, SKIP_TIMEOUT);
    }
    return () => clearTimeout(timeout);
  }, [showClose]);

  return show ? (
    <div
      className="exchange-page-video absolute left-0 top-0 z-50 h-full w-full"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed bottom-0 left-0 right-0 top-0 flex overflow-y-auto backdrop-brightness-0 backdrop-opacity-95">
        <div className="flex w-full items-center justify-center landscape:items-start landscape:lg:items-center">
          <div className={`rounded-2x relative flex-col px-4`}>
            {showClose && (
              <div className="absolute -top-2 right-2 z-50 md:-top-1">
                <button className="h-6 w-6 rounded-full bg-white p-2 text-black" onClick={() => setShow(false)}>
                  <Icon name="Cross"></Icon>
                </button>
              </div>
            )}
            <div
              className="mx-auto h-full min-h-[50px] w-80 bg-center bg-no-repeat md:w-[640px]"
              style={{ backgroundImage: 'url(/images/SpinningWheel.svg)' }}
            >
              <p className="fixed left-1/2 top-1/2 z-0 -translate-x-1/2 -translate-y-1/2 pt-20 text-center">
                Loading video...
              </p>
              <iframe
                frameBorder="0"
                width="100%"
                height="100%"
                src={`${process.env.REACT_APP_URL}/${TAG}`}
                title="Scuti Exchange"
                style={{ minHeight: '50px' }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
});
