import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Input } from '@/components/Common/Input';
import { Button } from '@/components/Common/Button';
import { NavLink } from 'react-router-dom';
import { appRoutes } from '@/helpers/routes.helper';
import { LoginFormData, schema } from './LoginForm.schema';
import { useLogin } from '@/hooks/useLogin';
import { useResentEmail } from '@/hooks/useResentEmail';
import { Spinner } from '@/components/Common/Spinner';

interface LoginFormProps {
  className?: string;
}

export function LoginForm({ className }: LoginFormProps) {
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    getValues,
  } = useForm<LoginFormData>({
    resolver: yupResolver(schema),
    defaultValues: { email: '', password: '' },
    mode: 'all',
  });

  const { handleLogin, needResend } = useLogin();
  const { mutateAsync: onResend, isLoading } = useResentEmail();

  const handleResend = () => onResend(getValues().email);

  return (
    <>
      <form noValidate className={className} onSubmit={handleSubmit((data) => handleLogin(data))}>
        <div className="flex flex-col space-y-4">
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <Input
                error={errors.email?.message}
                label="Email"
                placeholder="Your Email"
                required
                type="email"
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="password"
            render={({ field }) => (
              <Input
                error={errors.password?.message}
                label="Password"
                placeholder="Your Password"
                required
                type="password"
                {...field}
              />
            )}
          />
          <NavLink className="mt-2 self-end" to={appRoutes.forgotPassword}>
            Forgot Password?
          </NavLink>
        </div>
        <Button disabled={!isValid} type="submit" className="mt-12 w-full">
          Log In
        </Button>
      </form>
      {needResend && (
        <Button variant="secondary" className="mt-4 w-full" disabled={isLoading} onClick={handleResend}>
          {isLoading ? <Spinner className="h-6 w-6" spinnerClassName="border-gray" /> : 'Resend Verification Email'}
        </Button>
      )}
    </>
  );
}
