type Props = {
    className?: string;
    onClick?: () => void;
};

export function DollarCoin(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                clipRule="evenodd"
                d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM15.4195 16.2596C16.0116 15.6738 16.3076 14.9738 16.3076 14.1596C16.3076 13.5043 16.1448 12.978 15.8191 12.5809C15.4935 12.1738 15.0889 11.8709 14.6053 11.6723C14.1316 11.4638 13.5493 11.2801 12.8585 11.1213V8.58936C13.6283 8.67872 14.3832 8.93688 15.1234 9.36383L15.8636 7.53192C15.0741 7.02553 14.0724 6.7227 12.8585 6.6234V5H11.3782V6.65319C10.2532 6.78227 9.38968 7.14468 8.78769 7.74043C8.19557 8.32624 7.8995 9.03121 7.8995 9.85532C7.8995 10.5206 8.06234 11.0567 8.388 11.4638C8.71367 11.861 9.11829 12.1638 9.60186 12.3723C10.0854 12.5809 10.6775 12.7695 11.3782 12.9383V15.4255C10.8453 15.3759 10.3272 15.2567 9.8239 15.0681C9.3206 14.8794 8.88144 14.6461 8.50643 14.3681L7.69226 16.1851C8.09688 16.5028 8.62979 16.7709 9.29099 16.9894C9.9522 17.2078 10.6479 17.3369 11.3782 17.3766V19H12.8585V17.3319C13.9737 17.1929 14.8273 16.8355 15.4195 16.2596ZM10.5641 10.3468C10.3766 10.1879 10.2828 9.9844 10.2828 9.73617C10.2828 9.47801 10.3716 9.25461 10.5493 9.06596C10.7269 8.86738 11.0032 8.7234 11.3782 8.63404V10.7489C11.023 10.6397 10.7516 10.5057 10.5641 10.3468ZM13.6579 14.9787C13.4803 15.1574 13.2138 15.2865 12.8585 15.366V13.3106C13.2039 13.4298 13.4655 13.5688 13.6431 13.7277C13.8306 13.8865 13.9244 14.0901 13.9244 14.3383C13.9244 14.5865 13.8355 14.8 13.6579 14.9787Z"
                fill="white"
                fillRule="evenodd"
            />
        </svg>
    );
}
