import { useNavigate } from 'react-router-dom';
import { useActivateRewardsMutation, useMyRewardsQuery, useMyWalletQuery } from '@/graphql/generated';
import { useCallback, useMemo } from 'react';
import { appRoutes } from '@/helpers/routes.helper';
import { useQueryClient } from '@tanstack/react-query';
import { getErrorMessage } from '@/utils/error.utils';
import { toast } from 'react-toastify';
import { useLogOut } from '@/hooks/useLogout';

export function useRewards() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data } = useMyRewardsQuery();

  const totalReward = useMemo(() => {
    if (!data) return 0;
    return data.myRewards.nodes.reduce((accumulator, currentValue) => accumulator + (currentValue?.scutis || 0), 0);
  }, [data]);

  const activateRewards = useActivateRewardsMutation().mutateAsync;
  const handleLogout = useLogOut();

  const handleClaim = useCallback(async () => {
    if (!data?.myRewards) return;

    try {
      await activateRewards({ rewardId: data?.myRewards.nodes.map((reward) => reward.id) });
      await queryClient.invalidateQueries({ queryKey: useMyRewardsQuery.getKey() });
      await queryClient.invalidateQueries({ queryKey: useMyWalletQuery.getKey() });
    } catch (error) {
      toast.error(getErrorMessage(error), { autoClose: 8000 });
      handleLogout();
    }

    navigate(appRoutes.store);
  }, [activateRewards, data?.myRewards, handleLogout, navigate, queryClient]);

  return useMemo(
    () => ({
      totalReward,
      handleClaim,
    }),
    [handleClaim, totalReward],
  );
}
