import { useEffect, useRef } from 'react';

import { sendMetric } from './metics-sender';
import { CampaignType } from '@/graphql/generated';
import { OfferAdSize } from '@/types/OfferTypes';

export const useImpressionMetric = (
  impression: { offerId: string; type: CampaignType; adSize?: OfferAdSize },
  isVisible: boolean,
) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const timeout = timeoutRef.current;
    if (isVisible) {
      timeoutRef.current = setTimeout(() => {
        sendMetric({
          name: impression.type === CampaignType.ProductListing ? 'LISTING_IMPRESSION' : 'OFFER_IMPRESSION',
          customData: impression,
        });
      }, 1000);
    } else if (timeout) clearTimeout(timeout);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [impression, isVisible]);
};
