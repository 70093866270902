import { QueryClient } from '@tanstack/react-query';
import { NonIndexRouteObject, redirect } from 'react-router-dom';
import { useMyScutiTransactionsQuery } from '@/graphql/generated';
import { fetcherGraphql } from '@/helpers/fetch.helper';
import { pagingDefaults } from '@/utils/paging.utils';
import { appLocalStorage } from '@/helpers/storage.helper';
import { appRoutes } from '@/helpers/routes.helper';

export const scutiTransactionsLoader =
  (queryClient: QueryClient): NonIndexRouteObject['loader'] =>
  async () => {
    if (!appLocalStorage.getItem('refreshToken')) {
      return redirect(appRoutes.welcome);
    }
    const variables = {
      paging: pagingDefaults,
      sorting: { name: 'createdAt', dir: 'desc' },
    };

    return await queryClient.ensureQueryData({
      queryFn: fetcherGraphql(useMyScutiTransactionsQuery.document, variables),
      queryKey: useMyScutiTransactionsQuery.getKey(variables),
    });
  };
