import { PaymentWithScutisForm } from '@/components/Form/PaymentWithScutisForm';

export function PaymentWithScutisPage() {
  return (
    <div className="flex h-full flex-col items-center pt-4 md:pt-8">
      <div className="flex w-full justify-center">
        <h4>Use your Scuti$ Reward Points</h4>
      </div>
      <PaymentWithScutisForm className="mt-4 w-full" />
    </div>
  );
}
