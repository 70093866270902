import ReactDOM from 'react-dom/client';
import 'react-toastify/dist/ReactToastify.css';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './styles/index.scss';
import './helpers/sdk-wrapper/methods';
import reportWebVitals from './reportWebVitals';
import { App } from './App';
import { initAppSessionStorage, initVuplexPaymentSessionStorage, readAppUrlParams } from './helpers/initialize.helper';
import { createSentryWrappedRouter, initSentry } from './utils/sentry.utils';
import { routing } from './routing';
import { appSessionStorage } from './helpers/storage.helper';
import { initializeSDK } from './helpers/sdk-wrapper/sdk-initialize.helper';
import { VuplexPaymentPage } from './helpers/sdk-wrapper/VuplexPaymentPage';
import { delay } from './utils/timing.utils';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './helpers/query.helper';

initSentry();

const params = readAppUrlParams();

if (params.type === 'store') initAppSessionStorage(params);
if (params.type === 'vuplex') initVuplexPaymentSessionStorage(params);

const router = createSentryWrappedRouter(routing);
const container = document.getElementById('root') as HTMLElement;
const ScutiApp = <App routeProviderProps={{ router }} />;

setTimeout(async () => {
  if (params.type === 'vuplex') {
    return ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
      <QueryClientProvider client={queryClient}>
        <VuplexPaymentPage stripeKey={params.stripeKey} />
      </QueryClientProvider>,
    );
  }

  const platform = appSessionStorage.getItem('platform');

  if (platform) {
    await delay(300);
    await initializeSDK(ScutiApp, container);
  } else return ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(ScutiApp);
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
