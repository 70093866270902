export type OrderTrackingProps = {
  className?: string;
  trackingNumber: string;
  orderTotal: string;
};

export function OrderTracking({ className, trackingNumber, orderTotal }: OrderTrackingProps) {
  return (
    <div
      className={`mt-6 flex w-full max-w-4xl flex-col space-y-4 self-center bg-darker-gray p-8 px-4 py-5 md:rounded-2xl lg:mt-0 ${className}`}
    >
      <div className="flex flex-row justify-between">
        <p className="text-white">Tracking Number: </p>
        <p className="text-light-gray">#{trackingNumber}</p>
      </div>
      <div className="flex flex-row justify-between">
        <p className=" text-white">Order Total: </p>
        <p className="text-light-gray">${orderTotal}</p>
      </div>
    </div>
  );
}
