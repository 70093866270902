type Props = {
    className?: string;
    onClick?: () => void;
};

export function Minus(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7 7L11 7C11.5523 7 12 6.55229 12 6C12 5.44772 11.5523 5 11 5H7H5H1C0.447715 5 0 5.44772 0 6C0 6.55229 0.447715 7 1 7L5 7H7Z"
                fill="currentColor"
            />
        </svg>
    );
}
