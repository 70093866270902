import { proxy } from 'valtio';
import { derive } from 'valtio/utils';

import { MyCartProducts, UserCartItem } from '@/types/CartTypes';
import { fetcherGraphql } from '@/helpers/fetch.helper';
import {
  PromoCodesInput,
  UpdateCartDocument,
  UpdateCartMutation,
  UpdateCartMutationVariables,
} from '@/graphql/generated';

type Store = { products: Record<string, UserCartItem>; promoCodes: PromoCodesInput[] };
type Derived = { itemsInCart: number; cartSubtotal: number };
export type CartType = Store & Derived;

const state = proxy<Store>({
  products: {},
  promoCodes: [],
});

derive<Store, Derived>(
  {
    itemsInCart: (get) => Object.values(get(state).products).reduce((acc, { quantity }) => acc + quantity, 0),
    cartSubtotal: (get) =>
      Object.values(get(state).products).reduce(
        (acc, { quantity, product }) => acc + quantity * product.variant.price,
        0,
      ),
  },
  {
    proxy: state,
  },
);

const setCart = (products: MyCartProducts) => {
  state.products = products.reduce<Store['products']>((acc, product) => {
    const { quantity } = product.variant;
    if (acc[product.variant.variantId]) {
      acc[product.variant.variantId].quantity = acc[product.variant.variantId].quantity + quantity;
    } else {
      acc[product.variant.variantId] = {
        product,
        quantity,
      };
    }
    return acc;
  }, {});
};

const add = async (product: UserCartItem['product'], quantity: number = 1) => {
  const { products } = state;
  if (products[product.variant.variantId]) {
    products[product.variant.variantId].quantity = products[product.variant.variantId].quantity + quantity;
  } else {
    products[product.variant.variantId] = {
      product,
      quantity,
    };
  }

  await syncWithServer(products);
};

const remove = async (product: UserCartItem['product']) => {
  const { products } = state;
  if (products[product.variant.variantId]) {
    const { quantity } = products[product.variant.variantId];
    products[product.variant.variantId].quantity = Math.max(1, quantity - 1);
    await syncWithServer(products);
  }
};

const clear = async (product: UserCartItem['product']) => {
  const { products } = state;
  delete products[product.variant.variantId];
  await syncWithServer(products);
};

const clearAll = async () => {
  state.products = {};
  state.promoCodes = [];
  await syncWithServer({});
};

const resetState = () => {
  state.products = {};
  state.promoCodes = [];
};

const syncWithServer = async (products: Store['products']) => {
  const items = Object.values(products).map(({ product, quantity }) => ({
    offerId: product.variant.offerId,
    variantId: product.variant.variantId,
    quantity,
  }));

  await fetcherGraphql<UpdateCartMutation, UpdateCartMutationVariables>(UpdateCartDocument, { input: { items } })();
};

const addPromoCode = (promoCode: PromoCodesInput) => {
  state.promoCodes.push(promoCode);
};
const removePromoCode = (id: string) => {
  state.promoCodes = state.promoCodes.filter((c) => c.id !== id);
};

export const cartStore = {
  state: state as CartType,
  actions: {
    add,
    remove,
    clear,
    clearAll,
    setCart,
    resetState,
    promoCodes: {
      add: addPromoCode,
      remove: removePromoCode,
    },
  },
};
