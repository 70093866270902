import { NameAgeForm } from '@/components/Form/NameAgeForm';

export function NameAndAge() {
  return (
    <div className="flex h-full flex-col pb-8">
      <h2 className="mb-8 font-semibold">Edit Name & Age</h2>
      <div className="grow">
        <NameAgeForm />
      </div>
    </div>
  );
}
