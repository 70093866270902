type Props = {
    className?: string;
    onClick?: () => void;
};

export function Password(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
            <path
                clipRule="evenodd"
                d="M63 27H54V24C54 19.2261 52.1036 14.6477 48.7279 11.2721C45.3523 7.89642 40.7739 6 36 6C31.2261 6 26.6477 7.89642 23.2721 11.2721C19.8964 14.6477 18 19.2261 18 24V27H9C8.20435 27 7.44129 27.3161 6.87868 27.8787C6.31607 28.4413 6 29.2043 6 30V63C6 63.7956 6.31607 64.5587 6.87868 65.1213C7.44129 65.6839 8.20435 66 9 66H63C63.7956 66 64.5587 65.6839 65.1213 65.1213C65.6839 64.5587 66 63.7956 66 63V30C66 29.2043 65.6839 28.4413 65.1213 27.8787C64.5587 27.3161 63.7956 27 63 27ZM48 24V27H24V24C24 20.8174 25.2643 17.7652 27.5147 15.5147C29.7652 13.2643 32.8174 12 36 12C39.1826 12 42.2348 13.2643 44.4853 15.5147C46.7357 17.7652 48 20.8174 48 24ZM27.5209 38.4028H30.6996L31.6979 35.2074C32.2904 33.3189 33.9706 32 35.9989 32H36.001C38.0289 32 39.7101 33.319 40.3014 35.2091L41.3009 38.4028H44.479C46.506 38.4028 48.189 39.7206 48.7805 41.6118C49.3686 43.4922 48.7541 45.5262 47.1632 46.7279L44.5265 48.7189L45.0578 50.4187L45.1652 50.7652L45.1673 50.772L45.5441 51.973C46.1296 53.8509 45.5187 55.8846 43.9265 57.0872L43.9256 57.0879C43.1388 57.6816 42.2024 58 41.24 58C40.2827 58 39.345 57.6844 38.5558 57.0866L38.5533 57.0847L35.9999 55.1584L33.4455 57.0863C32.6554 57.6842 31.7175 58 30.7578 58C29.7939 58 28.8609 57.68 28.076 57.0892L28.0724 57.0865C26.4837 55.885 25.8696 53.8524 26.4569 51.9726L26.4573 51.9713L26.8348 50.7617L26.9446 50.4139L26.9464 50.4083L27.4744 48.7189L24.8378 46.7279C23.2455 45.5262 22.6313 43.4922 23.2194 41.6118C23.811 39.7201 25.4954 38.4028 27.5209 38.4028Z"
                fill="#6A6B70"
                fillRule="evenodd"
            />
            <path
                clipRule="evenodd"
                d="M46.8717 42.2088C46.5286 41.1116 45.5896 40.4028 44.4791 40.4028H40.1611C39.9643 40.4028 39.7906 40.2715 39.7295 40.0779L38.3927 35.8061C38.0495 34.7089 37.1116 34 36.001 34H35.9989C34.8884 34 33.9505 34.7089 33.6063 35.8061L32.8147 38.3418L32.2705 40.0779C32.2084 40.2715 32.0357 40.4028 31.8399 40.4028H27.5209C26.4114 40.4028 25.4714 41.1116 25.1283 42.2088C24.7851 43.3061 25.1441 44.4536 26.043 45.1318L29.6315 47.8415C29.7336 47.9191 29.7757 48.0537 29.7389 48.1784L28.8536 51.0106L28.742 51.3639L28.3662 52.5683C28.023 53.6656 28.382 54.8131 29.2788 55.4913C29.7294 55.8304 30.2431 56 30.7578 56C31.2736 56 31.7894 55.8315 32.2389 55.4913L34.8305 53.5354C35.18 53.2706 35.5895 53.1394 36 53.1383C36.4105 53.1394 36.82 53.2706 37.1695 53.5354L39.7621 55.4913C40.2106 55.8315 40.7264 56 41.2401 56C41.7559 56 42.2717 55.8304 42.7211 55.4913C43.619 54.8131 43.9769 53.6656 43.6348 52.5683L43.2569 51.3639L43.1475 51.0106L42.2622 48.1784C42.2253 48.0537 42.2664 47.9191 42.3696 47.8415L45.958 45.1318C46.8559 44.4536 47.2149 43.3061 46.8717 42.2088Z"
                fill="url(#paint0_linear_2155_5990)"
                fillRule="evenodd"
            />
            <defs>
                <linearGradient gradientUnits="userSpaceOnUse" id="paint0_linear_2155_5990" x1="36" x2="36" y1="29.0257" y2="52.886">
                    <stop stopColor="#1D35EE" />
                    <stop offset="1" stopColor="#1024A3" />
                </linearGradient>
            </defs>
        </svg>
    );
}
