import { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

const variants = {
  primary: 'rounded-full bg-blue px-6 py-3 font-bold hover:bg-dark-blue disabled:bg-gray disabled:text-light-gray',
  secondary:
    'rounded-full border-2 border-blue px-6 py-3 font-bold hover:border-dark-blue disabled:border-gray disabled:text-light-gray',
  warning: `rounded-full py-3 px-6 bg-transparent border-2 border-red hover:border-red text-white`,
};

export type ButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  children: ReactNode;
  variant?: keyof typeof variants;
  href?: string;
};

export function Button(props: ButtonProps) {
  const { children, href, variant = 'primary', className, ...other } = props;

  return href ? (
    <NavLink to={href}>
      <button
        {...other}
        className={clsx(variants[variant], `flex items-center justify-center text-white transition-all`, className)}
      >
        {children}
      </button>
    </NavLink>
  ) : (
    <button
      {...other}
      className={clsx(variants[variant], `flex items-center justify-center text-white transition-all`, className)}
    >
      {children}
    </button>
  );
}
