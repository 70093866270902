import * as SvgIcons from './Svg';

export type IconName = keyof typeof SvgIcons;

type Props = {
  className?: string;
  name: IconName;
  hover?: boolean;
  onClick?: () => void;
};

export function Icon({ className, name, hover, onClick }: Props) {
  const Component = SvgIcons[name];
  return <Component className={`w-auto ${className} ${hover ? 'hover:opacity-80' : ''}`} onClick={onClick} />;
}
