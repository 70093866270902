import * as yup from 'yup';
import { passwordYup } from '@/utils/schema.utils';

const fullNameRegEx = /^([a-zA-ZÀ-ÖØ-öø-ÿ]|\d){2,}\s([a-zA-ZÀ-ÖØ-öø-ÿ]|\d){2,}$/;

export const schema = yup
  .object({
    fullName: yup.string().matches(fullNameRegEx, 'Full name is not correct').required('Field is required'),
    email: yup.string().email('Email is incorrect').required('Field is required'),
    birthYear: yup
      .number()
      .transform((value) => (value ? parseInt(value, 10) : undefined))
      .min(1940, 'Must be in yyyy format')
      .max(new Date().getFullYear() - 14, 'Must be at least 14 years old to create an account')
      .required('Field is required'),
    password: passwordYup,
    passwordConfirmation: yup.string().oneOf([yup.ref('password')], 'Password must match'),
    gender: yup.string().required('Field is required'),
    promoCode: yup.string().max(25, 'Invalid Promo Code').trim(),
  })
  .required();

export type RegistrationFormData = yup.InferType<typeof schema>;
