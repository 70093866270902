import * as yup from 'yup';
import { passwordYup } from '@/utils/schema.utils';

export const schema = yup
  .object({
    currentPassword: yup.string().required('Field is required'),
    newPassword: passwordYup,
    newPasswordConfirmation: yup.string().oneOf([yup.ref('newPassword')], 'Passwords must match'),
  })
  .required();

export type ChangePasswordFormData = yup.InferType<typeof schema>;
