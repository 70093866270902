import { Icon } from '../Icon';
import { useMenuItems } from './UserMenu.hooks';
import { appRoutes } from '@/helpers/routes.helper';
import { NavLink } from 'react-router-dom';
import { useLogOut } from '@/hooks/useLogout';
import { useMe } from '@/hooks/useMe';
import { useZendesk } from '@/hooks/useZendesk';
import { appSessionStorage } from '@/helpers/storage.helper';
import { useRestartWelcomeTour } from '@/hooks/useRestartWelcomeTour';

interface UserMenuPopupProps {
  className?: string;
  onClose: () => void;
}

export function UserMenuPopup({ className, onClose }: UserMenuPopupProps) {
  const me = useMe();
  const menuItems = useMenuItems(!!me);

  const handleLogOut = useLogOut();

  const restartWelcomeTour = useRestartWelcomeTour({ onClose });

  // Zendesk does not work for UWP
  const platform = appSessionStorage.getItem('platform');
  const disableHelpCenter = platform === 'Vuplex';

  const showHelper = useZendesk(disableHelpCenter);

  return (
    <>
      <div
        className="bg-secondary-600 absolute bottom-0 left-0 right-0 top-0 z-10 w-screen opacity-20"
        onClick={onClose}
      />
      <div className={`${className} w-72 rounded-2xl bg-darker-gray py-4`}>
        {!!me && (
          <>
            <div className="flex w-full px-7 pt-4">
              <Icon name="Avatar" className="mr-4 h-12" />
              <div>
                <div className="text-base text-light-gray">Welcome back</div>
                <div className="text-lg font-medium text-white">{me.fullName}</div>
              </div>
            </div>
            <div className="mx-4 mt-5 h-[1px] bg-dark-gray" />
          </>
        )}
        <div className="flex-1 overflow-y-auto">
          {menuItems.map((item) => (
            <NavLink
              to={item.route}
              key={item.label}
              onClick={onClose}
              className="flex cursor-pointer items-center px-7 py-4 hover:bg-blue hover:opacity-100"
            >
              <Icon name={item.icon} className="mr-5 h-6 pb-[2px] text-white" />
              <div className="text-sm font-medium text-white">{item.label}</div>
            </NavLink>
          ))}
          {!!me ? (
            <div className="flex cursor-pointer items-center px-7  py-4 hover:bg-blue" onClick={restartWelcomeTour}>
              <Icon name="Fire" className="mr-5 h-6 pb-[2px]" />
              <div className="text-sm font-medium text-white">Restart Tutorial</div>
            </div>
          ) : null}
        </div>
        <div>
          <div className="mx-4 h-[1px] bg-dark-gray" />
          {!disableHelpCenter && (
            <div className="flex cursor-pointer items-center px-7 py-4 hover:bg-blue" onClick={showHelper}>
              <Icon name="HelpCenter" className="mr-5 h-6 pb-[2px]" />
              <div className="cursor-pointer text-sm font-medium text-white">Help Center</div>
            </div>
          )}
          {!!me ? (
            <div className="flex cursor-pointer items-center px-7  py-4 hover:bg-blue" onClick={handleLogOut}>
              <Icon name="Logout" className="mr-5 h-6 pb-[2px]" />
              <div className="cursor-pointer text-sm font-medium text-white">Log Out</div>
            </div>
          ) : (
            <NavLink className="flex items-center px-7 py-4  hover:bg-blue hover:opacity-100" to={appRoutes.login}>
              <Icon name="Logout" className="mr-5 h-6 pb-[2px] text-white" />
              <div className="text-sm font-medium text-white">Log In</div>
            </NavLink>
          )}
          <div className="flex items-center px-7 py-4">
            <div className="text-sm font-medium text-light-gray">App Version 1.09</div>
          </div>
        </div>
      </div>
    </>
  );
}
