type SpinnerProps = {
  className?: string;
  spinnerClassName?: string;
};

export function Spinner({ className = '', spinnerClassName = '' }: SpinnerProps) {
  return (
    <div className={`flex items-center justify-center ${className}`}>
      <span className={`spinner border-3  ${spinnerClassName}`}></span>
    </div>
  );
}
