import { useMutation, useQueryClient } from '@tanstack/react-query';
import { fetcherGraphql, fetcherRest } from '@/helpers/fetch.helper';
import { Credentials, Tokens } from '@/types/UserTypes';
import { toast } from 'react-toastify';
import { getErrorMessage } from '@/utils/error.utils';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '@/helpers/routes.helper';
import { appLocalStorage, appSessionStorage } from '@/helpers/storage.helper';
import { MyCartQuery, useMeQuery, useMyCartQuery } from '@/graphql/generated';
import { freshToken } from '@/helpers/freshToken.helper';
import { cartStore } from '../store/cart.store';
import { useState } from 'react';
import { sendMessage } from '@/helpers/sdk-wrapper';

export function useLogin() {
  const navigate = useNavigate();
  const client = useQueryClient();
  const [needResend, setNeedResend] = useState(false);

  const { mutateAsync } = useMutation({
    mutationFn: async (data: Credentials) =>
      fetcherRest<Tokens>(
        { url: '/auth/login', method: 'POST' },
        {
          ...data,
          gameId: appSessionStorage.getItem('gameId'),
          gameUserId: appSessionStorage.getItem('gameUserId'),
        },
      ),
    onSuccess: async ({ token, refreshToken }) => {
      freshToken.setTokens({ token, refreshToken });
      appLocalStorage.setItem('refreshToken', refreshToken);
      sendMessage({ message: 'USER_TOKEN', payload: refreshToken });

      try {
        await client.fetchQuery({
          queryFn: fetcherGraphql(useMeQuery.document),
          queryKey: useMeQuery.getKey(),
        });
        const data = await client.fetchQuery<MyCartQuery>({
          queryFn: fetcherGraphql(useMyCartQuery.document),
          queryKey: useMyCartQuery.getKey(),
        });
        cartStore.actions.setCart(data.myCart.products);
        navigate(appRoutes.store);
      } catch (error) {
        toast.error(getErrorMessage(error), { autoClose: 8000 });
      }
    },
    onError: (error) => {
      if (getErrorMessage(error) === 'email is not verified') {
        setNeedResend(true);
      } else {
        setNeedResend(false);
      }
      toast.error(getErrorMessage(error), { autoClose: 8000 });
    },
  });

  return { needResend, handleLogin: mutateAsync };
}
