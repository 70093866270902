import { BannerOfferFragment, CampaignType, FilterInput, Filter_Type, ProductOfferFragment } from '@/graphql/generated';
import { appRoutes } from '@/helpers/routes.helper';
import {
  HeaderBannerOfferType,
  OfferAdSize,
  OfferListItem,
  OfferListItemServerType,
  ProductOffer,
  productOfferDefaults,
  ProductOfferOptionKey,
  ProductVariantOptions,
} from '@/types/OfferTypes';
import { isAndroid, isIOS } from 'react-device-detect';
import { getAppStoreUrl, getPlayMarketUrl } from './platform.utils';

export const clientToServerCategory = (category: string) => {
  switch (category) {
    case `Today's Deals`:
      return 'Today Deals';
    case `Latest Products`:
      return 'Latest Deals';
    case `Miscellaneous`:
      return 'Misc Deals';
  }
  return category;
};

export const serverToClientToCategory = (category: string) => {
  switch (category) {
    case 'Today Deals':
      return `Today's Deals`;
    case 'Latest Deals':
      return `Latest Products`;
    case 'Misc Deals':
      return `Miscellaneous`;
  }
  return category;
};

export const getOrderedServerCategories = (categories: string[]): string[] => [
  'Today Deals',
  'Latest Deals',
  ...categories.filter((c) => ['Today Deals', 'Latest Deals', 'Misc Deals'].indexOf(c) < 0),
  'Misc Deals',
];

export const mapClientCategoryToFilter = (category: string): FilterInput => {
  if (category === `Today's Deals`) {
    return { name: 'isTodayDeal', operator: Filter_Type.Eq, value: ['true'] };
  }
  if (category === `Latest Products`) {
    return { name: 'isLatest', operator: Filter_Type.Eq, value: ['true'] };
  }
  if (category === `Miscellaneous`) {
    return { name: 'isMisc', operator: Filter_Type.Eq, value: ['true'] };
  }
  return { name: 'category', operator: Filter_Type.Eq, value: [category] };
};

const getOfferItemImage = (offer: OfferListItemServerType): string | undefined => {
  const mainImage = offer.media?.image;
  switch (offer.type) {
    case CampaignType.Column6Ad:
    case CampaignType.Video:
    case CampaignType.AppDownload:
    case CampaignType.Product: {
      const { vertical, tile } = offer.media || { vertical: undefined, tile: undefined };
      return vertical || tile || mainImage;
    }
  }
  return mainImage;
};

const getAdSize = (offer: OfferListItemServerType): OfferAdSize | undefined => {
  if ([CampaignType.ProductListing, CampaignType.Product, CampaignType.Column6Ad].some((t) => t === offer.type)) {
    const { vertical, tile } = offer.media || { vertical: undefined, tile: undefined };
    if (vertical) return '300x600';
    if (tile) return '300x250';
  }
};

const getOfferItemTitle = (offer: OfferListItemServerType): string => {
  const { videoUrl } = offer.media || { videoUrl: undefined };
  // TODO: add proper explanation
  const isVideo = videoUrl?.slice(0, 4) === 'SCT:';
  if (isVideo) return offer.name;

  const result = offer.type === CampaignType.ProductListing ? offer.product?.name || '' : offer.name;
  return result ? result.replace(/\\&/g, '') : result;
};

export const mapOfferItem = (offer: OfferListItemServerType): OfferListItem => {
  return {
    id: offer.id,
    type: offer.type as CampaignType,
    image: getOfferItemImage(offer),
    adSize: getAdSize(offer),
    title: getOfferItemTitle(offer),
    price: offer.product?.price ?? 0,
    scutisExclusive: offer.product?.scutisExclusive || false,
    scutiRewards: offer.product?.rewards.scutis || 0,
    shopThumbnail: offer.shop?.thumbnail || undefined,
    isCampaignOffer: offer.media?.videoUrl?.slice(0, 4) === 'SCT:',
    androidId: offer.androidId,
    appleId: offer.appleId,
    pcLink: offer.pcLink,
  };
};

export const getBannerLink = (banner: HeaderBannerOfferType) => {
  if (banner.videoUrl) {
    return appRoutes.campaignOffer(banner.id);
  } else if (banner.productId) {
    return appRoutes.productOffer(banner.id);
  }
};

export const getOfferLink = (offer: OfferListItem) => {
  const { id, type, androidId, appleId, pcLink } = offer;

  switch (type) {
    case CampaignType.Product:
    case CampaignType.ProductListing:
      if (offer.isCampaignOffer) {
        return appRoutes.campaignOffer(id);
      } else {
        return appRoutes.productOffer(id);
      }
    case CampaignType.Video:
      return appRoutes.videoOffer(id);
    case CampaignType.AppDownload:
      if (isAndroid && androidId) {
        return getPlayMarketUrl(androidId);
      } else if (isIOS && appleId) {
        return getAppStoreUrl(appleId);
      } else if (pcLink) {
        return pcLink;
      }
      break;
  }

  return '#';
};

export type SelectedOptions = { optionKey: ProductOfferOptionKey; options: ProductVariantOptions };

export const getDefaultVariantOptions = (
  offer: ProductOfferFragment,
  selected?: SelectedOptions,
): ProductVariantOptions => {
  const product = offer?.product;

  if (!product) return productOfferDefaults;

  if (selected) {
    if (selected.optionKey === 'option1') {
      const { option1, option2, option3 } = product.variants.find(
        ({ option1 }) => option1 === selected.options.option1,
      )!;
      return { option1, option2, option3 };
    }
    if (selected.optionKey === 'option2') {
      const { option1, option2, option3 } = product.variants.find(
        ({ option1, option2 }) => option1 === selected.options.option1 && option2 === selected.options.option2,
      )!;
      return { option1, option2, option3 };
    }
    if (selected.optionKey === 'option3') {
      const { option1, option2, option3 } = product.variants.find(
        ({ option1, option2, option3 }) =>
          option1 === selected.options.option1 &&
          option2 === selected.options.option2 &&
          option3 === selected.options.option3,
      )!;
      return { option1, option2, option3 };
    }
  }

  const defaultOptions = product.variants.find((variant) => !!variant.inStock) || {
    option1: '',
    option2: '',
    option3: '',
  };

  // current variant
  const { option1, option2, option3 } = product.variants.find(
    ({ option1, option2, option3 }) =>
      option1 === defaultOptions.option1 && option2 === defaultOptions.option2 && option3 === defaultOptions.option3,
  )!;

  return { option1, option2, option3 };
};

export const mapProductOffer = (offer: ProductOfferFragment, selected: ProductVariantOptions): ProductOffer => {
  const product = offer?.product;

  if (!product) return productOfferDefaults;

  // current variant
  const variant = product.variants.find(
    ({ option1, option2, option3 }) =>
      option1 === selected.option1 && option2 === selected.option2 && option3 === selected.option3,
  )!;

  const { image, inStock } = variant;

  const productImages = product.images || [];
  const updatedImages = Array.from(new Set(image ? [image, ...productImages] : productImages));
  const images =
    updatedImages.length > 0
      ? updatedImages
      : Array.from(new Set(image ? [image, ...updatedImages.filter((i) => i !== image)] : updatedImages));

  return {
    id: offer.id,
    productId: variant.id,
    name: product.name,
    description: product.description,
    brand: offer.brand,
    shop: {
      name: offer.shop?.name,
      thumbnail: offer.shop?.thumbnail,
    },
    price: {
      current: variant.price.amount,
      old: variant.compareAt?.amount,
    },
    option1: variant.option1,
    option2: variant.option2,
    option3: variant.option3,
    optionNames: {
      option1: product.option1,
      option2: product.option2,
      option3: product.option3,
    },
    images,
    reward: (variant.price.amount * (offer.reward.scutiPercentage || 0)) / 100,
    inStock,
    scutisExclusive: product.scutisExclusive || false,
  };
};

type ProductOption = {
  name?: string | null;
  inStock?: number | null;
};

export type ProductVariantOptionsMatrix = {
  option1: ProductOption[];
  option2: ProductOption[];
  option3: ProductOption[];
};

const removeDuplicates = (acc: ProductOption[], next: ProductOption) => {
  return !acc.find((e) => e.name === next.name) ? [...acc, next] : acc;
};

export const mapOfferToOptionsMatrix = (
  productOffer: ProductOfferFragment,
  selected: ProductVariantOptions,
): ProductVariantOptionsMatrix => {
  if (!productOffer.product) {
    return {
      option1: [],
      option2: [],
      option3: [],
    };
  }

  const option1 = productOffer.product.variants
    .map((v) => ({ name: v.option1, inStock: v.inStock }))
    .reduce<ProductOption[]>(removeDuplicates, []);

  const option2 = productOffer.product.variants
    .filter(({ option1 }) => !option1 || option1 === selected.option1)
    .map((v) => ({ name: v.option2!, inStock: v.inStock }))
    .reduce<ProductOption[]>(removeDuplicates, []);

  const option3 = productOffer.product.variants
    .filter(
      ({ option1, option2 }) =>
        (!option1 || option1 === selected.option1) && (!option2 || option2 === selected.option2),
    )
    .map((v) => ({ name: v.option3!, inStock: v.inStock }))
    .reduce<ProductOption[]>(removeDuplicates, []);

  return {
    option1,
    option2,
    option3,
  };
};

export const mapBannerOfferToHeaderBanner = (bannerOffer: BannerOfferFragment): HeaderBannerOfferType => {
  return {
    id: bannerOffer.id,
    productId: bannerOffer.product?.id,
    imageUrl: bannerOffer.media.banner?.bigUrl,
    videoUrl: bannerOffer.media.videoUrl,
  };
};
