import { useStoreInfo } from '@/hooks/useStoreInfo';
import { useMyWalletQuery } from '@/graphql/generated';
import { useState } from 'react';
import { cartStore } from '@/store/cart.store';
import { checkoutStore } from '@/store/checkout.store';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '@/helpers/routes.helper';

type Return = {
  totalOrder: number;
  payWithCurrency: number;
  usedScutis: number;
  scutiBalance: number;
  maxToUse: number;
  exchangeRate: number;
  transactionStatus?: 'inProgress' | 'Success';
  onChange: (value: number) => void;
  onPayWithScutis: () => Promise<void>;
};

export function usePaymentWithScutis(): Return {
  const navigate = useNavigate();
  const { platform } = useStoreInfo();
  const { data } = useMyWalletQuery(
    {},
    { refetchOnWindowFocus: 'always', refetchOnMount: 'always', staleTime: 0, cacheTime: 0 },
  );
  const { cartSubtotal } = cartStore.state;

  const scutiExchangeRate = platform.scutiExchangeRate ?? 1;
  const [transactionStatus, setTransactionStatus] = useState<'inProgress' | 'Success'>();

  const [usedScutis, setUsedScutis] = useState(0);

  const scutiBalance = (data?.myWallet.promotional || 0) + (data?.myWallet.purchase || 0);
  const maxToUse = Math.min(Math.floor(cartSubtotal * scutiExchangeRate), scutiBalance);
  const payWithScutis = usedScutis / scutiExchangeRate;

  return {
    totalOrder: cartSubtotal,
    payWithCurrency: cartSubtotal - payWithScutis,
    scutiBalance,
    usedScutis,
    maxToUse,
    transactionStatus,
    exchangeRate: scutiExchangeRate,
    onChange: setUsedScutis,
    onPayWithScutis: async () => {
      setTransactionStatus('inProgress');
      setTimeout(() => {
        checkoutStore.actions.setState({ scutisUsed: usedScutis });
        setTransactionStatus('Success');
        navigate(appRoutes.checkout);
      }, 2000);
    },
  };
}
