export function debounce<T extends unknown[]>(func: (...args: T) => void, timeout = 300) {
  let timerId: ReturnType<typeof setTimeout>;

  return (...args: T) => {
    clearTimeout(timerId);

    timerId = setTimeout(() => func(...args), timeout);
  };
}

export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
