import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RegistrationFormData, schema } from './RegistrationForm.schema';
import { Button } from '@/components/Common/Button';
import { SelectInput } from '@/components/Common/SelectInput';
import { genderOptions, getOptionByValue } from '@/utils/select-options.utils';
import { Input } from '@/components/Common/Input';
import { MaskedInput } from '@/components/Common/MaskedInput';
import { useRegistration } from './RegistrationForm.hooks';
import { Credentials } from '@/types/UserTypes';

export type RegistrationFormProps = {
  className?: string;
  onRegistration: (params: Credentials) => void;
};

export function RegistrationForm({ className, onRegistration }: RegistrationFormProps) {
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<RegistrationFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      fullName: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      gender: undefined,
      promoCode: '',
    },
    mode: 'all',
  });

  const handleRegistration = useRegistration();

  const onSubmit = async (data: RegistrationFormData) => {
    await handleRegistration(data);
    const { email, password } = data;
    onRegistration({ email, password });
  };

  return (
    <form noValidate className={className} onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col space-y-4">
        <Controller
          control={control}
          name="fullName"
          render={({ field }) => (
            <Input
              label="Full Name"
              placeholder="Your full name"
              required
              {...field}
              error={errors.fullName?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <Input
              error={errors.email?.message}
              label="Email"
              placeholder="Your Email"
              required
              type="email"
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="gender"
          render={({ field }) => (
            <SelectInput
              label="Gender"
              required
              options={genderOptions}
              placeholder="Select a Gender"
              tooltip="This information helps us curate product for you"
              value={getOptionByValue(field.value, genderOptions)}
              onChange={field.onChange}
              error={errors.gender?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="birthYear"
          render={({ field }) => (
            <MaskedInput
              label="Year of Birth"
              required
              placeholder="YYYY"
              value={`${field.value || ''}`}
              onChange={field.onChange}
              error={errors.birthYear?.message}
              options={{ date: true, datePattern: ['Y'] }}
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          render={({ field }) => (
            <Input
              error={errors.password?.message}
              label="Password"
              placeholder="Your Password"
              required
              type="password"
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="passwordConfirmation"
          render={({ field }) => (
            <Input
              error={errors.passwordConfirmation?.message}
              label="Confirm Password"
              placeholder="Confirm Your Password"
              required
              type="password"
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="promoCode"
          render={({ field }) => (
            <MaskedInput
              error={errors.promoCode?.message}
              label="Promo Code"
              placeholder="Enter Promo Code"
              type="text"
              {...field}
              options={{ uppercase: true }}
            />
          )}
        />
      </div>
      <Button disabled={!isValid} type="submit" className="mt-12 w-full">
        Create Account
      </Button>
    </form>
  );
}
