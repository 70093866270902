type Props = {
    className?: string;
    onClick?: () => void;
};

export function Check(props: Props) {
    return (
        <svg {...props} fill="none" height="10" viewBox="0 0 14 10" width="14" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 5.73322L4.90278 8.55675L11.6436 2" stroke="white" strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" />
        </svg>
    );
}
