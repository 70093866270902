type Props = {
    className?: string;
    onClick?: () => void;
};

export function Linkedin(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                clipRule="evenodd"
                d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12ZM6.15 9.975V18H8.7V9.975H6.15ZM6 7.425C6 8.25 6.6 8.85 7.425 8.85C8.25 8.85 8.85 8.25 8.85 7.425C8.85 6.6 8.25 6 7.425 6C6.675 6 6 6.6 6 7.425ZM15.45 18H17.85V13.05C17.85 10.575 16.35 9.75 14.925 9.75C13.65 9.75 12.75 10.575 12.525 11.1V9.975H10.125V18H12.675V13.725C12.675 12.6 13.425 12 14.175 12C14.925 12 15.45 12.375 15.45 13.65V18Z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </svg>
    );
}
