import { getDevice, getPlatform } from '@/utils/platform.utils';
import { freshToken } from './freshToken.helper';
import { clearSession } from '@/utils/session.utils';
import { appSessionStorage } from './storage.helper';

const getHeaders = async (): Promise<Record<string, string>> => {
  let headers: Record<string, string> = {
    'Content-Type': 'application/json',
    'x-platform': getPlatform(),
    'x-device': getDevice(),
  };

  const token = await freshToken.getToken();
  const gameId = appSessionStorage.getItem('gameId');
  const sessionId = appSessionStorage.getItem('sessionId');

  if (token) headers['authorization'] = `Bearer ${token}`;
  if (gameId) headers['x-game-id'] = gameId;
  if (sessionId) headers['x-session-id'] = sessionId;

  return headers;
};

export function fetcherGraphql<TData, TVariables>(query: string, variables?: TVariables) {
  return async (): Promise<TData> => {
    const headers = await getHeaders();

    const res = await fetch(`${process.env.REACT_APP_API_URL}/graphql`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ query, variables }),
    });

    const json = await res.json();

    if (json.errors) {
      const { message, extensions } = json.errors[0];
      if (extensions.code === 'FORBIDDEN') {
        clearSession();
      }

      throw new Error(message);
    }

    return json.data;
  };
}

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';
type Params = { url: string; method?: HttpMethod };

export async function fetcherRest<TData = undefined>({ url, method = 'GET' }: Params, body?: any): Promise<TData> {
  const headers = await getHeaders();

  const res = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
    method,
    headers,
    body: body && JSON.stringify(body),
  });

  if (!res.headers.get('content-type')?.includes('application/json')) return undefined as TData;

  if (!res.ok) {
    const error = await res.json();
    throw error;
  }

  return res.json();
}
