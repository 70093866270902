export const appRoutes = {
  welcome: '/welcome',
  login: '/login',
  registration: '/register',
  forgotPassword: '/forgotPassword',
  store: '/',
  category: (category: string = ':category') => `/category/${category}`,
  productOffer: (id: string = ':id') => `/product-offer/${id}`,
  videoOffer: (id: string = ':id') => `/video-offer/${id}`,
  campaignOffer: (id: string = ':id') => `/campaign-offer/${id}`,
  appDownload: (id: string = ':id') => `/app-download/${id}`,
  cart: '/cart',
  checkout: '/checkout',
  checkoutAddress: '/checkout/address',
  payment: '/payment',
  paymentWithScutis: '/payment/with-scutis',
  paymentComplete: '/payment/complete',
  about: '/about',
  // Legal
  legal: '/legal',
  legalTermsAndConditions: `/legal/terms-and-conditions`,
  legalPrivacyPolicy: '/legal/privacy-policy',
  // AccountSettings
  accountSettings: '/account-settings',
  accountSettingsNameAndAge: '/account-settings/name-and-age',
  accountSettingsPassword: '/account-settings/password',
  accountSettingsAddress: '/account-settings/address',
  accountSettingsPreferences: '/account-settings/preferences',

  rewards: '/rewards',
  wallet: '/wallet',
  transactions: '/transactions',

  orderHistory: (type?: 'active' | 'past') => `/order-history/${type || ':type'}`,
  orderDetails: (id: string = ':id') => `/order-details/${id}`,
};
