import { QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider, RouterProviderProps } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Icon } from './components/Common/Icon';
import { queryClient } from './helpers/query.helper';
import { useOpenStoreMetric } from './helpers/metrics/useOpenStoreMetric';
import { useEndSessionMetric } from './helpers/metrics/useEndSessionMetric';

export function App({ routeProviderProps }: { routeProviderProps: RouterProviderProps }) {
  useOpenStoreMetric();
  useEndSessionMetric();

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={routeProviderProps.router} />
      <ToastContainer limit={1} icon={<Icon name="Information" />} autoClose={2000} position={'bottom-right'} />
    </QueryClientProvider>
  );
}
