import { OrderItem } from '@/types/OrderTypes';
import { getLowerDecimal, getUpperDecimal } from '@/utils/number.utils';
import { Button } from './Button';
import { appRoutes } from '@/helpers/routes.helper';
import { useStoreInfo } from '@/hooks/useStoreInfo';

type OrderCardProps = {
  className?: string;
  orderItem: OrderItem;
  hideDetails?: boolean;
};

export function OrderCard({ className, orderItem, hideDetails }: OrderCardProps) {
  const image = orderItem.product.variant.image || orderItem.product.defaultImage;
  const productName = orderItem.product.name;
  const scutisExclusive = orderItem.product.scutisExclusive;
  const unitPrice = orderItem.product.variant.price;
  const unitOldPrice = orderItem.product.variant.compareAt;
  const quantity = orderItem.quantity;

  const { platform } = useStoreInfo();

  const exchangeRate = platform.scutiExchangeRate || 1;

  return (
    <div className={`flex flex-row space-x-6 bg-darker-gray px-4 py-8 md:rounded-2xl md:py-4 ${className}`}>
      <div className="h-full w-28">{image && <img alt="" className="rounded-lg bg-white" src={image} />}</div>
      <div className="flex w-full flex-col">
        <div className="flex w-full flex-row items-center justify-between ">
          <p className="text-white">{productName}</p>
        </div>
        <div className="mt-2">
          <h2 className="font-semibold">
            {scutisExclusive ? (
              `${unitPrice.amount * quantity * exchangeRate} Scuti$`
            ) : (
              <>
                ${getUpperDecimal(unitPrice.amount)}
                {unitOldPrice && <sup>{getLowerDecimal(unitPrice.amount)}</sup>}
              </>
            )}
          </h2>
        </div>
        <div className="flex flex-col md:flex-row md:justify-between">
          <div className="mt-4 flex flex-row flex-wrap space-x-3 md:mt-0 md:justify-between">
            <div className="flex flex-col items-start">
              <p className="text-white">Quantity: {quantity}</p>
              <p className="text-white">Order Number: {orderItem.id.slice(0, 8)}</p>
              <p className="text-sm text-light-gray">Purchased on {new Date(orderItem.createdAt).toDateString()}</p>
            </div>
          </div>
          <div className="flex flex-row items-end md:flex-row-reverse">
            {!hideDetails && (
              <Button
                href={appRoutes.orderDetails(orderItem.id)}
                className={`flex items-center justify-center rounded-full bg-dark-gray  px-6 py-2 text-white transition-all hover:bg-darker-gray  disabled:border-gray disabled:text-light-gray`}
              >
                Order Details
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
