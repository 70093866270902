import { DisclosureNoBorders } from '@/components/Common/Disclosure';

export function TermAndConditions() {
  return (
    <div className="flex h-full flex-col">
      <h2 className="mb-1 font-semibold">Terms & Conditions</h2>
      <p>Last updated: January 01, 2020.</p>

      <p className="mt-4 text-white">
        Please review these Terms of Use carefully. They include a binding arbitration clause (see Section 20) requiring
        you and us to arbitrate our claims instead of suing in court.
      </p>
      <ul className="grow-1 mb-8 mt-2 overflow-y-auto pr-4">
        <li>
          <DisclosureNoBorders title="1. Introduction">
            <div>
              <p className="text-white">
                Welcome to the family of websites and applications provided by Scuti™. These Terms of Use govern your
                access to and use of all Scuti™ Sites. By using the Scuti™ Sites, you affirm that you are of legal age
                to enter into these Terms of Use, or, if you are not, that you have obtained parental or guardian
                consent to enter into these Terms of Use. If you violate or do not agree to these Terms of Use, then
                your access to and use of the Scuti™ Sites is unauthorized. Additional terms and conditions apply to
                some services offered on the Scuti™ Sites (e.g., Scuti™ Rewards). Those terms and conditions can be
                found where the relevant service is offered on the Scuti™ Sites and are incorporated into these Terms of
                Use by reference.
              </p>
              <p className="mt-4 text-white">
                <b className="text-white">DEFINED TERMS: In these Terms of Use:</b>
              </p>

              <ul className="text-white">
                <li>
                  <p className="text-white">
                    When we say “Scuti™,” we mean Scuti™, LLC and gStore, LLC. We also refer to Scuti™ as “we,” “us” and
                    “our.” But when we say “Scuti™ Entities,” we mean Scuti™ and its affiliates; its and their
                    suppliers, vendors, contractors, and licensors; and its and their directors, officers, employees,
                    and agents.
                  </p>
                </li>
                <li>
                  <p className="text-white">
                    When we say “Scuti™ Sites,” we mean Scuti.store, the Scuti™ Apps and Scuti™ API, and all related
                    functionality, services, and Content offered by or for Scuti™ on or through Scuti.store and the
                    Scuti™ Apps or the systems, servers, and networks used to make the Scuti™ Sites available.
                  </p>
                </li>
                <li>
                  <p className="text-white">
                    When we say “Scuti™ Apps,” we mean the official “Scuti™ App” for iPhone and Android, which can be
                    downloaded from the iTunes App Store or the Google Play Store.
                  </p>
                </li>
                <li>
                  <p className="text-white">
                    When we say “you” or “your” we mean any user (like you!) of any Scuti™ Site.
                  </p>
                </li>
                <li>
                  <p className="text-white">
                    When we say “Terms of Use,” we mean these Terms of Use and all other terms and policies posted by
                    Scuti™ on the Scuti™ Sites (and any updates by Scuti™ to these Terms of Use and those terms and
                    policies).
                  </p>
                </li>
                <li>
                  <p className="text-white">A few other key terms used in these Terms of Use:</p>
                </li>
                <li>
                  <p className="text-white">
                    When we say “Content,” we mean merchandise information, product descriptions, reviews, comments,
                    messages, reviews, communications, feedback, submissions, suggestions, questions, and other
                    information, data, content, and materials (including page headers, images, text, illustrations,
                    formats, logos, hashtags, designs, icons, photographs, software programs, music clips or downloads,
                    video clips, and written and other materials.)
                  </p>
                </li>

                <ul className="text-white">
                  <li>
                    <p className="text-white">
                      When we say “Ideas,” we mean ideas, concepts, feedback, and know-how that you make available in
                      connection with the Scuti™ Sites.
                    </p>
                  </li>
                  <li>
                    <p className="text-white">
                      When we say “make available,” we mean post, transit, publish, upload, distribute, transmit,
                      display, provide, or otherwise submit or make available (including through any part of the Scuti™
                      Sites administrated by third-party social media platforms (e.g., Facebook or Instagram) that allow
                      interaction with the Scuti™ Sites through the tools offered by such social media platforms).
                    </p>
                  </li>
                  <li>
                    <p className="text-white">
                      When we say “Materials,” we mean Content that Scuti™ Entities make available on or through the
                      Scuti™ Sites.
                    </p>
                  </li>
                </ul>
              </ul>

              <p className="mt-4 text-white">
                <b className="text-white">
                  IMPORTANT: THIS AGREEMENT CONTAINS A MANDATORY ARBITRATION PROVISION THAT, AS FURTHER SET FORTH IN
                  SECTION 20 BELOW, REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES. THIS
                  MEANS THAT YOU AND SCUTI™ ARE EACH GIVING UP OUR RIGHTS TO SUE EACH OTHER IN COURT OR IN CLASS ACTIONS
                  OF ANY KIND.
                </b>
              </p>

              <p className="text-white">
                <b className="text-white">WARRANTY DISCLAIMERS AND LIABILITY LIMITATIONS:</b> While there are important
                points throughout these Terms of Use, please note the warranty disclaimers and limitations on Scuti™’s
                liability explained in Sections 17 and 18, respectively.
              </p>

              <p className="mt-4 text-white">
                <b className="text-white">UPDATES:</b> We may update these Terms of Use from time to time by notifying
                you of such changes by any reasonable means, including by posting a revised Terms of Use through the
                Scuti™ Sites. Any such changes will not apply to any dispute between you and us arising prior to the
                date on which we posted the revised Terms of Use incorporating such changes or otherwise notified you of
                such changes. By continuing to use or access any of the Scuti™ Sites after we post any changes, you
                accept the updated Terms of Use. The “Last Updated” legend above indicates when these Terms of Use were
                last changed.
              </p>

              <hr className="mb-0 mt-4" />
              <hr className="mb-4 mt-0.5" />
            </div>
          </DisclosureNoBorders>
        </li>

        <li>
          <DisclosureNoBorders title="2. Your Use of the Scuti™ Sites">
            <div>
              <p className="text-white">
                You certify that the Content you provide on or through the Scuti™ Sites is accurate and that the
                information you provide on or through the Scuti™ Sites is complete. You are solely responsible for
                maintaining the confidentiality and security of your account including username, password, and PIN.
                Scuti™ is not responsible for any losses arising out of the unauthorized use of your account. You agree
                that Scuti™ does not have any responsibility if you lose or share access to your device. Any agreement
                between you and the issuer of your credit card, debit card, or other form of payment will continue to
                govern your use of such payment method on the Scuti™ Sites. You agree that Scuti™ is not a party to any
                such agreement, nor is Scuti™ responsible for the content, accuracy, or unavailability of any method
                used for payment. Your account may be restricted or terminated for any reason, at our sole discretion.
                Except as otherwise provided by law, at any time without notice to you, we may (1) change, restrict
                access to, suspend, or discontinue the Scuti™ Sites or any portion of the Scuti™ Sites, and (2) charge,
                modify, or waive any fees required to use any services, functionality or other content available through
                the Scuti™ Sites or any portion of the Scuti™ Sites.
              </p>

              <ul className="mt-4 text-white">
                <li>
                  <p className="text-white">In connection with the Scuti™ Sites, you will not:</p>
                </li>
                <li>
                  <p className="text-white">
                    Make available any Content through or in connection with the Scuti™ Sites that is or may be in
                    violation of the content guidelines set forth in Section 3.C (Prohibited Content) below.
                  </p>
                </li>

                <ul className="text-white">
                  <li>
                    <p className="text-white">
                      Make available through or in connection with the Scuti™ Sites any virus, worm, Trojan horse,
                      Easter egg, time bomb, spyware, or other computer code, file or program that is or is potentially
                      harmful or invasive or intended to damage or hijack the operation of, or to monitor the use of,
                      any hardware, software, or equipment.
                    </p>
                  </li>
                  <li>
                    <p className="text-white">
                      Use the Scuti™ Sites for any commercial purpose, or for any purpose that is fraudulent or
                      otherwise tortious or unlawful.
                    </p>
                  </li>
                  <li>
                    <p className="text-white">Harvest or collect information about users of the Scuti™ Sites.</p>
                  </li>
                  <li>
                    <p className="text-white">
                      Interfere with or disrupt the operation of the Scuti™ Sites or the systems, servers, or networks
                      used to make the Scuti™ Sites available, including by hacking or defacing any portion of the
                      Scuti™ Sites; or violate any requirement, procedure or policy of such servers or networks.
                    </p>
                  </li>
                  <li>
                    <p className="text-white">Restrict or inhibit any other person from using the Scuti™ Sites.</p>
                  </li>
                  <li>
                    <p className="text-white">
                      Reproduce, modify, adapt, translate, create derivative works of, sell, rent, lease, loan,
                      timeshare, distribute, or otherwise exploit any portion of (or any use of) the Scuti™ Sites except
                      as expressly authorized in these Terms of Use, without Scuti™’s express prior written consent.
                    </p>
                  </li>

                  <li>
                    <p className="text-white">
                      Reverse engineer, decompile, or disassemble any portion of the Scuti™ Sites, except where such
                      restriction is expressly prohibited by applicable law.
                    </p>
                  </li>

                  <li>
                    <p className="text-white">
                      Remove any copyright, trademark, or other proprietary rights notice from the Scuti™ Sites.
                    </p>
                  </li>
                  <li>
                    <p className="text-white">
                      Frame or mirror any portion of the Scuti™ Sites, or otherwise incorporate any portion of the
                      Scuti™ Sites into any product or service, unless you obtain Scuti™’s express prior written consent
                      to do so.
                    </p>
                  </li>
                  <li>
                    <p className="text-white">Systematically download and store any Materials.</p>
                  </li>

                  <li>
                    <p className="text-white">
                      Use any robot, spider, site search/retrieval application or other manual or automatic device to
                      retrieve, index, “scrape,” “data mine” or otherwise gather any Materials, or reproduce or
                      circumvent the navigational structure or presentation of the Scuti™ Sites, without Scuti™’s
                      express prior written consent.
                    </p>
                  </li>

                  <li>
                    <p className="text-white">Cause injury to any person or entity.</p>
                  </li>

                  <li>
                    <p className="text-white">Violate any law, rule, or regulation, or these Terms of Use.</p>
                  </li>
                </ul>

                <li>
                  <p className="text-white">
                    You will not use the Scuti™ Sites or Scuti™’s name, logo, or brand to (1) send any unsolicited or
                    unauthorized Content, including advertising, promotional materials, email, junk mail, spam, or other
                    form of solicitation; or (2) use any meta tags or other hidden text or metadata utilizing a Scuti™
                    trademark, logo, URL, or product name without Scuti™’s written consent;
                  </p>
                </li>

                <li>
                  <p className="text-white">
                    You will not attempt to do anything, or permit, encourage, assist, or allow any third party to do
                    anything, prohibited in this Section, or attempt, permit, encourage, assist, or allow any other
                    violation of these Terms of Use.
                  </p>
                </li>
              </ul>

              <hr className="mb-0 mt-4" />
              <hr className="mb-4 mt-0.5" />
            </div>
            )
          </DisclosureNoBorders>
        </li>
        <li>
          <DisclosureNoBorders title="3. Content and Ideas">
            <div>
              <p className="text-white">
                <b className="text-white">A. Submitting Content and Ideas</b>
              </p>
              <p className="text-white">
                Scuti™ provides functionality that enables users to make available Content and Ideas in connection with
                the Scuti™ Sites. Any text in Content should be written in English. You acknowledge and agree that you
                are responsible for all Content and Ideas you make available in connection with any Scuti™ Site. You
                represent and warrant that (1) you have the authority to grant the rights in such Content and Ideas as
                set forth in these Terms of Use, including in Section 3(B) below; and (2) such Content and Ideas, and
                the use of such Content and Ideas, will not violate any term of these Terms of Use. This means you will
                be responsible for the legality, the accuracy, the appropriateness, the originality, and your rights in
                any such Content and Ideas.
              </p>
              <p className="text-white">
                <b className="text-white">B. Scuti™’s Rights to Use Content and Ideas</b>
              </p>
              <p className="text-white">
                You grant to Scuti™ a royalty-free, perpetual, irrevocable, worldwide, unlimited, nonexclusive license
                to use, reproduce, create derivative works from, modify, publish, edit, translate, distribute, perform
                and display (publicly or otherwise) any Content that you make available, in any media or medium, and in
                any form, format, or forum now known or hereafter developed. You further agree that Scuti™ is free to
                use any Ideas for any purpose. Scuti™ may sublicense its rights in Content and Ideas through multiple
                tiers of sublicenses. Scuti™ is, and will be, under no obligation (1) to maintain any Content or Idea in
                confidence; (2) to pay any compensation for any Content or Idea; or (3) to respond to any Content or
                Idea. You grant to Scuti™ the right to use any name associated with any Content or Idea that you make
                available to Scuti™, although Scuti™ has no obligation to exercise such right, or to otherwise provide
                any attribution for any Content or Idea.
              </p>

              <p className="text-white">
                <b className="text-white">C. Prohibited Content</b>
              </p>
              <p className="text-white">
                You agree that you will not make available Content in connection with the Scuti™ Sites that:
              </p>

              <ul className="mt-4 text-white">
                <li>
                  <p className="text-white">is false, fraudulent, inaccurate, or misleading;</p>
                </li>
                <li>
                  <p className="text-white">
                    contains your full name(s), or any other confidential personally identifiable information of
                    yourself or others;
                  </p>
                </li>
                <li>
                  <p className="text-white">
                    violates any local, state, federal, or international laws or is otherwise tortious;
                  </p>
                </li>

                <li>
                  <p className="text-white">
                    is protected by or would infringe on the rights of others (including Scuti™), including any patent,
                    copyright, trademark, trade secret, right of publicity or privacy, or any other proprietary right,
                    without the express prior written consent of the applicable owner;
                  </p>
                </li>

                <li>
                  <p className="text-white">is obscene, indecent, pornographic, or otherwise objectionable;</p>
                </li>

                <li>
                  <p className="text-white">
                    is derogatory, defamatory, threatening, harassing, abusive, slanderous, hateful, or embarrassing to
                    any other person or entity as determined by Scuti™ in its sole discretion;
                  </p>
                </li>

                <li>
                  <p className="text-white">
                    victimizes, harasses, degrades, or intimidates an individual or group of individuals on the basis of
                    religion, gender, sexual orientation, race, ethnicity, age or disability;
                  </p>
                </li>

                <li>
                  <p className="text-white">
                    is violent or threatening, or promotes violence against, or actions that are threatening to, any
                    individual or group;
                  </p>
                </li>

                <li>
                  <p className="text-white">
                    contains advertisements, solicitations, or spam links to other web sites or individuals, without
                    prior written permission from Scuti™;
                  </p>
                </li>

                <li>
                  <p className="text-white">contains or relates to chain letters or pyramid schemes;</p>
                </li>

                <li>
                  <p className="text-white">
                    impersonates another business, person, or entity, including Scuti™, its related entities, employees,
                    and agents;
                  </p>
                </li>

                <li>
                  <p className="text-white">violates any policy posted on the Scuti™ Sites; or</p>
                </li>

                <li>
                  <p className="text-white">
                    is intended to cause harm, damage, disable, or otherwise interfere with the Scuti™ Sites or our
                    partners.
                  </p>
                </li>
              </ul>

              <hr className="mb-0 mt-4" />
              <hr className="mb-4 mt-0.5" />
            </div>
          </DisclosureNoBorders>
        </li>

        <li>
          <DisclosureNoBorders title="4. Monitoring by Scuti™">
            <div>
              <p className="text-white">
                Scuti™ will have the right (but not the obligation), in our sole discretion, to monitor, evaluate, and
                analyze Content, and any use of and access to the Scuti™ Sites, including to determine compliance with
                these Terms of Use and any other operating rules that may be established by Scuti™ from time to time.
                Scuti™ will also have the right (but not the obligation), in our sole discretion, to edit, move, delete,
                or refuse to make available any Content made available through, the Scuti™ Sites, for any reason,
                including violation of these Terms of Use, whether for legal or other reasons. Despite this right of
                ours, you are solely responsible for any Content you make available, and you agree to indemnify Scuti™
                for all claims resulting from any Content you make available.
              </p>
              <hr className="mb-0 mt-4" />
              <hr className="mb-4 mt-0.5" />
            </div>
          </DisclosureNoBorders>
        </li>

        <li>
          <DisclosureNoBorders title="5. Materials Available on the Scuti™ Sites">
            <div>
              <p className="text-white">
                Scuti™ and its suppliers and licensors may make available various Materials. The Materials are for
                educational and informational purposes only, and errors may appear from time to time. Before you act in
                reliance on any Materials, you should confirm any facts that are important to your decision. The Scuti™
                Entities make no warranty as to the reliability, accuracy, timeliness, usefulness, or completeness of
                any Materials. If you find an error or notice something that does not look quite right on the Scuti™
                Sites, we would appreciate it if you let us know by contacting us at http://help.Scuti/. (Your feedback
                is a big part of what helps Scuti™ to get better at helping you!)
              </p>
              <p className="mt-4 text-white">
                NO MATERIALS RELATED TO HEALTH, WELLNESS, PRESCRIPTIONS, OR PHARMACEUTICALS ARE INTENDED TO SUBSTITUTE
                FOR THE DIAGNOSIS, TREATMENT AND ADVICE OF A MEDICAL PROFESSIONAL, AND SUCH MATERIALS DO NOT COVER ALL
                POSSIBLE USES, PRECAUTIONS, SIDE EFFECTS, AND INTERACTIONS, AND SHOULD NOT BE CONSTRUED TO INDICATE THAT
                ANY DRUG IS SAFE OR EFFECTIVE FOR YOU. CONSULT THE PRODUCT INFORMATION (INCLUDING PACKAGE INSERTS)
                REGARDING DOSAGE, PRECAUTIONS, WARNINGS, AND INTERACTIONS, AND YOUR MEDICAL PROFESSIONAL, FOR GUIDANCE
                BEFORE USING ANY PRESCRIPTION OR OVER-THE-COUNTER DRUG.
              </p>

              <p className="mt-4 text-white">
                THE SCUTI™ ENTITIES ASSUME NO RESPONSIBILITY FOR ANY CONSEQUENCE RELATING DIRECTLY OR INDIRECTLY TO ANY
                ACTION OR INACTION YOU TAKE BASED ON THE MATERIALS.
              </p>

              <hr className="mb-0 mt-4" />
              <hr className="mb-4 mt-0.5" />
            </div>
          </DisclosureNoBorders>
        </li>

        <li>
          <DisclosureNoBorders title="6. Merchandise">
            <div>
              <p className="text-white">
                The Sites may make available listings, descriptions, and images of goods and services (collectively,
                “Products”). Such Products may be made available by us or by third parties. We make no representations
                as to the completeness, accuracy, reliability, validity, or timeliness of such listings, descriptions,
                or images (including any features, specifications, and prices contained on the Sites). Such information
                and the availability of any Product are subject to change at any time without notice.
              </p>
              <p className="mt-4 text-white">
                We have made efforts to accurately display the attributes of Products, including the applicable colors.
                However, as the actual colors you see will depend on your monitor, we cannot guarantee that your
                monitor&apos;s display of any color will accurately reflect actual product color or finish. In addition,
                certain weights, measures, and similar descriptions are approximate and are for convenience only.
              </p>

              <p className="mt-4 text-white">
                We sell Products for children’s use; however, these Products are intended for sale to adults.
              </p>

              <p className="mt-4 text-white">
                Scuti™ has no liability to you for content on the Scuti™ Sites that you find to be offensive, indecent,
                or objectionable. Certain videos, movies, TV programs, video games, computer games, and other Products
                are labeled with age restrictions or are intended for individuals of certain ages or “mature audiences”
                only. By ordering an age restricted item, you certify that you satisfy the age restrictions.
              </p>

              <p className="mt-4 text-white">
                Scuti™ is not responsible for, and cannot guarantee the performance of, goods and services provided by
                third parties, including any Scuti™ Entity, Marketplace Vendor (as defined below in Section 16.A), our
                advertisers, or other third parties to whose sites we link. While our goal is to provide accurate
                information, product packaging and material may contain more and/or different information than that
                provided on Scuti™ Sites, including the product description, country of origin, nutrition, ingredient,
                allergen, and other information. Always read labels, warnings, directions, and other information
                provided with the product before using or consuming the product. For additional information about a
                product, please contact the manufacturer. Information and statements regarding dietary supplements have
                not been evaluated by the Food and Drug Administration and are not intended to diagnose, treat, cure, or
                prevent any disease or health condition. If you find a product is not as described, your sole remedy is
                to return it in unused condition (excluding products that are not eligible for return), in accordance
                with Scuti™’s return policy. It is your responsibility to ascertain and obey all applicable local,
                state, federal, and foreign laws (including minimum age requirements) regarding the purchase,
                possession, and use of any Product.
              </p>

              <hr className="mb-0 mt-4" />
              <hr className="mb-4 mt-0.5" />
            </div>
          </DisclosureNoBorders>
        </li>

        <li>
          <DisclosureNoBorders title="7. Third Party Sites">
            <div>
              <p className="text-white">
                References on Scuti™ Sites to any names, marks, products, or services of third parties, or links to
                third-party sites or information, are not an endorsement, sponsorship, or recommendation of the third
                party or its information, products, or services. Scuti™ is not responsible for the content of any
                third-party linked site or any link contained in a linked site, including any third-party social media
                or mobile app platform with which the Scuti™ Sites operate or otherwise interact, nor is Scuti™
                responsible for the acts or omissions of any operator of any such site or platform. Your use of any such
                third-party site or platform is at your own risk, and will be governed by such third party&apos;s terms
                and policies (including its privacy policy).
              </p>

              <hr className="mb-0 mt-4" />
              <hr className="mb-4 mt-0.5" />
            </div>
          </DisclosureNoBorders>
        </li>

        <li>
          <DisclosureNoBorders title="8. Placing an Order">
            <div>
              <p className="text-white">
                <b className="text-white"> A. Order Acceptance and Billing</b>
              </p>
              <p className="text-white">
                You represent and warrant that you have the right to use any credit card or other means of payment that
                you provide to us. By providing payment card information to us, you authorize us to store and use the
                card as a payment method for purchases made through your Scuti™ account, including on Scuti™ affiliated
                sites and properties which you access via your Scuti™ account credentials. Scuti™ participates in
                account update services offered by some banks. If your bank participates in account updater services,
                these services will automatically update your card number or expiration date in our system when it
                changes. If you do not want to have your cards automatically updated, you can opt out of these services
                by contacting your issuing bank. All billing information you provide to us must be truthful and
                accurate. Providing any untruthful or inaccurate information is a breach of these Terms of Use and may
                result in cancellation of your order. Prior to accepting an order we may also request additional
                information from you. Verification of information may be required prior to the acknowledgment or
                completion of any purchase. We reserve the right to refuse or cancel an order for any reason including
                limitations on quantities available for purchase, inaccuracies, or errors in product or pricing
                information, or problems identified by our credit and fraud avoidance department. If your order is
                canceled after your credit card (or other payment account) has been charged, we will issue a credit to
                your credit card (or other applicable payment account) in the amount of the charge. We will attempt to
                contact you if all or any portion of your order is canceled or if additional information is required to
                accept your order. Scuti™ may request a pre-authorization for some orders placed online with a credit or
                debit card. This pre-authorization will not be billed to you; however, your card issuer may hold this
                amount for a short period. Your card issuer determines the length of time the pre-authorization is held.
                We do this to ensure that the card details are still valid and that you have sufficient funds to
                complete the transaction.
              </p>

              <p className="mt-4 text-white">
                By confirming your purchase at the end of the checkout process, you agree to accept and pay for the
                Products, as well as all shipping and handling charges and applicable taxes. You will not be charged for
                most orders until the order has shipped. Some exceptions (when you will be charged at the time your
                order is placed) are: (i) orders or preorders paid for with a Gift Card, eGift Card, or PayPal account;
                and (ii) orders paid using the in-store “Cash” payment method.Scuti™ reserves the right, including
                without prior notice, to limit the quantity of items purchased per person, per household, or per order
                for any reason. We will attempt to notify you should such limits be applied. Scuti™ also reserves the
                right, at our sole discretion, to prohibit sales to dealers or resellers. For purposes of these Terms of
                Use, “reselling” will be defined as purchasing or intending to purchase any Product(s) from Scuti™ for
                the purpose of engaging in a commercial sale of the same Product(s) to a third party.
              </p>

              <p className="mt-4 text-white">
                <b className="text-white"> B. Pricing Information; Availability</b>
              </p>

              <p className="text-white">
                Scuti™ cannot confirm the price or availability of an item until after your order is placed. Pricing or
                availability errors may occur on the Scuti™ Sites or through Marketplace Vendors. The receipt of an
                order confirmation does not constitute our acceptance of an order or our confirmation of an offer to
                sell a Product. Scuti™ reserves the right to cancel any orders containing pricing or availability
                errors, with no further obligations to you, even after your receipt of an order confirmation or shipping
                notice from Scuti™. Scuti™ may, at its discretion, either contact you for instructions or cancel your
                order and notify you of such cancellation. Pricing for products may be different on the Scuti™ Sites or
                from prices available in Scuti™ stores or on Scuti™ Apps.
              </p>

              <p className="mt-4 text-white">
                <b className="text-white"> C. Promotional Codes</b>
              </p>
              <p className="text-white">
                Promotional codes are limited in nature and may expire or be discontinued with or without notice.
                Promotional codes are void where prohibited by law. Promotional codes may not be copied, sold, or
                otherwise transferred. They are not redeemable for cash and are subject to cancellation or change at any
                time for any reason without notice. We reserve the right in our discretion to impose conditions on the
                offering of any promotional code.
              </p>

              <p className="mt-4 text-white">
                <b className="text-white"> D. Gift Cards</b>
              </p>
              <p className="text-white">
                The risk of loss and title to any gift cards passes to the purchaser upon our electronic transmission to
                the recipient or delivery to the carrier, whichever is applicable. Your purchase and use of gift cards
                is subject to the full terms and conditions related to gift cards, available at
                http://help.Scuti/app/answers/detail/a_id/57.
              </p>

              <hr className="mb-0 mt-4" />
              <hr className="mb-4 mt-0.5" />
            </div>
          </DisclosureNoBorders>
        </li>

        <li>
          <DisclosureNoBorders title="9. Shipping and Delivery">
            <div>
              <p className="text-white">
                Products will be shipped to an address designated by you, if applicable, so long as such address is
                complete and complies with the shipping restrictions contained on the Scuti™ Sites. All transactions are
                made pursuant to a shipping contract, and, as a result, risk of loss and title for Products pass to you
                upon delivery of the Products to the carrier.
              </p>

              <p className="mt-4 text-white">
                Delivery of Products purchased from the Scuti™ Sites to addresses outside the United States is limited.
                Some Products also have restricted delivery within the United States. Some Products may be available for
                pick up at physical Vendor store locations. Estimated delivery times are determined based on the method
                of shipping chosen when Products are purchased and the destination of the Products. Scuti™ does not ship
                products itself. All products purchased are shipped direct from the Vendor.
              </p>

              <hr className="mb-0 mt-4" />
              <hr className="mb-4 mt-0.5" />
            </div>
          </DisclosureNoBorders>
        </li>

        <li>
          <DisclosureNoBorders title="10. Export Policy">
            <div>
              <p className="text-white">
                You acknowledge that (a) goods licensed or sold on the Scuti™ Sites, and (b) any software or technology
                purchased, downloaded, or used from the Scuti™ Sites, are subject to the customs and export control laws
                and regulations of the United States of America and may also be subject to the customs and export laws
                and regulations of the country in which the products are manufactured and/or received, and you agree to
                comply with all applicable laws. You agree, represent, and warrant that no Materials will be accessed
                from, downloaded in, released in, carried to, transferred to, transshipped through, exported to, or
                re-exported (collectively “transferred”) to any territory (or national resident thereof), person,
                entity, or organization to which such Materials could not be transferred directly from the United States
                or by a U.S. person without a license, including without limitation to any person on the U.S. Treasury
                Department’s List of Specially Designated Nationals or the U.S. Department of Commerce’s Denied Persons
                List or Entity List.
              </p>
              <hr className="mb-0 mt-4" />
              <hr className="mb-4 mt-0.5" />
            </div>
          </DisclosureNoBorders>
        </li>

        <li>
          <DisclosureNoBorders title="11. Intellectual Property">
            <div>
              <p className="text-white">
                The Scuti™ Sites and all Materials, and all copyrights, trademarks, trade dress, and other intellectual
                property rights there (collectively, the “IP”) are owned or controlled by or licensed to Scuti™, and are
                protected by U.S. and international trademark, copyright, and other intellectual property laws.
                Materials are licensed (not sold) to end users. Subject to your compliance with these Terms of Use, and
                solely for so long as you are permitted by Scuti™ to use the Scuti™ Sites, you may access, view,
                download, and print the Materials for your personal, non-commercial use only; provided, however, that
                you (1) retain all copyright, trademark, or other proprietary designations contained on all Materials;
                (2) do not modify or alter the Materials in any way; and (3) do not provide or make available the
                Materials to any third party in a commercial manner. In addition, subject to your compliance with these
                Terms of Use, and solely for so long as you are permitted by Scuti™ to use the Scuti™ Apps, we permit
                you, on a limited, non-exclusive, revocable, non-transferable, non-sublicensable basis, to install and
                use the Scuti™ Apps on a mobile device that you own or control, solely for your personal, non-commercial
                use. If you fail to comply with any of the terms or conditions of these Terms of Use, you must
                immediately cease using the Scuti™ Apps and remove (that is, uninstall and delete) the Scuti™ Apps from
                your mobile device.
              </p>

              <p className="mt-4 text-white">
                No license, right, title, or interest in the Scuti™ Sites or any Materials is transferred to you as a
                result of your use of the Scuti™ Sites or your accessing, viewing, downloading, or printing of the
                Materials. You may not reproduce (except as noted above), publish, transmit, distribute, display,
                modify, create derivative works from, sell, or participate in any sale of or exploit in any way, in
                whole or in part, any of the Materials or the Scuti™ Sites. The Scuti™ Sites and Materials may be used
                only as a personal shopping resource. Any other use, including the reproduction, modification,
                distribution, transmission, republication, display, or performance, of the Scuti™ Sites and the
                Materials is strictly prohibited. The compilation (meaning the collection, arrangement, and assembly) of
                the Scuti™ Sites and Materials is the exclusive property of Scuti™ and is also protected by U.S. and
                international copyright laws.
              </p>

              <p className="mt-4 text-white">
                The SCUTI™ name and logo, and all other graphics, logos, page headers, button icons, scripts, and
                service names included in or made available through any of the Scuti™ Sites are trademarks or trade
                dress of Scuti™ in the U.S. and other countries. All other marks are the property of their respective
                companies.
              </p>

              <hr className="mb-0 mt-4" />
              <hr className="mb-4 mt-0.5" />
            </div>
          </DisclosureNoBorders>
        </li>

        <li>
          <DisclosureNoBorders title="12 Procedure for Making a Claim of Copyright Infringement">
            <div>
              <p className="text-white">
                We respect the intellectual property of others.If you believe that your work has been copiedand is
                accessible on the Scuti™ Sites in a way that constitutes copyright infringement, please contact us at
                support@scuti.store.
              </p>

              <hr className="mb-0 mt-4" />
              <hr className="mb-4 mt-0.5" />
            </div>
          </DisclosureNoBorders>
        </li>

        <li>
          <DisclosureNoBorders title="13. Privacy">
            <div>
              <p className="text-white">
                You acknowledge that any personal information that you provide through the Scuti™ Sites will be used by
                Scuti™ in accordance with Scuti™’s Privacy Policy (available at http://www.scuti.store/privacy-policy
                which may be updated by Scuti™ from time to time. Any item purchased from a Marketplace Vendor or
                Vendor, Scuti™ may share certain information with that Marketplace Vendor or vendor to permit the
                Marketplace Vendor or vendor, as applicable, to fulfill and ship your order, process returns, and
                provide customer service.
              </p>

              <p className="mt-4 text-white">
                You authorize your wireless operator (AT&T, Sprint, T-Mobile, US Cellular, Verizon, or any other branded
                wireless operator) to disclose to Scuti™ and its third-party service providers your mobile number, name,
                address, email, network status, customer type, customer role, billing type, mobile device identifiers
                (IMSI and IMEI) and other subscriber status and device details, if available, solely to verify your
                identity and prevent fraud for the duration of the business relationship. See the Scuti™ Privacy Policy
                for how Scuti™ treats your data.
              </p>

              <hr className="mb-0 mt-4" />
              <hr className="mb-4 mt-0.5" />
            </div>
          </DisclosureNoBorders>
        </li>

        <li>
          <DisclosureNoBorders title="14. Third-Party Software & Licensing Notices">
            <div>
              <p className="text-white">
                The Scuti™ Sites may include certain third-party technologies and open source materials (collectively,
                “Third-Party Technology”). Your use of such Third-Party Technology is subject to these Terms of Use, as
                well as well the applicable terms and conditions of such third parties, which is incorporated in these
                Terms of Use by reference.
              </p>

              <hr className="mb-0 mt-4" />
              <hr className="mb-4 mt-0.5" />
            </div>
          </DisclosureNoBorders>
        </li>

        <li>
          <DisclosureNoBorders title="15. Promotions">
            <div>
              <p className="text-white">
                Any sweepstakes, contests, raffles, surveys, games, or similar promotions (collectively, “Promotions”)
                made available through the Scuti™ Sites may be governed by rules that are separate from these Terms of
                Use. If you participate in any Promotions, please review the applicable rules as well as our Privacy
                Policy. If the rules for a Promotion conflict with these Terms of Use, the Promotion rules will govern.
              </p>

              <hr className="mb-0 mt-4" />
              <hr className="mb-4 mt-0.5" />
            </div>
          </DisclosureNoBorders>
        </li>

        <li>
          <DisclosureNoBorders title="16. Terms Applicable to Special Scuti™ Services">
            <div>
              <p className="mt-4 text-white">
                <b className="text-white">A. Scuti™ Marketplace</b>
              </p>
              <p className="text-white">
                Scuti™ operates an online marketplace program on the Scuti™ Sites which enables Marketplace Vendors to
                sell their products and, once an order is placed, to ship such Marketplace Vendors’ products directly to
                the customer (the “Scuti™ Marketplace”). Any use of the Scuti™ Marketplace is subject to the following
                additional terms:
              </p>

              <ul className="text-white">
                <li>
                  <p className="text-white">
                    When we say “Marketplace Vendor,” we mean any entity or retailer that sells goods or services in the
                    Scuti™ Marketplace through the Scuti™ Sites, uses any order processing, fulfillment, shipping, or
                    other services related to the Scuti™ Marketplace provided by or for Scuti™, or uses any platform,
                    portal, web service, application, interface, or other tool provided by or for Scuti™ in connection
                    with the Scuti™ Marketplace.
                  </p>
                </li>
                <li>
                  <p className="text-white">
                    By purchasing a product from any of our Marketplace Vendors, you acknowledge that all Scuti™
                    Marketplace orders will be fulfilled by the third party Marketplace Vendor and not Scuti™. The
                    Marketplace Vendor (and not Scuti™) will be responsible for all processing, shipping, returns, and
                    customer service related to your Scuti™ Marketplace order. Products purchased from a Marketplace
                    Vendor can only be returned to that Marketplace Vendor in accordance with its return policy and
                    cannot be returned to Scuti™ stores or Scuti™. Each Marketplace Vendor’s shipping information,
                    return policy, customer service information, and Marketplace Privacy Policy can be found on that
                    Marketplace Vendor’s Seller Information page. For other terms that apply to Scuti™ Marketplace,
                    including important notices and DisclosureNoBorderss, see About Marketplace.
                  </p>
                </li>
                <li>
                  <p className="text-white">
                    To the fullest extent provided by applicable law, Scuti™ has no responsibility or liability for any
                    Marketplace Vendor or their products.
                  </p>
                </li>
              </ul>
              <p className="text-white">
                <b className="text-white">B. iOS Scuti™ App</b>
              </p>
              <p className="text-white">
                In addition to your agreement with these Terms of Use, the following provisions apply with respect to
                your use of any version of the Scuti™ App compatible with the iOS operating system of Apple Inc. (“
                <b className="text-white">Apple</b>
                ”):
              </p>

              <ul className="text-white">
                <li>
                  <p className="text-white">
                    Apple is not a party to these Terms of Use and does not own and is not responsible for any Scuti™
                    App. Apple is not providing any warranty for the Scuti™ App except, if applicable, to refund the
                    purchase price for it. Apple is not responsible for maintenance or other support services for the
                    Scuti™ App and will not be responsible for any other claims, losses, liabilities, damages, costs, or
                    expenses with respect to the Scuti™ App, including any third-party product liability claims, claims
                    that the Scuti™ App fails to conform to any applicable legal or regulatory requirement, claims
                    arising under consumer protection or similar legislation, and claims with respect to intellectual
                    property infringement. Any inquiries or complaints relating to the use of the Scuti™ App, including
                    those pertaining to intellectual property rights, must be directed to Scuti™ in accordance with the
                    “How to Contact Us” section.
                  </p>
                </li>

                <li>
                  <p className="text-white">
                    The license you have been granted in these Terms of Use is limited to a non-transferable license to
                    use the Scuti™ App on an Apple-branded product that runs Apple’s iOS operating system and is owned
                    or controlled by you, or as otherwise permitted by the Usage Rules set forth in Apple’s App Store
                    Terms of Service. In addition, you must comply with the terms of any third-party agreement
                    applicable to you when using the Scuti™ App, such as your wireless data service agreement.
                  </p>
                </li>
                <li>
                  <p className="text-white">
                    You represent and warrant that (1) you are not located in a country that is subject to a U.S.
                    Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting”
                    country; and (2) you are not listed on any U.S. Government list of prohibited or restricted parties.
                  </p>
                </li>

                <li>
                  <p className="text-white">
                    Apple and Apple’s subsidiaries are third-party beneficiaries of these Terms of Use and, upon your
                    acceptance of the terms and conditions of these Terms of Use, will have the right (and will be
                    deemed to have accepted the right) to enforce these Terms of Use against you as a third-party
                    beneficiary thereof; notwithstanding the foregoing, Scuti™’s right to enter into, rescind or
                    terminate any variation, waiver, or settlement under these Terms of Use is not subject to the
                    consent of any third party.
                  </p>
                </li>
              </ul>
              <p className="text-white">
                <b className="text-white">C. Orders</b>
              </p>

              <ul className="text-white">
                <li>
                  <p className="text-white">
                    setting out what you have ordered. This is not an order confirmation or acceptance from us.
                  </p>
                </li>
                <li>
                  <p className="text-white">
                    All prices quoted are exclusive of tax and you will see estimated taxes and fees when you place the
                    order. The final taxes and fees will be calculated on the day your order is picked and delivered, or
                    picked up, as applicable. In the event your order must be fulfilled using products of different
                    weight or volume than what you ordered, you will be charged only for the actual weight or volume
                    delivered.
                  </p>
                </li>
                <li>
                  <p className="text-white">
                    Due to shipment and delivery times, there are limitations on when changes or cancellations to orders
                    can be made. These limitations will be provided when you place your order. For orders that are
                    delivered to you, title to the goods purchased by you and the related risk of loss on these items
                    passes to you upon delivery of the items to the carrier.
                  </p>
                </li>
                <li>
                  <p className="text-white">
                    We may, in our discretion, provide you with a refund in certain circumstances. If you are not
                    satisfied with your order, please contact the Scuti™ Contact Center at contact@scuti.store.
                  </p>
                </li>
                <li>
                  <p className="text-white">
                    We will not provide you a refund for certain goods which by nature cannot be returned. Such items
                    include items which cannot be resold for health and hygiene reasons once unwrapped (for example body
                    jewelry, mattresses, bedding, certain items of clothing, personal grooming products, medicines, and
                    certain baby products, etc.).
                  </p>
                </li>
                <li>
                  <p className="text-white">
                    Notwithstanding the foregoing, all refunds will be subject to our discretion.
                  </p>
                </li>
              </ul>
              <p className="text-white">
                <b className="text-white">D. Accounts</b>
              </p>

              <ul className="text-white">
                <li>
                  <p className="text-white">
                    Rewards. From time to time, you may be offered Scuti™ Crypto credits which allow you to redeem or
                    exchange these rewards for in-game credits to registered video games in the Scuti™ Marketplace. Such
                    rewards are intended for your use only and are not to be sold by you and may be governed by rules
                    that are separate from these Terms. We reserve the right to restrict access to rewards redemptions
                    in the event of a violation of these Terms of Use and to modify, terminate, or otherwise amend our
                    credits and promotional offerings.
                  </p>
                </li>

                <li>
                  <p className="text-white">
                    Cancellations and Refunds. You may cancel your Scuti™ account at any time by contacting customer
                    support at support at support@scuti.store. Any remaining Scuti™ Crypto balance in your account will
                    be terminated at that time unless you gift or transfer your Scuti™ Crypto to another registered
                    Scuti™ account. Once your account has been closed, you will need to register as a new user.
                  </p>
                </li>

                <li>
                  <p className="text-white">
                    Account Access. If you believe that you may be victim of identity theft involving your Scuti™
                    account, you should notify customer service and report any information about this instance to your
                    card issuers and local law enforcement. We reserve the right to place any account on hold at any
                    time with or without notification to you in order to protect ourselves and our partners from what we
                    believe to be fraudulent activity. We are not obligated to credit or discount a membership for holds
                    placed on the account by either a representative of us or by any automated process.
                  </p>
                </li>

                <li>
                  <p className="text-white">
                    Information or Complaints. If you have a question or complaint regarding Vudu (or your bills, please
                    send an e-mail to support@scuti.store You may also contact us by writing to Scuti™ LLC.,
                    contact@scuti.store. Please note that e-mail communications will not necessarily be secure;
                    accordingly you should not include credit card information or other sensitive information in your
                    e-mail correspondence with us.
                  </p>
                </li>
                <li>
                  <p className="text-white">
                    Age Restrictions; Parental Controls. While Scuti™ does distribute our vendor’s video content that
                    may be watched by children, not all Scuti™ Content is appropriate for children. If any children
                    watch Scuti™ Content through your Scuti™ account, you are responsible for those viewing selections.
                    Scuti™ vendors must display the respective Motion Picture Association of America and ESRB rating on
                    all their content.
                  </p>
                </li>
              </ul>

              <p className="text-white">
                <b className="text-white">E. Mobile Device Communications</b>
              </p>

              <ul className="text-white">
                <li>
                  <p className="text-white">
                    By creating a new Scuti™ account with a phone number or adding a phone number to an existing Scuti™
                    account, you consent to receiving communications, which may include phone calls or SMS messages,
                    from us regarding your Scuti™ account and Scuti™ Vendor offerings. You agree that you are the
                    primary owner of that phone number and that the information provided is accurate. Standard message
                    and data rates may apply. Messages will be sent from an automatic telephone dialing system and
                    messages may include an artificial or pre-recorded voice. Your agreement to receive those messages
                    is not a condition of purchase or required to receive services.
                  </p>
                </li>

                <li>
                  <p className="text-white">
                    You may opt out of receiving SMS and telephone communications from us regarding Vudu at any time by
                    texting the word ‘STOP’ to MyScuti™ (203) 864-9500 and we will send you a single SMS text message
                    indicating that you will no longer receive any SMS or telephone communication from us regarding
                    Scuti™. For additional help, you can text ‘HELP’ to MyScuti (203) 864-9500 or email customer support
                    at support@scuti.store. For information about email communications and preferences, refer to the
                    Privacy Policy.
                  </p>
                </li>

                <li>
                  <p className="text-white">
                    Supported carriers currently include: ACS Wireless, AIO Wireless, AT&T, Bluegrass Cellular, Boost,
                    Carolina West Wireless, Cellcom, Cellular One, Cellular One of East Central Illinois, Cellular
                    South, Chat Mobility, Cincinnati Bell, Cricket, ECIT, Element Wireless, GCI, Golden State Cellular,
                    Illinois Valley, Immix Wireless, Inland Cellular, iWireless, LongLines Wireless, MetroPCS, Nex-Tech,
                    Northwest Missouri Cellular, NTelos, Plateau Wireless, PTCI, Sprint, T Mobile, Thumb Cellular, U.S.
                    Cellular, Verizon Wireless, Virgin Mobile USA, West Central Wireless and other carriers that may be
                    added from time to time. SMS communications may not be compatible with all handsets.
                  </p>
                </li>
              </ul>

              <hr className="mb-0 mt-4" />
              <hr className="mb-4 mt-0.5" />
            </div>
          </DisclosureNoBorders>
        </li>

        <li>
          <DisclosureNoBorders title="17. Disclaimer of Warranties">
            <div>
              <p className="text-white">
                THE SCUTI™ SITES, AND ALL CONTENT, MATERIALS, PRODUCTS, SERVICES, FUNCTIONALITY, AND OTHER ITEMS
                INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE SCUTI™ SITES, ARE PROVIDED BY SCUTI™ ON AN
                “AS IS” AND “AS AVAILABLE” BASIS. NO SCUTI™ ENTITY MAKES ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND,
                EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE SCUTI™ SITES OR THE CONTENT, MATERIALS, PRODUCTS,
                SERVICES, FUNCTIONALITY, OR OTHER ITEMS INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE
                SCUTI™ SITES. TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW, THE SCUTI™ ENTITIES DISCLAIM ALL
                WARRANTIES, EXPRESS OR IMPLIED, INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A
                PARTICULAR PURPOSE. WITHOUT LIMITING THE FOREGOING, THE SCUTI™ ENTITIES DISCLAIM ANY AND ALL WARRANTIES,
                EXPRESS OR IMPLIED, FOR ANY MERCHANDISE OFFERED ON THE SCUTI™ SITES. YOU ACKNOWLEDGE THAT, TO THE
                FULLEST EXTENT PROVIDED BY APPLICABLE LAW, YOUR USE OF THE SCUTI™ SITES IS AT YOUR SOLE RISK. THIS
                SECTION 17 DOES NOT LIMIT THE TERMS OF ANY PRODUCT WARRANTY OFFERED BY THE MANUFACTURER OF AN ITEM THAT
                IS SOLD BY SCUTI™ TO YOU THROUGH THE SCUTI™ SITES. THIS DISCLAIMER CONSTITUTES AN ESSENTIAL PART OF
                THESE TERMS OF USE. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU ASSUME FULL RESPONSIBILITY
                FOR YOUR USE OF THE SCUTI™ SITES AND AGREE THAT ANY INFORMATION YOU SEND OR RECEIVE DURING YOUR USE OF
                THE SCUTI™ SITES MAY NOT BE SECURE AND MAY BE INTERCEPTED OR OTHERWISE ACCESSED BY UNAUTHORIZED PARTIES.
                YOU AGREE THAT, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, NO SCUTI™ ENTITY IS RESPONSIBLE FOR
                ANY LOSS OR DAMAGE TO YOUR PROPERTY OR DATA THAT RESULTS FROM ANY MATERIALS YOU ACCESS OR DOWNLOAD FROM
                THE SCUTI™ SITES. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE
                FOREGOING LIMITATIONS MAY NOT APPLY TO YOU.
              </p>

              <hr className="mb-0 mt-4" />
              <hr className="mb-4 mt-0.5" />
            </div>
          </DisclosureNoBorders>
        </li>

        <li>
          <DisclosureNoBorders title="18. Limitation of Liability">
            <div>
              <p className="text-white">
                YOU ACKNOWLEDGE AND AGREE THAT, TO THE FULLEST EXTENT PROVIDED BY APPLICABLE LAW, SCUTI™ WILL NOT BE
                LIABLE TO YOU OR TO ANY OTHER PERSON UNDER ANY CIRCUMSTANCES OR UNDER ANY LEGAL OR EQUITABLE THEORY,
                WHETHER IN TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE, FOR ANY INDIRECT, SPECIAL, INCIDENTAL, OR
                CONSEQUENTIAL LOSSES OR DAMAGES OF ANY NATURE ARISING OUT OF OR IN CONNECTION WITH THE USE OF OR
                INABILITY TO USE THE SCUTI™ SITES, EVEN IF AN AUTHORIZED REPRESENTATIVE OF A SCUTI™ ENTITY HAS BEEN
                ADVISED OF OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES. TO THE FULLEST EXTENT PROVIDED BY
                APPLICABLE LAW, THIS DISCLAIMER APPLIES TO ANY DAMAGES OR INJURY ARISING FROM ANY FAILURE OF
                PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DELETION, DEFECTS, DELAY IN OPERATION OR TRANSMISSION, LOST
                PROFITS, LOSS OF GOODWILL, LOSS OF DATA, WORK STOPPAGE, ACCURACY OF RESULTS, COMPUTER FAILURE OR
                MALFUNCTION, COMPUTER VIRUSES, FILE CORRUPTION, COMMUNICATION FAILURE, NETWORK OR SYSTEM OUTAGE, THEFT,
                DESTRUCTION, UNAUTHORIZED ACCESS TO, ALTERATION OF, LOSS OF USE OF ANY RECORD OR DATA, AND ANY OTHER
                TANGIBLE OR INTANGIBLE LOSS. SUBJECT TO THE FOREGOING, TO THE FULLEST EXTENT PROVIDED BY APPLICABLE LAW,
                NO SCUTI™ ENTITY WILL BE LIABLE FOR ANY DAMAGES IN EXCESS OF THE FEES PAID BY YOU IN CONNECTION WITH
                YOUR USE OF THE SCUTI™ SITES DURING THE SIX (6) MONTH PERIOD PRECEDING THE DATE ON WHICH THE CLAIM
                AROSE.
              </p>

              <p className="mt-4 text-white">
                YOU SPECIFICALLY ACKNOWLEDGE AND AGREE THAT, TO THE FULLEST EXTENT PROVIDED BY APPLICABLE LAW, NO SCUTI™
                ENTITY WILL BE LIABLE FOR ANY DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY SELLER (INCLUDING ANY
                MARKETPLACE VENDOR), SHOPPER, OR OTHER USER OF THE SCUTI™ SITES.
              </p>

              <hr className="mb-0 mt-4" />
              <hr className="mb-4 mt-0.5" />
            </div>
          </DisclosureNoBorders>
        </li>

        <li>
          <DisclosureNoBorders title="19. Indemnification">
            <div>
              <p className="text-white">
                You agree to defend (at Scuti™’s option), indemnify, and hold the Scuti™ Entities harmless from and
                against any and all liabilities, claims, damages, costs, and expenses, including attorneys’ fees and
                costs, arising from or related to your misuse of the Scuti™ Sites or any breach by you of these Terms of
                Use. Scuti™ reserves the right, at our expense, to assume exclusive defense and control of any matter
                otherwise subject to indemnification by you and, in any case, you agree to cooperate with Scuti™ if and
                as requested by Scuti™ in the defense and settlement of such matter.
              </p>

              <hr className="mb-0 mt-4" />
              <hr className="mb-4 mt-0.5" />
            </div>
          </DisclosureNoBorders>
        </li>

        <li>
          <DisclosureNoBorders title="20. Disputes & Arbitration; Applicable Law">
            <div>
              <p className="text-white">
                <b className="text-white">PLEASE READ THIS SECTION CAREFULLY.</b> IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL
                RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT. Using or accessing the Scuti™ Sites constitutes
                your acceptance of this Arbitration provision. Please read it carefully as it provides that you and
                Scuti™ will waive any right to file a lawsuit in court or participate in a class action for matters
                within the terms of the Arbitration provision.
              </p>

              <p className="mt-4 text-white">
                EXCEPT FOR DISPUTES THAT QUALIFY FOR SMALL CLAIMS COURT, ALL DISPUTES ARISING OUT OF OR RELATED TO THESE
                TERMS OF USE OR ANY ASPECT OF THE RELATIONSHIP BETWEEN YOU AND SCUTI™, WHETHER BASED IN CONTRACT, TORT,
                STATUTE, FRAUD, MISREPRESENTATION, OR ANY OTHER LEGAL THEORY, WILL BE RESOLVED THROUGH FINAL AND BINDING
                ARBITRATION BEFORE A NEUTRAL ARBITRATOR INSTEAD OF IN A COURT BY A JUDGE OR JURY, AND YOU AGREE THAT
                SCUTI™ AND YOU ARE EACH WAIVING THE RIGHT TO SUE IN COURT AND TO HAVE A TRIAL BY A JURY. YOU AGREE THAT
                ANY ARBITRATION WILL TAKE PLACE ON AN INDIVIDUAL BASIS; CLASS ARBITRATIONS AND CLASS ACTIONS ARE NOT
                PERMITTED AND YOU ARE AGREEING TO GIVE UP THE ABILITY TO PARTICIPATE IN A CLASS ACTION. The arbitration
                will be administered by Judicial Arbitration Mediation Services, Inc. (“JAMS”) pursuant to the JAMS
                Streamlined Arbitration Rules & Procedures effective July 1, 2014 (the “JAMS Rules”) and as modified by
                this agreement to arbitrate. The JAMS Rules, including instructions for bringing arbitration, are
                available on the JAMS website at http://www.jamsadr.com/rules-streamlined-arbitration. The Minimum
                Standards are available at http://www.jamsadr.com/consumer-arbitration.
              </p>

              <p className="mt-4 text-white">
                The arbitrator will conduct hearings, if any, by teleconference or videoconference, rather than by
                personal appearances, unless the arbitrator determines upon request by you or by us that an in-person
                hearing is appropriate. Any in-person appearances will be held at a location which is reasonably
                convenient to both parties with due consideration of their ability to travel and other pertinent
                circumstances. If the parties are unable to agree on a location, such determination should be made by
                JAMS or by the arbitrator. The arbitrator’s decision will follow the terms of these Terms of Use and
                will be final and binding. The arbitrator will have authority to award temporary, interim, or permanent
                injunctive relief or relief providing for specific performance of these Terms of Use, but only to the
                extent necessary to provide relief warranted by the individual claim before the arbitrator. The award
                rendered by the arbitrator may be confirmed and enforced in any court having jurisdiction thereof.
                Notwithstanding any of the foregoing, nothing in these Terms of Use will preclude you from bringing
                issues to the attention of federal, state, or local agencies and, if the law allows, they can seek
                relief against us for you.
              </p>

              <p className="mt-4 text-white">
                These Terms of Use will be governed by and construed under the laws of the United States (including
                federal arbitration law) and the State of California, without regard to conflicts of law principles.
              </p>

              <hr className="mb-0 mt-4" />
              <hr className="mb-4 mt-0.5" />
            </div>
          </DisclosureNoBorders>
        </li>

        <li>
          <DisclosureNoBorders title="21. Termination">
            <div>
              <p className="text-white">
                These Terms of Use are effective unless and until terminated by either you or Scuti™. You may terminate
                these Terms of Use at any time, provided that you discontinue any further use of the Scuti™ Sites. We
                also may terminate these Terms of Use at any time and may do so immediately without notice, and deny you
                access to the Scuti™ Sites, if in our sole discretion you fail to comply with any term or provision of
                these Terms of Use. Upon any termination of these Terms of Use by either you or Scuti™, you must
                promptly destroy all Materials and other Content downloaded or otherwise obtained from the Scuti™ Sites,
                as well as all copies of such Content, whether made under these Terms of Use or otherwise. The following
                sections will survive any termination of these Terms of Use: “Your Use of the Scuti™ Sites,” “Content
                and Ideas,” “Monitoring by Scuti™,” “Materials Available on the Scuti™ Sites,” “Merchandise,” “Third
                Party Sites,” “Placing an Order with Scuti™,” “Shipping and Delivery,” “Export Policy,” “Intellectual
                Property” (excluding the rights granted to you in that Section), “Privacy,” “Third-Party Software &
                Licensing Notices,” “Indemnification,” “Termination,” “Disclaimer of Warranties,” “Limitation of
                Liability,” “Disputes & Arbitration,” and “General”.
              </p>

              <hr className="mb-0 mt-4" />
              <hr className="mb-4 mt-0.5" />
            </div>
          </DisclosureNoBorders>
        </li>

        <li>
          <DisclosureNoBorders title="22. General">
            <div>
              <p className="text-white">
                These Terms of Use represent the complete agreement and understanding between you and Scuti™ and
                supersede all prior agreements and representations between the parties with respect to the subject
                matter of these Terms of Use. These Terms of Use do not, and shall not be construed to, create any
                partnership, joint venture, employer-employee, agency, or franchisor-franchisee relationship between you
                and Scuti™. Headings used in these Terms of Use are for reference purposes only and in no way define or
                limit the scope of the section. If any provision of these Terms of Use is held to be unenforceable for
                any reason, such provision will be reformed only to the extent necessary to make it enforceable and the
                other terms of these Terms of Use will remain in full force and effect. The failure of Scuti™ to act
                with respect to a breach of these Terms of Use by you or others does not constitute a waiver and will
                not limit Scuti™&apos;s rights with respect to such breach or any subsequent breaches. You may not
                assign, transfer, or sublicense any or all of your rights or obligations under these Terms of Use
                without our express prior written consent. We may assign, transfer, or sublicense any or all of our
                rights or obligations under these Terms of Use without restriction. Any use of the term “including” or
                variations thereof in these Terms of Use shall be construed as if followed by the phrase “without
                limitation.” Notices to you (including notices of changes to this these Terms of Use) may be made via
                posting to the Scuti™ Sites or by e-mail (including in each case via links), or by regular mail. Without
                limitation, a printed version of these Terms of Use and of any notice given in electronic form shall be
                admissible in judicial or administrative proceedings based upon or relating to these Terms of Use to the
                same extent and subject to the same conditions as other business documents and records originally
                generated and maintained in printed form.
              </p>

              <hr className="mb-0 mt-4" />
              <hr className="mb-4 mt-0.5" />
            </div>
          </DisclosureNoBorders>
        </li>

        <li>
          <DisclosureNoBorders title="23. Filtering">
            <div>
              <p className="text-white">
                This is to notify you that parental control protections (such as computer hardware, software, or
                filtering services) are commercially available that may assist you in limiting access to material that
                is harmful to minors. Information identifying current providers of such protections is available from
                https://en.wikipedia.org/wiki/Comparison_of_content-control_software_and_providers. Please note that we
                do not endorse any of the products or services listed on such site.
              </p>

              <hr className="mb-0 mt-4" />
              <hr className="mb-4 mt-0.5" />
            </div>
          </DisclosureNoBorders>
        </li>

        <li>
          <DisclosureNoBorders title="24. How to Contact Us">
            <div>
              <p className="text-white">
                If you have any questions or comments, please contact us at contact@scuti.store.
              </p>

              <p className="mt-4 text-white">
                Please note that e-mail communications will not necessarily be secure; accordingly, you should not
                include credit card information or other sensitive information in your e-mail correspondence with us.
                California residents may reach the Complaint Assistance Unit of the Division of Consumer Services of the
                California Department of Consumer Affairs by mail at 1625 North Market Blvd., Sacramento, CA 95834, or
                by telephone at (916) 445-1254 or (800) 952-5210.
              </p>

              <p className="mt-4 text-white">Scuti™ Sites: ©2019 Scuti™. All Rights Reserved.</p>
            </div>
          </DisclosureNoBorders>
        </li>
      </ul>
    </div>
  );
}
