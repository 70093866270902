import { Step } from 'react-joyride';
import { proxy } from 'valtio';

export type TourStore = {
  run: boolean;
  stepIndex: number;
  steps: Step[];
  forceRun: boolean;
};

const state = proxy<TourStore>({
  run: false,
  stepIndex: 0,
  steps: [],
  forceRun: false,
});

const setSteps = (steps: Step[]) => {
  state.steps = steps;
};

const setTourState = (running: boolean, stepIndex?: number) => {
  state.run = running;
  if (stepIndex) {
    state.stepIndex = stepIndex;
  }
};

const setForceRun = (run = true) => {
  // state.run = run;
  state.forceRun = run;
};

export const tourStore = {
  state,
  actions: { setTourState, setSteps, setForceRun },
};
