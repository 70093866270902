type Props = {
  className?: string;
  onClick?: () => void;
};

export function ExchangeDisabled(props: Props) {
  return (
    <svg {...props} width="75" height="74" viewBox="0 0 75 74" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M55.9723 33.8253C56.776 33.8261 57.5581 33.5652 58.2003 33.0821C58.8425 32.5989 59.31 31.9197 59.5319 31.1473C59.7539 30.3749 59.7182 29.5512 59.4305 28.8008C59.1427 28.0504 58.6184 27.4141 57.9369 26.9881L47.9919 17.0431C47.6481 16.6996 47.2401 16.4272 46.791 16.2414C46.342 16.0556 45.8608 15.96 45.3748 15.9602C44.8889 15.9604 44.4077 16.0563 43.9588 16.2424C43.5099 16.4285 43.102 16.7012 42.7585 17.045C42.415 17.3887 42.1426 17.7968 41.9568 18.2458C41.771 18.6948 41.6754 19.1761 41.6756 19.662C41.6758 20.148 41.7717 20.6292 41.9578 21.0781C42.1439 21.527 42.4166 21.9348 42.7604 22.2783L46.9078 26.4221L33.7736 26.4221C32.7924 26.4221 31.8513 26.8119 31.1575 27.5057C30.4636 28.1996 30.0738 29.1406 30.0738 30.1219C30.0738 31.1031 30.4636 32.0441 31.1575 32.738C31.8513 33.4318 32.7924 33.8216 33.7736 33.8216L55.9723 33.8253ZM18.9227 41.1065C18.119 41.1057 17.3369 41.3666 16.6947 41.8498C16.0525 42.3329 15.585 43.0121 15.3631 43.7845C15.1411 44.557 15.1768 45.3806 15.4645 46.131C15.7523 46.8814 16.2766 47.5177 16.9581 47.9437L26.9031 57.8887C27.5974 58.5824 28.5387 58.972 29.5202 58.9716C30.5016 58.9713 31.4427 58.5811 32.1365 57.8868C32.8302 57.1926 33.2197 56.2512 33.2194 55.2698C33.2191 54.2883 32.8288 53.3472 32.1346 52.6535L27.9872 48.5097L41.1214 48.5097C42.1026 48.5097 43.0437 48.12 43.7375 47.4261C44.4314 46.7323 44.8212 45.7912 44.8212 44.81C44.8212 43.8287 44.4314 42.8877 43.7375 42.1938C43.0437 41.5 42.1026 41.1102 41.1214 41.1102L18.9227 41.1065Z"
        fill="white"
      />
      <path
        d="M70.0155 24.3368C71.5204 28.2354 72.3455 32.472 72.3455 36.9013C72.3455 56.1767 56.7197 71.8025 37.4442 71.8025C18.1688 71.8025 2.54297 56.1767 2.54297 36.9013C2.54297 17.6258 18.1688 2 37.4442 2C41.8735 2 46.1101 2.8251 50.0087 4.32998"
        stroke="black"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M59.4941 11.0743C59.4941 10.6888 59.8067 10.3763 60.1922 10.3763H68.5685C68.954 10.3763 69.2665 10.6888 69.2665 11.0743C69.2665 11.4598 68.954 11.7724 68.5685 11.7724H60.1922C59.8067 11.7724 59.4941 11.4598 59.4941 11.0743Z"
        fill="#FF1463"
      />
      <circle cx="64.3829" cy="11.0743" r="8.37353" stroke="#FF1463" strokeWidth="1.40161" />
    </svg>
  );
}
