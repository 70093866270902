import { OffersRow } from '@/components/Common/OffersRow';
import { useStore } from './StorePage.hooks';
import { useMyRewards } from '@/hooks/useMyRewards';
import { InsticatorVideoAd } from '@/components/Common/InsticatorVideoAd';
import { WelcomeVideo } from '@/components/Common/WelcomeVideo';

export function StorePage() {
  useMyRewards();
  const store = useStore();

  return (
    <div className="">
      <WelcomeVideo />
      {store.map(([category, offers], index) => {
        if (index === 1) {
          return (
            <div key={category + index}>
              <InsticatorVideoAd />
              <OffersRow id={index} key={category} category={category} offers={offers} />
            </div>
          );
        }

        return <OffersRow id={index} key={category} category={category} offers={offers} />;
      })}
    </div>
  );
}
