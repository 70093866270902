import { Disclosure as DisclosureBase } from '@headlessui/react';
import { ReactNode } from 'react';
import { Icon } from '../Icon';

export type DisclosureProps = {
  className?: string;
  title: string;
  children: ReactNode;
  defaultOpen?: boolean;
  alwaysOpen?: boolean
};

export function Disclosure({ className, title, children, defaultOpen, alwaysOpen }: DisclosureProps) {
  return (
    <DisclosureBase defaultOpen={defaultOpen}>
      <DisclosureBase.Button disabled={alwaysOpen} className="w-full">
        {({ open }) => (
          <div
            className={`flex flex-row items-center justify-between border-t border-t-darker-gray py-4 ${
              open ? '' : 'border-b border-b-darker-gray'
            } ${className}`}
          >
            <h3 className="text-left font-bold">{title}</h3>
            {alwaysOpen ? null : <Icon name={open ? 'Minus' : 'Plus'} className="h-3" />}
          </div>
        )}
      </DisclosureBase.Button>
      <DisclosureBase.Panel className="border-b border-b-darker-gray pb-4">{children}</DisclosureBase.Panel>
    </DisclosureBase>
  );
}
