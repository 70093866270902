import { NavLink } from 'react-router-dom';
import { Welcome } from '@/components/Common/Welcome';
import { WelcomeLayout } from '@/components/Layout/WelcomeLayout';
import { Icon } from '@/components/Common/Icon';
import { appRoutes } from '@/helpers/routes.helper';
import { useState } from 'react';
import { ForgotPasswordForm } from '@/components/Form/ForgotPasswordForm';
import { PasswordReset } from './PasswordReset';

export function ForgotPasswordPage() {
  const [isSent, setIsSent] = useState(false);

  return (
    <WelcomeLayout
      split
      left={
        <>
          <div className={`${isSent ? 'flex' : 'flex'} w-full items-center justify-center md:h-full`}>
            <div className="md:bg-dark-gray/80 z-10 flex w-full flex-col rounded-2xl bg-transparent p-4 md:w-[60%] md:px-16 md:py-12 md:backdrop-blur-sm">
              <NavLink to={appRoutes.login}>
                <div className="flex items-center">
                  <Icon name="ArrowLeft" className="mr-1 h-3" />
                  Back
                </div>
              </NavLink>
              <div className="flex w-full flex-col items-center md:mt-16 md:items-start">
                <Icon className="mb-8 h-16 w-16 md:mb-0" name="Password" />
              </div>
              <h1 className="bold mb-2 text-center md:mt-4 md:text-left">Forgot Password?</h1>
              <p className="text-secondary-200 mb-10 text-center md:text-left">
                Enter your email to reset your password
              </p>
              <div className="overflow-y-scroll pr-6">
                <ForgotPasswordForm onForgot={() => setIsSent(true)} />
              </div>
            </div>
          </div>
          {isSent && <PasswordReset />}
        </>
      }
      right={
        <div className="flex h-full w-full flex-col items-center justify-center backdrop-brightness-35">
          <Welcome />
        </div>
      }
    />
  );
}
