import { Popover, Transition } from '@headlessui/react';
import { ReactNode, forwardRef, useRef, useImperativeHandle, useEffect } from 'react';

export type TooltipButtonProps = { open?: boolean; children: ReactNode };

export type TooltipButtonRef = HTMLButtonElement | null;

export const TooltipButton = forwardRef<HTMLButtonElement, TooltipButtonProps>((props, ref) => {
  const internalRef = useRef<TooltipButtonRef>(null);
  useImperativeHandle<TooltipButtonRef, TooltipButtonRef>(ref, () => internalRef.current);

  useEffect(() => {
    if (!internalRef.current) return;

    const autoClick = () => internalRef.current?.click();
    const button = internalRef.current;

    button.addEventListener('mouseenter', autoClick);
    return () => button.removeEventListener('mouseenter', autoClick);
  }, [internalRef]);

  return (
    <Popover.Button className="border-transparent focus:border-transparent focus:ring-0" {...props} ref={internalRef} />
  );
});

export type TooltipProps = {
  tooltip: string;
  icon: ReactNode;
};

export function Tooltip(props: TooltipProps) {
  return (
    <Popover>
      <TooltipButton>{props.icon}</TooltipButton>
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Popover.Panel className="absolute w-44 rounded-2xl bg-dark-gray p-2 py-4 text-center shadow-xl">
          {props.tooltip}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
