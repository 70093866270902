import { useMyRewardsQuery } from '@/graphql/generated';
import { appRoutes } from '@/helpers/routes.helper';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMe } from './useMe';

export function useMyRewards() {
  const navigate = useNavigate();
  const me = useMe();
  const { data } = useMyRewardsQuery(undefined, {
    enabled: !!me,
    refetchOnWindowFocus: 'always',
    refetchOnMount: 'always',
    staleTime: 0,
    cacheTime: 0,
  });

  useEffect(() => {
    if (!data?.myRewards.nodes.length) return;

    navigate(appRoutes.rewards);
    
  }, [data, navigate]);
}
