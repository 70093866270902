import { useMutation } from '@tanstack/react-query';
import { fetcherRest } from '@/helpers/fetch.helper';
import { appSessionStorage } from '@/helpers/storage.helper';
import { toast } from 'react-toastify';

export function useResentEmail() {
  return useMutation({
    mutationFn: async (email: string) => {
      fetcherRest(
        { url: `/user/email/resend-email-verify`, method: 'POST' },
        { email, gameId: appSessionStorage.getItem('gameId') },
      );
    },
    onSuccess: () => {
      toast.success('Verification email has been re-sent.');
    },
    onError: () => {
      toast.error('There was a problem. Please try again.');
    },
  });
}
