import { useEffect, useMemo } from 'react';

import { sendMetric } from './metics-sender';
import { UserCartItem } from '@/types/CartTypes';

const mapCartItemToMetricData = ({ product, quantity }: UserCartItem) => {
  return {
    qty: quantity,
    product_claims: {
      productId: product.variant.variantId,
      productName: product.productName,
      description: product.productDescription,
      currency: 'USD',
      price: product.variant.price,
    },
    rewardAmt: product.reward.scutis,
  };
};

export const useCartMetric = () => {
  useEffect(() => {
    sendMetric({ name: 'CART', customData: { action: 'Enter' } });
    return () => {
      sendMetric({ name: 'CART', customData: { action: 'Leave' } });
    };
  }, []);
};

export const useCartItemsMetrics = () => {
  return useMemo(() => {
    return {
      addToCartMetric: (cartItem: UserCartItem) => {
        sendMetric({
          name: 'CART',
          customData: { action: 'Add', ...mapCartItemToMetricData(cartItem) },
        });
      },
      changeCartMetric: (cartItem: UserCartItem) => {
        sendMetric({
          name: 'CART',
          customData: { action: 'ChangeCount', ...mapCartItemToMetricData(cartItem) },
        });
      },
      removeFromCartMetric: (cartItem: UserCartItem) => {
        sendMetric({ name: 'CART', customData: { action: 'Remove', ...mapCartItemToMetricData(cartItem) } });
      },
    };
  }, []);
};
