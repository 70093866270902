import { QueryClient } from '@tanstack/react-query';
import { NonIndexRouteObject, redirect } from 'react-router-dom';
import { useOrderDetailsQuery } from '@/graphql/generated';
import { fetcherGraphql } from '@/helpers/fetch.helper';
import { appLocalStorage } from '@/helpers/storage.helper';
import { appRoutes } from '@/helpers/routes.helper';

export const orderDetailsLoader =
  (queryClient: QueryClient): NonIndexRouteObject['loader'] =>
  async ({ params }) => {
    if (!appLocalStorage.getItem('refreshToken')) {
      return redirect(appRoutes.welcome);
    }
    const id = params.id!;
    return await queryClient.ensureQueryData({
      queryFn: fetcherGraphql(useOrderDetailsQuery.document, { id }),
      queryKey: useOrderDetailsQuery.getKey({ id }),
    });
  };
