import { QueryClient } from '@tanstack/react-query';
import { NonIndexRouteObject } from 'react-router-dom';
import { useProductOfferQuery } from '@/graphql/generated';
import { fetcherGraphql } from '@/helpers/fetch.helper';

export const productOfferLoader =
  (queryClient: QueryClient): NonIndexRouteObject['loader'] =>
  async ({ params }) => {
    const id = params.id!;
    return await queryClient.ensureQueryData({
      queryFn: fetcherGraphql(useProductOfferQuery.document, { id }),
      queryKey: useProductOfferQuery.getKey({ id }),
    });
  };
