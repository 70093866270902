import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/Common/Button';
import { Icon } from '@/components/Common/Icon';
import { OffersRow } from '@/components/Common/OffersRow';
import { appRoutes } from '@/helpers/routes.helper';
import { useRecommendedItems } from '@/hooks/useRecommendedItems';

export function EmptyCart() {
  const navigate = useNavigate();
  const { recommendedItems, isLoading } = useRecommendedItems();

  return (
    <div className="flex h-full flex-col">
      <div className="flex flex-1 items-center justify-center">
        <div className="flex flex-col items-center">
          <Icon name="CartBuyNow" className="h-16 text-black" />
          <h1 className="mb-4 mt-9 font-bold">Your Cart is Empty</h1>
          <p className="text-sm">Looks like you haven’t added any products to your Cart</p>
          <Button className="mt-14" onClick={() => navigate(appRoutes.store)}>
            Start shopping
          </Button>
        </div>
      </div>
      <div className="text-center">
        <h2 className="mb-2">Recommended Items</h2>
        <div>
          {isLoading ? <div className="h-[172px] xl:h-[252px]" /> : <OffersRow id={1} offers={recommendedItems} />}
        </div>
      </div>
    </div>
  );
}
