export const formatPrice = (value: number | string): string => {
  const v = typeof value === 'number' ? value : parseFloat(value);
  return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(v).replace('$', '');
};

export const getUpperDecimal = (value: number | string): string => {
  const strValue = formatPrice(value).split('.');
  return strValue[0];
};

export const getLowerDecimal = (value: number | string): string => {
  const strValue = formatPrice(value).split('.');
  return strValue.length > 1 ? strValue[1] : '00';
};

export const formatScore = (value: number | string): string => {
  const v = Math.floor(typeof value === 'number' ? value : parseFloat(value));
  return v.toLocaleString(undefined, { minimumFractionDigits: 0 });
};

export const between = (number: number, low: number, hight: number) => {
  const min = Math.min.apply(Math, [low, hight]);
  const max = Math.max.apply(Math, [low, hight]);
  return number >= min && number < max;
};
