import { Menu } from '@headlessui/react';
import { useRef } from 'react';
import { Button } from './Button';
import { Icon } from './Icon';

export interface DropDownItem {
  label: string;
  value: string | number;
  disabled?: boolean;
}

interface DropDownProps {
  className?: string;
  buttonClassName?: string;
  title?: string;
  value?: DropDownItem | null;
  options: DropDownItem[];
  onSelect: (item: DropDownItem) => void;
}

export function DropDown(props: DropDownProps) {
  const { className, buttonClassName, title = '', value, options, onSelect } = props;
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    dropdownRef?.current?.dispatchEvent(
      new KeyboardEvent('keydown', {
        key: 'Escape',
        bubbles: true,
        cancelable: true,
      }),
    );
  };

  const onlyOneOption = options.length === 1;

  return (
    <Menu>
      {({ open }) => {
        return (
          <div className={`sm:relative ${className}`}>
            <Menu.Button as="div">
              <Button variant="secondary" className={buttonClassName}>
                <span className="truncate">{value?.label || title} </span>
                {!onlyOneOption && <Icon name={open ? 'ChevronUp' : 'ChevronDown'} className="ml-3 h-3 shrink-0" />}
              </Button>
            </Menu.Button>
            {!onlyOneOption && (
              <Menu.Items
                ref={dropdownRef}
                className="fixed bottom-0 left-0 right-0 top-auto z-10 max-h-80 w-full overflow-y-auto rounded-t-xl bg-darker-gray sm:absolute sm:bottom-auto sm:left-0 sm:right-0 sm:top-[60px] sm:mt-[1px] sm:w-auto sm:rounded-xl sm:border sm:border-dark-blue sm:pt-0"
              >
                <div className="flex w-full justify-end p-4 sm:hidden" onClick={handleClose}>
                  <div className="flex h-10 w-10 items-center justify-center rounded-full bg-dark-gray">
                    <Icon name="Cross" className="h-4" />
                  </div>
                </div>
                {options.map((item) => (
                  <Menu.Item key={item.value} disabled={item.disabled}>
                    <div
                      className={`border-b-black p-4 text-center font-bold first:rounded-t-xl last:rounded-b-xl last:border-0  sm:border-b ${
                        item.disabled ? 'text-light-gray' : 'hover:bg-blue'
                      }`}
                      onClick={item.disabled ? undefined : () => onSelect(item)}
                    >
                      {item.label}
                    </div>
                  </Menu.Item>
                ))}
              </Menu.Items>
            )}
          </div>
        );
      }}
    </Menu>
  );
}
