import { useEffect } from 'react';

export function useZendesk(disable?: boolean) {
  useEffect(() => {
    return () => {
      if (!disable) {
        window.zE?.('messenger', 'hide');
      }
    };
  }, [disable]);

  return () => {
    if (!disable) {
      window.zE?.('messenger', 'show');
    }
  };
}
