import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Image } from './Image';
import { ImageModal } from './Image/ImageModal';
import { useState } from 'react';

export type ProductImagesProps = {
  className?: string;
  images: string[];
};

export function ProductImages({ className, images }: ProductImagesProps) {
  const [showModal, setShowModal] = useState<boolean | string>(false);
  return (
    <>
      {showModal && (
        <ImageModal onClose={() => setShowModal(false)}>
          <Image className={`max-h-screen rounded-2xl object-contain ${''}`} src={showModal as string} />
        </ImageModal>
      )}
      <Swiper
        pagination={{ dynamicBullets: true, clickable: true }}
        modules={[Pagination]}
        spaceBetween={15}
        simulateTouch
        className="swiper-product-images rounded-2xl pb-8"
      >
        {images.map((image) => (
          <SwiperSlide className="rounded-2xl" key={image} onClick={() => !showModal && setShowModal(image)}>
            <Image className={`rounded-2xl object-cover ${className}`} src={image} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
