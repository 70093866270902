import { useQueryClient } from '@tanstack/react-query';
import { useMeQuery, useMyAddressMutation } from '@/graphql/generated';
import { toast } from 'react-toastify';
import { getErrorMessage } from '@/utils/error.utils';
import { AddressFormData } from './AddressForm.schema';

export function useAddressForm() {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMyAddressMutation({
    onSuccess: () => {
      queryClient.refetchQueries(useMeQuery.getKey());
      toast.success('Updated successfully');
    },
    onError: (error) => toast.error(getErrorMessage(error), { autoClose: 8000 }),
  });

  return (data: AddressFormData) => {
    return mutateAsync({ input: data });
  };
}
