import { formatScore } from '@/utils/number.utils';
import { Image } from './Image/Image';

export type PriceProps = {
  className?: string;
  earn?: number;
};

export function ScutiEarn({ className, earn = 0 }: PriceProps) {
  return (
    <div className={`flex flex-row items-center space-x-2 rounded-lg border-2 border-yellow px-3 py-2 ${className}`}>
      <Image src="/images/ScutiCoin.png" className="h-7 w-7" />
      <span className="text-lg font-bold">Earn: +{formatScore(earn)}</span>
    </div>
  );
}
