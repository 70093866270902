import { useMemo } from 'react';
import { useLoaderData } from 'react-router-dom';
import { AppDownloadOfferQuery, useAppDownloadOfferQuery } from '@/graphql/generated';

const cleanUrl = (videoUrl?: string | null) => {
  const [url = ''] = videoUrl?.replace('SCT:', '').match(/http.*/) || [];
  return url;
};

type Return = {
  name: string;
  videoUrl: string;
  pcLink: string;
  androidId: string;
  appleId: string;
};

export function useAppDownloadOffer(id: string): Return | null {
  const initialData = useLoaderData() as AppDownloadOfferQuery;

  const { data } = useAppDownloadOfferQuery({ id: id! }, { initialData });

  return useMemo(() => {
    if (!data) return null;

    return {
      name: data.offer.name,
      videoUrl: cleanUrl(data.offer.media.videoUrl),
      pcLink: data.offer.pcLink?.toString() || '',
      androidId: data.offer.androidId?.toString() || '',
      appleId: data.offer.appleId?.toString() || '',
    };
  }, [data]);
}
