import { CampaignType, FilterInput, Filter_Type } from '@/graphql/generated';
import { OfferListItem } from '@/types/OfferTypes';
import { mapOfferItem } from '@/utils/offer.utils';
import { useOffers } from './useOffers';

type Return = {
  isLoading: boolean;
  recommendedItems: OfferListItem[];
};

export function useRecommendedItems(shopName?: string | null): Return {
  const filters: FilterInput[] = [
    { name: 'type', operator: Filter_Type.In, value: [CampaignType.Product, CampaignType.ProductListing] },
  ];
  if (shopName) filters.push({ name: 'shopName', operator: Filter_Type.Eq, value: [shopName] });

  const { data, isLoading } = useOffers({
    paging: { limit: 10, offset: 0 },
    filters,
  });

  if (!data) return { isLoading, recommendedItems: [] };

  return {
    isLoading,
    recommendedItems: data.nodes.map((item) => mapOfferItem(item)),
  };
}
