import { useMemo } from 'react';
import { useLoaderData } from 'react-router-dom';
import { VideoOfferQuery, useVideoOfferQuery } from '@/graphql/generated';

const cleanUrl = (videoUrl?: string | null) => {
  const [url = ''] = videoUrl?.match(/http.*/) || [];
  return url;
};

type Return = {
  name: string;
  videoUrl: string;
};

export function useCampaignOffer(id: string): Return | null {
  const initialData = useLoaderData() as VideoOfferQuery;

  const { data } = useVideoOfferQuery({ id: id! }, { initialData });

  return useMemo(() => {
    if (!data) return null;

    return {
      name: data?.offer.name,
      videoUrl: cleanUrl(data?.offer.media.videoUrl),
    };
  }, [data]);
}
