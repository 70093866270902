import { useMutation } from '@tanstack/react-query';
import { fetcherRest } from '@/helpers/fetch.helper';
import { RegisterInfo } from '@/types/UserTypes';
import { ForgotPasswordFormData } from './ForgotPasswordForm.schema';

export function useForgotPassword() {
  const { mutateAsync } = useMutation({
    mutationFn: async (data: ForgotPasswordFormData) =>
      fetcherRest<RegisterInfo>({ url: '/user/password/reset', method: 'POST' }, data),
  });

  return mutateAsync;
}
