import { useEffect, useRef } from 'react';

import { sendMetric } from './metics-sender';

export const useViewVideoMetric = (offerId: string, isPlaying: boolean) => {
  const playingRef = useRef<boolean>(false);
  const startedRef = useRef<Date | null>(null);

  useEffect(() => {
    if (!playingRef.current && isPlaying) {
      playingRef.current = true;
      startedRef.current = new Date();
    }
    return () => {
      if (startedRef.current && isPlaying) {
        sendMetric({
          name: 'VIEW_VIDEO',
          customData: { offerId, duration: new Date().getTime() - startedRef.current.getTime() },
        });
      }
    };
  }, [isPlaying, offerId]);
};
