import { messageSender } from '@/helpers/sdk-wrapper/wrapper-sender';
import { clearSession } from '@/utils/session.utils';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '@/helpers/routes.helper';
import { appSessionStorage } from '@/helpers/storage.helper';

export function useLogOut() {
  const navigate = useNavigate();

  return () => {
    const platform = appSessionStorage.getItem('platform');
    if (platform) messageSender({ message: 'LOG_OUT' });
    clearSession();
    navigate(appRoutes.welcome);
  };
}
