import { useNavigate } from 'react-router';
import { Button } from '@/components/Common/Button';
import { Icon } from '@/components/Common/Icon';
import { appRoutes } from '@/helpers/routes.helper';
import { cartStore } from '@/store/cart.store';

export function ProceedToPayment() {
  const navigate = useNavigate();

  return (
    <div className="flex h-full items-center justify-center">
      <div className="flex flex-col items-center text-center">
        <Icon name="CheckedStar" className="my-4 h-20 w-20" />
        <h1 className="my-4 font-semibold text-white">Please complete payment in your browser</h1>
        <Button
          className="mt-2"
          onClick={() => {
            cartStore.actions.clearAll();
            navigate(appRoutes.store);
          }}
        >
          Back to the Store
        </Button>
      </div>
    </div>
  );
}
