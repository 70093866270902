import { useState } from 'react';

import { sendMetric } from './metics-sender';

export const useCloseMetric = () => {
  const [shoppingStarted] = useState(new Date());

  return () =>
    sendMetric({
      name: 'CLOSE',
      customData: {
        duration: new Date().getTime() - shoppingStarted.getTime(),
      },
    });
};
