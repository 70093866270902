import { Slider } from '@/components/Common/Slider';
import { usePaymentWithScutis } from './PaymentWithScutisForm.hooks';
import { Button } from '@/components/Common/Button';
import { Icon } from '@/components/Common/Icon';
import { formatPrice } from '@/utils/number.utils';
import { PaymentWithScutisFormProgress } from './PaymentWithScutisFormProgress';
import { useNavigate } from 'react-router-dom';

export type PaymentWithScutisFormProps = {
  className?: string;
};

export function PaymentWithScutisForm({ className }: PaymentWithScutisFormProps) {
  const {
    totalOrder,
    payWithCurrency,
    usedScutis,
    maxToUse,
    scutiBalance,
    exchangeRate,
    transactionStatus,
    onChange,
    onPayWithScutis,
  } = usePaymentWithScutis();

  const navigate = useNavigate();

  return (
    <div className={`flex h-full flex-col justify-between  ${className}`}>
      <div className="flex w-full flex-col items-center  justify-center md:m-auto md:max-w-lg">
        <img alt="" className="h-20 lg:h-40" src="/images/ScutiCoin.png" />
        <p className="text-paragraph-lg mt-4 text-lg text-white">Balance: {scutiBalance - usedScutis}</p>
        <div className="mt-8 text-2xl font-semibold lg:mt-16 ">{usedScutis}</div>
        <div className="w-full px-3">
          <Slider
            className="mt-9"
            value={usedScutis}
            max={maxToUse}
            withButtons
            step={exchangeRate / 100}
            onChange={(value) => (typeof value === 'number' ? onChange(value) : onChange(value[0]))}
            autoFocus
          />
        </div>
        <p className="mt-8 text-xs text-light-gray">Select number of Scuti$ to use</p>
      </div>
      <div className="flex w-full flex-col justify-center space-y-8 md:m-auto md:max-w-lg">
        <div className="flex w-full items-center justify-between">
          <p className="text-light-gray">Total Order: </p>
          <p className="text-white">${formatPrice(totalOrder)}</p>
        </div>
        <div className="h-[1px] bg-darker-gray" />
        <div>
          <div className="flex w-full items-center justify-between">
            <div className="flex w-full items-center">
              <Icon name="DollarCoin" className="mr-2 h-8" />
              <div className="text-lg">You pay</div>
            </div>
            <div className="text-2xl">${formatPrice(payWithCurrency)}</div>
          </div>
        </div>
        <div className="flex-row space-y-2 pb-28 lg:pb-0">
          <Button className="w-full" onClick={onPayWithScutis}>
            Apply Scuti$
          </Button>
          <Button className="w-full" onClick={() => navigate(-1)} variant="secondary">
            Back
          </Button>
        </div>
      </div>
      {transactionStatus === 'inProgress' && <PaymentWithScutisFormProgress />}
    </div>
  );
}
