type Props = {
    className?: string;
    onClick?: () => void;
};

export function HelpCenter(props: Props) {
    return (
        <svg {...props} width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.33943 13.6629C2.15193 12.3973 0.678711 10.0312 0.678711 7.32143C0.678711 3.27902 3.95773 0 8.00014 0C12.0426 0 15.3216 3.27902 15.3216 7.32143C15.3216 10.0312 13.8484 12.3973 11.6609 13.6629V16.25C11.6609 16.6451 11.3417 16.9643 10.9466 16.9643H5.05371C4.65862 16.9643 4.33943 16.6451 4.33943 16.25V13.6629ZM5.32157 18.3929H10.6787C10.7769 18.3929 10.8573 18.4732 10.8573 18.5714V19.2857C10.8573 19.6808 10.5381 20 10.143 20H5.85728C5.46219 20 5.143 19.6808 5.143 19.2857V18.5714C5.143 18.4732 5.22335 18.3929 5.32157 18.3929Z"
                fill="white"
            />
        </svg>
    );
}
