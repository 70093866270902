import { memo, useEffect, useRef } from 'react';
import postscribe from '@/lib/postscribe/postscribe';

const INSTICATOR_VIDEO_TAG = `<script>!function(n){if(!window.cnx){window.cnx={},window.cnx.cmd=[];var t=n.createElement('iframe');t.src='javascript:false'; t.display='none',t.onload=function(){var n=t.contentWindow.document,c=n.createElement('script');c.src='//cd.connatix.com/connatix.player.js?cid=e208a2f1-d970-4702-8c0a-e6e5d478279a',c.setAttribute('async','1'),c.setAttribute('type','text/javascript'),n.body.appendChild(c)},n.head.appendChild(t)}}(document);</script><script id="8dc16f0c2d594740b3c9b5fdbe0f5ce4">(new Image()).src = 'https://capi.connatix.com/tr/si?token=4e0b3013-39c0-4925-ae17-49842fee4024&cid=e208a2f1-d970-4702-8c0a-e6e5d478279a'; cnx.cmd.push(function() { cnx({ playerId: "4e0b3013-39c0-4925-ae17-49842fee4024" }).render("8dc16f0c2d594740b3c9b5fdbe0f5ce4"); });</script>`;

export const InsticatorVideoAd = memo(() => {
  const adRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const node = adRef.current;
    postscribe(adRef.current, INSTICATOR_VIDEO_TAG, {
      done: () => {},
      error: (err: any) => {
        /* do nothing */
      },
    });

    return () => {
      postscribe(node, INSTICATOR_VIDEO_TAG, 'clear');
      if (node) {
        node.innerHTML = '';
      }
    };
  }, []);

  return (
    <div className="scuti-insticator-video mx-auto mt-8 max-w-3xl">
      <div className="mx-auto" ref={adRef} />
    </div>
  );
});
