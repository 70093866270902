import { useOffersByCategories } from '@/hooks/useOffersByCategories';
import { OfferListItem } from '@/types/OfferTypes';
import { getOrderedServerCategories, serverToClientToCategory } from '@/utils/category.utils';
import { mapOfferItem } from '@/utils/offer.utils';

export function useStore(): [string, OfferListItem[]][] {
  const { data } = useOffersByCategories();

  if (!data) return [];

  const { nodes } = data;
  const orderedCategories = getOrderedServerCategories(nodes.map(({ category }) => category));

  return orderedCategories.reduce<[string, OfferListItem[]][]>((acc, category) => {
    const match = nodes.find((n) => n.category === category);

    if (match) acc.push([serverToClientToCategory(match.category), match.nodes.map((offer) => mapOfferItem(offer))]);

    return acc;
  }, []);
}
