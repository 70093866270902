type Props = {
    className?: string;
    onClick?: () => void;
};

export function CB(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                clipRule="evenodd"
                d="M2.73223 5.73223C2.26339 6.20107 2 6.83696 2 7.5V13.75H22V7.5C22 6.83696 21.7366 6.20107 21.2678 5.73223C20.7989 5.26339 20.163 5 19.5 5H4.5C3.83696 5 3.20107 5.26339 2.73223 5.73223ZM2 17.5V16.25H22V17.5C22 18.163 21.7366 18.7989 21.2678 19.2678C20.7989 19.7366 20.163 20 19.5 20H4.5C3.83696 20 3.20107 19.7366 2.73223 19.2678C2.26339 18.7989 2 18.163 2 17.5ZM19.5416 8.19571C19.7436 8.19571 19.9143 8.31973 19.9767 8.51169C20.0391 8.70366 19.9738 8.90442 19.8106 9.02309L19.1581 9.49716C19.1393 9.51075 19.1319 9.53429 19.1386 9.55611L19.2995 10.0516L19.3194 10.1134L19.3882 10.3242C19.4504 10.5161 19.3853 10.7169 19.222 10.8356C19.1403 10.8949 19.0465 10.9245 18.9527 10.9245C18.8593 10.9245 18.7656 10.8951 18.684 10.8356L18.2126 10.4933C18.1491 10.447 18.0746 10.4241 18 10.4239C17.9254 10.4241 17.8509 10.447 17.7874 10.4933L17.3162 10.8356C17.2344 10.8951 17.1407 10.9245 17.0469 10.9245C16.9533 10.9245 16.8599 10.8949 16.778 10.8356C16.6149 10.7169 16.5496 10.5161 16.612 10.3242L16.6804 10.1134L16.7007 10.0516L16.8616 9.55611C16.8683 9.53429 16.8607 9.51075 16.8421 9.49716L16.1896 9.02309C16.0262 8.90442 15.9609 8.70366 16.0233 8.51169C16.0857 8.31973 16.2566 8.19571 16.4584 8.19571H17.2436C17.2792 8.19571 17.3106 8.17274 17.3219 8.13886L17.4209 7.83513L17.5648 7.39149C17.6274 7.19952 17.7983 7.0755 18.0002 7.0755C18.2021 7.0755 18.3726 7.19952 18.435 7.39149L18.6781 8.13886C18.6892 8.17274 18.7208 8.19571 18.7566 8.19571H19.5416Z"
                fill="white"
                fillRule="evenodd"
            />
        </svg>
    );
}
