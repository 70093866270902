import { QueryClient } from '@tanstack/react-query';
import { NonIndexRouteObject } from 'react-router-dom';
import { useVideoOfferQuery } from '@/graphql/generated';
import { fetcherGraphql } from '@/helpers/fetch.helper';

export const campaignOfferLoader =
  (queryClient: QueryClient): NonIndexRouteObject['loader'] =>
  async ({ params }) => {
    const id = params.id!;
    return await queryClient.ensureQueryData({
      queryFn: fetcherGraphql(useVideoOfferQuery.document, { id }),
      queryKey: useVideoOfferQuery.getKey({ id }),
    });
  };
