import { useEffect, useMemo, useState } from 'react';
import { PagingInput, useMyScutiTransactionsQuery } from '@/graphql/generated';
import { hasNextPage, pagingDefaults } from '@/utils/paging.utils';
import { ScutiTransactionItem } from '@/types/ScutiTransactionType';

type Return = {
  scutiTransactions: ScutiTransactionItem[];
  isLoading: boolean;
  hasNext: boolean;
  onLoadNext: () => void;
};

export function useMyScutiTransactions(): Return {
  const [paging, setPaging] = useState<PagingInput>(pagingDefaults);
  const [hasNext, setHasNext] = useState(false);

  const [scutiTransactions, setScutiTransactions] = useState<ScutiTransactionItem[]>([]);

  const { data, isLoading } = useMyScutiTransactionsQuery(
    {
      paging,
      sorting: { name: 'createdAt', dir: 'desc' },
    },
    { staleTime: 0 },
  );

  useEffect(() => {
    if (!data) return;
    setHasNext(hasNextPage(data.myScutiTransactions.paging));
    setScutiTransactions((prev) => [...prev, ...data.myScutiTransactions.nodes]);
  }, [data]);

  return useMemo(() => {
    return {
      isLoading,
      scutiTransactions,
      hasNext,
      onLoadNext: () =>
        setPaging((prev = pagingDefaults) => ({
          ...prev,
          offset: (prev.offset ?? pagingDefaults.offset) + (prev.limit ?? pagingDefaults.limit),
        })),
    };
  }, [hasNext, isLoading, scutiTransactions]);
}
