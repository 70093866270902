import { useMemo } from 'react';
import { useLoaderData } from 'react-router-dom';
import { VideoOfferQuery, useVideoOfferQuery } from '@/graphql/generated';

export function useVideoOffer(id: string) {
  const initialData = useLoaderData() as VideoOfferQuery;

  const { data } = useVideoOfferQuery({ id: id! }, { initialData });

  const videoUrl = data?.offer.media.videoUrl || '';
  const isMp4 = videoUrl.endsWith('.mp4') || videoUrl.includes('.mp4');

  return useMemo(() => {
    return isMp4
      ? {
          video: {
            media: {
              videoUrl: [
                {
                  src: videoUrl,
                  type: 'video/mp4',
                },
              ],
            },
          },
          isMp4,
        }
      : {
          video: {
            media: {
              videoUrl,
            },
          },
          isMp4,
        };
  }, [isMp4, videoUrl]);
}
