import { Congratulations } from './Congratulations';

export function RewardsPage() {
  return (
    <div className="relative h-screen w-screen bg-dark-gray">
      <Congratulations />
      <img
        alt=""
        className="absolute left-0 top-0 hidden w-full max-w-lg overflow-hidden object-cover md:visible"
        src="/backgrounds/WaveSmall.png"
      />
      <img
        alt=""
        className="absolute -bottom-16 right-0 hidden w-full max-w-lg object-cover md:visible md:bottom-0"
        src="/backgrounds/WaveBig.png"
      />
      <img
        alt=""
        className="absolute hidden w-40 rotate-12 blur-sm md:bottom-40 md:left-72 md:block lg:bottom-60 lg:left-96 lg:w-60 lg:blur-none"
        src="/images/ScutiCoin45.png"
      />
      <img
        alt=""
        className="lg:blur-xs absolute hidden w-40 -rotate-12 blur-sm md:bottom-28 md:left-40 md:block lg:bottom-48 lg:w-72"
        src="/images/ScutiCoin45.png"
      />
      <img
        alt=""
        className="absolute -right-40 hidden w-full max-w-lg rotate-45 blur-md md:-top-10 md:block lg:-top-40 lg:max-w-2xl"
        src="/images/ScutiCoin45.png"
      />
      <img
        alt=""
        className="absolute -left-72 hidden w-full max-w-lg rotate-0 blur-lg md:bottom-40 md:block lg:bottom-40 lg:max-w-2xl"
        src="/images/ScutiCoin45.png"
      />
    </div>
  );
}
