import { Button } from '@/components/Common/Button';
import { Icon } from '@/components/Common/Icon';
import { Modal } from '@/components/Common/Modal';
import { appRoutes } from '@/helpers/routes.helper';
import { useLogin } from '@/hooks/useLogin';
import { Credentials } from '@/types/UserTypes';
import { Spinner } from '@/components/Common/Spinner';
import { Link } from 'react-router-dom';
import { useResentEmail } from '@/hooks/useResentEmail';

export type RegistrationFormProps = {
  className?: string;
  credentials: Credentials;
};

export function VerifyEmail({ className, credentials }: RegistrationFormProps) {
  const { handleLogin } = useLogin();

  const { isLoading, mutateAsync: resendEmail } = useResentEmail();

  return (
    <Modal className="w-full flex-col items-center justify-center md:w-[60%] md:px-16 md:py-12 lg:w-[45%] xl:w-[35%]">
      <div className={className}>
        <div className="mb-2 flex h-full flex-col items-center justify-center">
          <Icon name="Email" className="h-20" />
        </div>
        <h1 className="mb-4 text-center">Check Your Email</h1>
        <p className="text-paragraph-sm text-secondary-200 text-left">
          Please open the e-mail from Scuti and follow its instructions to verify your account an start earning daily
          rewards.
        </p>
        <p className="text-paragraph-sm text-secondary-200 my-8 text-left">
          Follow the instructions to verify your account.
        </p>
        <div className="my-10 flex-col">
          <div className="mb-3 flex items-center">
            <Icon name="Star" className="mr-3 h-5 shrink-0  text-blue" />
            <p className="font-bold text-white">Start Discovering Great Offers</p>
          </div>
          <div className="mb-3 flex items-center">
            <Icon name="Star" className="mr-3 h-5 shrink-0  text-blue" />
            <p className="font-bold text-white">Earn more Scuti$ Every Day</p>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <Button className="w-full" onClick={() => handleLogin(credentials)}>
            I Verified My Account
          </Button>
          <Button className="w-full" variant="secondary" onClick={() => resendEmail(credentials.email)}>
            {isLoading ? <Spinner className="h-6 w-6" spinnerClassName="border-gray" /> : 'Resend Email'}
          </Button>
          <Link className="w-full py-4 text-center" to={appRoutes.store}>
            Skip for Now
          </Link>
        </div>
      </div>
    </Modal>
  );
}
