import { countryPhoneLocaleMap } from '@/utils/phone.utils';
import * as yup from 'yup';
import validator from 'validator';
import type { MobilePhoneLocale } from 'validator/lib/isMobilePhone';

export const schema = yup
  .object({
    address1: yup.string().required('Field is required'),
    address2: yup.string().nullable(),
    country: yup.string().required('Field is required'),
    phone: yup
      .string()
      .when('country', ([country], schema) => {
        const _country: keyof typeof countryPhoneLocaleMap = countryPhoneLocaleMap.hasOwnProperty(country)
          ? country
          : 'US';

        return schema.test('country phone number', 'Invalid phone number', (phone) => {
          try {
            return validator.isMobilePhone(phone || '', countryPhoneLocaleMap[_country] as MobilePhoneLocale);
          } catch (e) {
            // in-case locale not found defined in validator
            return validator.isMobilePhone(phone || '');
          }
        });
      })
      .required('Field is required'),
    city: yup.string().required('Field is required'),
    state: yup.string().nullable(),
    zipCode: yup.string().required('Field is required'),
  })
  .required('Field is required');

export type AddressFormData = yup.InferType<typeof schema>;
