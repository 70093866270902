import { between } from '@/utils/number.utils';
import { useEffect, useState } from 'react';

type ScreenSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | null;

// Took from tailwind (customized)
// screens: {
//    custom size
//   'xs': '375px',
//   // => @media (min-width: 375px) { ... }

//   'sm': '640px',
//   // => @media (min-width: 640px) { ... }

//   'md': '768px',
//   // => @media (min-width: 768px) { ... }

//   'lg': '1024px',
//   // => @media (min-width: 1024px) { ... }

//   'xl': '1280px',
//   // => @media (min-width: 1280px) { ... }

//   '2xl': '1536px',
//   // => @media (min-width: 1536px) { ... }
// }

function handleResize() {
  if (between(window.innerWidth, 0, 620)) return 'xs';
  if (between(window.innerWidth, 620, 768)) return 'sm';
  if (between(window.innerWidth, 768, 1024)) return 'md';
  if (between(window.innerWidth, 1024, 1280)) return 'lg';
  if (between(window.innerWidth, 1280, 1536)) return 'xl';
  if (between(window.innerWidth, 1536, 1600)) return '2xl';
  if (window.innerWidth >= 1600) return '3xl';
  return null;
}

export function useScreenSize() {
  const [screenSize, setScreenSize] = useState<ScreenSize>(handleResize());

  useEffect(() => {
    // Add event listener
    window.addEventListener('resize', () => setScreenSize(handleResize));
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return screenSize;
}
