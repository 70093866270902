type Props = {
    className?: string;
    onClick?: () => void;
};

export function Visible(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.2851 12C10.2851 12.6789 10.5548 13.3299 11.0348 13.81C11.5148 14.29 12.1659 14.5597 12.8448 14.5597C13.5236 14.5597 14.1747 14.29 14.6547 13.81C15.1348 13.3299 15.4044 12.6789 15.4044 12C15.4044 11.3211 15.1348 10.6701 14.6547 10.19C14.1747 9.70999 13.5236 9.44031 12.8448 9.44031C12.1659 9.44031 11.5148 9.70999 11.0348 10.19C10.5548 10.6701 10.2851 11.3211 10.2851 12ZM22.7681 11.4104C20.6015 6.84635 17.3265 4.5495 12.9362 4.5495C8.54358 4.5495 5.27085 6.84635 3.10426 11.4126C3.01736 11.5967 2.97229 11.7976 2.97229 12.0011C2.97229 12.2046 3.01736 12.4056 3.10426 12.5896C5.27085 17.1536 8.54586 19.4505 12.9362 19.4505C17.3288 19.4505 20.6015 17.1536 22.7681 12.5873C22.9441 12.2171 22.9441 11.7874 22.7681 11.4104ZM12.8448 16.0223C10.6233 16.0223 8.8224 14.2214 8.8224 12C8.8224 9.77856 10.6233 7.97764 12.8448 7.97764C15.0662 7.97764 16.8671 9.77856 16.8671 12C16.8671 14.2214 15.0662 16.0223 12.8448 16.0223Z"
                fill="currentColor"
            />
        </svg>
    );
}
