type Props = {
    className?: string;
    onClick?: () => void;
};

export function Warning(props: Props) {
    return (
        <svg {...props} fill="none" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
            <path
                clipRule="evenodd"
                d="M44.0784 10.125C40.7105 4.29167 32.2908 4.29166 28.9229 10.125L5.10721 51.375C1.73933 57.2083 5.94916 64.5 12.6849 64.5H60.3163C67.0521 64.5 71.2619 57.2084 67.8941 51.375L44.0784 10.125ZM32.5716 57L32.5716 34.6964H40.3829V57H32.5716ZM40.6619 25.6992C40.9098 26.2674 41.0338 26.8672 41.0338 27.4986C41.0338 28.0984 40.9098 28.6667 40.6619 29.2033C40.4449 29.7716 40.1194 30.2451 39.6854 30.624C39.2825 31.0344 38.802 31.35 38.2441 31.571C37.6861 31.8236 37.0972 31.9499 36.4772 31.9499C35.8573 31.9499 35.2684 31.8236 34.7104 31.571C34.1525 31.35 33.672 31.0344 33.269 30.624C32.8661 30.2136 32.5406 29.7242 32.2926 29.156C32.0756 28.6193 31.9672 28.0511 31.9672 27.4513C31.9672 26.8199 32.0911 26.2358 32.3391 25.6992C32.5871 25.1625 32.9126 24.689 33.3155 24.2786C33.7185 23.8682 34.1834 23.5525 34.7104 23.3315C35.2683 23.1105 35.8573 23 36.4772 23C37.0972 23 37.6861 23.1263 38.2441 23.3788C38.802 23.5998 39.2825 23.8997 39.6854 24.2786C40.1194 24.689 40.4449 25.1625 40.6619 25.6992Z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </svg>
    );
}
