import { Modal } from '@/components/Common/Modal';
import { Icon } from '@/components/Common/Icon';
import { useDeleteAccount } from './DeleteAccountModal.hooks';
import { Spinner } from '@/components/Common/Spinner';
import { Button } from '@/components/Common/Button';

type DeleteAccountModalProps = {
  onDismiss?: () => void;
};

export function DeleteAccountModal({ onDismiss }: DeleteAccountModalProps) {
  const { mutateAsync: handleDeleteAccount, isLoading } = useDeleteAccount();

  return (
    <Modal className="w-[500px] bg-darker-gray text-center md:bg-darker-gray">
      <div className="flex flex-col p-4">
        <div className="cursor-pointer self-end">
          <Icon name="Cross" className="h-6 w-6" onClick={onDismiss} />
        </div>
        <div className="self-center">
          <Icon name="Warning" className="mb-4 h-16 w-16 text-red" />
        </div>
        <h1 className="mt-4 text-3xl font-bold">Delete Account?</h1>
        <p className="mt-8 px-10 text-sm text-white">
          Please confirm you would like to delete your Scuti account. Deleting your account will remove your account
          from our system including the Scuti Rewards currently earned.
        </p>
        <Button onClick={() => handleDeleteAccount()} disabled={isLoading} variant="warning">
          {isLoading ? <Spinner className="h-6 w-6" spinnerClassName="border-gray" /> : 'Delete Account'}
        </Button>
      </div>
    </Modal>
  );
}
