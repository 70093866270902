import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input } from '@/components/Common/Input';
import * as yup from 'yup';
import { Button } from '@/components/Common/Button';
import { CartType, cartStore } from '@/store/cart.store';
import { useValidateCodesMutation } from '@/graphql/generated';
import { toast } from 'react-toastify';
import { getErrorMessage } from '@/utils/error.utils';
import { useSnapshot } from 'valtio';
import { Icon } from '@/components/Common/Icon';

const schema = yup.object({
  promoCode: yup.string().max(25, 'Invalid Promo Code').trim(),
});
type FormData = yup.InferType<typeof schema>;

const CheckoutPromoCodes = () => {
  const {
    reset,
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      promoCode: '',
    },
    mode: 'all',
  });

  const validatePromoCode = useValidateCodesMutation();
  const { promoCodes } = useSnapshot(cartStore.state) as CartType;

  const applyPromoCode = useCallback(
    async (code: string) => {
      try {
        const validCode = await validatePromoCode.mutateAsync({
          newCode: code,
          activeCodes: promoCodes,
        });
        cartStore.actions.promoCodes.add(validCode.validateCodes);
        toast.success(`${code} has been applied succesfully.`);
      } catch (e) {
        toast.error(getErrorMessage(e));
      }
    },
    [validatePromoCode, promoCodes],
  );

  const removePromoCode = useCallback(async (id: string) => {
    cartStore.actions.promoCodes.remove(id);
  }, []);

  const isPromoCodeValid = useCallback(
    async (data: FormData) => {
      if (data.promoCode) {
        await applyPromoCode(data.promoCode);
        reset();
      }
    },
    [applyPromoCode, reset],
  );

  const handlePromoCodeKeyDown = useCallback((event: React.KeyboardEvent) => {
    if (event.code === 'Space') {
      event.preventDefault();
    }
  }, []);

  const handlePromoCodeChange = useCallback((event: React.FormEvent<HTMLInputElement>) => {
    (event.target as HTMLInputElement).value = `${(event.target as HTMLInputElement).value}`
      .toUpperCase()
      .replace(/\s+/gi, '');
  }, []);

  return (
    <div className="flex flex-col">
      {promoCodes.length ? (
        <div className="mb-4 flex gap-2">
          {promoCodes.map((p) => (
            <span
              className="text-blue-800 inline-flex items-center gap-1.5 rounded-full bg-blue py-1.5 pl-3 pr-2 text-sm font-medium"
              key={p.id}
            >
              <strong>{p.code.toUpperCase()}</strong> - {p.description}
              <button
                onClick={() => removePromoCode(p.id)}
                type="button"
                className="inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-light-blue hover:bg-light-blue hover:text-blue focus:bg-light-blue focus:text-light-blue focus:outline-none"
              >
                <span className="sr-only">Remove badge</span>
                <Icon name="Cross" className="h-2 w-2" />
              </button>
            </span>
          ))}
        </div>
      ) : null}

      <div>
        <form onSubmit={handleSubmit(isPromoCodeValid)} className="flex items-center">
          <Controller
            control={control}
            name="promoCode"
            render={({ field, fieldState }) => (
              <Input
                maxLength={25}
                error={fieldState.error?.message}
                label=""
                placeholder="Enter Promo Code"
                type="text"
                onKeyDown={handlePromoCodeKeyDown}
                {...field}
                onChange={(e) => {
                  handlePromoCodeChange(e);
                  field?.onChange(e);
                }}
              />
            )}
          />
          <Button disabled={isSubmitting} variant="primary" className="inline h-10">
            Add
          </Button>
        </form>
      </div>
    </div>
  );
};

export default CheckoutPromoCodes;
