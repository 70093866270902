import { useEffect } from 'react';

import { sendMetric } from './metics-sender';

export const useEndSessionMetric = () => {
  useEffect(() => {
    let shoppingStarted = new Date();

    const onVisible = () => {
      if (window.document.visibilityState === 'visible') {
        shoppingStarted = new Date();
      }
      if (window.document.visibilityState === 'hidden') {
        sendMetric({ name: 'END_SESSION', customData: { duration: new Date().getTime() - shoppingStarted.getTime() } });
      }
    };

    window.addEventListener('visibilitychange', onVisible);

    return () => {
      window.removeEventListener('visibilitychange', onVisible);
      sendMetric({ name: 'END_SESSION', customData: { duration: new Date().getTime() - shoppingStarted.getTime() } });
    };
  }, []);
};
