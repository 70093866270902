import { ScutiMetric } from './types';
import { freshToken } from '../freshToken.helper';
import { appSessionStorage } from '../storage.helper';
import { getDevice, getPlatform } from '@/utils/platform.utils';

export const sendMetric = async (metric: ScutiMetric): Promise<void> => {
  try {
    const userToken = await freshToken.getToken();

    if (!process.env.REACT_APP_IS_TEST && navigator.sendBeacon) {
      navigator.sendBeacon(
        `${process.env.REACT_APP_API_URL}/metric/register-text${userToken ? `?access_token=${userToken}` : ''}`,
        JSON.stringify({
          ...metric,
          headers: {
            gameId: appSessionStorage.getItem('gameId'),
            sessionId: appSessionStorage.getItem('sessionId'),
            gameUserId: appSessionStorage.getItem('gameUserId'),
            platform: getPlatform(),
            device: getDevice(),
          },
        }),
      );
    }
  } catch (error) {
    console.error('metric error:', error);
  }
};
