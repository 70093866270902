type Props = {
    className?: string;
    onClick?: () => void;
};

export function Filter(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                clipRule="evenodd"
                d="M16 7V5H3C2.44772 5 2 5.44772 2 6C2 6.55228 2.44772 7 3 7H16ZM18 7H21C21.5523 7 22 6.55228 22 6C22 5.44772 21.5523 5 21 5H18V7Z"
                fill="currentColor"
                fillRule="evenodd"
            />
            <path
                clipRule="evenodd"
                d="M9 13L9 11H3C2.44772 11 2 11.4477 2 12C2 12.5523 2.44772 13 3 13H9ZM11 13H21C21.5523 13 22 12.5523 22 12C22 11.4477 21.5523 11 21 11H11L11 13Z"
                fill="currentColor"
                fillRule="evenodd"
            />
            <path
                clipRule="evenodd"
                d="M4 19L4 17H3C2.44772 17 2 17.4477 2 18C2 18.5523 2.44772 19 3 19H4ZM6 19H21C21.5523 19 22 18.5523 22 18C22 17.4477 21.5523 17 21 17H6L6 19Z"
                fill="currentColor"
                fillRule="evenodd"
            />
            <path
                clipRule="evenodd"
                d="M18 9C17.4477 9 17 8.55229 17 8V4C17 3.44772 17.4477 3 18 3C18.5523 3 19 3.44772 19 4V8C19 8.55228 18.5523 9 18 9ZM11 15C10.4477 15 10 14.5523 10 14V10C10 9.44772 10.4477 9 11 9C11.5523 9 12 9.44771 12 10L12 14C12 14.5523 11.5523 15 11 15ZM5 20C5 20.5523 5.44772 21 6 21C6.55229 21 7 20.5523 7 20L7 16C7 15.4477 6.55228 15 6 15C5.44772 15 5 15.4477 5 16L5 20Z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </svg>
    );
}
