type Props = {
    className?: string;
    onClick?: () => void;
};

export function Email(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M56.3 24H15.7C13.3926 24 11.1797 24.9166 9.54817 26.5482C7.9166 28.1797 7 30.3926 7 32.7V61.7C7 64.0074 7.9166 66.2203 9.54817 67.8518C11.1797 69.4834 13.3926 70.4 15.7 70.4H56.3C58.6074 70.4 60.8203 69.4834 62.4518 67.8518C64.0834 66.2203 65 64.0074 65 61.7V32.7C65 30.3926 64.0834 28.1797 62.4518 26.5482C60.8203 24.9166 58.6074 24 56.3 24ZM56.3 29.8L37.45 42.763C37.0091 43.0175 36.5091 43.1515 36 43.1515C35.4909 43.1515 34.9909 43.0175 34.55 42.763L15.7 29.8H56.3Z"
                fill="#101015"
            />
            <path
                clipRule="evenodd"
                d="M52.9041 13.9401C52.3854 12.3442 50.966 11.3131 49.2874 11.3131H42.7604C42.4628 11.3131 42.2003 11.1222 42.108 10.8405L40.0872 4.62702C39.5685 3.03108 38.1508 2 36.4721 2H36.4689C34.7902 2 33.3725 3.03108 32.8522 4.62702L31.6556 8.31535L30.833 10.8405C30.7391 11.1222 30.4782 11.3131 30.1822 11.3131H23.6536C21.9765 11.3131 20.5556 12.3442 20.0369 13.9401C19.5182 15.5361 20.0608 17.2052 21.4196 18.1917L26.8439 22.1331C26.9983 22.246 27.0619 22.4417 27.0062 22.6231L25.668 26.7427L25.4994 27.2566L24.9313 29.0085C24.4126 30.6044 24.9552 32.2736 26.3109 33.2601C26.9919 33.7534 27.7684 34 28.5465 34C29.3261 34 30.1058 33.755 30.7852 33.2601L34.7027 30.4151C35.231 30.03 35.8499 29.8391 36.4705 29.8375C37.0911 29.8391 37.71 30.03 38.2383 30.4151L42.1573 33.2601C42.8352 33.755 43.6148 34 44.3913 34C45.171 34 45.9507 33.7534 46.6301 33.2601C47.9874 32.2736 48.5284 30.6044 48.0112 29.0085L47.44 27.2566L47.2745 26.7427L45.9364 22.6231C45.8807 22.4417 45.9427 22.246 46.0987 22.1331L51.523 18.1917C52.8802 17.2052 53.4228 15.5361 52.9041 13.9401Z"
                fill="url(#paint0_linear_2155_6007)"
                fillRule="evenodd"
            />
            <path
                clipRule="evenodd"
                d="M65.201 5.32298C65.0567 4.87882 64.6616 4.59187 64.1945 4.59187H62.378C62.2952 4.59187 62.2221 4.53873 62.1964 4.46035L61.634 2.73111C61.4897 2.28695 61.0951 2 60.6279 2H60.627C60.1599 2 59.7653 2.28695 59.6205 2.73111L59.2875 3.75758L59.0585 4.46035C59.0324 4.53873 58.9598 4.59187 58.8774 4.59187H57.0605C56.5938 4.59187 56.1983 4.87882 56.054 5.32298C55.9096 5.76714 56.0606 6.23166 56.4388 6.50622L57.9484 7.6031C57.9913 7.63454 58.009 7.68901 57.9935 7.73949L57.6211 8.88597L57.5742 9.02901L57.4161 9.51656C57.2717 9.96072 57.4227 10.4252 57.8 10.6998C57.9896 10.8371 58.2057 10.9057 58.4222 10.9057C58.6392 10.9057 58.8562 10.8375 59.0453 10.6998L60.1355 9.90802C60.2825 9.80086 60.4548 9.74772 60.6275 9.74728C60.8002 9.74772 60.9724 9.80086 61.1195 9.90802L62.2102 10.6998C62.3988 10.8375 62.6158 10.9057 62.8319 10.9057C63.0489 10.9057 63.2659 10.8371 63.4549 10.6998C63.8327 10.4252 63.9832 9.96072 63.8393 9.51656L63.6803 9.02901L63.6343 8.88597L63.2619 7.73949C63.2464 7.68901 63.2636 7.63454 63.307 7.6031L64.8166 6.50622C65.1944 6.23166 65.3454 5.76714 65.201 5.32298Z"
                fill="url(#paint1_linear_2155_6007)"
                fillRule="evenodd"
            />
            <path
                clipRule="evenodd"
                d="M16.201 5.32298C16.0567 4.87882 15.6616 4.59187 15.1945 4.59187H13.378C13.2952 4.59187 13.2221 4.53873 13.1964 4.46035L12.634 2.73111C12.4897 2.28695 12.0951 2 11.6279 2H11.627C11.1599 2 10.7653 2.28695 10.6205 2.73111L10.2875 3.75758L10.0585 4.46035C10.0324 4.53873 9.9598 4.59187 9.87743 4.59187H8.06051C7.59377 4.59187 7.19832 4.87882 7.05396 5.32298C6.9096 5.76714 7.0606 6.23166 7.43878 6.50622L8.94838 7.6031C8.99133 7.63454 9.00904 7.68901 8.99354 7.73949L8.62113 8.88597L8.57419 9.02901L8.4161 9.51656C8.27174 9.96072 8.42274 10.4252 8.80003 10.6998C8.98956 10.8371 9.20566 10.9057 9.4222 10.9057C9.63919 10.9057 9.85617 10.8375 10.0453 10.6998L11.1355 9.90802C11.2825 9.80086 11.4548 9.74772 11.6275 9.74728C11.8002 9.74772 11.9724 9.80086 12.1195 9.90802L13.2102 10.6998C13.3988 10.8375 13.6158 10.9057 13.8319 10.9057C14.0489 10.9057 14.2659 10.8371 14.4549 10.6998C14.8327 10.4252 14.9832 9.96072 14.8393 9.51656L14.6803 9.02901L14.6343 8.88597L14.2619 7.73949C14.2464 7.68901 14.2636 7.63454 14.307 7.6031L15.8166 6.50622C16.1944 6.23166 16.3454 5.76714 16.201 5.32298Z"
                fill="url(#paint2_linear_2155_6007)"
                fillRule="evenodd"
            />
            <defs>
                <linearGradient gradientUnits="userSpaceOnUse" id="paint0_linear_2155_6007" x1="36.4705" x2="36.4705" y1="-5.23529" y2="29.4706">
                    <stop stopColor="#1D35EE" />
                    <stop offset="1" stopColor="#1024A3" />
                </linearGradient>
                <linearGradient gradientUnits="userSpaceOnUse" id="paint1_linear_2155_6007" x1="60.6275" x2="60.6275" y1="-0.0136059" y2="9.64516">
                    <stop stopColor="#1D35EE" />
                    <stop offset="1" stopColor="#1024A3" />
                </linearGradient>
                <linearGradient gradientUnits="userSpaceOnUse" id="paint2_linear_2155_6007" x1="11.6275" x2="11.6275" y1="-0.0136059" y2="9.64516">
                    <stop stopColor="#1D35EE" />
                    <stop offset="1" stopColor="#1024A3" />
                </linearGradient>
            </defs>
        </svg>
    );
}
