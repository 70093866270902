type Props = {
    className?: string;
    onClick?: () => void;
};

export function CartAdd(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path clipRule="evenodd" d="M20 1H19V3L17 3V4H19V6H20V4H22V3L20 3V1Z" fill="currentColor" fillRule="evenodd" />
            <path
                d="M13.9407 20.8225C13.9407 21.8667 14.7619 22.7143 15.7778 22.7143C16.7918 22.7143 17.6148 21.8667 17.6148 20.8225C17.6148 19.7763 16.7918 18.9306 15.7778 18.9306C14.7619 18.9306 13.9407 19.7763 13.9407 20.8225ZM4.75555 20.8225C4.75555 21.8667 5.57763 22.7143 6.59259 22.7143C7.60572 22.7143 8.42963 21.8667 8.42963 20.8225C8.42963 19.7763 7.60572 18.9306 6.59259 18.9306C5.57763 18.9306 4.75555 19.7763 4.75555 20.8225ZM8.01354 15.5677L18.1797 12.5767C18.3735 12.519 18.5333 12.3034 18.5333 12.0943V6.89182H5.67407V5.37836C5.67407 5.17026 5.50782 5 5.30758 5H2.36649C2.16533 5 2 5.17026 2 5.37836V6.89182H3.83704L5.59141 15.3643L5.67407 16.2563V17.8161C5.67407 18.0233 5.83941 18.1945 6.04148 18.1945H18.1659C18.368 18.1945 18.5333 18.0223 18.5333 17.8161V16.3509H8.20183C7.14554 16.3509 7.12349 15.8297 8.01354 15.5677Z"
                fill="currentColor"
            />
        </svg>
    );
}
