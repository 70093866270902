import { ChangePasswordForm } from '@/components/Form/ChangePasswordForm';

export function ChangePassword() {
  return (
    <div className="flex h-full flex-col pb-8">
      <h2 className="mb-8 font-semibold">Edit Password</h2>
      <ChangePasswordForm />
    </div>
  );
}
