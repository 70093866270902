type Props = {
  className?: string;
  onClick?: () => void;
};

export function CartBuyNow(props: Props) {
  return (
    <svg {...props} width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M49.3333 61.1651C49.3333 64.9379 52.3133 68 56 68C59.68 68 62.6667 64.9379 62.6667 61.1651C62.6667 57.3853 59.68 54.3301 56 54.3301C52.3133 54.3301 49.3333 57.3853 49.3333 61.1651ZM16 61.1651C16 64.9379 18.9833 68 22.6667 68C26.3433 68 29.3333 64.9379 29.3333 61.1651C29.3333 57.3853 26.3433 54.3301 22.6667 54.3301C18.9833 54.3301 16 57.3853 16 61.1651ZM27.8233 42.18L64.7167 31.374C65.42 31.1655 66 30.3863 66 29.6311V10.835H19.3333V5.36699C19.3333 4.61515 18.73 4 18.0033 4H7.33C6.6 4 6 4.61515 6 5.36699V10.835H12.6667L19.0333 41.4453L19.3333 44.668V50.3034C19.3333 51.0518 19.9333 51.6704 20.6667 51.6704H64.6667C65.4 51.6704 66 51.0484 66 50.3034V45.0097H28.5067C24.6733 45.0097 24.5933 43.1267 27.8233 42.18Z"
        fill="#101015"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.8172 13.0745C55.3795 11.7279 54.1819 10.8579 52.7655 10.8579H47.2584C47.0074 10.8579 46.7858 10.6968 46.708 10.4592L45.0029 5.21655C44.5653 3.86997 43.369 3 41.9527 3H41.95C40.5336 3 39.3374 3.86997 38.8984 5.21655L37.8888 8.32858L37.1947 10.4592C37.1154 10.6968 36.8953 10.8579 36.6456 10.8579H31.1371C29.722 10.8579 28.5231 11.7279 28.0855 13.0745C27.6478 14.4211 28.1056 15.8294 29.2521 16.6618L33.8289 19.9873C33.9591 20.0826 34.0128 20.2477 33.9658 20.4008L32.8367 23.8766L32.6944 24.3103L32.2151 25.7884C31.7775 27.135 32.2353 28.5433 33.3791 29.3757C33.9537 29.7919 34.6089 30 35.2654 30C35.9233 30 36.5811 29.7932 37.1544 29.3757L40.4597 26.9752C40.9055 26.6503 41.4277 26.4892 41.9513 26.4879C42.4749 26.4892 42.9972 26.6503 43.4429 26.9752L46.7496 29.3757C47.3215 29.7932 47.9794 30 48.6345 30C49.2924 30 49.9502 29.7919 50.5235 29.3757C51.6687 28.5433 52.1251 27.135 51.6888 25.7884L51.2068 24.3103L51.0672 23.8766L49.9381 20.4008C49.8911 20.2477 49.9435 20.0826 50.0751 19.9873L54.6518 16.6618C55.797 15.8294 56.2548 14.4211 55.8172 13.0745Z"
        fill="url(#paint0_linear_3092_12035)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3092_12035"
          x1="41.9513"
          y1="-3.10477"
          x2="41.9513"
          y2="26.1783"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1D35EE" />
          <stop offset="1" stopColor="#1024A3" />
        </linearGradient>
      </defs>
    </svg>
  );
}
