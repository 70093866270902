import { useProductCategoriesQuery } from '@/graphql/generated';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Pill } from '@/components/Common/Pill';
import { chunk } from '@/utils/array.utils';
import { useScreenSize } from '@/hooks/useScreenSize';
import { useMe } from '@/hooks/useMe';
import { Button } from '@/components/Common/Button';
import { usePreferencesForm } from './PreferencesForm.hooks';

export type PreferencesFormProps = {
  className?: string;
};

export function PreferencesForm({ className }: PreferencesFormProps) {
  const me = useMe();
  const { data } = useProductCategoriesQuery();
  const [selected, setSelected] = useState<{ label: string; selected?: boolean }[]>([]);

  useEffect(() => {
    if (!data) return;
    const myPreferences = me?.preferences?.categories || [];

    setSelected(data.productCategories.map((label) => ({ label, selected: myPreferences.some((v) => v === label) })));
  }, [data, me]);

  const screenSize = useScreenSize();

  const chunkSize = useMemo(() => {
    if (['xs', 'sm'].some((v) => v === screenSize)) return 2;
    if (['md', 'lg'].some((v) => v === screenSize)) return 3;
    return 4;
  }, [screenSize]);

  const categoriesChunks = useMemo<{ label: string; selected?: boolean }[][]>(() => {
    return chunk(selected, chunkSize);
  }, [chunkSize, selected]);

  const handleSelect = useCallback(
    (label: string) => () => {
      const prevIndex = selected.findIndex((p) => p.label === label);
      selected[prevIndex] = { ...selected[prevIndex], selected: !selected[prevIndex].selected };
      setSelected([...selected]);
    },
    [selected],
  );

  const onSave = usePreferencesForm();

  const handleSave = () => onSave(selected.filter(({ selected }) => selected).map(({ label }) => label));

  return (
    <div className={className}>
      {categoriesChunks.map((categories, index) => {
        return (
          <div className="mb-4 flex flex-row items-stretch space-x-4" key={index}>
            {categories.map(({ label, selected }) => {
              return (
                <Pill
                  className="h-full grow basis-1"
                  key={label}
                  label={label}
                  selected={selected}
                  onClick={handleSelect(label)}
                />
              );
            })}
          </div>
        );
      })}
      <Button disabled={!selected.length} type="submit" className="mt-12 w-full" onClick={handleSave}>
        Save
      </Button>
    </div>
  );
}
