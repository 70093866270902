import { useMutation } from '@tanstack/react-query';
import { fetcherRest } from '@/helpers/fetch.helper';
import { toast } from 'react-toastify';
import { getErrorMessage } from '@/utils/error.utils';
import { ChangePasswordFormData } from './ChangePasswordForm.schema';

export function useChangePassword() {
  const { mutateAsync } = useMutation({
    mutationFn: async (data: ChangePasswordFormData) => {
      const { currentPassword, newPassword } = data;
      await fetcherRest(
        { url: '/user/change-password', method: 'POST' },
        {
          password: newPassword,
          oldPassword: currentPassword,
        },
      );
      toast.success('Password has been changed successfully');
    },
    onError: (error) => toast.error(getErrorMessage(error), { autoClose: 8000 }),
  });

  return mutateAsync;
}
