import { useQueryClient } from '@tanstack/react-query';
import { useMeQuery, useMyPreferencesMutation } from '@/graphql/generated';
import { toast } from 'react-toastify';
import { getErrorMessage } from '@/utils/error.utils';

export function usePreferencesForm() {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMyPreferencesMutation({
    onSuccess: () => {
      queryClient.refetchQueries(useMeQuery.getKey());
      toast.success('Updated successfully');
    },
    onError: (error) => toast.error(getErrorMessage(error), { autoClose: 8000 }),
  });

  return (categories: string[]) => {
    return mutateAsync({
      input: { categories },
    });
  };
}
