import { useMutation } from '@tanstack/react-query';
import { fetcherRest } from '@/helpers/fetch.helper';
import { toast } from 'react-toastify';
import { getErrorMessage } from '@/utils/error.utils';
import { useLogOut } from '@/hooks/useLogout';
import { useMe } from '@/hooks/useMe';

export function useDeleteAccount() {
  const me = useMe();
  const handleLogOut = useLogOut();

  return useMutation({
    mutationFn: async () => {
      if (me) fetcherRest({ url: `/user/me`, method: 'DELETE' }, { id: me.id });
    },
    onSettled: (error) => {
      if (error) {
        toast.error(getErrorMessage(error), { autoClose: 8000 });
      }
      handleLogOut();
    },
  });
}
