type Props = {
    className?: string;
    onClick?: () => void;
};

export function ChevronDown(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.23737 9.18499L12.1993 4.35162C12.6157 3.94626 12.619 3.28539 12.208 2.87606C11.7963 2.46608 11.1251 2.46278 10.7093 2.86814L6.49577 6.97198L2.32711 2.82325C1.91541 2.41392 1.24422 2.40996 0.82849 2.81599C0.412094 3.22069 0.408742 3.88156 0.820445 4.29088L5.72399 9.17112C5.82927 9.27676 5.95264 9.35466 6.08407 9.40616C6.46694 9.56262 6.92423 9.49 7.23737 9.18499Z"
                fill="currentColor"
            />
        </svg>
    );
}
