import { useMutation, useQuery, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
import { fetcherGraphql } from '@/helpers/fetch.helper';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  LocalDate: any;
  Money: any;
  Object: any;
};

export type AccessInfo = {
  claims: Scalars['Object'];
  games?: Maybe<Array<Maybe<ShopAccess>>>;
  roles: Array<Scalars['String']>;
  shops?: Maybe<Array<Maybe<ShopAccess>>>;
};

export type Address = {
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export type AddressInput = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export type Advertisement = {
  appDownload?: Maybe<AdvertisementAppDownload>;
  brandId: Scalars['String'];
  category?: Maybe<Scalars['String']>;
  coupon?: Maybe<AdvertisementCoupon>;
  cpa?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  internal?: Maybe<AdvertisementInternal>;
  leadGen?: Maybe<AdvertisementLeadGen>;
  name: Scalars['String'];
  noAction?: Maybe<AdvertisementNoAction>;
  type: AdvertisementType;
  video?: Maybe<AdvertisementVideo>;
};

export type AdvertisementAppDownload = {
  androidId?: Maybe<Scalars['String']>;
  appleId?: Maybe<Scalars['Float']>;
  pcLink?: Maybe<Scalars['String']>;
};

export type AdvertisementAppDownloadInput = {
  androidId?: InputMaybe<Scalars['String']>;
  appleId?: InputMaybe<Scalars['Float']>;
  pcLink?: InputMaybe<Scalars['String']>;
};

export type AdvertisementCoupon = {
  couponDescription?: Maybe<Scalars['String']>;
  couponImages?: Maybe<Scalars['String']>;
  couponLabel?: Maybe<Scalars['String']>;
};

export type AdvertisementCouponInput = {
  couponDescription?: InputMaybe<Scalars['String']>;
  couponImages?: InputMaybe<Scalars['String']>;
  couponLabel?: InputMaybe<Scalars['String']>;
};

export type AdvertisementInternal = {
  internalHtml5Url?: Maybe<Scalars['String']>;
  internalLabel?: Maybe<Scalars['String']>;
  internalUnityUrl?: Maybe<Scalars['String']>;
};

export type AdvertisementInternalInput = {
  internalHtml5Url?: InputMaybe<Scalars['String']>;
  internalLabel?: InputMaybe<Scalars['String']>;
  internalUnityUrl?: InputMaybe<Scalars['String']>;
};

export type AdvertisementLeadGen = {
  affiliateUrl?: Maybe<Scalars['String']>;
};

export type AdvertisementLeadGenInput = {
  affiliateUrl?: InputMaybe<Scalars['String']>;
};

export type AdvertisementNoAction = {
  noActionLabel?: Maybe<Scalars['String']>;
};

export type AdvertisementNoActionInput = {
  noActionLabel?: InputMaybe<Scalars['String']>;
};

export type AdvertisementPage = Page & {
  nodes: Array<Advertisement>;
  paging: Paging;
  sorting?: Maybe<Array<SortField>>;
};

export enum AdvertisementType {
  AppDownload = 'APP_DOWNLOAD',
  Coupon = 'COUPON',
  Internal = 'INTERNAL',
  LeadGen = 'LEAD_GEN',
  NoActionAd = 'NO_ACTION_AD',
  VideoPromo = 'VIDEO_PROMO'
}

export type AdvertisementVideo = {
  videoUrl?: Maybe<Scalars['String']>;
};

export type AdvertisementVideoInput = {
  videoUrl?: InputMaybe<Scalars['String']>;
};

export type BankAccount = {
  accountNumber: Scalars['String'];
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  bankName: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  name: Scalars['String'];
  organization: Organization;
  owner: UserInfo;
  phone?: Maybe<Scalars['String']>;
  routingNumber: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export type Banner = {
  bigUrl?: Maybe<Scalars['String']>;
  smallUrl?: Maybe<Scalars['String']>;
  tallUrl?: Maybe<Scalars['String']>;
};

export type BillingAddress = {
  address1: Scalars['String'];
  address2: Scalars['String'];
  fullName: Scalars['String'];
  zipCode: Scalars['String'];
};

export type BillingAddressInput = {
  address1: Scalars['String'];
  address2: Scalars['String'];
  fullName: Scalars['String'];
  zipCode: Scalars['String'];
};

export type Campaign = {
  appDownloadIds?: Maybe<CampaignAppDownloadIds>;
  budget?: Maybe<CampaignBudget>;
  category?: Maybe<Scalars['String']>;
  demographic: CampaignDemographic;
  duration: CampaignDuration;
  durationEndDate?: Maybe<Scalars['DateTime']>;
  durationStartDate?: Maybe<Scalars['DateTime']>;
  extras: CampaignExtras;
  game?: Maybe<CampaignGame>;
  id: Scalars['ID'];
  inStock: Scalars['Int'];
  internalUrl?: Maybe<Scalars['String']>;
  location?: Maybe<CampaignLocation>;
  media?: Maybe<CampaignMedia>;
  name: Scalars['String'];
  product?: Maybe<CampaignProduct>;
  productListingId?: Maybe<Scalars['String']>;
  reward?: Maybe<CampaignReward>;
  shop?: Maybe<ShopInfo>;
  shopBrand?: Maybe<ShopBrand>;
  statistics?: Maybe<CampaignStatistics>;
  status: CampaignStatus;
  type: CampaignType;
  video?: Maybe<CampaignVideo>;
};

export type CampaignAppDownloadIds = {
  androidId?: Maybe<Scalars['String']>;
  appleId?: Maybe<Scalars['Float']>;
  pcLink?: Maybe<Scalars['String']>;
};

export type CampaignAppDownloadIdsInput = {
  androidId?: InputMaybe<Scalars['String']>;
  appleId?: InputMaybe<Scalars['Float']>;
  pcLink?: InputMaybe<Scalars['String']>;
};

export type CampaignBudget = {
  limitOfImpressions: Scalars['Int'];
  maxDailySpend: Scalars['Float'];
  maxSpend: Scalars['Float'];
  option?: Maybe<CampaignBudgetOption>;
};

export type CampaignBudgetInput = {
  limitOfImpressions: Scalars['Int'];
  maxDailySpend: Scalars['Float'];
  maxSpend: Scalars['Float'];
  option?: InputMaybe<CampaignBudgetOption>;
};

export enum CampaignBudgetOption {
  Featured = 'FEATURED',
  Normal = 'NORMAL'
}

export type CampaignDemographic = {
  contentCategory?: Maybe<ContentCategory>;
  gender?: Maybe<Scalars['String']>;
  maxAge: Scalars['Int'];
  minAge: Scalars['Int'];
};

export type CampaignDemographicInput = {
  contentCategory?: InputMaybe<ContentCategory>;
  gender?: InputMaybe<Scalars['String']>;
  maxAge: Scalars['Int'];
  minAge: Scalars['Int'];
};

export type CampaignDuration = {
  end?: Maybe<Scalars['DateTime']>;
  inventory?: Maybe<Scalars['Int']>;
  runUntilStockZero?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['DateTime']>;
};

export type CampaignDurationInput = {
  end?: InputMaybe<Scalars['DateTime']>;
  inventory?: InputMaybe<Scalars['Int']>;
  runUntilStockZero?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export type CampaignExtras = {
  isTodayDeal?: Maybe<Scalars['Boolean']>;
  moreExposure?: Maybe<Scalars['Boolean']>;
};

export type CampaignExtrasInput = {
  isTodayDeal?: InputMaybe<Scalars['Boolean']>;
  moreExposure?: InputMaybe<Scalars['Boolean']>;
};

export type CampaignGame = {
  gameGenres?: Maybe<Array<Scalars['String']>>;
  games?: Maybe<Array<GameInfo>>;
};

export type CampaignGameInput = {
  gameGenres?: InputMaybe<Array<Scalars['String']>>;
  gameIds?: InputMaybe<Array<Scalars['String']>>;
};

export type CampaignInfo = {
  category?: Maybe<Scalars['String']>;
  durationEndDate?: Maybe<Scalars['DateTime']>;
  durationStartDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  status: CampaignStatus;
  type: CampaignType;
};

export type CampaignLocation = {
  country?: Maybe<Scalars['String']>;
  states?: Maybe<Array<Scalars['String']>>;
};

export type CampaignLocationInput = {
  country?: InputMaybe<Scalars['String']>;
  states?: InputMaybe<Array<Scalars['String']>>;
};

export type CampaignMedia = {
  banner?: Maybe<Scalars['String']>;
  tile?: Maybe<Scalars['String']>;
  vertical?: Maybe<Scalars['String']>;
};

export type CampaignMediaInput = {
  banner?: InputMaybe<Scalars['String']>;
  tile?: InputMaybe<Scalars['String']>;
  vertical?: InputMaybe<Scalars['String']>;
};

export type CampaignPage = Page & {
  nodes: Array<Campaign>;
  paging: Paging;
  sorting?: Maybe<Array<SortField>>;
};

export type CampaignProduct = {
  product: ProductInfo;
  promotion?: Maybe<CampaignProductPromotion>;
};

export type CampaignProductInput = {
  productId: Scalars['String'];
  promotion?: InputMaybe<CampaignProductPromotionInput>;
};

export type CampaignProductPromotion = {
  discount?: Maybe<Scalars['Float']>;
  offerCopy?: Maybe<Scalars['String']>;
  offerType?: Maybe<OfferType>;
  promotionType?: Maybe<PromotionType>;
};

export type CampaignProductPromotionInput = {
  discount?: InputMaybe<Scalars['Float']>;
  offerCopy?: InputMaybe<Scalars['String']>;
  offerType?: InputMaybe<OfferType>;
  promotionType?: InputMaybe<PromotionType>;
};

export type CampaignReward = {
  scutiPercentage: Scalars['Float'];
};

export type CampaignRewardInput = {
  scutiPercentage?: InputMaybe<Scalars['Float']>;
};

export type CampaignStatistics = {
  roi: Scalars['Float'];
  totalRevenue: Scalars['Float'];
  totalSpend: Scalars['Float'];
};

export enum CampaignStatus {
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Deleted = 'DELETED',
  Paused = 'PAUSED',
  Pending = 'PENDING'
}

export enum CampaignType {
  AppDownload = 'APP_DOWNLOAD',
  Banner = 'BANNER',
  Column6Ad = 'COLUMN6_AD',
  InternalUrl = 'INTERNAL_URL',
  Product = 'PRODUCT',
  ProductListing = 'PRODUCT_LISTING',
  Takeover = 'TAKEOVER',
  Video = 'VIDEO'
}

export type CampaignVideo = {
  videoUrl: Scalars['String'];
};

export type CampaignVideoInput = {
  videoUrl: Scalars['String'];
};

export type CartDataInput = {
  items: Array<CartItemInput>;
};

export type CartInput = {
  gameId: Scalars['String'];
  items: Array<CheckoutItemInput>;
  promoCodes: Array<PromoCodesInput>;
  scutisToApply?: InputMaybe<Scalars['Int']>;
  shippingInfo: ShippingInformationInput;
};

export type CartItem = {
  offerId: Scalars['String'];
  quantity: Scalars['Int'];
  variantId: Scalars['String'];
};

export type CartItemInput = {
  cartId?: InputMaybe<Scalars['String']>;
  offerId: Scalars['String'];
  quantity: Scalars['Int'];
  variantId: Scalars['String'];
};

export type CartOutput = {
  products: Array<CartOutputProduct>;
};

export type CartOutputProduct = {
  images?: Maybe<Array<Scalars['String']>>;
  option1?: Maybe<Scalars['String']>;
  option2?: Maybe<Scalars['String']>;
  option3?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  productId: Scalars['String'];
  productName: Scalars['String'];
  reward: CartReward;
  variant: CartOutputVariant;
};

export type CartOutputVariant = {
  image?: Maybe<Scalars['String']>;
  inStock: Scalars['Int'];
  offerId: Scalars['String'];
  option1?: Maybe<Scalars['String']>;
  option2?: Maybe<Scalars['String']>;
  option3?: Maybe<Scalars['String']>;
  price: Scalars['Money'];
  quantity: Scalars['Int'];
  variantId: Scalars['String'];
};

export type CartReward = {
  scutis?: Maybe<Scalars['Int']>;
};

export type CheckoutEncryption = {
  keyId: Scalars['String'];
  publicKey: Scalars['String'];
};

export type CheckoutInput = {
  gameId: Scalars['String'];
  items: Array<CheckoutItemInput>;
  promoCodes: Array<PromoCodesInput>;
  scutisToApply?: InputMaybe<Scalars['Int']>;
  shippingInfo: ShippingInformationInput;
  source: PaymentSource;
};

export type CheckoutItemInput = {
  offerId: Scalars['String'];
  quantity: Scalars['Int'];
  variantId: Scalars['String'];
};

export type ClaimsInfo = {
  claims: Scalars['Object'];
};

export enum ContentCategory {
  AdultsOnly = 'ADULTS_ONLY',
  EarlyChildhood = 'EARLY_CHILDHOOD',
  Everyone = 'EVERYONE',
  Everyone_10Plus = 'EVERYONE_10_PLUS',
  Mature = 'MATURE',
  RatingPending = 'RATING_PENDING',
  Teen = 'TEEN'
}

export type CreateBankAccountInput = {
  accountNumber: Scalars['String'];
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  bankName: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  isDefault: Scalars['Boolean'];
  name: Scalars['String'];
  organizationId: Scalars['String'];
  routingNumber: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export type CreditCard = {
  billingAddress: AddressInput;
  encrypted: EncryptedInput;
  expiryMonth: Scalars['Int'];
  expiryYear: Scalars['Int'];
  name: Scalars['String'];
};

export type Currency = {
  /** How much vbucks do I get for 1 dollar? */
  exchangeRate: Scalars['Float'];
  name: Scalars['String'];
  /** How much vbucks do I get for 1 scuti? */
  scutiExchangeRate: Scalars['Float'];
  thumbnail?: Maybe<Scalars['String']>;
};

export type CurrencyInput = {
  /** Exchange rate for US dollar */
  exchangeRate: Scalars['Float'];
  name: Scalars['String'];
  thumbnail?: InputMaybe<Scalars['String']>;
};

export type EditShopBrandsInput = {
  brands: Array<ShopBrandInput>;
  id: Scalars['ID'];
};

export type EncryptedInput = {
  encryptedData: Scalars['String'];
  keyId: Scalars['String'];
};

export enum EntityType {
  Game = 'GAME',
  Organization = 'ORGANIZATION',
  Shop = 'SHOP'
}

export enum Filter_Type {
  Between = 'BETWEEN',
  Contains = 'CONTAINS',
  Eq = 'EQ',
  Gt = 'GT',
  GtEq = 'GT_EQ',
  Ieq = 'IEQ',
  In = 'IN',
  Includes = 'INCLUDES',
  IsNotNull = 'IS_NOT_NULL',
  IsNull = 'IS_NULL',
  Like = 'LIKE',
  Lt = 'LT',
  LtEq = 'LT_EQ',
  NotIn = 'NOT_IN'
}

export type FilterInput = {
  allowNulls?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  operator: Filter_Type;
  value?: InputMaybe<Array<Scalars['String']>>;
};

export type FulfillmentMethod = {
  id: Scalars['ID'];
  name: Scalars['String'];
  shop: Shop;
  type: FulfillmentType;
};

export type FulfillmentMethodInput = {
  name: Scalars['String'];
  shopId: Scalars['String'];
  type: FulfillmentType;
};

export enum FulfillmentType {
  Digital = 'DIGITAL',
  Pickup = 'PICKUP',
  Shipping = 'SHIPPING'
}

export type Game = {
  admins: Array<UserWithRoles>;
  ageFrom: Scalars['Int'];
  ageTo: Scalars['Int'];
  banner?: Maybe<Scalars['String']>;
  category: Scalars['String'];
  contentCategory: ContentCategory;
  currency?: Maybe<Currency>;
  genderPercent?: Maybe<GenderPercent>;
  id: Scalars['ID'];
  name: Scalars['String'];
  owner: UserInfo;
  tags: Array<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
};

export type GameInfo = {
  banner?: Maybe<Scalars['String']>;
  category: Scalars['String'];
  contentCategory: ContentCategory;
  currency?: Maybe<Currency>;
  id: Scalars['ID'];
  name: Scalars['String'];
  tags: Array<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
};

export type GameInput = {
  ageFrom: Scalars['Int'];
  ageTo: Scalars['Int'];
  banner?: InputMaybe<Scalars['String']>;
  category: Scalars['String'];
  contentCategory: ContentCategory;
  currency?: InputMaybe<CurrencyInput>;
  genderPercent?: InputMaybe<GenderPercentInput>;
  name: Scalars['String'];
  organizationId?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  thumbnail?: InputMaybe<Scalars['String']>;
};

export type GamePage = Page & {
  nodes: Array<Game>;
  paging: Paging;
  sorting?: Maybe<Array<SortField>>;
};

export type GenderPercent = {
  female: Scalars['Float'];
  male: Scalars['Float'];
};

export type GenderPercentInput = {
  female: Scalars['Float'];
  male: Scalars['Float'];
};

export type Invite = {
  accepted: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  hide: Scalars['Boolean'];
  id: Scalars['ID'];
  roles: Array<Role>;
  updatedAt: Scalars['DateTime'];
};

export type InviteInput = {
  email: Scalars['String'];
  entityId?: InputMaybe<Scalars['String']>;
  entityType?: InputMaybe<EntityType>;
  roles: Array<Role>;
  twoFactorEnabled: Scalars['Boolean'];
};

export type InvitePage = Page & {
  nodes: Array<Invite>;
  paging: Paging;
  sorting?: Maybe<Array<SortField>>;
};

export type Mutation = {
  /** [SCUTI_USER,SCUTI_ADMIN,SHOP_ADMIN,GAME_ADMIN,SHOP_OWNER,GAME_OWNER,ORGANIZATION_OWNER] - accept invite */
  acceptInvite?: Maybe<Invite>;
  /** [SCUTI_USER] - activate/claim my rewards */
  activateRewards: Array<Reward>;
  /** [SCUTI_USER] - calculate cart */
  calculateCart: Receipt;
  /** [SCUTI_USER] - checkout shopping cart */
  checkout: Receipt;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - create new advertisement */
  createAdvertisement: Advertisement;
  /** [SCUTI_ADMIN,SHOP_OWNER] - create new bank account for organization */
  createBankAccount: BankAccount;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - create new campaign */
  createCampaign: Campaign;
  /** @deprecated subject to change */
  createFulfillmentMethod: FulfillmentMethod;
  /** [SCUTI_ADMIN,GAME_OWNER] - create game for organization */
  createGame: Game;
  /** [SCUTI_ADMIN,GAME_OWNER] - create/generate game token */
  createGameToken: Token;
  /** [SCUTI_USER] - create credit card */
  createOrReplaceMyCard: UserCard;
  /** [SCUTI_ADMIN,SHOP_OWNER,GAME_OWNER,ORGANIZATION_OWNER] - create organization/business details */
  createOrganization: Organization;
  createPaymentMethod: PaymentMethod;
  /** [SCUTI_ADMIN,SHOP_ADMIN,SHOP_OWNER] - create product */
  createProduct: Product;
  /** [SHOP_OWNER,SCUTI_ADMIN] - create shop */
  createShop: Shop;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - delete advertisement by id */
  deleteAdvertisement?: Maybe<Scalars['String']>;
  /** [SCUTI_ADMIN,SHOP_OWNER] - delete bank account for organization */
  deleteBankAccount?: Maybe<Scalars['String']>;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - delete campaign by id */
  deleteCampaign?: Maybe<Scalars['String']>;
  /** @deprecated subject to change */
  deleteFulfillmentMethod?: Maybe<Scalars['String']>;
  /** [SCUTI_ADMIN,GAME_OWNER] - delete game by id */
  deleteGame?: Maybe<Scalars['String']>;
  /** [SCUTI_ADMIN,GAME_OWNER] - delete game token(s) by game id */
  deleteGameToken: Scalars['String'];
  /** [SCUTI_USER] - delete my cards by card ids */
  deleteMyCards: Array<UserCard>;
  deletePaymentMethod?: Maybe<Scalars['String']>;
  /** [SCUTI_ADMIN,SHOP_ADMIN,SHOP_OWNER] - delete product by id */
  deleteProduct?: Maybe<Scalars['String']>;
  /** [SHOP_OWNER] - delete shop by id */
  deleteShop?: Maybe<Scalars['String']>;
  /** [SHOP_OWNER,SCUTI_ADMIN,SHOP_ADMIN] - edit shop brands */
  editShopBrands: Array<ShopBrand>;
  /** [SCUTI_USER] - exchange my scutis */
  exchange: ScutiExchange;
  invite: Invite;
  inviteToGame: Array<Role>;
  inviteToShop: Array<Role>;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN?] -  create or update  refund order   */
  produceRefundOrder: RefundOrder;
  removeFromGame: Scalars['Boolean'];
  removeFromShop: Scalars['Boolean'];
  resendInviteMail: Scalars['Boolean'];
  /** [SCUTI_USER] - set my default card by id */
  setMyDefaultCard: UserCard;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - set order status by order id */
  setOrderStatus: OrderHistory;
  /** [SCUTI_ADMIN,ORGANIZATION_OWNER,SHOP_OWNER,GAME_OWNER] - pass organization ownership. Also pass ownership for organization shops and games. */
  setOwner: UserInfo;
  /** [SCUTI_ADMIN] - set today deals by campaign ids */
  setTodayDeals: Array<Campaign>;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - set  vendor order id   by order id */
  setVendorOrderID: Order;
  /** [SCUTI_USER] - sets my welcome tour completed */
  setWelcomeTourCompleted: User;
  /** [SCUTI_USER] - stripe checkout shopping cart */
  stripeCheckout?: Maybe<StripePaymentIntent>;
  /** [SHOP_OWNER,SCUTI_ADMIN,SHOP_ADMIN] - sync shop with eBridge data */
  syncEbridge?: Maybe<SyncEbridgeResult>;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - toggle campaign status (pause/unpause) by id */
  toggleCampaign: Campaign;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - update advertisement by id */
  updateAdvertisement: Advertisement;
  /** [SCUTI_ADMIN,SHOP_OWNER] - update bank account for organization */
  updateBankAccount: BankAccount;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - update campaign by id */
  updateCampaign: Campaign;
  /** [SCUTI_USER?] - update cart */
  updateCart: CartOutput;
  /** @deprecated subject to change */
  updateFulfillmentMethod: FulfillmentMethod;
  /** [SCUTI_ADMIN,GAME_OWNER,GAME_ADMIN] - update game */
  updateGame: Game;
  /** [SCUTI_USER] - update my personal information */
  updateMyPersonalInfo: PersonalInfo;
  /** [SCUTI_USER] - update my preferences */
  updateMyPreferences: Preferences;
  /** [SCUTI_USER] - update my shipping information */
  updateMyShippingInfo: ShippingInfo;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - update order  by order id */
  updateOrder: Order;
  /** [ORGANIZATION_OWNER,SCUTI_ADMIN] - update organization/business details */
  updateOrganization: Organization;
  updatePaymentMethod: PaymentMethod;
  /** [SCUTI_ADMIN,SHOP_ADMIN,SHOP_OWNER] - update product */
  updateProduct: Product;
  /** [SHOP_OWNER,SCUTI_ADMIN,SHOP_ADMIN] - update shop */
  updateShop?: Maybe<Shop>;
  /** [SCUTI_USER] - validate cart promo codes */
  validateCodes: PromoCodes;
};


export type MutationAcceptInviteArgs = {
  inviteId: Scalars['String'];
};


export type MutationActivateRewardsArgs = {
  rewardId: Array<Scalars['String']>;
};


export type MutationCalculateCartArgs = {
  input: CartInput;
};


export type MutationCheckoutArgs = {
  input: CheckoutInput;
};


export type MutationCreateAdvertisementArgs = {
  input: NewAdvertisementInput;
};


export type MutationCreateBankAccountArgs = {
  input: CreateBankAccountInput;
};


export type MutationCreateCampaignArgs = {
  input: NewCampaignInput;
};


export type MutationCreateFulfillmentMethodArgs = {
  input: FulfillmentMethodInput;
};


export type MutationCreateGameArgs = {
  input: GameInput;
};


export type MutationCreateGameTokenArgs = {
  id: Scalars['String'];
};


export type MutationCreateOrReplaceMyCardArgs = {
  input: CreditCard;
};


export type MutationCreateOrganizationArgs = {
  input: OrganizationInput;
};


export type MutationCreatePaymentMethodArgs = {
  input: PaymentMethodInput;
};


export type MutationCreateProductArgs = {
  input: ProductInput;
};


export type MutationCreateShopArgs = {
  input: ShopInput;
};


export type MutationDeleteAdvertisementArgs = {
  id: Scalars['String'];
};


export type MutationDeleteBankAccountArgs = {
  id: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationDeleteCampaignArgs = {
  id: Scalars['String'];
};


export type MutationDeleteFulfillmentMethodArgs = {
  id: Scalars['String'];
};


export type MutationDeleteGameArgs = {
  id: Scalars['String'];
};


export type MutationDeleteGameTokenArgs = {
  id: Scalars['String'];
};


export type MutationDeleteMyCardsArgs = {
  cardIds: Array<Scalars['String']>;
};


export type MutationDeletePaymentMethodArgs = {
  id: Scalars['String'];
};


export type MutationDeleteProductArgs = {
  id: Scalars['String'];
};


export type MutationDeleteShopArgs = {
  id: Scalars['String'];
};


export type MutationEditShopBrandsArgs = {
  input: EditShopBrandsInput;
};


export type MutationExchangeArgs = {
  amount: Scalars['Int'];
  gameId: Scalars['String'];
  gameUserId: Scalars['String'];
};


export type MutationInviteArgs = {
  input: InviteInput;
};


export type MutationInviteToGameArgs = {
  id: Scalars['String'];
  user: UserRoleInput;
};


export type MutationInviteToShopArgs = {
  id: Scalars['String'];
  user: UserRoleInput;
};


export type MutationProduceRefundOrderArgs = {
  input: RefundOrderInput;
};


export type MutationRemoveFromGameArgs = {
  email: Scalars['String'];
  gameId: Scalars['String'];
};


export type MutationRemoveFromShopArgs = {
  email: Scalars['String'];
  shopId: Scalars['String'];
};


export type MutationResendInviteMailArgs = {
  email: Scalars['String'];
};


export type MutationSetMyDefaultCardArgs = {
  id: Scalars['String'];
};


export type MutationSetOrderStatusArgs = {
  id: Scalars['String'];
  status: OrderStatus;
};


export type MutationSetOwnerArgs = {
  organizationId: Scalars['String'];
  userEmail: Scalars['String'];
};


export type MutationSetTodayDealsArgs = {
  ids: Array<Scalars['String']>;
  shopId: Scalars['String'];
};


export type MutationSetVendorOrderIdArgs = {
  id: Scalars['String'];
  vendorOrderId: Scalars['String'];
};


export type MutationStripeCheckoutArgs = {
  input: StripeCheckoutInput;
};


export type MutationSyncEbridgeArgs = {
  input: SyncEbridgeInput;
};


export type MutationToggleCampaignArgs = {
  input: ToggleCampaignInput;
};


export type MutationUpdateAdvertisementArgs = {
  input: UpdateAdvertisementInput;
};


export type MutationUpdateBankAccountArgs = {
  input: UpdateBankAccountInput;
};


export type MutationUpdateCampaignArgs = {
  input: UpdateCampaignInput;
};


export type MutationUpdateCartArgs = {
  input: CartDataInput;
};


export type MutationUpdateFulfillmentMethodArgs = {
  input: UpdateFulfillmentMethodInput;
};


export type MutationUpdateGameArgs = {
  input: UpdateGameInput;
};


export type MutationUpdateMyPersonalInfoArgs = {
  input: PersonalInformationInput;
};


export type MutationUpdateMyPreferencesArgs = {
  input: PreferencesInput;
};


export type MutationUpdateMyShippingInfoArgs = {
  input: ShippingInformationInput;
};


export type MutationUpdateOrderArgs = {
  id: Scalars['String'];
  input: UpdateOrderInput;
};


export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};


export type MutationUpdatePaymentMethodArgs = {
  input: UpdatePaymentMethodInput;
};


export type MutationUpdateProductArgs = {
  input: UpdateProductInput;
};


export type MutationUpdateShopArgs = {
  input: UpdateShopInput;
};


export type MutationValidateCodesArgs = {
  activeCodes: Array<PromoCodesInput>;
  newCode: Scalars['String'];
};

export type NewAdvertisementInput = {
  appDownload?: InputMaybe<AdvertisementAppDownloadInput>;
  brandId: Scalars['String'];
  category?: InputMaybe<Scalars['String']>;
  coupon?: InputMaybe<AdvertisementCouponInput>;
  cpa?: InputMaybe<Scalars['Float']>;
  internal?: InputMaybe<AdvertisementInternalInput>;
  leadGen?: InputMaybe<AdvertisementLeadGenInput>;
  name?: InputMaybe<Scalars['String']>;
  noAction?: InputMaybe<AdvertisementNoActionInput>;
  type: AdvertisementType;
  video?: InputMaybe<AdvertisementVideoInput>;
};

export type NewCampaignInput = {
  appDownloadIds?: InputMaybe<CampaignAppDownloadIdsInput>;
  budget?: InputMaybe<CampaignBudgetInput>;
  category?: InputMaybe<Scalars['String']>;
  demographic: CampaignDemographicInput;
  duration: CampaignDurationInput;
  extras: CampaignExtrasInput;
  game?: InputMaybe<CampaignGameInput>;
  internalUrl?: InputMaybe<Scalars['String']>;
  location: CampaignLocationInput;
  media?: InputMaybe<CampaignMediaInput>;
  name: Scalars['String'];
  product?: InputMaybe<CampaignProductInput>;
  productListingId?: InputMaybe<Scalars['String']>;
  reward?: InputMaybe<CampaignRewardInput>;
  shopBrandId?: InputMaybe<Scalars['ID']>;
  shopId: Scalars['String'];
  type: CampaignType;
  video?: InputMaybe<CampaignVideoInput>;
};

export type Offer = {
  androidId?: Maybe<Scalars['String']>;
  appleId?: Maybe<Scalars['Float']>;
  brand?: Maybe<ShopBrand>;
  category?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  internalUrl?: Maybe<Scalars['String']>;
  media: OfferMedia;
  name: Scalars['String'];
  pcLink?: Maybe<Scalars['String']>;
  product?: Maybe<OfferProduct>;
  promotions: OfferPromotions;
  review?: Maybe<OfferReview>;
  reward: OfferReward;
  shop?: Maybe<ShopInfo>;
  type: Scalars['String'];
};

export type OfferCategoryCount = {
  category: Scalars['String'];
  count: Scalars['Float'];
};

export type OfferMedia = {
  banner?: Maybe<Banner>;
  images?: Maybe<Array<Scalars['String']>>;
  tile?: Maybe<Scalars['String']>;
  vertical?: Maybe<Scalars['String']>;
  videoUrl?: Maybe<Scalars['String']>;
};

export type OfferPage = Page & {
  nodes: Array<Offer>;
  paging: Paging;
  seed?: Maybe<Scalars['Int']>;
  sorting?: Maybe<Array<SortField>>;
};

export type OfferProduct = {
  category: Scalars['String'];
  chargeTaxes: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  images?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  option1?: Maybe<Scalars['String']>;
  option2?: Maybe<Scalars['String']>;
  option3?: Maybe<Scalars['String']>;
  price: Price;
  scutisExclusive?: Maybe<Scalars['Boolean']>;
  shop: ShopInfo;
  tags?: Maybe<Array<Scalars['String']>>;
  variants: Array<ProductVariant>;
};

export type OfferPromotions = {
  isBestSeller: Scalars['Boolean'];
  isFeatured: Scalars['Boolean'];
  isHotItem: Scalars['Boolean'];
  isHotPrice: Scalars['Boolean'];
  isLatest: Scalars['Boolean'];
  isMisc: Scalars['Boolean'];
  isMoreExposure: Scalars['Boolean'];
  isNew: Scalars['Boolean'];
  isRecommended: Scalars['Boolean'];
  isScuti: Scalars['Boolean'];
  isSpecialOffer: Scalars['Boolean'];
  isTodayDeal: Scalars['Boolean'];
  specialOfferText?: Maybe<Scalars['String']>;
  specialOfferType?: Maybe<OfferType>;
};

export type OfferReview = {
  count: Scalars['Int'];
  score: Scalars['Float'];
};

export type OfferReward = {
  scutiPercentage?: Maybe<Scalars['Float']>;
  scutis?: Maybe<Scalars['Float']>;
};

export type OfferStatistics = {
  byCategories: Array<OfferCategoryCount>;
  newOffers: Scalars['Float'];
  total: Scalars['Float'];
};

export enum OfferType {
  BuyOneGetOne = 'BUY_ONE_GET_ONE',
  FreeSamples = 'FREE_SAMPLES',
  FreeShipping = 'FREE_SHIPPING',
  GiftWithPurchase = 'GIFT_WITH_PURCHASE',
  LimitedTimeOffer = 'LIMITED_TIME_OFFER',
  TripWire = 'TRIP_WIRE'
}

export type OffersByCategories = {
  nodes: Array<OffersWithinCategory>;
};

export type OffersWithinCategory = {
  category: Scalars['String'];
  nodes: Array<Offer>;
};

export type Order = {
  amount: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  ebridgeOrderId: Scalars['String'];
  game: GameInfo;
  id: Scalars['ID'];
  items: Array<OrderItem>;
  note?: Maybe<Scalars['String']>;
  payoutStatus: PayoutStatus;
  shippingInformation?: Maybe<OrderShippingInformationSnapshot>;
  shop: ShopInfo;
  status: OrderStatus;
  trackingNumber?: Maybe<Scalars['String']>;
  user: UserInfo;
  vendorOrderId?: Maybe<Scalars['String']>;
};

export type OrderCampaignSnapshot = {
  id: Scalars['ID'];
};

export type OrderFulfillmentMethodSnapshot = {
  name: Scalars['String'];
  type: FulfillmentType;
};

export type OrderHistory = {
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  status: OrderStatus;
  user: UserInfo;
};

export type OrderItem = {
  amount: Scalars['Money'];
  campaign: OrderCampaignSnapshot;
  currency: Scalars['String'];
  fulfillmentMethod: OrderFulfillmentMethodSnapshot;
  id: Scalars['ID'];
  product: OrderProductSnapshot;
  quantity: Scalars['Money'];
};

export type OrderPage = Page & {
  nodes: Array<Order>;
  paging: Paging;
  sorting?: Maybe<Array<SortField>>;
};

export type OrderProductSnapshot = {
  category?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  defaultImage?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  option1?: Maybe<Scalars['String']>;
  option2?: Maybe<Scalars['String']>;
  option3?: Maybe<Scalars['String']>;
  reviewUrl?: Maybe<Scalars['String']>;
  scutisExclusive?: Maybe<Scalars['Boolean']>;
  variant: OrderProductVariantSnapshot;
};

export type OrderProductVariantSnapshot = {
  barcode?: Maybe<Scalars['String']>;
  compareAt?: Maybe<Price>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  inStock: Scalars['Float'];
  option1?: Maybe<Scalars['String']>;
  option2?: Maybe<Scalars['String']>;
  option3?: Maybe<Scalars['String']>;
  price: Price;
  sku: Scalars['String'];
  variantPosition?: Maybe<Scalars['Float']>;
  weight?: Maybe<Weight>;
};

export type OrderShippingInformationSnapshot = {
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export enum OrderStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Pending = 'PENDING',
  Shipping = 'SHIPPING'
}

export type Organization = {
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  category: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  dbaName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  identificationNumber: Scalars['String'];
  name: Scalars['String'];
  owner: UserInfo;
  phone?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export type OrganizationInput = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  category: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  dbaName: Scalars['String'];
  identificationNumber: Scalars['String'];
  name: Scalars['String'];
  owner?: InputMaybe<OwnerInput>;
  phone: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export type OrganizationPage = Page & {
  nodes: Array<Organization>;
  paging: Paging;
  sorting?: Maybe<Array<SortField>>;
};

export type Overview = {
  author: UserInfo;
  id: Scalars['ID'];
  name: Scalars['String'];
  text: Scalars['String'];
};

export type OwnerInput = {
  email: Scalars['String'];
  fullName: Scalars['String'];
};

export type Page = {
  paging: Paging;
  sorting?: Maybe<Array<SortField>>;
};

export type Paging = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PagingInput = {
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
};

export type PaymentMethod = {
  address: BillingAddress;
  author: Shop;
  cardNumber: Scalars['String'];
  cvc: Scalars['String'];
  default: Scalars['Boolean'];
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  id: Scalars['ID'];
  nameOnCard: Scalars['String'];
  shop: Shop;
};

export type PaymentMethodInput = {
  address: BillingAddressInput;
  cardNumber: Scalars['String'];
  cvc: Scalars['String'];
  defaultMethod: Scalars['Boolean'];
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  nameOnCard: Scalars['String'];
  shopId: Scalars['String'];
};

export type PaymentSource = {
  card?: InputMaybe<CreditCard>;
  encrypted?: InputMaybe<EncryptedInput>;
  id?: InputMaybe<Scalars['String']>;
  persist?: InputMaybe<Scalars['Boolean']>;
  token?: InputMaybe<Scalars['String']>;
  type: PaymentSourceType;
};

export enum PaymentSourceType {
  Card = 'CARD',
  ScutiCrypto = 'SCUTI_CRYPTO',
  StoredCard = 'STORED_CARD',
  Token = 'TOKEN'
}

export enum PayoutStatus {
  Error = 'ERROR',
  Paid = 'PAID',
  Pending = 'PENDING'
}

export type PersonalInfo = {
  age?: Maybe<Scalars['Float']>;
  birthDate?: Maybe<Scalars['LocalDate']>;
  dateTruncated?: Maybe<Scalars['Boolean']>;
  gender?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type PersonalInformationInput = {
  birthDate?: InputMaybe<Scalars['LocalDate']>;
  dateTruncated: Scalars['Boolean'];
  fullName: Scalars['String'];
  gender: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export type PlatformSettings = {
  isMiscOfferCategoryLimit: Scalars['Float'];
  isNewOfferDays: Scalars['Float'];
  scutiExchangeRate: Scalars['Float'];
};

export type Preferences = {
  categories?: Maybe<Array<Scalars['String']>>;
};

export type PreferencesInput = {
  categories: Array<Scalars['String']>;
};

export type Price = {
  amount: Scalars['Float'];
  currency: Scalars['String'];
};

export type PriceInput = {
  amount: Scalars['Float'];
  currency: Scalars['String'];
};

export type Product = {
  category?: Maybe<Scalars['String']>;
  chargeTaxes: Scalars['Boolean'];
  defaultImage?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  images?: Maybe<Array<Scalars['String']>>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  option1?: Maybe<Scalars['String']>;
  option2?: Maybe<Scalars['String']>;
  option3?: Maybe<Scalars['String']>;
  productListings: Array<CampaignInfo>;
  reviewUrl?: Maybe<Scalars['String']>;
  scutisExclusive?: Maybe<Scalars['Boolean']>;
  shop: ShopInfo;
  tags?: Maybe<Array<Scalars['String']>>;
  variants: Array<ProductVariant>;
};

export type ProductInfo = {
  category?: Maybe<Scalars['String']>;
  defaultImage?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  images?: Maybe<Array<Scalars['String']>>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  option1?: Maybe<Scalars['String']>;
  option2?: Maybe<Scalars['String']>;
  option3?: Maybe<Scalars['String']>;
  reviewUrl?: Maybe<Scalars['String']>;
  scutisExclusive?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Scalars['String']>>;
};

export type ProductInput = {
  category: Scalars['String'];
  chargeTaxes: Scalars['Boolean'];
  defaultImage?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  option1?: InputMaybe<Scalars['String']>;
  option2?: InputMaybe<Scalars['String']>;
  option3?: InputMaybe<Scalars['String']>;
  reviewUrl?: InputMaybe<Scalars['String']>;
  shopId: Scalars['String'];
  tags?: InputMaybe<Array<Scalars['String']>>;
  taxCode?: InputMaybe<Scalars['String']>;
  variants: Array<ProductVariantInput>;
};

export type ProductPage = Page & {
  nodes: Array<Product>;
  paging: Paging;
  sorting?: Maybe<Array<SortField>>;
};

export type ProductVariant = {
  barcode?: Maybe<Scalars['String']>;
  compareAt?: Maybe<Price>;
  fulfillmentMethod: FulfillmentMethod;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  inStock: Scalars['Float'];
  option1?: Maybe<Scalars['String']>;
  option2?: Maybe<Scalars['String']>;
  option3?: Maybe<Scalars['String']>;
  price: Price;
  sku: Scalars['String'];
  variantPosition?: Maybe<Scalars['Float']>;
  weight?: Maybe<Weight>;
};

export type ProductVariantInput = {
  barcode?: InputMaybe<Scalars['String']>;
  compareAt?: InputMaybe<PriceInput>;
  ebridgeProductId?: InputMaybe<Scalars['String']>;
  fulfillmentMethodId: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<Scalars['String']>;
  inStock: Scalars['Float'];
  option1?: InputMaybe<Scalars['String']>;
  option2?: InputMaybe<Scalars['String']>;
  option3?: InputMaybe<Scalars['String']>;
  price: PriceInput;
  sku: Scalars['String'];
  taxCode?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<WeightInput>;
};

export type PromoCodeCheckoutReward = {
  discounts: PromoCodeCheckoutRewardDiscount;
  scutis: Scalars['Float'];
};

export type PromoCodeCheckoutRewardDiscount = {
  cart: Scalars['Float'];
  shop: Scalars['Float'];
};

export type PromoCodes = {
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  endDate?: Maybe<Scalars['DateTime']>;
  gameId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  reward?: Maybe<Scalars['Float']>;
  rewardMode?: Maybe<Scalars['String']>;
  rewardType?: Maybe<Scalars['String']>;
  rewardTypeSourceId?: Maybe<Scalars['ID']>;
  stackable?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

export type PromoCodesInput = {
  code: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
};

export enum PromotionType {
  HotPrice = 'HOT_PRICE',
  SpecialOffer = 'SPECIAL_OFFER'
}

export type Query = {
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - get advertisement by id */
  advertisement: Advertisement;
  /** [SCUTI_ADMIN] - query for advertisements */
  advertisements: AdvertisementPage;
  /** [SCUTI_ADMIN] - query for brand advertisements by brand id */
  brandAdvertisements: AdvertisementPage;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - get campaign by id */
  campaign: Campaign;
  /** [SCUTI_ADMIN] - query for campaigns */
  campaigns: CampaignPage;
  debugClaims?: Maybe<ClaimsInfo>;
  /** [SCUTI_USER] - get encryption key */
  encryptionKey: CheckoutEncryption;
  /** [SCUTI_ADMIN,GAME_OWNER,GAME_ADMIN,GAME_SERVER] - get game by id */
  game: Game;
  /** [SCUTI_USER?] - get game info by id */
  gameInfo?: Maybe<GameInfo>;
  /** [SCUTI_ADMIN,GAME_OWNER,GAME_ADMIN] - query for game invites */
  gameInvites: InvitePage;
  /** [SCUTI_ADMIN,GAME_OWNER,GAME_ADMIN] - get game token by game id */
  gameToken?: Maybe<Token>;
  /** [SCUTI_ADMIN,GAME_OWNER,GAME_ADMIN,SHOP_ADMIN] - query for games */
  games: GamePage;
  /** [SCUTI_ADMIN,SCUTI_USER,SCUTI_ADMIN,SHOP_ADMIN,GAME_ADMIN,SHOP_OWNER,GAME_OWNER,ORGANIZATION_OWNER] - query for invites */
  invites: InvitePage;
  /** [SCUTI_USER] - me/my profile */
  me: User;
  /** [DEBUG] [GAME_ADMIN,SHOP_ADMIN,GAME_OWNER,SHOP_OWNER,SCUTI_ADMIN] - print my access */
  myAccess: AccessInfo;
  /** [SCUTI_USER] - get my card details */
  myCardDetails: UserCardDetails;
  /** [SCUTI_USER] - get my cards */
  myCards: Array<UserCard>;
  /** [SCUTI_USER] - get my cart */
  myCart: CartOutput;
  /** [SCUTI_USER] - get my oder by id */
  myOrder: Order;
  /** [SCUTI_USER] - get my order history by order id */
  myOrderHistory: Array<OrderHistory>;
  /** [SCUTI_USER] - query for my orders */
  myOrders: OrderPage;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - get my refunded order */
  myRefundOrder: RefundOrder;
  /** [SCUTI_USER] - my rewards */
  myRewards: RewardPage;
  /** [SCUTI_USER] - my scuti transactions */
  myScutiTransactions: ScutiTransactionPage;
  /** [SCUTI_USER] - my wallet */
  myWallet: Wallet;
  /** [SCUTI_USER?] - get offer by id */
  offer: Offer;
  /** [SCUTI_USER?]  - get overall offer statistics */
  offerStatistics: OfferStatistics;
  /** [SCUTI_USER?] - query for offers */
  offers: OfferPage;
  /** [SCUTI_USER?] - query for offers by categories */
  offersByCategories: OffersByCategories;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - get order by id */
  order: Order;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - get order history by order id */
  orderHistory: Array<OrderHistory>;
  /** [SCUTI_ADMIN,ORGANIZATION_OWNER,SHOP_OWNER,GAME_OWNER] - get organization by id */
  organization: Organization;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - get all bank accounts by organization id */
  organizationBankAccounts?: Maybe<Array<BankAccount>>;
  /** [SCUTI_ADMIN,ORGANIZATION_OWNER,SHOP_OWNER,GAME_OWNER] - query for organizations */
  organizations: OrganizationPage;
  /** [SCUTI_USER?] - show platform settings */
  platformSettings: PlatformSettings;
  /** [SCUTI_ADMIN,SHOP_ADMIN,SHOP_OWNER] - get product by id */
  product: Product;
  /** [SCUTI_USER,SCUTI_ADMIN,SHOP_ADMIN,GAME_ADMIN,SHOP_OWNER,GAME_OWNER,ORGANIZATION_OWNER] - get available product categories */
  productCategories: Array<Scalars['String']>;
  /** [SCUTI_USER?] - get random offer */
  randomFancyOffer?: Maybe<Offer>;
  /** [SHOP_OWNER,SCUTI_ADMIN,SHOP_ADMIN] - get shop by id */
  shop?: Maybe<Shop>;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - get assigned shop bank account or default organization bank account */
  shopBankAccount?: Maybe<BankAccount>;
  /** [SHOP_OWNER,SCUTI_ADMIN,SHOP_ADMIN] - get shop brands */
  shopBrands: Array<ShopBrand>;
  /** [SCUTI_ADMIN] - query for shop campaigns by shop id */
  shopCampaigns: CampaignPage;
  /** @deprecated subject to change */
  shopFulfillmentMethods: Array<FulfillmentMethod>;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - query for shop invites */
  shopInvites: InvitePage;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - query for shop orders by shop id */
  shopOrders: OrderPage;
  shopOverviews: Array<Overview>;
  shopPaymentMethods: Array<PaymentMethod>;
  /** [SCUTI_ADMIN,SHOP_ADMIN,SHOP_OWNER] - query for shop's products */
  shopProducts: ProductPage;
  /** [SHOP_OWNER,SCUTI_ADMIN,SHOP_ADMIN] - query for shops */
  shops: ShopPage;
  /** [SCUTI_USER] - get stripe settings */
  stripeSettings: StripeSettings;
  /** [SCUTI_ADMIN] - query for today deals campaigns */
  todayDeals: Array<Campaign>;
};


export type QueryAdvertisementArgs = {
  id: Scalars['String'];
};


export type QueryAdvertisementsArgs = {
  filters?: InputMaybe<Array<FilterInput>>;
  paging?: InputMaybe<PagingInput>;
  sorting?: InputMaybe<Array<SortFieldInput>>;
};


export type QueryBrandAdvertisementsArgs = {
  filters?: InputMaybe<Array<FilterInput>>;
  id: Scalars['ID'];
  paging?: InputMaybe<PagingInput>;
  sorting?: InputMaybe<Array<SortFieldInput>>;
};


export type QueryCampaignArgs = {
  id: Scalars['String'];
};


export type QueryCampaignsArgs = {
  filters?: InputMaybe<Array<FilterInput>>;
  paging?: InputMaybe<PagingInput>;
  sorting?: InputMaybe<Array<SortFieldInput>>;
};


export type QueryGameArgs = {
  id: Scalars['String'];
};


export type QueryGameInfoArgs = {
  id: Scalars['String'];
};


export type QueryGameInvitesArgs = {
  filters?: InputMaybe<Array<FilterInput>>;
  id: Scalars['String'];
  paging?: InputMaybe<PagingInput>;
  sorting?: InputMaybe<Array<SortFieldInput>>;
};


export type QueryGameTokenArgs = {
  id: Scalars['String'];
};


export type QueryGamesArgs = {
  filters?: InputMaybe<Array<FilterInput>>;
  paging?: InputMaybe<PagingInput>;
  sorting?: InputMaybe<Array<SortFieldInput>>;
};


export type QueryInvitesArgs = {
  filters?: InputMaybe<Array<FilterInput>>;
  paging?: InputMaybe<PagingInput>;
  sorting?: InputMaybe<Array<SortFieldInput>>;
};


export type QueryMyCardDetailsArgs = {
  id: Scalars['String'];
};


export type QueryMyOrderArgs = {
  id: Scalars['String'];
};


export type QueryMyOrderHistoryArgs = {
  id: Scalars['String'];
};


export type QueryMyOrdersArgs = {
  filters?: InputMaybe<Array<FilterInput>>;
  paging?: InputMaybe<PagingInput>;
  sorting?: InputMaybe<Array<SortFieldInput>>;
};


export type QueryMyRefundOrderArgs = {
  orderId: Scalars['String'];
  userId: Scalars['String'];
};


export type QueryMyRewardsArgs = {
  filters?: InputMaybe<Array<FilterInput>>;
  paging?: InputMaybe<PagingInput>;
  sorting?: InputMaybe<Array<SortFieldInput>>;
};


export type QueryMyScutiTransactionsArgs = {
  filters?: InputMaybe<Array<FilterInput>>;
  paging?: InputMaybe<PagingInput>;
  sorting?: InputMaybe<Array<SortFieldInput>>;
};


export type QueryOfferArgs = {
  id: Scalars['String'];
};


export type QueryOffersArgs = {
  filters?: InputMaybe<Array<FilterInput>>;
  paging?: InputMaybe<PagingInput>;
  seed?: InputMaybe<Scalars['Int']>;
  sorting?: InputMaybe<Array<SortFieldInput>>;
};


export type QueryOffersByCategoriesArgs = {
  seed?: InputMaybe<Scalars['Int']>;
};


export type QueryOrderArgs = {
  id: Scalars['String'];
};


export type QueryOrderHistoryArgs = {
  id: Scalars['String'];
};


export type QueryOrganizationArgs = {
  id: Scalars['String'];
};


export type QueryOrganizationBankAccountsArgs = {
  id: Scalars['String'];
};


export type QueryOrganizationsArgs = {
  filters?: InputMaybe<Array<FilterInput>>;
  paging?: InputMaybe<PagingInput>;
  sorting?: InputMaybe<Array<SortFieldInput>>;
};


export type QueryProductArgs = {
  id: Scalars['String'];
};


export type QueryShopArgs = {
  id: Scalars['String'];
};


export type QueryShopBankAccountArgs = {
  id: Scalars['String'];
};


export type QueryShopBrandsArgs = {
  id: Scalars['String'];
};


export type QueryShopCampaignsArgs = {
  filters?: InputMaybe<Array<FilterInput>>;
  id: Scalars['ID'];
  paging?: InputMaybe<PagingInput>;
  sorting?: InputMaybe<Array<SortFieldInput>>;
};


export type QueryShopFulfillmentMethodsArgs = {
  id: Scalars['String'];
};


export type QueryShopInvitesArgs = {
  filters?: InputMaybe<Array<FilterInput>>;
  id: Scalars['String'];
  paging?: InputMaybe<PagingInput>;
  sorting?: InputMaybe<Array<SortFieldInput>>;
};


export type QueryShopOrdersArgs = {
  filters?: InputMaybe<Array<FilterInput>>;
  id: Scalars['ID'];
  paging?: InputMaybe<PagingInput>;
  sorting?: InputMaybe<Array<SortFieldInput>>;
};


export type QueryShopOverviewsArgs = {
  id: Scalars['ID'];
  paging?: InputMaybe<PagingInput>;
  sorting?: InputMaybe<Array<SortFieldInput>>;
};


export type QueryShopPaymentMethodsArgs = {
  id: Scalars['ID'];
  paging?: InputMaybe<PagingInput>;
  sorting?: InputMaybe<Array<SortFieldInput>>;
};


export type QueryShopProductsArgs = {
  filters?: InputMaybe<Array<FilterInput>>;
  id: Scalars['ID'];
  paging?: InputMaybe<PagingInput>;
  sorting?: InputMaybe<Array<SortFieldInput>>;
};


export type QueryShopsArgs = {
  filters?: InputMaybe<Array<FilterInput>>;
  paging?: InputMaybe<PagingInput>;
  sorting?: InputMaybe<Array<SortFieldInput>>;
};


export type QueryTodayDealsArgs = {
  shopId: Scalars['String'];
};

export enum Role {
  AcceptEntityInvite = 'ACCEPT_ENTITY_INVITE',
  AcceptSystemInvite = 'ACCEPT_SYSTEM_INVITE',
  ChangePassword = 'CHANGE_PASSWORD',
  ChangePassword_2Fa = 'CHANGE_PASSWORD_2FA',
  GameAdmin = 'GAME_ADMIN',
  GameOwner = 'GAME_OWNER',
  GameServer = 'GAME_SERVER',
  Login_2Fa = 'LOGIN_2FA',
  OrganizationOwner = 'ORGANIZATION_OWNER',
  Refresh = 'REFRESH',
  ScutiAdmin = 'SCUTI_ADMIN',
  ScutiUser = 'SCUTI_USER',
  ShopAdmin = 'SHOP_ADMIN',
  ShopOwner = 'SHOP_OWNER',
  VerifyEmail = 'VERIFY_EMAIL'
}

export type Receipt = {
  breakdown: ReceiptBreakdown;
  currency: Scalars['String'];
  extraRewards: PromoCodeCheckoutReward;
  id: Scalars['String'];
  items: Array<ReceiptItem>;
  promoCodes: Array<PromoCodes>;
  user: UserInfo;
};

export type ReceiptBreakdown = {
  discount: Scalars['Money'];
  retail: Scalars['Money'];
  shipping: Scalars['Money'];
  tax: Scalars['Money'];
  total: Scalars['Money'];
};

export type ReceiptItem = {
  breakdown: ReceiptBreakdown;
  products: Array<ReceiptProduct>;
  shop: ReceiptShop;
};

export type ReceiptProduct = {
  breakdown: ReceiptBreakdown;
  country?: Maybe<Scalars['String']>;
  offerId: Scalars['String'];
  price: Scalars['Money'];
  productDescription?: Maybe<Scalars['String']>;
  productName: Scalars['String'];
  quantity: Scalars['Int'];
  reward: ReceiptReward;
  variantId: Scalars['String'];
};

export type ReceiptReward = {
  scutis?: Maybe<Scalars['Int']>;
};

export type ReceiptShop = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type RefundOrder = {
  orderId: Scalars['String'];
  orderItems: Array<RefundOrderItem>;
  userId: Scalars['String'];
};

export type RefundOrderInput = {
  items: Array<RefundOrderInputItem>;
  orderId: Scalars['String'];
  userId: Scalars['String'];
};

export type RefundOrderInputItem = {
  activated?: InputMaybe<Scalars['Boolean']>;
  currencyName: Scalars['String'];
  orderItemId: Scalars['String'];
  reason?: InputMaybe<Scalars['String']>;
  refundQuantity?: InputMaybe<Scalars['Float']>;
};

export type RefundOrderItem = {
  activated?: Maybe<Scalars['Boolean']>;
  currencyName: Scalars['String'];
  orderItemId: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
  refundQuantity?: Maybe<Scalars['Float']>;
  rewardId?: Maybe<Scalars['String']>;
  scutis?: Maybe<Scalars['Int']>;
};

export type Reward = {
  activated: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  scutiAccountType: Scalars['String'];
  scutis?: Maybe<Scalars['Float']>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: UserInfo;
};

export type RewardPage = Page & {
  nodes: Array<Reward>;
  paging: Paging;
  sorting?: Maybe<Array<SortField>>;
};

export type ScutiExchange = {
  currencyEarned: Scalars['Float'];
  currencyName: Scalars['String'];
  id: Scalars['String'];
  scutisSpent: Scalars['Float'];
};

export type ScutiTransaction = {
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  promotionalBalanceChange: Scalars['Float'];
  purchaseBalanceChange: Scalars['Float'];
  receiptId?: Maybe<Scalars['String']>;
  rewardId?: Maybe<Scalars['String']>;
  rewardType?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type ScutiTransactionPage = Page & {
  nodes: Array<ScutiTransaction>;
  paging: Paging;
  sorting?: Maybe<Array<SortField>>;
};

export type ShippingInfo = {
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export type ShippingInformationInput = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export type Shop = {
  admins: Array<UserWithRoles>;
  analytics: ShopAnalytics;
  description?: Maybe<Scalars['String']>;
  flatShippingRate: Scalars['Float'];
  freeShippingThreshold?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  owner: UserInfo;
  returnPolicy: Scalars['String'];
  shopifyName?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
};

export type ShopAccess = {
  id: Scalars['String'];
  name: Scalars['String'];
  owner: UserInfo;
  role: Scalars['String'];
};

export type ShopAgeAnalytics = {
  age: Scalars['Float'];
  avgPurchaseValue: Scalars['Float'];
  itemsPerCheckout: Scalars['Float'];
  revenue: Scalars['Float'];
  units: Scalars['Float'];
};

export type ShopAnalytics = {
  ageAnalytics: Array<ShopAgeAnalytics>;
  avgPurchaseValue: Scalars['Float'];
  campaignAnalytics: Array<ShopCampaignAnalytics>;
  genderAnalytics: Array<ShopGenderAnalytics>;
  itemsPerCheckout: Scalars['Float'];
  revenue: Scalars['Float'];
  units: Scalars['Float'];
};

export type ShopBrand = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ShopBrandInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ShopCampaignAnalytics = {
  avgPurchaseValue: Scalars['Float'];
  campaignId: Scalars['ID'];
  itemsPerCheckout: Scalars['Float'];
  revenue: Scalars['Float'];
  units: Scalars['Float'];
};

export type ShopGenderAnalytics = {
  avgPurchaseValue: Scalars['Float'];
  gender: Scalars['String'];
  itemsPerCheckout: Scalars['Float'];
  revenue: Scalars['Float'];
  units: Scalars['Float'];
};

export type ShopInfo = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  shopifyName?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
};

export type ShopInput = {
  description?: InputMaybe<Scalars['String']>;
  flatShippingRate: Scalars['Float'];
  freeShippingThreshold?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  organizationId?: InputMaybe<Scalars['String']>;
  returnPolicy: Scalars['String'];
  shopifyName?: InputMaybe<Scalars['String']>;
  thumbnail?: InputMaybe<Scalars['String']>;
};

export type ShopPage = Page & {
  nodes: Array<Shop>;
  paging: Paging;
  sorting?: Maybe<Array<SortField>>;
};

export type SortField = {
  dir: Scalars['String'];
  name: Scalars['String'];
};

export type SortFieldInput = {
  dir: Scalars['String'];
  name: Scalars['String'];
};

export type StripeCheckoutInput = {
  gameId: Scalars['String'];
  items: Array<CheckoutItemInput>;
  promoCodes: Array<PromoCodesInput>;
  scutisToApply?: InputMaybe<Scalars['Int']>;
  shippingInfo: ShippingInformationInput;
};

export type StripePaymentIntent = {
  amount: Scalars['Float'];
  clientSecret: Scalars['String'];
  currency: Scalars['String'];
  paidInFullWithScutis?: Maybe<Scalars['Boolean']>;
  paymentMethodTypes: Array<Scalars['String']>;
};

export type StripeSettings = {
  publicKey: Scalars['String'];
};

export type SyncEbridgeInput = {
  id: Scalars['ID'];
};

export type SyncEbridgeResult = {
  date: Scalars['DateTime'];
};

export type ToggleCampaignInput = {
  id: Scalars['ID'];
  isPaused: Scalars['Boolean'];
};

export type Token = {
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  token: Scalars['String'];
};

export type UpdateAdvertisementInput = {
  appDownload?: InputMaybe<AdvertisementAppDownloadInput>;
  brandId: Scalars['String'];
  category?: InputMaybe<Scalars['String']>;
  coupon?: InputMaybe<AdvertisementCouponInput>;
  cpa?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  internal?: InputMaybe<AdvertisementInternalInput>;
  leadGen?: InputMaybe<AdvertisementLeadGenInput>;
  name?: InputMaybe<Scalars['String']>;
  noAction?: InputMaybe<AdvertisementNoActionInput>;
  type: AdvertisementType;
  video?: InputMaybe<AdvertisementVideoInput>;
};

export type UpdateBankAccountInput = {
  accountNumber: Scalars['String'];
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  bankName: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  name: Scalars['String'];
  organizationId: Scalars['String'];
  routingNumber: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export type UpdateCampaignInput = {
  appDownloadIds?: InputMaybe<CampaignAppDownloadIdsInput>;
  budget?: InputMaybe<CampaignBudgetInput>;
  category?: InputMaybe<Scalars['String']>;
  demographic: CampaignDemographicInput;
  duration: CampaignDurationInput;
  extras: CampaignExtrasInput;
  game?: InputMaybe<CampaignGameInput>;
  id: Scalars['ID'];
  internalUrl?: InputMaybe<Scalars['String']>;
  location: CampaignLocationInput;
  media?: InputMaybe<CampaignMediaInput>;
  name: Scalars['String'];
  product?: InputMaybe<CampaignProductInput>;
  productListingId?: InputMaybe<Scalars['String']>;
  reward?: InputMaybe<CampaignRewardInput>;
  shopBrandId?: InputMaybe<Scalars['ID']>;
  shopId: Scalars['String'];
  type: CampaignType;
  video?: InputMaybe<CampaignVideoInput>;
};

export type UpdateFulfillmentMethodInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type UpdateGameInput = {
  ageFrom: Scalars['Int'];
  ageTo: Scalars['Int'];
  banner?: InputMaybe<Scalars['String']>;
  category: Scalars['String'];
  contentCategory: ContentCategory;
  currency?: InputMaybe<CurrencyInput>;
  genderPercent?: InputMaybe<GenderPercentInput>;
  id: Scalars['ID'];
  name: Scalars['String'];
  tags?: InputMaybe<Array<Scalars['String']>>;
  thumbnail?: InputMaybe<Scalars['String']>;
};

export type UpdateOrderInput = {
  ebridgeOrderId?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  payoutStatus: PayoutStatus;
  trackingNumber?: InputMaybe<Scalars['String']>;
  vendorOrderId?: InputMaybe<Scalars['String']>;
};

export type UpdateOrganizationInput = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  category: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  dbaName: Scalars['String'];
  id: Scalars['ID'];
  identificationNumber: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export type UpdatePaymentMethodInput = {
  address?: InputMaybe<BillingAddressInput>;
  cardNumber?: InputMaybe<Scalars['String']>;
  cvc?: InputMaybe<Scalars['String']>;
  defaultMethod?: InputMaybe<Scalars['Boolean']>;
  expirationMonth?: InputMaybe<Scalars['Int']>;
  expirationYear?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  nameOnCard?: InputMaybe<Scalars['String']>;
};

export type UpdateProductInput = {
  category: Scalars['String'];
  chargeTaxes: Scalars['Boolean'];
  defaultImage?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  images?: InputMaybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  option1?: InputMaybe<Scalars['String']>;
  option2?: InputMaybe<Scalars['String']>;
  option3?: InputMaybe<Scalars['String']>;
  reviewUrl?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  taxCode?: InputMaybe<Scalars['String']>;
  variants: Array<ProductVariantInput>;
};

export type UpdateShopInput = {
  description?: InputMaybe<Scalars['String']>;
  flatShippingRate: Scalars['Float'];
  freeShippingThreshold?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  returnPolicy: Scalars['String'];
  shopifyName?: InputMaybe<Scalars['String']>;
  thumbnail?: InputMaybe<Scalars['String']>;
};

export type User = {
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  emailVerified: Scalars['Boolean'];
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  personalInfo?: Maybe<PersonalInfo>;
  phone?: Maybe<Scalars['String']>;
  phoneVerified: Scalars['Boolean'];
  preferences?: Maybe<Preferences>;
  roles: Array<Role>;
  shippingInfo?: Maybe<ShippingInfo>;
  twoFactorEnabled: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  welcomeTourCompleted: Scalars['Boolean'];
};

export type UserCard = {
  createdAt: Scalars['DateTime'];
  expiryMonth: Scalars['Float'];
  expiryYear: Scalars['Float'];
  id: Scalars['String'];
  isDefault: Scalars['Boolean'];
  last4: Scalars['String'];
  scheme: Scalars['String'];
};

export type UserCardDetails = {
  billingAddress: Address;
  createdAt: Scalars['DateTime'];
  expiryMonth: Scalars['Float'];
  expiryYear: Scalars['Float'];
  id: Scalars['String'];
  isDefault: Scalars['Boolean'];
  last4: Scalars['String'];
  name: Scalars['String'];
  scheme: Scalars['String'];
};

export type UserInfo = {
  email: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type UserRoleInput = {
  email: Scalars['String'];
  roles: Array<Role>;
};

export type UserWithRoles = {
  email: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  roles: Array<Role>;
};

export type Wallet = {
  promotional: Scalars['Float'];
  purchase: Scalars['Float'];
};

export type Weight = {
  amount: Scalars['Float'];
  unit: WeightUnit;
};

export type WeightInput = {
  amount: Scalars['Float'];
  unit: WeightUnit;
};

export enum WeightUnit {
  FlOz = 'FL_OZ',
  G = 'G',
  Kg = 'KG',
  Lb = 'LB'
}

export type UpdateCartMutationVariables = Exact<{
  input: CartDataInput;
}>;


export type UpdateCartMutation = { updateCart: { products: Array<{ productId: string }> } };

export type CartCalculationMutationVariables = Exact<{
  input: CartInput;
}>;


export type CartCalculationMutation = { calculateCart: { id: string, items: Array<{ products: Array<{ offerId: string, variantId: string, productName: string, productDescription?: string | null, price: any, quantity: number, country?: string | null, reward: { scutis?: number | null } }> }>, breakdown: { total: any, retail: any, tax: any, shipping: any }, promoCodes: Array<{ id: string, code: string, description: string, reward?: number | null, rewardType?: string | null }>, extraRewards: { scutis: number, discounts: { cart: number, shop: number } } } };

export type ExchangeMutationVariables = Exact<{
  gameId: Scalars['String'];
  amount: Scalars['Int'];
  gameUserId: Scalars['String'];
}>;


export type ExchangeMutation = { exchange: { id: string, scutisSpent: number, currencyEarned: number, currencyName: string } };

export type StripeCheckoutMutationVariables = Exact<{
  input: StripeCheckoutInput;
}>;


export type StripeCheckoutMutation = { stripeCheckout?: { amount: number, currency: string, paymentMethodTypes: Array<string>, clientSecret: string, paidInFullWithScutis?: boolean | null } | null };

export type OfferListItemFragment = { id: string, type: string, name: string, appleId?: number | null, androidId?: string | null, pcLink?: string | null, shop?: { id: string, name: string, thumbnail?: string | null } | null, product?: { id: string, name: string, category: string } | null, media: { tile?: string | null, vertical?: string | null, videoUrl?: string | null, images?: Array<string> | null, banner?: { tallUrl?: string | null, smallUrl?: string | null } | null } };

export type ProductOfferFragment = { id: string, type: string, name: string, shop?: { name: string, thumbnail?: string | null } | null, brand?: { name: string } | null, product?: { id: string, name: string, description?: string | null, category: string, images?: Array<string> | null, option1?: string | null, option2?: string | null, option3?: string | null, scutisExclusive?: boolean | null, price: { amount: number, currency: string }, variants: Array<{ id: string, sku: string, image?: string | null, inStock: number, option1?: string | null, option2?: string | null, option3?: string | null, price: { amount: number, currency: string }, compareAt?: { amount: number, currency: string } | null, weight?: { amount: number, unit: WeightUnit } | null }> } | null, reward: { scutis?: number | null, scutiPercentage?: number | null } };

export type BannerOfferFragment = { id: string, media: { tile?: string | null, vertical?: string | null, videoUrl?: string | null, images?: Array<string> | null, banner?: { bigUrl?: string | null, tallUrl?: string | null, smallUrl?: string | null } | null }, product?: { id: string, images?: Array<string> | null, name: string, price: { amount: number } } | null };

export type VideoOfferFragment = { name: string, media: { videoUrl?: string | null } };

export type AppDownloadOfferFragment = { name: string, androidId?: string | null, appleId?: number | null, pcLink?: string | null, media: { videoUrl?: string | null } };

export type OrderListItemFragment = { id: string, createdAt: any, amount: number, status: OrderStatus, items: Array<{ id: string, quantity: any, amount: any, product: { name: string, defaultImage?: string | null, scutisExclusive?: boolean | null, variant: { image?: string | null, sku: string, barcode?: string | null, price: { amount: number, currency: string }, compareAt?: { amount: number, currency: string } | null } } }> };

export type OrderDetailsFragment = { id: string, createdAt: any, amount: number, status: OrderStatus, shippingInformation?: { phone?: string | null, address1: string, address2?: string | null, city: string, zipCode: string, country: string, state?: string | null } | null, items: Array<{ id: string, quantity: any, amount: any, product: { name: string, defaultImage?: string | null, scutisExclusive?: boolean | null, variant: { image?: string | null, sku: string, barcode?: string | null, price: { amount: number, currency: string }, compareAt?: { amount: number, currency: string } | null } } }> };

export type ScutiTransactionItemFragment = { id: string, purchaseBalanceChange: number, promotionalBalanceChange: number, type: string, receiptId?: string | null, rewardId?: string | null, rewardType?: string | null, createdAt: any };

export type CartFragment = { products: Array<{ productId: string, productName: string, option1?: string | null, option2?: string | null, option3?: string | null, images?: Array<string> | null, variant: { offerId: string, variantId: string, quantity: number, inStock: number, option1?: string | null, option2?: string | null, option3?: string | null, image?: string | null, price: any }, reward: { scutis?: number | null } }> };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { me: { id: string, email: string, phone?: string | null, fullName?: string | null, emailVerified: boolean, phoneVerified: boolean, personalInfo?: { phone?: string | null, gender?: string | null, age?: number | null, birthDate?: any | null, dateTruncated?: boolean | null } | null, preferences?: { categories?: Array<string> | null } | null, shippingInfo?: { address1: string, address2?: string | null, city: string, zipCode: string, country: string, state?: string | null, phone?: string | null } | null } };

export type SetWelcomeTourCompletedMutationVariables = Exact<{ [key: string]: never; }>;


export type SetWelcomeTourCompletedMutation = { setWelcomeTourCompleted: { id: string } };

export type MyNameAndAgeMutationVariables = Exact<{
  input: PersonalInformationInput;
}>;


export type MyNameAndAgeMutation = { updateMyPersonalInfo: { birthDate?: any | null } };

export type MyAddressMutationVariables = Exact<{
  input: ShippingInformationInput;
}>;


export type MyAddressMutation = { updateMyShippingInfo: { phone?: string | null } };

export type MyPreferencesMutationVariables = Exact<{
  input: PreferencesInput;
}>;


export type MyPreferencesMutation = { updateMyPreferences: { categories?: Array<string> | null } };

export type MyOrdersQueryVariables = Exact<{
  paging?: InputMaybe<PagingInput>;
  sorting?: InputMaybe<Array<SortFieldInput> | SortFieldInput>;
  filters?: InputMaybe<Array<FilterInput> | FilterInput>;
}>;


export type MyOrdersQuery = { myOrders: { paging: { offset: number, limit: number, totalCount: number }, nodes: Array<{ id: string, createdAt: any, amount: number, status: OrderStatus, items: Array<{ id: string, quantity: any, amount: any, product: { name: string, defaultImage?: string | null, scutisExclusive?: boolean | null, variant: { image?: string | null, sku: string, barcode?: string | null, price: { amount: number, currency: string }, compareAt?: { amount: number, currency: string } | null } } }> }> } };

export type OrderDetailsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type OrderDetailsQuery = { myOrder: { id: string, createdAt: any, amount: number, status: OrderStatus, user: { email: string, fullName?: string | null }, shippingInformation?: { phone?: string | null, address1: string, address2?: string | null, city: string, zipCode: string, country: string, state?: string | null } | null, items: Array<{ id: string, quantity: any, amount: any, product: { name: string, defaultImage?: string | null, scutisExclusive?: boolean | null, variant: { image?: string | null, sku: string, barcode?: string | null, price: { amount: number, currency: string }, compareAt?: { amount: number, currency: string } | null } } }> } };

export type MyWalletQueryVariables = Exact<{ [key: string]: never; }>;


export type MyWalletQuery = { myWallet: { promotional: number, purchase: number } };

export type MyRewardsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyRewardsQuery = { myRewards: { nodes: Array<{ id: string, activated: boolean, type: string, scutiAccountType: string, scutis?: number | null, user: { id: string, email: string, fullName?: string | null } }> } };

export type ActivateRewardsMutationVariables = Exact<{
  rewardId: Array<Scalars['String']> | Scalars['String'];
}>;


export type ActivateRewardsMutation = { activateRewards: Array<{ id: string }> };

export type MyScutiTransactionsQueryVariables = Exact<{
  paging?: InputMaybe<PagingInput>;
  sorting?: InputMaybe<Array<SortFieldInput> | SortFieldInput>;
  filters?: InputMaybe<Array<FilterInput> | FilterInput>;
}>;


export type MyScutiTransactionsQuery = { myScutiTransactions: { sorting?: Array<{ name: string, dir: string }> | null, paging: { offset: number, limit: number, totalCount: number }, nodes: Array<{ id: string, purchaseBalanceChange: number, promotionalBalanceChange: number, type: string, receiptId?: string | null, rewardId?: string | null, rewardType?: string | null, createdAt: any }> } };

export type MyCartQueryVariables = Exact<{ [key: string]: never; }>;


export type MyCartQuery = { myCart: { products: Array<{ productId: string, productName: string, option1?: string | null, option2?: string | null, option3?: string | null, images?: Array<string> | null, variant: { offerId: string, variantId: string, quantity: number, inStock: number, option1?: string | null, option2?: string | null, option3?: string | null, image?: string | null, price: any }, reward: { scutis?: number | null } }> } };

export type ProductOfferQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ProductOfferQuery = { offer: { id: string, type: string, name: string, shop?: { name: string, thumbnail?: string | null } | null, brand?: { name: string } | null, product?: { id: string, name: string, description?: string | null, category: string, images?: Array<string> | null, option1?: string | null, option2?: string | null, option3?: string | null, scutisExclusive?: boolean | null, price: { amount: number, currency: string }, variants: Array<{ id: string, sku: string, image?: string | null, inStock: number, option1?: string | null, option2?: string | null, option3?: string | null, price: { amount: number, currency: string }, compareAt?: { amount: number, currency: string } | null, weight?: { amount: number, unit: WeightUnit } | null }> } | null, reward: { scutis?: number | null, scutiPercentage?: number | null } } };

export type VideoOfferQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type VideoOfferQuery = { offer: { name: string, media: { videoUrl?: string | null } } };

export type AppDownloadOfferQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type AppDownloadOfferQuery = { offer: { name: string, androidId?: string | null, appleId?: number | null, pcLink?: string | null, media: { videoUrl?: string | null } } };

export type BannerOffersQueryVariables = Exact<{
  paging?: InputMaybe<PagingInput>;
  seed?: InputMaybe<Scalars['Int']>;
}>;


export type BannerOffersQuery = { offers: { seed?: number | null, paging: { offset: number, limit: number, totalCount: number }, nodes: Array<{ id: string, media: { tile?: string | null, vertical?: string | null, videoUrl?: string | null, images?: Array<string> | null, banner?: { bigUrl?: string | null, tallUrl?: string | null, smallUrl?: string | null } | null }, product?: { id: string, images?: Array<string> | null, name: string, price: { amount: number } } | null }> } };

export type NewProductsOffersQueryVariables = Exact<{ [key: string]: never; }>;


export type NewProductsOffersQuery = { offers: { nodes: Array<{ id: string, name: string }> } };

export type ValidateCodesMutationVariables = Exact<{
  newCode: Scalars['String'];
  activeCodes: Array<PromoCodesInput> | PromoCodesInput;
}>;


export type ValidateCodesMutation = { validateCodes: { id: string, code: string, description: string } };

export type StoreInfoQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type StoreInfoQuery = { gameInfo?: { id: string, name: string, currency?: { name: string, thumbnail?: string | null, exchangeRate: number, scutiExchangeRate: number } | null } | null, platformSettings: { scutiExchangeRate: number }, stripeSettings: { publicKey: string } };

export type ProductCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductCategoriesQuery = { productCategories: Array<string> };

export const OfferListItemFragmentDoc = `
    fragment OfferListItem on Offer {
  id
  type
  name
  shop {
    id
    name
    thumbnail
  }
  product {
    id
    name
    category
  }
  media {
    tile
    vertical
    videoUrl
    images
    banner {
      tallUrl
      smallUrl
    }
  }
  appleId
  androidId
  pcLink
}
    `;
export const ProductOfferFragmentDoc = `
    fragment ProductOffer on Offer {
  id
  type
  name
  shop {
    name
    thumbnail
  }
  brand {
    name
  }
  product {
    id
    name
    description
    category
    images
    price {
      amount
      currency
    }
    option1
    option2
    option3
    variants {
      id
      sku
      image
      price {
        amount
        currency
      }
      compareAt {
        amount
        currency
      }
      weight {
        amount
        unit
      }
      inStock
      option1
      option2
      option3
    }
    scutisExclusive
  }
  reward {
    scutis
    scutiPercentage
  }
}
    `;
export const BannerOfferFragmentDoc = `
    fragment BannerOffer on Offer {
  id
  media {
    banner {
      bigUrl
      tallUrl
      smallUrl
    }
    tile
    vertical
    videoUrl
    images
  }
  product {
    id
    images
    name
    price {
      amount
    }
  }
}
    `;
export const VideoOfferFragmentDoc = `
    fragment VideoOffer on Offer {
  name
  media {
    videoUrl
  }
}
    `;
export const AppDownloadOfferFragmentDoc = `
    fragment AppDownloadOffer on Offer {
  name
  androidId
  appleId
  pcLink
  media {
    videoUrl
  }
}
    `;
export const OrderListItemFragmentDoc = `
    fragment OrderListItem on Order {
  id
  createdAt
  amount
  items {
    id
    product {
      name
      defaultImage
      scutisExclusive
      variant {
        image
        price {
          amount
          currency
        }
        compareAt {
          amount
          currency
        }
        sku
        barcode
      }
    }
    quantity
    amount
  }
  status
}
    `;
export const OrderDetailsFragmentDoc = `
    fragment OrderDetails on Order {
  shippingInformation {
    phone
    address1
    address2
    city
    zipCode
    country
    state
  }
  ...OrderListItem
}
    ${OrderListItemFragmentDoc}`;
export const ScutiTransactionItemFragmentDoc = `
    fragment ScutiTransactionItem on ScutiTransaction {
  id
  purchaseBalanceChange
  promotionalBalanceChange
  type
  receiptId
  rewardId
  rewardType
  createdAt
}
    `;
export const CartFragmentDoc = `
    fragment Cart on CartOutput {
  products {
    productId
    productName
    option1
    option2
    option3
    images
    variant {
      offerId
      variantId
      offerId
      quantity
      inStock
      option1
      option2
      option3
      image
      price
    }
    reward {
      scutis
    }
  }
}
    `;
export const UpdateCartDocument = `
    mutation UpdateCart($input: CartDataInput!) {
  updateCart(input: $input) {
    products {
      productId
    }
  }
}
    `;
export const useUpdateCartMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateCartMutation, TError, UpdateCartMutationVariables, TContext>) =>
    useMutation<UpdateCartMutation, TError, UpdateCartMutationVariables, TContext>(
      ['UpdateCart'],
      (variables?: UpdateCartMutationVariables) => fetcherGraphql<UpdateCartMutation, UpdateCartMutationVariables>(UpdateCartDocument, variables)(),
      options
    );
useUpdateCartMutation.getKey = () => ['UpdateCart'];

export const CartCalculationDocument = `
    mutation CartCalculation($input: CartInput!) {
  calculateCart(input: $input) {
    id
    items {
      products {
        offerId
        variantId
        productName
        productDescription
        price
        quantity
        country
        reward {
          scutis
        }
      }
    }
    breakdown {
      total
      retail
      tax
      shipping
    }
    promoCodes {
      id
      code
      description
      reward
      rewardType
    }
    extraRewards {
      discounts {
        cart
        shop
      }
      scutis
    }
  }
}
    `;
export const useCartCalculationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CartCalculationMutation, TError, CartCalculationMutationVariables, TContext>) =>
    useMutation<CartCalculationMutation, TError, CartCalculationMutationVariables, TContext>(
      ['CartCalculation'],
      (variables?: CartCalculationMutationVariables) => fetcherGraphql<CartCalculationMutation, CartCalculationMutationVariables>(CartCalculationDocument, variables)(),
      options
    );
useCartCalculationMutation.getKey = () => ['CartCalculation'];

export const ExchangeDocument = `
    mutation Exchange($gameId: String!, $amount: Int!, $gameUserId: String!) {
  exchange(gameId: $gameId, amount: $amount, gameUserId: $gameUserId) {
    id
    scutisSpent
    currencyEarned
    currencyName
  }
}
    `;
export const useExchangeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ExchangeMutation, TError, ExchangeMutationVariables, TContext>) =>
    useMutation<ExchangeMutation, TError, ExchangeMutationVariables, TContext>(
      ['Exchange'],
      (variables?: ExchangeMutationVariables) => fetcherGraphql<ExchangeMutation, ExchangeMutationVariables>(ExchangeDocument, variables)(),
      options
    );
useExchangeMutation.getKey = () => ['Exchange'];

export const StripeCheckoutDocument = `
    mutation StripeCheckout($input: StripeCheckoutInput!) {
  stripeCheckout(input: $input) {
    amount
    currency
    paymentMethodTypes
    clientSecret
    paidInFullWithScutis
  }
}
    `;
export const useStripeCheckoutMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<StripeCheckoutMutation, TError, StripeCheckoutMutationVariables, TContext>) =>
    useMutation<StripeCheckoutMutation, TError, StripeCheckoutMutationVariables, TContext>(
      ['StripeCheckout'],
      (variables?: StripeCheckoutMutationVariables) => fetcherGraphql<StripeCheckoutMutation, StripeCheckoutMutationVariables>(StripeCheckoutDocument, variables)(),
      options
    );
useStripeCheckoutMutation.getKey = () => ['StripeCheckout'];

export const MeDocument = `
    query Me {
  me {
    id
    email
    phone
    fullName
    emailVerified
    phoneVerified
    personalInfo {
      phone
      gender
      age
      birthDate
      dateTruncated
    }
    preferences {
      categories
    }
    shippingInfo {
      address1
      address2
      city
      zipCode
      country
      state
      phone
    }
  }
}
    `;
export const useMeQuery = <
      TData = MeQuery,
      TError = unknown
    >(
      variables?: MeQueryVariables,
      options?: UseQueryOptions<MeQuery, TError, TData>
    ) =>
    useQuery<MeQuery, TError, TData>(
      variables === undefined ? ['Me'] : ['Me', variables],
      fetcherGraphql<MeQuery, MeQueryVariables>(MeDocument, variables),
      options
    );
useMeQuery.document = MeDocument;


useMeQuery.getKey = (variables?: MeQueryVariables) => variables === undefined ? ['Me'] : ['Me', variables];
;

export const SetWelcomeTourCompletedDocument = `
    mutation setWelcomeTourCompleted {
  setWelcomeTourCompleted {
    id
  }
}
    `;
export const useSetWelcomeTourCompletedMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SetWelcomeTourCompletedMutation, TError, SetWelcomeTourCompletedMutationVariables, TContext>) =>
    useMutation<SetWelcomeTourCompletedMutation, TError, SetWelcomeTourCompletedMutationVariables, TContext>(
      ['setWelcomeTourCompleted'],
      (variables?: SetWelcomeTourCompletedMutationVariables) => fetcherGraphql<SetWelcomeTourCompletedMutation, SetWelcomeTourCompletedMutationVariables>(SetWelcomeTourCompletedDocument, variables)(),
      options
    );
useSetWelcomeTourCompletedMutation.getKey = () => ['setWelcomeTourCompleted'];

export const MyNameAndAgeDocument = `
    mutation MyNameAndAge($input: PersonalInformationInput!) {
  updateMyPersonalInfo(input: $input) {
    birthDate
  }
}
    `;
export const useMyNameAndAgeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<MyNameAndAgeMutation, TError, MyNameAndAgeMutationVariables, TContext>) =>
    useMutation<MyNameAndAgeMutation, TError, MyNameAndAgeMutationVariables, TContext>(
      ['MyNameAndAge'],
      (variables?: MyNameAndAgeMutationVariables) => fetcherGraphql<MyNameAndAgeMutation, MyNameAndAgeMutationVariables>(MyNameAndAgeDocument, variables)(),
      options
    );
useMyNameAndAgeMutation.getKey = () => ['MyNameAndAge'];

export const MyAddressDocument = `
    mutation MyAddress($input: ShippingInformationInput!) {
  updateMyShippingInfo(input: $input) {
    phone
  }
}
    `;
export const useMyAddressMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<MyAddressMutation, TError, MyAddressMutationVariables, TContext>) =>
    useMutation<MyAddressMutation, TError, MyAddressMutationVariables, TContext>(
      ['MyAddress'],
      (variables?: MyAddressMutationVariables) => fetcherGraphql<MyAddressMutation, MyAddressMutationVariables>(MyAddressDocument, variables)(),
      options
    );
useMyAddressMutation.getKey = () => ['MyAddress'];

export const MyPreferencesDocument = `
    mutation MyPreferences($input: PreferencesInput!) {
  updateMyPreferences(input: $input) {
    categories
  }
}
    `;
export const useMyPreferencesMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<MyPreferencesMutation, TError, MyPreferencesMutationVariables, TContext>) =>
    useMutation<MyPreferencesMutation, TError, MyPreferencesMutationVariables, TContext>(
      ['MyPreferences'],
      (variables?: MyPreferencesMutationVariables) => fetcherGraphql<MyPreferencesMutation, MyPreferencesMutationVariables>(MyPreferencesDocument, variables)(),
      options
    );
useMyPreferencesMutation.getKey = () => ['MyPreferences'];

export const MyOrdersDocument = `
    query MyOrders($paging: PagingInput, $sorting: [SortFieldInput!], $filters: [FilterInput!]) {
  myOrders(paging: $paging, sorting: $sorting, filters: $filters) {
    paging {
      offset
      limit
      totalCount
    }
    nodes {
      ...OrderListItem
    }
  }
}
    ${OrderListItemFragmentDoc}`;
export const useMyOrdersQuery = <
      TData = MyOrdersQuery,
      TError = unknown
    >(
      variables?: MyOrdersQueryVariables,
      options?: UseQueryOptions<MyOrdersQuery, TError, TData>
    ) =>
    useQuery<MyOrdersQuery, TError, TData>(
      variables === undefined ? ['MyOrders'] : ['MyOrders', variables],
      fetcherGraphql<MyOrdersQuery, MyOrdersQueryVariables>(MyOrdersDocument, variables),
      options
    );
useMyOrdersQuery.document = MyOrdersDocument;


useMyOrdersQuery.getKey = (variables?: MyOrdersQueryVariables) => variables === undefined ? ['MyOrders'] : ['MyOrders', variables];
;

export const OrderDetailsDocument = `
    query OrderDetails($id: String!) {
  myOrder(id: $id) {
    ...OrderDetails
    user {
      email
      fullName
    }
  }
}
    ${OrderDetailsFragmentDoc}`;
export const useOrderDetailsQuery = <
      TData = OrderDetailsQuery,
      TError = unknown
    >(
      variables: OrderDetailsQueryVariables,
      options?: UseQueryOptions<OrderDetailsQuery, TError, TData>
    ) =>
    useQuery<OrderDetailsQuery, TError, TData>(
      ['OrderDetails', variables],
      fetcherGraphql<OrderDetailsQuery, OrderDetailsQueryVariables>(OrderDetailsDocument, variables),
      options
    );
useOrderDetailsQuery.document = OrderDetailsDocument;


useOrderDetailsQuery.getKey = (variables: OrderDetailsQueryVariables) => ['OrderDetails', variables];
;

export const MyWalletDocument = `
    query MyWallet {
  myWallet {
    promotional
    purchase
  }
}
    `;
export const useMyWalletQuery = <
      TData = MyWalletQuery,
      TError = unknown
    >(
      variables?: MyWalletQueryVariables,
      options?: UseQueryOptions<MyWalletQuery, TError, TData>
    ) =>
    useQuery<MyWalletQuery, TError, TData>(
      variables === undefined ? ['MyWallet'] : ['MyWallet', variables],
      fetcherGraphql<MyWalletQuery, MyWalletQueryVariables>(MyWalletDocument, variables),
      options
    );
useMyWalletQuery.document = MyWalletDocument;


useMyWalletQuery.getKey = (variables?: MyWalletQueryVariables) => variables === undefined ? ['MyWallet'] : ['MyWallet', variables];
;

export const MyRewardsDocument = `
    query MyRewards {
  myRewards(
    paging: {offset: 0, limit: 50}
    filters: [{name: "activated", operator: EQ, value: ["false"]}]
  ) {
    nodes {
      id
      activated
      type
      scutiAccountType
      scutis
      user {
        id
        email
        fullName
      }
    }
  }
}
    `;
export const useMyRewardsQuery = <
      TData = MyRewardsQuery,
      TError = unknown
    >(
      variables?: MyRewardsQueryVariables,
      options?: UseQueryOptions<MyRewardsQuery, TError, TData>
    ) =>
    useQuery<MyRewardsQuery, TError, TData>(
      variables === undefined ? ['MyRewards'] : ['MyRewards', variables],
      fetcherGraphql<MyRewardsQuery, MyRewardsQueryVariables>(MyRewardsDocument, variables),
      options
    );
useMyRewardsQuery.document = MyRewardsDocument;


useMyRewardsQuery.getKey = (variables?: MyRewardsQueryVariables) => variables === undefined ? ['MyRewards'] : ['MyRewards', variables];
;

export const ActivateRewardsDocument = `
    mutation ActivateRewards($rewardId: [String!]!) {
  activateRewards(rewardId: $rewardId) {
    id
  }
}
    `;
export const useActivateRewardsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ActivateRewardsMutation, TError, ActivateRewardsMutationVariables, TContext>) =>
    useMutation<ActivateRewardsMutation, TError, ActivateRewardsMutationVariables, TContext>(
      ['ActivateRewards'],
      (variables?: ActivateRewardsMutationVariables) => fetcherGraphql<ActivateRewardsMutation, ActivateRewardsMutationVariables>(ActivateRewardsDocument, variables)(),
      options
    );
useActivateRewardsMutation.getKey = () => ['ActivateRewards'];

export const MyScutiTransactionsDocument = `
    query MyScutiTransactions($paging: PagingInput, $sorting: [SortFieldInput!], $filters: [FilterInput!]) {
  myScutiTransactions(paging: $paging, sorting: $sorting, filters: $filters) {
    sorting {
      name
      dir
    }
    paging {
      offset
      limit
      totalCount
    }
    nodes {
      ...ScutiTransactionItem
    }
  }
}
    ${ScutiTransactionItemFragmentDoc}`;
export const useMyScutiTransactionsQuery = <
      TData = MyScutiTransactionsQuery,
      TError = unknown
    >(
      variables?: MyScutiTransactionsQueryVariables,
      options?: UseQueryOptions<MyScutiTransactionsQuery, TError, TData>
    ) =>
    useQuery<MyScutiTransactionsQuery, TError, TData>(
      variables === undefined ? ['MyScutiTransactions'] : ['MyScutiTransactions', variables],
      fetcherGraphql<MyScutiTransactionsQuery, MyScutiTransactionsQueryVariables>(MyScutiTransactionsDocument, variables),
      options
    );
useMyScutiTransactionsQuery.document = MyScutiTransactionsDocument;


useMyScutiTransactionsQuery.getKey = (variables?: MyScutiTransactionsQueryVariables) => variables === undefined ? ['MyScutiTransactions'] : ['MyScutiTransactions', variables];
;

export const MyCartDocument = `
    query MyCart {
  myCart {
    ...Cart
  }
}
    ${CartFragmentDoc}`;
export const useMyCartQuery = <
      TData = MyCartQuery,
      TError = unknown
    >(
      variables?: MyCartQueryVariables,
      options?: UseQueryOptions<MyCartQuery, TError, TData>
    ) =>
    useQuery<MyCartQuery, TError, TData>(
      variables === undefined ? ['MyCart'] : ['MyCart', variables],
      fetcherGraphql<MyCartQuery, MyCartQueryVariables>(MyCartDocument, variables),
      options
    );
useMyCartQuery.document = MyCartDocument;


useMyCartQuery.getKey = (variables?: MyCartQueryVariables) => variables === undefined ? ['MyCart'] : ['MyCart', variables];
;

export const ProductOfferDocument = `
    query ProductOffer($id: String!) {
  offer(id: $id) {
    ...ProductOffer
  }
}
    ${ProductOfferFragmentDoc}`;
export const useProductOfferQuery = <
      TData = ProductOfferQuery,
      TError = unknown
    >(
      variables: ProductOfferQueryVariables,
      options?: UseQueryOptions<ProductOfferQuery, TError, TData>
    ) =>
    useQuery<ProductOfferQuery, TError, TData>(
      ['ProductOffer', variables],
      fetcherGraphql<ProductOfferQuery, ProductOfferQueryVariables>(ProductOfferDocument, variables),
      options
    );
useProductOfferQuery.document = ProductOfferDocument;


useProductOfferQuery.getKey = (variables: ProductOfferQueryVariables) => ['ProductOffer', variables];
;

export const VideoOfferDocument = `
    query VideoOffer($id: String!) {
  offer(id: $id) {
    ...VideoOffer
  }
}
    ${VideoOfferFragmentDoc}`;
export const useVideoOfferQuery = <
      TData = VideoOfferQuery,
      TError = unknown
    >(
      variables: VideoOfferQueryVariables,
      options?: UseQueryOptions<VideoOfferQuery, TError, TData>
    ) =>
    useQuery<VideoOfferQuery, TError, TData>(
      ['VideoOffer', variables],
      fetcherGraphql<VideoOfferQuery, VideoOfferQueryVariables>(VideoOfferDocument, variables),
      options
    );
useVideoOfferQuery.document = VideoOfferDocument;


useVideoOfferQuery.getKey = (variables: VideoOfferQueryVariables) => ['VideoOffer', variables];
;

export const AppDownloadOfferDocument = `
    query AppDownloadOffer($id: String!) {
  offer(id: $id) {
    ...AppDownloadOffer
  }
}
    ${AppDownloadOfferFragmentDoc}`;
export const useAppDownloadOfferQuery = <
      TData = AppDownloadOfferQuery,
      TError = unknown
    >(
      variables: AppDownloadOfferQueryVariables,
      options?: UseQueryOptions<AppDownloadOfferQuery, TError, TData>
    ) =>
    useQuery<AppDownloadOfferQuery, TError, TData>(
      ['AppDownloadOffer', variables],
      fetcherGraphql<AppDownloadOfferQuery, AppDownloadOfferQueryVariables>(AppDownloadOfferDocument, variables),
      options
    );
useAppDownloadOfferQuery.document = AppDownloadOfferDocument;


useAppDownloadOfferQuery.getKey = (variables: AppDownloadOfferQueryVariables) => ['AppDownloadOffer', variables];
;

export const BannerOffersDocument = `
    query BannerOffers($paging: PagingInput, $seed: Int) {
  offers(
    filters: [{name: "mediaBanner", operator: IS_NOT_NULL}, {name: "type", operator: EQ, value: ["BANNER"]}]
    paging: $paging
    seed: $seed
  ) {
    paging {
      offset
      limit
      totalCount
    }
    nodes {
      ...BannerOffer
    }
    seed
  }
}
    ${BannerOfferFragmentDoc}`;
export const useBannerOffersQuery = <
      TData = BannerOffersQuery,
      TError = unknown
    >(
      variables?: BannerOffersQueryVariables,
      options?: UseQueryOptions<BannerOffersQuery, TError, TData>
    ) =>
    useQuery<BannerOffersQuery, TError, TData>(
      variables === undefined ? ['BannerOffers'] : ['BannerOffers', variables],
      fetcherGraphql<BannerOffersQuery, BannerOffersQueryVariables>(BannerOffersDocument, variables),
      options
    );
useBannerOffersQuery.document = BannerOffersDocument;


useBannerOffersQuery.getKey = (variables?: BannerOffersQueryVariables) => variables === undefined ? ['BannerOffers'] : ['BannerOffers', variables];
;

export const NewProductsOffersDocument = `
    query NewProductsOffers {
  offers(
    filters: [{name: "isNew", operator: EQ, value: ["true"]}, {name: "type", operator: IN, value: ["PRODUCT", "PRODUCT_LISTING"]}]
    paging: {offset: 0, limit: 50}
  ) {
    nodes {
      id
      name
    }
  }
}
    `;
export const useNewProductsOffersQuery = <
      TData = NewProductsOffersQuery,
      TError = unknown
    >(
      variables?: NewProductsOffersQueryVariables,
      options?: UseQueryOptions<NewProductsOffersQuery, TError, TData>
    ) =>
    useQuery<NewProductsOffersQuery, TError, TData>(
      variables === undefined ? ['NewProductsOffers'] : ['NewProductsOffers', variables],
      fetcherGraphql<NewProductsOffersQuery, NewProductsOffersQueryVariables>(NewProductsOffersDocument, variables),
      options
    );
useNewProductsOffersQuery.document = NewProductsOffersDocument;


useNewProductsOffersQuery.getKey = (variables?: NewProductsOffersQueryVariables) => variables === undefined ? ['NewProductsOffers'] : ['NewProductsOffers', variables];
;

export const ValidateCodesDocument = `
    mutation ValidateCodes($newCode: String!, $activeCodes: [PromoCodesInput!]!) {
  validateCodes(newCode: $newCode, activeCodes: $activeCodes) {
    id
    code
    description
  }
}
    `;
export const useValidateCodesMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ValidateCodesMutation, TError, ValidateCodesMutationVariables, TContext>) =>
    useMutation<ValidateCodesMutation, TError, ValidateCodesMutationVariables, TContext>(
      ['ValidateCodes'],
      (variables?: ValidateCodesMutationVariables) => fetcherGraphql<ValidateCodesMutation, ValidateCodesMutationVariables>(ValidateCodesDocument, variables)(),
      options
    );
useValidateCodesMutation.getKey = () => ['ValidateCodes'];

export const StoreInfoDocument = `
    query StoreInfo($id: String!) {
  gameInfo(id: $id) {
    id
    name
    currency {
      name
      thumbnail
      exchangeRate
      scutiExchangeRate
    }
  }
  platformSettings {
    scutiExchangeRate
  }
  stripeSettings {
    publicKey
  }
}
    `;
export const useStoreInfoQuery = <
      TData = StoreInfoQuery,
      TError = unknown
    >(
      variables: StoreInfoQueryVariables,
      options?: UseQueryOptions<StoreInfoQuery, TError, TData>
    ) =>
    useQuery<StoreInfoQuery, TError, TData>(
      ['StoreInfo', variables],
      fetcherGraphql<StoreInfoQuery, StoreInfoQueryVariables>(StoreInfoDocument, variables),
      options
    );
useStoreInfoQuery.document = StoreInfoDocument;


useStoreInfoQuery.getKey = (variables: StoreInfoQueryVariables) => ['StoreInfo', variables];
;

export const ProductCategoriesDocument = `
    query ProductCategories {
  productCategories
}
    `;
export const useProductCategoriesQuery = <
      TData = ProductCategoriesQuery,
      TError = unknown
    >(
      variables?: ProductCategoriesQueryVariables,
      options?: UseQueryOptions<ProductCategoriesQuery, TError, TData>
    ) =>
    useQuery<ProductCategoriesQuery, TError, TData>(
      variables === undefined ? ['ProductCategories'] : ['ProductCategories', variables],
      fetcherGraphql<ProductCategoriesQuery, ProductCategoriesQueryVariables>(ProductCategoriesDocument, variables),
      options
    );
useProductCategoriesQuery.document = ProductCategoriesDocument;


useProductCategoriesQuery.getKey = (variables?: ProductCategoriesQueryVariables) => variables === undefined ? ['ProductCategories'] : ['ProductCategories', variables];
;
