import { Icon } from '@/components/Common/Icon';
import { useMyWalletQuery } from '@/graphql/generated';
import { useMemo } from 'react';

export function WalletDisabled() {
  const { data } = useMyWalletQuery(
    {},
    { refetchOnWindowFocus: 'always', refetchOnMount: 'always', staleTime: 0, cacheTime: 0 },
  );

  const balance = useMemo(() => {
    if (!data) return 0;
    const { promotional, purchase } = data.myWallet;
    return promotional + purchase;
  }, [data]);

  return (
    <div className="flex flex-col items-center">
      <img alt="" className="h-40" src="/images/ScutiCoin.png" />
      <p className="text-paragraph-lg mt-4 text-lg text-white">Balance: {balance}</p>
      <div className="align-items-center mt-12 max-w-lg rounded-xl border-2 border-black bg-zinc-900 p-6">
        <div className="flex w-full justify-center py-4">
          <Icon name="ExchangeDisabled" />
        </div>
        <h2 className="my-6 text-center text-xl text-white">
          Scuti exchange in this game is not available at this time
        </h2>
      </div>
    </div>
  );
}
