import { QueryClient } from '@tanstack/react-query';
import { NonIndexRouteObject, redirect } from 'react-router-dom';
import { useMyOrdersQuery } from '@/graphql/generated';
import { fetcherGraphql } from '@/helpers/fetch.helper';
import { pagingDefaults } from '@/utils/paging.utils';
import { mapOrderTypeToFilter } from '@/utils/order.utils';
import { appLocalStorage } from '@/helpers/storage.helper';
import { appRoutes } from '@/helpers/routes.helper';

export const orderHistoryLoader =
  (queryClient: QueryClient): NonIndexRouteObject['loader'] =>
  async ({ params }) => {
    if (!appLocalStorage.getItem('refreshToken')) {
      return redirect(appRoutes.welcome);
    }
    const variables = {
      paging: pagingDefaults,
      filters: params.type ? [mapOrderTypeToFilter(params.type as 'active' | 'past')] : undefined,
      sorting: { name: 'createdAt', dir: 'desc' },
    };

    return await queryClient.ensureQueryData({
      queryFn: fetcherGraphql(useMyOrdersQuery.document, variables),
      queryKey: useMyOrdersQuery.getKey(variables),
    });
  };
