import { useMemo } from 'react';
import { IconName } from '../Icon';
import { appRoutes } from '@/helpers/routes.helper';
import { useScreenSize } from '@/hooks/useScreenSize';

export function useMenuItems(isLogIn: boolean) {
  const screenSize = useScreenSize();
  const isMobile = ['xs', 'sm'].some((size) => size === screenSize);

  return useMemo(() => {
    const items = [
      {
        label: 'About Scuti',
        icon: 'Star' as IconName,
        route: appRoutes.about,
      },
      {
        label: 'Legal',
        icon: 'Legal' as IconName,
        route: isMobile ? appRoutes.legal : appRoutes.legalTermsAndConditions,
      },
    ];

    if (isLogIn) {
      return [
        {
          label: 'My Orders',
          icon: 'Fire' as IconName,
          route: appRoutes.orderHistory('active'),
        },
        {
          label: 'Scuti Wallet',
          icon: 'ScutiWallet' as IconName,
          route: appRoutes.wallet,
        },
        {
          label: 'My Account',
          icon: 'Avatar' as IconName,
          route: isMobile ? appRoutes.accountSettings : appRoutes.accountSettingsNameAndAge,
        },
        // {
        //   label: 'Refer Friend',
        //   icon: 'Referral' as IconName,
        //   route: '#',
        // },
        ...items,
      ];
    }
    return items;
  }, [isLogIn, isMobile]);
}
