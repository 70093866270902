import { memo, useEffect, useMemo, useRef } from 'react';
import postscribe from '@/lib/postscribe/postscribe';
import { v4 as uuid } from 'uuid';
import { OfferAdSize } from '@/types/OfferTypes';
import { useMe } from '@/hooks/useMe';
import { appSessionStorage } from '@/helpers/storage.helper';

type BannerTiles = {
  id: string;
  url?: string;
  adSize?: OfferAdSize;
};

const loadAd = (el: HTMLDivElement | null, content: string) => {
  postscribe(el, content, {
    error: (e: any) => console.error(e),
  });
};

export const BannerTile = memo(({ id, url, adSize }: BannerTiles) => {
  const adRef = useRef<HTMLDivElement>(null);

  const [width, height] = useMemo(() => adSize?.split('x') || [300, 250], [adSize]);

  const me = useMe();

  const gameId = appSessionStorage.getItem('gameId');
  const sessionId = appSessionStorage.getItem('sessionId');
  const userId = me?.id ?? '';

  const target = useMemo(() => `offer-${id}-${uuid()}`, [id]);
  const scriptUrl = useMemo(
    () =>
      `${url}&width=${width}&height=${height}&selector=.${target}&app_id=${gameId}&app_name=${sessionId}&app_uri=${userId}`,
    [url, width, height, target, gameId, sessionId, userId],
  );

  useEffect(() => {
    const el = adRef.current;
    const script = `<script src="${scriptUrl}"></script>`;
    loadAd(adRef.current, script);

    return () => {
      postscribe(el, script, 'clear');
    };
  }, [scriptUrl]);

  return <div className={`${target} min-h-[250px]`} ref={adRef} />;
});
