import { WrapperPlatform } from '@/types/App';
import { SDKReceiveMessage } from './types';
import { appSessionStorage } from '../storage.helper';

function eventListener(event: MessageEvent<SDKReceiveMessage>) {
  const { data } = event;

  if (data.message === 'INIT_SDK') window.initSdk();
  if (data.message === 'TOGGLE_STORE') window.toggleStore(data.payload);
  if (data.message === 'UI_SETTING') window.updateLabels(data.payload);
  if (data.message === 'GET_NEW_REWARDS') window.getNewRewards();
  if (data.message === 'GET_NEW_PRODUCTS') window.getNewProducts();
  if (data.message === 'END_SESSION') window.endSession();
  if (data.message === 'HIDE_BACK_TO_THE_GAME') window.hideBackToTheGame();
  if (data.message === 'SET_GAME_USER_ID') window.setGameUserId(data.payload);
  if (data.message === 'COIN_EXCHANGE_SETTING') appSessionStorage.setItem('exchangeSettings', data.payload);
}

export function messageListener(platform: WrapperPlatform) {
  if (platform === 'Vuplex') window.vuplex.addEventListener('message', eventListener);

  if ((['WebGL', 'iFrame', 'Unreal'] as WrapperPlatform[]).includes(platform)) {
    window.addEventListener('message', eventListener);
  }
}
