import { memo, useEffect, useMemo, useState } from 'react';
import postscribe from '@/lib/postscribe/postscribe';
import { useScreenSize } from '@/hooks/useScreenSize';
import { appSessionStorage } from '@/helpers/storage.helper';
import { useMe } from '@/hooks/useMe';
import { useAdMessageListener } from '@/hooks/useAdMessageListener';
import { getEnv } from '@/utils/sentry.utils';

const env = getEnv();
let TAG = {
  mobile: 'https://arena.siprocalads.com/EPK7G98BG12B9A3O4K5O0T5KO8?us_privacy=1NYN',
  desktop: 'https://arena.siprocalads.com/TCO95DIS7D2HLC4IMPE3M58QSS?us_privacy=1NYN',
};

if (env === 'production') {
  TAG = {
    mobile: 'https://arena.siprocalads.com/GV0PL2UV857V90CQHFFK2BVKR8?us_privacy=1NYN',
    desktop: 'https://arena.siprocalads.com/7H3DFG3SVP66H1MNO1UFSV381K?us_privacy=1NYN',
  };
}

const AD_REFRESH_TIMER = 30000;

const MOBILE_AD_SIZE = [320, 50];
// const TABLET_AD_SIZE = [364, 45];
const DESKTOP_AD_SIZE = [728, 90];

export const BannerAds = memo(({ selector }: { selector: string }) => {
  const screensize = useScreenSize();

  useAdMessageListener();

  const [counter, setCounter] = useState(0);

  const me = useMe();

  const gameId = appSessionStorage.getItem('gameId');
  const sessionId = appSessionStorage.getItem('sessionId');

  const userId = useMemo(() => {
    return me?.id ?? '';
  }, [me]);

  const [width, height] = useMemo(() => {
    return ['xs', 'sm', 'md', 'lg'].includes(screensize!) ? MOBILE_AD_SIZE : DESKTOP_AD_SIZE;
  }, [screensize]);

  const src = useMemo(() => {
    return ['xs', 'sm', 'md', 'lg'].includes(screensize!) ? TAG.mobile : TAG.desktop;
  }, [screensize]);

  const scriptSource = useMemo(() => {
    return `<script async src="${src}&width=${width}&height=${height}&selector=.${selector}&app_id=${gameId}&app_name=${sessionId}&app_uri=${userId}&t=${counter}"></script>`;
  }, [height, width, gameId, sessionId, userId, src, selector, counter]);

  useEffect(() => {
    let timeout: number;

    const el = document.querySelector(`.${selector}`);
    if (el && el?.childNodes.length > 1) {
      el.innerHTML = '';
    }

    postscribe(el, scriptSource, {
      done: () => {
        timeout = window.setTimeout(() => {
          setCounter((prev) => prev + 1);
        }, AD_REFRESH_TIMER);
      },
      error: () => {
        /* do nothing */
      },
    });

    return () => {
      postscribe(el, scriptSource, 'clear');
      clearTimeout(timeout);
    };
  }, [selector, scriptSource]);

  return <div className={`scuti-banners-component`} />;
});
