import * as yup from 'yup';

const fullNameRegEx = /^([a-zA-ZÀ-ÖØ-öø-ÿ]|\d){2,}\s([a-zA-ZÀ-ÖØ-öø-ÿ]|\d){2,}$/;
const monthAndYearRegEx = /(0[1-9]|1[012])\/[0-9]{2}/;
export const schema = yup
  .object({
    fullName: yup.string().matches(fullNameRegEx, 'Full Name is incorrect').required('Field is required'),
    birthDate: yup.string().test('birthDate', 'Date is incorrect', (value) => {
      if (value) {
        const schema = yup.string().matches(monthAndYearRegEx);
        return schema.isValidSync(value);
      }
      return true;
    }),
    birthYear: yup
      .number()
      .transform((value) => (value ? parseInt(value, 10) : undefined))
      .min(1940, 'Must be in yyyy format')
      .max(new Date().getFullYear() - 14, 'Must be at least 14 years old to create an account')
      .required('Field is required'),
    gender: yup.string().required('Field is required'),
  })
  .required();

export type NameAgeFormData = yup.InferType<typeof schema>;
