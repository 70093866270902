import { CampaignType, FilterInput, Filter_Type } from '@/graphql/generated';

export const clientToServerCategory = (category: string) => {
  switch (category) {
    case `Today's Deals`:
      return 'Today Deals';
    case `Latest Products`:
      return 'Latest Deals';
    case `Miscellaneous`:
      return 'Misc Deals';
  }
  return category;
};

export const serverToClientToCategory = (category: string) => {
  switch (category) {
    case 'Today Deals':
      return `Today's Deals`;
    case 'Latest Deals':
      return `Latest Products`;
    case 'Misc Deals':
      return `Miscellaneous`;
  }
  return category;
};

export const getOrderedServerCategories = (categories: string[]): string[] => [
  'Today Deals',
  'Latest Deals',
  ...categories.filter((c) => ['Today Deals', 'Latest Deals', 'Misc Deals'].indexOf(c) < 0),
  'Misc Deals',
];

export const productListingsAndColumn6AdsFilter = {
  name: 'type',
  operator: Filter_Type.In,
  value: [CampaignType.ProductListing, CampaignType.Column6Ad],
};

export const mapClientCategoryToFilter = (category: string): FilterInput => {
  if (category === `Today's Deals`) {
    return { name: 'isTodayDeal', operator: Filter_Type.Eq, value: ['true'] };
  }
  if (category === `Latest Products`) {
    return { name: 'isLatest', operator: Filter_Type.Eq, value: ['true'] };
  }
  if (category === `Miscellaneous`) {
    return { name: 'isMisc', operator: Filter_Type.Eq, value: ['true'] };
  }
  return { name: 'category', operator: Filter_Type.Eq, value: [category] };
};
