type Props = {
    className?: string;
    onClick?: () => void;
};

export function Calendar(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                clipRule="evenodd"
                d="M9 2C9 1.44772 8.55228 1 8 1C7.44772 1 7 1.44772 7 2V4H6C3.79086 4 2 5.79086 2 8V18C2 20.2091 3.79086 22 6 22H18C20.2091 22 22 20.2091 22 18V8C22 5.79086 20.2091 4 18 4H17V2C17 1.44772 16.5523 1 16 1C15.4477 1 15 1.44772 15 2V4H9V2ZM4 10C4 9.44772 4.44772 9 5 9H19C19.5523 9 20 9.44772 20 10C20 10.5523 19.5523 11 19 11H5C4.44772 11 4 10.5523 4 10Z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </svg>
    );
}
