import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { useMyWalletQuery, useStripeCheckoutMutation } from '@/graphql/generated';
import { useMe } from '@/hooks/useMe';
import { appRoutes } from '@/helpers/routes.helper';
import { Modal } from './Modal';
import { Button } from './Button';
import { useStoreInfo } from '@/hooks/useStoreInfo';
import { ProductOffer } from '@/types/OfferTypes';
import { PaymentWithScutisFormProgress } from '@/components/Form/PaymentWithScutisForm/PaymentWithScutisFormProgress';
import { getErrorMessage } from '@/utils/error.utils';
import { toast } from 'react-toastify';

type Props = {
  product: ProductOffer;
  quantity: number;
  onClose: () => void;
  onConfirm?: () => void;
};
export const InstantCheckoutModal = ({ product, quantity, onClose, onConfirm }: Props) => {
  const [instantCheckoutInProgress, setInstantCheckoutInProgress] = useState(false);

  const navigate = useNavigate();
  const { data } = useMyWalletQuery(
    {},
    { refetchOnWindowFocus: 'always', refetchOnMount: 'always', staleTime: 0, cacheTime: 0 },
  );
  const { game, platform } = useStoreInfo();
  const stripeCheckoutMutation = useStripeCheckoutMutation({
    onError: (error) => {
      toast.error(getErrorMessage(error), { autoClose: 8000 });
      setInstantCheckoutInProgress(false);
    },
  });

  const me = useMe();

  const { shippingAddress } = { shippingAddress: me?.shippingInfo };

  const scutiBalance = (data?.myWallet.promotional || 0) + (data?.myWallet.purchase || 0);
  const exchangeRate = platform.scutiExchangeRate || 1;
  const scutisNeeded = Math.floor(quantity * product.price.current * exchangeRate);

  const canPurchase = scutiBalance > scutisNeeded;

  const confirmInstantCheckout = useCallback(
    async (scutisNeeded: number) => {
      const { phone, ...shippingInfo } = shippingAddress!;
      setInstantCheckoutInProgress(true);
      const { stripeCheckout } = await stripeCheckoutMutation.mutateAsync({
        input: {
          gameId: game.id,
          items: [
            {
              offerId: product.id,
              quantity,
              variantId: product.productId,
            },
          ],
          shippingInfo: {
            ...shippingInfo,
            address2: shippingInfo?.address2 || '',
          },
          scutisToApply: scutisNeeded,
          promoCodes: [],
        },
      });

      if (stripeCheckout?.paidInFullWithScutis) {
        navigate(`${appRoutes.paymentComplete}`);
      }
    },
    [
      setInstantCheckoutInProgress,
      navigate,
      game.id,
      product.id,
      quantity,
      stripeCheckoutMutation,
      shippingAddress,
      product.productId,
    ],
  );

  const handleConfirm = (amount: number) => {
    if (onConfirm) {
      onConfirm();
    } else {
      confirmInstantCheckout(amount);
    }
  };

  return (
    <>
      {instantCheckoutInProgress && <PaymentWithScutisFormProgress />}
      {!instantCheckoutInProgress && (
        <Modal className="max-w-screen-md bg-dark-gray/90 p-10">
          <div className="text-center">
            {canPurchase ? (
              <div>
                <h1 className="text-lg">Confirm Purchase</h1>
                <p className="text-center">Please confirm your purchase of</p>
                <img alt="" src={product.images[0]} className="mx-auto w-32 self-center" />
                <p>Using {scutisNeeded} Scuti$</p>

                <div className="mt-9 flex justify-around">
                  <Button variant="primary" className="w-48" onClick={() => handleConfirm(scutisNeeded)}>
                    Confirm
                  </Button>
                  <Button variant="warning" className="w-48" onClick={onClose}>
                    Cancel
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                <h1>Insufficient funds</h1>
                <p>You currently do not have enough Scuti$ to complete your purchase. </p>
                <div className="mt-9 flex justify-around">
                  <Button variant="warning" className="w-full" onClick={onClose}>
                    Cancel
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};
