import { ReactNode } from 'react';
import { Back } from './Back';
import clsx from 'clsx';

type SettingsLayoutProps = {
  leftClassName?: string;
  left?: ReactNode;
  rightClassName?: string;
  right?: ReactNode;
};

export function SettingsLayout({ leftClassName, rightClassName, left, right }: SettingsLayoutProps) {
  return (
    <div className="flex h-screen w-screen flex-col overflow-hidden bg-dark-gray">
      <div className="border-b-solid flex h-12 shrink-0 items-center border-b border-b-dark-gray bg-darker-gray px-4 md:h-14 md:py-4">
        <Back />
      </div>
      <div className="flex h-full w-full flex-1 flex-row overflow-hidden">
        <div
          className={`relative w-1/3 min-w-fit shrink-0 flex-col overflow-y-auto bg-transparent md:flex md:bg-darker-gray ${leftClassName}`}
        >
          <img alt="" className="absolute left-0 top-0 hidden w-full md:block" src="/backgrounds/WaveSmall.png" />
          {left}
        </div>
        <div className={`mt-8 h-full w-full overflow-y-auto px-4 md:w-2/3 lg:px-16 ${clsx(rightClassName)}`}>
          {right}
        </div>
      </div>
    </div>
  );
}
