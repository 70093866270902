import { formatPrice, getLowerDecimal, getUpperDecimal } from '@/utils/number.utils';

export type PriceProps = {
  className?: string;
  price: number;
  oldPrice?: number;
};

export function Price({ className, price, oldPrice }: PriceProps) {
  return (
    <div className={`flex flex-row items-center space-x-4 md:col-span-3 ${className}`}>
      <div className="flex">
        <h1 className="font-bold">${getUpperDecimal(price)}</h1>
        <span className="ml-1">{getLowerDecimal(price)}</span>
      </div>

      {!!oldPrice && (
        <label className="inline-flex items-center">
          <div>
            Was <span className="line-through">${formatPrice(oldPrice)}</span>
          </div>
        </label>
      )}
    </div>
  );
}
