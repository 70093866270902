type Props = {
    className?: string;
    onClick?: () => void;
};

export function Fire(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                clipRule="evenodd"
                d="M19.1414 10.595C18.3717 13.0083 16.5162 14.9133 14.1633 15.7042C15.7343 14.2417 16.7267 12.1375 16.7267 9.79C16.7267 9.08 16.6275 8.39417 16.4577 7.73917C15.9554 5.79583 14.7509 4.15917 13.149 3.095C12.3972 2.595 11.5666 2.21 10.6644 2C10.8603 2.52083 10.9708 3.08417 10.9708 3.675C10.9708 6.44083 8.56024 7.39 6.99083 9.18833C6.14964 10.1517 5.55146 11.3283 5.27107 12.5892C5.14672 13.1425 5.0752 13.7167 5.0752 14.3092C5.0752 15.2542 5.24912 16.155 5.55309 16.9908C5.77578 17.6017 6.07406 18.1725 6.43085 18.6992C7.78488 20.6908 10.0272 22 12.5752 22C16.7178 22 20.0752 18.5567 20.0752 14.3092C20.0752 12.9617 19.7347 11.6967 19.1414 10.595Z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </svg>
    );
}
