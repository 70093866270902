type Props = {
    className?: string;
    onClick?: () => void;
};

export function Edit(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.3572 5.64195L18.3578 10.6428L7.49911 21.502L3.04063 21.9942C2.44377 22.0602 1.93949 21.5555 2.00589 20.9586L2.50197 16.4968L13.3572 5.64195ZM21.4507 4.89741L19.1027 2.54933C18.3703 1.81689 17.1825 1.81689 16.45 2.54933L14.2411 4.75835L19.2418 9.75921L21.4507 7.55019C22.1831 6.81736 22.1831 5.62985 21.4507 4.89741Z"
                fill="currentColor"
            />
        </svg>
    );
}
