type Props = {
    className?: string;
    onClick?: () => void;
};

export function ChevronLeft(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.3289 6.72345L8.16226 11.6853C8.56763 12.1017 9.22849 12.1051 9.63782 11.6941C10.0478 11.2824 10.0511 10.6112 9.64574 10.1954L5.54191 5.98185L9.69064 1.8132C10.1 1.4015 10.1039 0.730301 9.6979 0.314575C9.2932 -0.101821 8.63233 -0.105173 8.223 0.30653L3.34276 5.21008C3.23713 5.31535 3.15923 5.43873 3.10773 5.57015C2.95126 5.95302 3.02388 6.41032 3.3289 6.72345Z"
                fill="currentColor"
            />
        </svg>
    );
}
