import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/Common/Button';
import { Icon } from '@/components/Common/Icon';
import { appRoutes } from '@/helpers/routes.helper';

export function EmptyOrders() {
  const navigate = useNavigate();

  return (
    <div className="flex grow flex-col">
      <div className="flex flex-1 items-center justify-center">
        <div className="flex flex-col items-center">
          <Icon name="CartBuyNow" className="h-16 text-black" />
          <h1 className="mb-4 mt-9 font-bold">Your Don’t Have Pending Orders</h1>
          {/* <p className="text-sm">Looks like you haven’t added any products to your Cart</p> */}
          <Button className="mt-8" onClick={() => navigate(appRoutes.store)}>
            Continue Shopping
          </Button>
        </div>
      </div>
    </div>
  );
}
