import { useRef } from 'react';
import { Listbox } from '@headlessui/react';
import { Icon } from './Icon';
import { Input } from './Input';
import { SelectItem } from '@/utils/select-options.utils';

export type SelectInputProps = {
  className?: string;
  required?: boolean;
  disabled?: boolean;
  label: string;
  placeholder: string;
  value?: SelectItem | null;
  options: SelectItem[];
  error?: string;
  tooltip?: string;
  onChange: (value: SelectItem['value']) => void;
};

export function SelectInput(props: SelectInputProps) {
  const { className, options, value, label, placeholder, required = false, disabled, error, tooltip, onChange } = props;

  const dropdownRef = useRef<HTMLUListElement>(null);

  const handleClose = () => {
    dropdownRef?.current?.dispatchEvent(
      new KeyboardEvent('keydown', {
        key: 'Escape',
        bubbles: true,
        cancelable: true,
      }),
    );
  };

  return (
    <Listbox value={value}>
      {({ open }) => {
        return (
          <div className={`md:relative ${className}`}>
            <Listbox.Button as="div" className="relative">
              <Input
                tooltip={tooltip}
                required={required}
                label={label}
                placeholder={placeholder}
                value={value?.label}
                disabled={disabled}
                error={error}
                onChange={() => ({})}
              />
              {<Icon name={open ? 'ChevronUp' : 'ChevronDown'} className="absolute right-0 top-8 h-3 w-3" />}
            </Listbox.Button>
            <Listbox.Options
              ref={dropdownRef}
              className="fixed bottom-0 left-0 right-0 top-auto z-30 max-h-80 w-full overflow-y-auto rounded-t-xl bg-darker-gray md:absolute md:bottom-auto md:left-0 md:right-0 md:top-[60px] md:mt-[1px] md:w-auto md:rounded-xl md:border md:border-dark-blue md:pt-0"
            >
              <div className="flex w-full justify-end p-4 md:hidden" onClick={handleClose}>
                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-dark-gray">
                  <Icon name="Cross" className="h-4" />
                </div>
              </div>
              {options.map((item) => (
                <Listbox.Option
                  key={item.value}
                  value={item.value}
                  className="border-b-black p-4 text-center font-bold first:rounded-t-xl last:rounded-b-xl last:border-0 hover:bg-blue md:border-b"
                  onClick={() => onChange(item.value)}
                >
                  {item.label}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </div>
        );
      }}
    </Listbox>
  );
}
