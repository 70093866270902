import { ReactElement } from 'react';

export type BlurredProps = {
  children?: ReactElement | ReactElement[];
};

export function Blurred({ children }: BlurredProps) {
  return (
    <div className="fixed bottom-0 left-0 right-0 top-0 z-10 flex h-full w-screen flex-col items-center justify-center overscroll-none px-4 pb-4 backdrop-blur-3xl md:px-8 md:pb-8">
      {children}
    </div>
  );
}
