import useAnimation from '@/hooks/useAnimation';
import { ProgressBar } from '@/components/Common/ProgressBar';
import { Icon } from '@/components/Common/Icon';

export function PaymentTransactionProcess() {
  const loading = useAnimation('linear', 2000);

  return (
    <div className="fixed bottom-0 left-0 right-0 top-0 z-40 flex flex-col items-center justify-center">
      <div className="bg-secondary-500 z-50 flex h-full w-full flex-col items-center justify-center px-4">
        <div className="flex flex-col items-center justify-between text-center">
          <Icon name="GCommerce" className="h-24 w-24" />

          <div className="bg-gray-600 h-1 w-full">
            <ProgressBar className="w-full max-w-lg" value={loading * 100} />
          </div>

          <h1 className="my-4 font-semibold text-white">Transaction in progress...</h1>

          <span className="mb-1 text-base font-medium text-gray">
            Your transaction is in process, this action might take a few seconds...
          </span>
        </div>
      </div>
    </div>
  );
}
