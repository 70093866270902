import { OrderAddressProps } from '@/components/Common/OrderAddress';
import { OrderTrackingProps } from '@/components/Common/OrderTracking';
import { OrderDetailsQuery, useOrderDetailsQuery } from '@/graphql/generated';
import { useMe } from '@/hooks/useMe';
import { useStoreInfo } from '@/hooks/useStoreInfo';
import { OrderItem } from '@/types/OrderTypes';
import { formatPrice } from '@/utils/number.utils';
import { mapOrderDetailsToOrderItems } from '@/utils/order.utils';
import { useMemo } from 'react';
import { useLoaderData, useParams } from 'react-router-dom';

type Return = {
  orderTracking?: Omit<OrderTrackingProps, 'className'>;
  orderAddress?: OrderAddressProps;
  orderItems?: OrderItem[];
};

export function useOrderDetails(): Return {
  const initialData = useLoaderData() as OrderDetailsQuery;
  const id = useParams<{ id: string }>().id!;

  const { platform } = useStoreInfo();
  const me = useMe();
  const { data } = useOrderDetailsQuery({ id }, { initialData });

  return useMemo(() => {
    if (!data || !me) return {};
    const { myOrder } = data;
    const { scutisExclusive } = myOrder.items[0].product;

    const orderTotal = scutisExclusive
      ? `${myOrder.amount * platform.scutiExchangeRate} Scuti$`
      : formatPrice(myOrder.amount);

    return {
      orderTracking: {
        trackingNumber: myOrder.id,
        orderTotal,
      },
      orderAddress: { fullName: me.fullName || '', shippingInfo: myOrder.shippingInformation! },
      orderItems: mapOrderDetailsToOrderItems(myOrder),
    };
  }, [data, me, platform.scutiExchangeRate]);
}
