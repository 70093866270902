export const DEFAULT_COUNTRY = 'United States';

export type SelectItem = {
  label: string;
  value?: string;
};

export const allCountryList = async () => {
  let { Country } = await import('country-state-city');
  return Country.getAllCountries().map<SelectItem>((item) => {
    return {
      label: item.name,
      value: item.isoCode,
    };
  });
};

export const allStatesWithCountry = async (country: string) => {
  const { Country, State } = await import('country-state-city');
  const countryObj = Country.getAllCountries().find((item) => item.isoCode === country);
  const countryCode = countryObj ? countryObj.isoCode : 'US';
  return State.getStatesOfCountry(countryCode).map<SelectItem>((item) => {
    return {
      label: item.name,
      value: item.isoCode,
    };
  });
};

export const getOptionByValue = (value: SelectItem['value'], options: SelectItem[]): SelectItem | undefined => {
  return options.find((o) => o.value === value);
};

export const genderOptions = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  { label: 'Non binary', value: 'non-binary' },
  { label: 'Prefer not to say', value: 'prefer-not-to-say' },
];
