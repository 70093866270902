import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  RouteObject,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

export const initSentry = () => {
  const env = getEnv();

  Sentry.init({
    dsn: 'https://cb0c2a372ba84b53b80da4e9aed55615@o920227.ingest.sentry.io/4505559559438336',
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    tracesSampleRate: 1.0,
    environment: env,
    enabled: env === 'production',
  });
};

export const createSentryWrappedRouter = (routing: RouteObject[]) => {
  const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
  return sentryCreateBrowserRouter(routing);
};

export const getEnv = () => {
  try {
    const url = new URL(process.env.REACT_APP_URL as string);
    switch (url.host) {
      case 'staging.run.app.scuti.store':
        return 'staging';
      case 'store.scutishopping.com':
        return 'production';
      case 'dev.run.app.scuti.store':
        return 'dev';
      default:
        return 'local';
    }
  } catch {
    return 'local';
  }
};
