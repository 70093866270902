import { useEffect } from 'react';

import { sendMetric } from './metics-sender';

export const useEngagementProductMetric = (offerId: string) => {
  useEffect(() => {
    const start = new Date();

    function onUnload() {
      const end = new Date();
      const duration = end.getTime() - start.getTime();
      if (duration >= 1000) {
        sendMetric({ name: 'ENGAGEMENT_PRODUCT', customData: { duration, offerId } });
      }
    }

    return onUnload;
  }, [offerId]);
};
