type Props = {
    className?: string;
    onClick?: () => void;
};

export function Categories(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.0688 5.52046C11.5231 5.52046 11.9072 5.79951 12.0476 6.23143C12.188 6.66335 12.0412 7.11508 11.6738 7.38207L10.2058 8.44873C10.1636 8.47931 10.1468 8.53228 10.1619 8.58137L10.5241 9.69626L10.5688 9.83536L10.7234 10.3095C10.8634 10.7414 10.717 11.1931 10.3497 11.4601C10.1658 11.5936 9.95477 11.6604 9.74376 11.6604C9.53361 11.6604 9.32261 11.594 9.13916 11.4601L8.07852 10.6902C7.93555 10.5859 7.76804 10.5343 7.60009 10.5338C7.43215 10.5343 7.26464 10.5859 7.12167 10.6902L6.06146 11.4601C5.87758 11.594 5.66657 11.6604 5.45557 11.6604C5.24499 11.6604 5.03484 11.5936 4.85054 11.4601C4.48364 11.1931 4.3368 10.7414 4.47718 10.3095L4.63091 9.83536L4.67656 9.69626L5.03872 8.58137C5.05379 8.53228 5.03657 8.47931 4.9948 8.44873L3.52678 7.38207C3.15903 7.11508 3.01218 6.66335 3.15257 6.23143C3.29295 5.79951 3.6775 5.52046 4.13139 5.52046H5.89825C5.97835 5.52046 6.04897 5.46879 6.07438 5.39257L6.29701 4.70916L6.62085 3.71097C6.76166 3.27905 7.14535 3 7.59966 3C8.05398 3 8.43853 3.27905 8.57891 3.71097L9.12581 5.39257C9.15079 5.46879 9.22184 5.52046 9.30237 5.52046H11.0688Z"
                fill="currentColor"
            />
            <path
                d="M21.0688 15.5205C21.5231 15.5205 21.9072 15.7995 22.0476 16.2314C22.188 16.6634 22.0412 17.1151 21.6738 17.3821L20.2058 18.4487C20.1636 18.4793 20.1468 18.5323 20.1619 18.5814L20.5241 19.6963L20.5688 19.8354L20.7234 20.3095C20.8634 20.7414 20.717 21.1931 20.3497 21.4601C20.1658 21.5936 19.9548 21.6604 19.7438 21.6604C19.5336 21.6604 19.3226 21.594 19.1392 21.4601L18.0785 20.6902C17.9356 20.5859 17.768 20.5343 17.6001 20.5338C17.4321 20.5343 17.2646 20.5859 17.1217 20.6902L16.0615 21.4601C15.8776 21.594 15.6666 21.6604 15.4556 21.6604C15.245 21.6604 15.0348 21.5936 14.8505 21.4601C14.4836 21.1931 14.3368 20.7414 14.4772 20.3095L14.6309 19.8354L14.6766 19.6963L15.0387 18.5814C15.0538 18.5323 15.0366 18.4793 14.9948 18.4487L13.5268 17.3821C13.159 17.1151 13.0122 16.6634 13.1526 16.2314C13.293 15.7995 13.6775 15.5205 14.1314 15.5205H15.8983C15.9783 15.5205 16.049 15.4688 16.0744 15.3926L16.297 14.7092L16.6208 13.711C16.7617 13.279 17.1454 13 17.5997 13C18.054 13 18.4385 13.279 18.5789 13.711L19.1258 15.3926C19.1508 15.4688 19.2218 15.5205 19.3024 15.5205H21.0688Z"
                fill="currentColor"
            />
            <path
                d="M3.1001 15C3.1001 13.8954 3.99553 13 5.1001 13H10.1001C11.2047 13 12.1001 13.8954 12.1001 15V20C12.1001 21.1046 11.2047 22 10.1001 22H5.1001C3.99553 22 3.1001 21.1046 3.1001 20V15Z"
                fill="currentColor"
            />
            <path
                d="M15.1001 3C13.9955 3 13.1001 3.89543 13.1001 5V10C13.1001 11.1046 13.9955 12 15.1001 12H20.1001C21.2047 12 22.1001 11.1046 22.1001 10V5C22.1001 3.89543 21.2047 3 20.1001 3H15.1001Z"
                fill="currentColor"
            />
        </svg>
    );
}
