import { NavLink } from 'react-router-dom';
import { Welcome } from '@/components/Common/Welcome';
import { LoginForm } from '@/components/Form/LoginForm';
import { WelcomeLayout } from '@/components/Layout/WelcomeLayout';
import { appRoutes } from '@/helpers/routes.helper';
import { Icon } from '@/components/Common/Icon';

export function LoginPage() {
  return (
    <WelcomeLayout
      split
      left={
        <div
          className={`relative flex min-h-full w-full items-center justify-center landscape:items-start md:landscape:items-center`}
        >
          <div className="z-10 flex w-full flex-col justify-center rounded-2xl bg-transparent p-4 md:w-[60%] md:bg-dark-gray/80 md:p-16 md:backdrop-blur-sm">
            <div className="flex flex-col items-center md:items-start">
              <Icon name="ScutiLogo" className="dark-blue my-4 block h-[120px] p-6 xl:hidden xl:animate-none" />
              <h1 className="font-bold">Welcome to Scuti</h1>
              <p className="mt-4 text-base">Log In to the Shopping Experience</p>
            </div>
            <LoginForm className="mt-14" />
            <div className="mt-8 flex items-center justify-center">
              <p>Don’t have an account?</p>
              &nbsp;
              <NavLink to={appRoutes.registration}>Sign Up</NavLink>
            </div>
            <NavLink className="mt-4 text-center" to={appRoutes.store}>
              Continue to the Store
            </NavLink>
          </div>
        </div>
      }
      right={
        <div className="flex h-full w-full flex-col items-center justify-center backdrop-brightness-35">
          <Welcome />
        </div>
      }
    />
  );
}
