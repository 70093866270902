type Props = {
    className?: string;
    onClick?: () => void;
};

export function NotVisible(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.3249 13.7955C13.8431 14.2773 13.1897 14.5479 12.5083 14.5479C12.4329 14.5479 12.3586 14.5445 12.2849 14.5381L15.0674 11.7556C15.0739 11.8292 15.0773 11.9038 15.0773 11.979C15.0773 12.6603 14.8066 13.3137 14.3249 13.7955Z"
                fill="currentColor"
            />
            <path
                clipRule="evenodd"
                d="M20.0317 3.0537L21.0116 4.03266C21.0286 4.0497 21.0422 4.06994 21.0514 4.09221C21.0606 4.11449 21.0654 4.13837 21.0654 4.16248C21.0654 4.1866 21.0606 4.21047 21.0514 4.23275C21.0422 4.25503 21.0286 4.27526 21.0116 4.29231L4.6767 20.6263C4.64229 20.6607 4.59563 20.68 4.54699 20.68C4.49834 20.68 4.45169 20.6607 4.41728 20.6263L3.43809 19.6471C3.40371 19.6127 3.38439 19.566 3.38439 19.5174C3.38439 19.4687 3.40371 19.4221 3.43809 19.3877L5.86461 16.9621C4.64359 15.8608 3.59957 14.397 2.73255 12.5708C2.64533 12.3861 2.6001 12.1844 2.6001 11.9801C2.6001 11.7759 2.64533 11.5742 2.73255 11.3895C4.9024 6.79749 8.19158 4.50149 12.6001 4.50149C14.3266 4.50149 15.8815 4.85495 17.2648 5.56187L19.7723 3.0537C19.8067 3.01932 19.8533 3 19.902 3C19.9506 3 19.9973 3.01932 20.0317 3.0537ZM8.99545 9.98955C8.65189 10.5963 8.47135 11.2817 8.47141 11.979C8.47043 12.6422 8.63371 13.2953 8.94667 13.88L10.0619 12.7648C9.91786 12.3151 9.90051 11.8343 10.0117 11.3754C10.123 10.9164 10.3585 10.497 10.6924 10.163C11.0263 9.82911 11.4458 9.59361 11.9047 9.48239C12.3637 9.37116 12.8444 9.3885 13.2942 9.53251L14.4094 8.41731C13.7942 8.08896 13.1046 7.92553 12.4075 7.94295C11.7104 7.96036 11.0298 8.15803 10.4318 8.5167C9.83387 8.87536 9.33901 9.38279 8.99545 9.98955Z"
                fill="currentColor"
                fillRule="evenodd"
            />
            <path
                d="M19.5847 7.22757C20.6992 8.30026 21.6602 9.6868 22.4676 11.3872C22.5549 11.5719 22.6001 11.7736 22.6001 11.9778C22.6001 12.1821 22.5549 12.3838 22.4676 12.5685C20.2978 17.1605 17.0086 19.4565 12.6001 19.4565C11.007 19.4565 9.56 19.1555 8.25901 18.5534L11.0633 15.7492C11.7918 16.0278 12.5854 16.0896 13.3483 15.927C14.1112 15.7644 14.8106 15.3844 15.3622 14.8328C15.9137 14.2813 16.2938 13.5818 16.4564 12.819C16.619 12.0561 16.5572 11.2625 16.2785 10.5339L19.5847 7.22757Z"
                fill="currentColor"
            />
        </svg>
    );
}
