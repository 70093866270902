type Props = {
    className?: string;
    onClick?: () => void;
};

export function Coin(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                clipRule="evenodd"
                d="M12.7 22C18.2228 22 22.7 17.5228 22.7 12C22.7 6.47715 18.2228 2 12.7 2C7.1771 2 2.69995 6.47715 2.69995 12C2.69995 17.5228 7.1771 22 12.7 22ZM12.7 6L6.69995 12L12.7 18L18.7 12L12.7 6Z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </svg>
    );
}
