import 'rc-slider/assets/index.css';

import { useCallback } from 'react';
import RCSlider from 'rc-slider';
import { Icon } from './Icon';

type Props = {
  className?: string;
  label?: string;
  value?: number;
  max?: number;
  step?: number;
  colors?: {
    handler?: string;
    rail?: string;
    track?: string;
  };
  disabled?: boolean;
  autoFocus?: boolean;
  onChange: (value: number | number[]) => void;
  withButtons?: boolean;
};

export function Slider({
  className = '',
  label,
  value = 0,
  max = 0,
  step = 1,
  autoFocus,
  onChange,
  colors,
  disabled,
  withButtons = false,
}: Props) {
  const handleChange = useCallback(
    (e: number | number[]) => {
      const sliderValue = Array.isArray(e) ? e[0] : e;
      onChange(sliderValue);
    },
    [onChange],
  );

  const increase = useCallback(() => {
    const v = +value + step;
    handleChange(Math.min(v, max));
  }, [value, step, max, handleChange]);

  const decrease = useCallback(() => {
    const v = value - step;
    handleChange(Math.max(v, 0));
  }, [value, step, handleChange]);

  return (
    <div className={`flex w-full flex-row items-center space-x-5 ${className}`}>
      {!withButtons && label && <label className="text-paragraph-sm cursor-pointer">{label}</label>}
      {withButtons && (
        <div className="flex justify-start">
          <button
            className="duration-400 h-8 w-8 rounded-full border-0 bg-blue p-2 transition-colors ease-in-out hover:bg-dark-blue disabled:bg-gray"
            onClick={decrease}
            disabled={value === 0}
          >
            <Icon name="Minus" />
          </button>
        </div>
      )}
      <div className="w-full">
        <RCSlider
          value={value}
          onChange={handleChange}
          disabled={disabled}
          max={max}
          step={step}
          autoFocus={autoFocus}
          handleStyle={{
            backgroundColor: colors?.handler || '#1D35EE',
            opacity: 1,
            border: 0,
            boxShadow: '0px 3px 0px #101015',
            height: '20px',
            width: '20px',
            marginTop: '-9px',
          }}
          railStyle={{
            background: colors?.rail || 'linear-gradient(180deg, #101015 -50%, #AEAEAE 220%)',
            boxShadow: '0px -0.5px 0px #101015',
            height: '5px',
          }}
          trackStyle={{
            background: colors?.track || 'linear-gradient(180deg, #101015 -50%, #AEAEAE 220%)',
            boxShadow: '0px -0.5px 0px #101015',
            height: '5px',
          }}
        />
      </div>
      {withButtons && (
        <div className="flex justify-end">
          <button
            className="duration-400 h-8 w-8 rounded-full border-0 bg-blue p-2 transition-colors ease-in-out hover:bg-dark-blue disabled:bg-gray"
            onClick={increase}
            disabled={value === max}
          >
            <Icon name="Plus" />
          </button>
        </div>
      )}
    </div>
  );
}
