import { waitFor } from '@/utils/wait-for.utils';
import { appSessionStorage } from '../storage.helper';

export async function waitForUnity() {
  await waitFor(() => {
    try {
      if (window.Unity) return true;
    } catch {
      console.error('Unity is not defined');
    }
    return false;
  }, 20000);
}

export async function waitForVuplex() {
  await waitFor(() => {
    try {
      if (window.vuplex) return true;
    } catch {
      console.error('Vuplex is not defined');
    }
    return false;
  }, 20000);
}

export const getVuplexPaymentLink = (key: string) => {
  const gameId = appSessionStorage.getItem('gameId');
  const sessionId = appSessionStorage.getItem('sessionId');
  return `${process.env.REACT_APP_URL}?stripeKey=${key}&gameId=${gameId}&sessionId=${sessionId}`;
};
