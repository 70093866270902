import { UserCartItem } from '@/types/CartTypes';
import { formatScore, getLowerDecimal, getUpperDecimal } from '@/utils/number.utils';
import { Icon } from './Icon';
import { QuantitySelector } from './QuantitySelector';
import { cartStore } from '@/store/cart.store';
import { useCallback } from 'react';
import { useCartItemsMetrics } from '@/helpers/metrics/useCartMetric';
import { ProductOfferOptionKey } from '@/types/OfferTypes';
import { Button } from './Button';
import { Alert } from './Alert';

type CartItemProps = {
  className?: string;
  cartItem: UserCartItem;
  viewOnly?: boolean;
};

export function CartItem({ className, cartItem, viewOnly }: CartItemProps) {
  const { add, remove, clear } = cartStore.actions;
  const { product, quantity } = cartItem;
  const { variant } = product;
  const image = product.variant.image || (product.images ? product.images[0] : '');

  const { changeCartMetric, removeFromCartMetric } = useCartItemsMetrics();

  const handleChange = useCallback(
    (value: number) => {
      value > quantity ? add(product) : remove(product);
      changeCartMetric({ product, quantity: value });
    },
    [add, changeCartMetric, product, quantity, remove],
  );

  const handleRemove = useCallback(() => {
    clear(product);
    removeFromCartMetric(cartItem);
  }, [cartItem, clear, product, removeFromCartMetric]);

  const { option1, option2, option3 } = product;

  const outOfStock = product.variant.inStock < quantity;

  return (
    <div className={`flex flex-row space-x-6 bg-darker-gray px-4 py-8 md:rounded-2xl md:py-4 ${className}`}>
      <div className="h-28 w-28 shrink-0">
        {image && <img alt="" className="h-28 w-28 rounded-lg bg-white object-contain" src={image} />}
      </div>
      <div className="flex w-full flex-col justify-between">
        <div>
          <div className="flex w-full justify-between">
            <p className="text-white">{product.productName}</p>
            {!viewOnly && (
              <Icon name="Trash" className="ml-8 h-4 w-4 shrink-0 cursor-pointer text-white" onClick={handleRemove} />
            )}
          </div>
          <div className="mt-2 font-semibold">
            ${getUpperDecimal(variant.price)} <sup>{getLowerDecimal(variant.price)}</sup>
          </div>
        </div>
        <div className="mt-4 flex flex-row flex-wrap md:mt-0 md:justify-between">
          <div className="flex w-full flex-row flex-wrap items-center space-x-3 md:w-auto">
            {Object.entries({ option1, option2, option3 }).map(([key, name]) => {
              return name ? (
                <p className="text-sm text-white" key={key}>
                  {name}: {variant[key as ProductOfferOptionKey]}
                </p>
              ) : null;
            })}
            {product.reward && (
              <div className="flex flex-row items-center space-x-1 text-sm">
                <img alt="" src="/images/ScutiCoin.png" className="h-5 w-5" />
                <span>+{formatScore(product.reward?.scutis || 0)}</span>
              </div>
            )}
          </div>
          {!viewOnly ? (
            <QuantitySelector className="mt-4 md:mt-0" value={quantity} max={variant.inStock} onChange={handleChange} />
          ) : (
            <Button
              className={`mt-4 flex items-center justify-center rounded-full bg-dark-gray px-6  py-2 text-white transition-all hover:bg-darker-gray disabled:border-gray  disabled:text-light-gray md:mt-0`}
            >
              {quantity}
            </Button>
          )}
        </div>
        {outOfStock && (
          <div className="mt-4 w-full basis-full">
            <Alert
              heading="Out of stock!"
              content="This product is now out of stock, please adjust the quantity or remove it in order to continue."
              variant="error"
            />
          </div>
        )}
      </div>
    </div>
  );
}
