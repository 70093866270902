import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PagingInput } from '@/graphql/generated';
import { appRoutes } from '@/helpers/routes.helper';
import { OfferListItem } from '@/types/OfferTypes';
import {
  productListingsAndColumn6AdsFilter,
  getOrderedServerCategories,
  mapClientCategoryToFilter,
  serverToClientToCategory,
} from '@/utils/category.utils';
import { mapOfferItem } from '@/utils/offer.utils';
import { hasNextPage, pagingDefaults } from '@/utils/paging.utils';
import { seed } from '@/utils/seed.utils';
import { useOffersByCategories } from '@/hooks/useOffersByCategories';
import { useOffers } from '@/hooks/useOffers';

type Return = {
  offers: OfferListItem[];
  isLoading: boolean;
  hasNext: boolean;
  onLoadNext: () => void;
  onLeft: () => void;
  onRight: () => void;
};

export function useCategory(category: string): Return {
  const [paging, setPaging] = useState<PagingInput>(pagingDefaults);
  const [hasNext, setHasNext] = useState(false);

  const [loadedCategory, setLoadedCategory] = useState<string>(category);
  const [offers, setOffers] = useState<OfferListItem[]>([]);

  const { data, isLoading } = useOffers({
    seed,
    paging,
    filters: [productListingsAndColumn6AdsFilter, mapClientCategoryToFilter(loadedCategory)],
  });

  useEffect(() => {
    setOffers([]);
    setPaging(pagingDefaults);
    setLoadedCategory(category);
  }, [category]);

  useEffect(() => {
    if (!data) return;
    setHasNext(hasNextPage(data.paging));
    setOffers((prev) => [...prev, ...data.nodes.map((offer) => mapOfferItem(offer))]);
  }, [data]);

  const offersByCategories = useOffersByCategories();

  const allOrderedCategories = useMemo(
    () =>
      getOrderedServerCategories(offersByCategories.data?.nodes.map(({ category }) => category) || []).map(
        serverToClientToCategory,
      ),
    [offersByCategories.data],
  );

  const currentIndex = allOrderedCategories.findIndex((al) => al === category);
  const navigate = useNavigate();

  return useMemo(() => {
    return {
      isLoading,
      offers,
      hasNext,
      onLoadNext: () =>
        setPaging((prev) => ({
          ...prev,
          offset: (prev.offset ?? pagingDefaults.offset) + (prev.limit ?? pagingDefaults.limit),
        })),
      onLeft: () => {
        const nextLeft = currentIndex - 1 >= 0 ? currentIndex - 1 : allOrderedCategories.length - 1;
        const nextLeftCategory = allOrderedCategories[nextLeft];
        navigate(appRoutes.category(nextLeftCategory));
      },
      onRight: () => {
        const nextRight = currentIndex + 1 >= allOrderedCategories.length ? 0 : currentIndex + 1;
        const nextRightCategory = allOrderedCategories[nextRight];
        navigate(appRoutes.category(nextRightCategory));
      },
    };
  }, [allOrderedCategories, currentIndex, hasNext, isLoading, navigate, offers]);
}
