import { ShippingInfo } from '@/graphql/generated';
import { Button } from './Button';
import { Icon } from './Icon';
import { useMe } from '@/hooks/useMe';
import { appRoutes } from '@/helpers/routes.helper';

export type CheckoutAddressProps = {
  shippingInfo?: ShippingInfo | null;
};

export function CheckoutAddress({ shippingInfo }: CheckoutAddressProps) {
  const me = useMe()!;

  return (
    <div className="flex min-w-[300px] flex-col space-y-4 bg-darker-gray p-8 md:rounded-2xl">
      <div className="flex flex-row items-center justify-between">
        <p className="text-sm text-light-gray">Shipping Address</p>
        <Button
          href={appRoutes.checkoutAddress}
          className={`flex flex-row items-center space-x-2 rounded-full bg-dark-gray px-[10px] py-[6px] text-[9px] hover:bg-darker-gray`}
        >
          <Icon name="Edit" className="h-3" />
          <span>EDIT</span>
        </Button>
      </div>
      <hr className="border border-b-0 border-dark-gray" />
      {shippingInfo ? (
        <div>
          <p className="text-lg font-semibold text-white">{me.fullName}</p>
          <p className="mt-4">{shippingInfo.address1}</p>
          {shippingInfo.address2 && <p>{shippingInfo.address2}</p>}
          <p>
            {shippingInfo.city}, {shippingInfo.state}, {shippingInfo.zipCode}, {shippingInfo.country}
          </p>
        </div>
      ) : (
        <div className="py-4">Address is missing</div>
      )}
    </div>
  );
}
