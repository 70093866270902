import { fetcherRest } from '@/helpers/fetch.helper';
import { OfferListItemServerType } from '@/types/OfferTypes';
import { seed } from '@/utils/seed.utils';
import { useQuery } from '@tanstack/react-query';

export const fetchOffersByCategories = async (): Promise<{
  nodes: { category: string; nodes: OfferListItemServerType[] }[];
}> => fetcherRest({ url: `/offers/by-categories?seed=${seed}` });

export function useOffersByCategories() {
  return useQuery({ queryKey: ['offersByCategories'], queryFn: fetchOffersByCategories });
}
