type Props = {
    className?: string;
    onClick?: () => void;
};

export function GoldenCoin(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 157 157" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M78.5 157C121.854 157 157 121.854 157 78.5C157 35.1456 121.854 0 78.5 0C35.1456 0 0 35.1456 0 78.5C0 121.854 35.1456 157 78.5 157Z"
                fill="url(#paint0_linear_1751_3509)"
            />
            <path
                d="M78.4999 153.808C120.091 153.808 153.808 120.091 153.808 78.4999C153.808 36.9087 120.091 3.19228 78.4999 3.19228C36.9086 3.19228 3.19226 36.9087 3.19226 78.4999C3.19226 120.091 36.9086 153.808 78.4999 153.808Z"
                fill="url(#paint1_linear_1751_3509)"
            />
            <path
                clipRule="evenodd"
                d="M55.8935 58.9289C53.3124 61.5101 51.8623 65.011 51.8623 68.6613C51.8623 72.3117 53.3124 75.8125 55.8935 78.3937C58.4747 80.9749 61.9756 82.425 65.6259 82.425H69.0276V98.8053H41.1863L43.8553 90.6413L21.8752 74.9413L29.9346 50.2923H57.0433L65.4689 24.5443H69.0276V54.8976H65.6259C61.9756 54.8976 58.4747 56.3477 55.8935 58.9289ZM127.065 50.2923H99.9043L91.531 24.5443H87.9723V54.8976H128.583L127.065 50.2923ZM59.9853 74.5268C58.4361 72.9678 57.5666 70.8592 57.5667 68.6614C57.5667 66.4684 58.4378 64.3652 59.9885 62.8146C61.5392 61.2639 63.6423 60.3927 65.8353 60.3927H69.237V76.9824H65.8353C63.6375 76.9686 61.5345 76.0858 59.9853 74.5268ZM74.4702 82.425H82.4772V99.0147H74.4702V82.425ZM82.4772 60.3927H74.4702V76.9824H82.4772V60.3927ZM98.637 84.8806C100.186 86.4396 101.056 88.5482 101.056 90.746C101.056 91.8318 100.842 92.9071 100.426 93.9103C100.011 94.9135 99.4016 95.825 98.6338 96.5928C97.866 97.3606 96.9545 97.9697 95.9513 98.3853C94.9481 98.8008 93.8728 99.0147 92.787 99.0147H87.9723V82.425H92.787C94.9847 82.4388 97.0878 83.3216 98.637 84.8806ZM74.4702 104.457H82.4772V120.995H74.4702V104.457ZM130.362 60.3927H87.9723V76.9824H92.787C94.6623 76.8666 96.5415 77.136 98.3088 77.774C100.076 78.412 101.694 79.4051 103.063 80.6921C104.432 81.9791 105.523 83.5328 106.269 85.2574C107.014 86.982 107.399 88.841 107.399 90.7199C107.399 92.5988 107.014 94.4579 106.269 96.1825C105.523 97.9071 104.432 99.4607 103.063 100.748C101.694 102.035 100.076 103.028 98.3088 103.666C96.5415 104.304 94.6623 104.573 92.787 104.457H87.9723V122.826L100.428 131.828L121.361 116.546L112.988 90.7984L134.915 75.0984L130.362 60.3927ZM74.4702 24.5443H82.4772V54.8976H74.4702V24.5443ZM56.5201 131.828L69.0277 122.826V104.457H39.4071L35.4298 116.651L56.5201 131.828Z"
                fill="url(#paint2_linear_1751_3509)"
                fillRule="evenodd"
            />
            <defs>
                <linearGradient gradientUnits="userSpaceOnUse" id="paint0_linear_1751_3509" x1="33.1793" x2="155.43" y1="33.1793" y2="155.43">
                    <stop stopColor="white" />
                    <stop offset="0.3" stopColor="#FFCF40" />
                    <stop offset="0.55" stopColor="#450F00" />
                    <stop offset="0.82" stopColor="#FFCF40" />
                </linearGradient>
                <linearGradient gradientUnits="userSpaceOnUse" id="paint1_linear_1751_3509" x1="35.9533" x2="176.6" y1="18.9754" y2="217.851">
                    <stop stopColor="#BF7100" />
                    <stop offset="0.01" stopColor="#C97F0A" />
                    <stop offset="0.04" stopColor="#DC9C1D" />
                    <stop offset="0.07" stopColor="#ECB32D" />
                    <stop offset="0.1" stopColor="#F7C338" />
                    <stop offset="0.14" stopColor="#FDCC3E" />
                    <stop offset="0.18" stopColor="#FFCF40" />
                    <stop offset="0.41" stopColor="#FFCF40" />
                    <stop offset="0.59" stopColor="#BF7100" />
                    <stop offset="0.86" stopColor="#FFCF40" />
                </linearGradient>
                <linearGradient gradientUnits="userSpaceOnUse" id="paint2_linear_1751_3509" x1="45.4252" x2="45.4252" y1="231.47" y2="-93.6244">
                    <stop stopColor="#BF7100" />
                    <stop offset="0.52" stopColor="#450F00" />
                    <stop offset="1" stopColor="#FFCF40" />
                </linearGradient>
            </defs>
        </svg>
    );
}
