type Props = {
    className?: string;
    onClick?: () => void;
};

export function NewCoin(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                clipRule="evenodd"
                d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM15.6905 6.00181L15.6916 6H15.6895H8.57754L6.362 9.7414L11.8018 9.74166L11.2941 10.5988H5.85431L5 12.0411H12.1141L14.3301 8.29916H14.3287L8.89026 8.29942L9.39795 7.44224H14.8359H14.8373L15.6905 6.00181ZM15.9692 6.51238L12.399 12.5411H12.387L14.1644 15.797L16.8999 11.1755L17.3696 12.036L14.6344 16.6575L15.4245 18.1054L19 12.0646L16.9496 8.30809L14.22 12.9199L13.7502 12.0595L16.4799 7.44786L15.9692 6.51238ZM11.8173 12.5411H5.27689L5.34407 12.6624L6.26744 14.3484L6.26821 14.3494L6.95736 15.6081H6.95838L7.0639 15.8003H12.5129L12.9724 16.6537H7.52011L8.3237 18.1054H14.8548L12.804 14.3484H7.92636L7.45104 13.4928H12.3369L11.8173 12.5411Z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </svg>
    );
}
