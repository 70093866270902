import { ScutiTransactionItem as ScutiTransactionItemType } from '@/types/ScutiTransactionType';
import { Icon } from './Icon';
import { formatPrice } from '@/utils/number.utils';
import { formatDateLevel } from '@/utils/date.utils';

export type TransactionItemProps = {
  className?: string;
  scutiTransaction: ScutiTransactionItemType;
};

export function ScutiTransactionItem({ className, scutiTransaction }: TransactionItemProps) {
  const title = `${scutiTransaction.rewardType || ''} ${scutiTransaction.type}`;
  const value = scutiTransaction.promotionalBalanceChange + scutiTransaction.purchaseBalanceChange;
  const date = formatDateLevel(scutiTransaction.createdAt);

  return (
    <div className={`flex w-full flex-row items-center justify-between ${className}`}>
      <div className="flex flex-col space-y-5">
        <p className="text-sm text-white">{title}</p>
        <div className="flex flex-row items-center space-x-2">
          <Icon name="Calendar" className="h-3 w-3 text-white" />
          <p className="text-xs align-middle">{date}</p>
        </div>
      </div>
      <div>
        <p className={Math.sign(value) === 1 ? 'text-green' : 'text-red'}>
          {Math.sign(value) === 1 ? '+' : '-'} {formatPrice(Math.abs(value))}
        </p>
      </div>
    </div>
  );
}
