import { useEffect, useMemo, useState } from 'react';
import { matchPath, useLocation, useParams } from 'react-router-dom';
import { PagingInput, useMyOrdersQuery } from '@/graphql/generated';
import { appRoutes } from '@/helpers/routes.helper';
import { hasNextPage, pagingDefaults } from '@/utils/paging.utils';
import { OrderListItem } from '@/types/OrderTypes';
import { mapOrderTypeToFilter } from '@/utils/order.utils';

type Return = {
  orders: OrderListItem[];
  isLoading: boolean;
  hasNext: boolean;
  onLoadNext: () => void;
};

export function useMyOrders(): Return {
  const [paging, setPaging] = useState<PagingInput>(pagingDefaults);
  const [hasNext, setHasNext] = useState(false);

  const { type } = useParams<{ type: 'active' | 'past' }>();
  const [orders, setOrders] = useState<OrderListItem[]>([]);

  const { data, isLoading } = useMyOrdersQuery(
    {
      paging,
      filters: type && [mapOrderTypeToFilter(type)],
      sorting: { name: 'createdAt', dir: 'desc' },
    },
    { staleTime: 0 },
  );

  useEffect(() => {
    setOrders([]);
    setPaging(pagingDefaults);
  }, [type]);

  useEffect(() => {
    if (!data) return;
    setHasNext(hasNextPage(data.myOrders.paging));
    setOrders((prev) => [...prev, ...data.myOrders.nodes]);
  }, [data]);

  return useMemo(() => {
    return {
      isLoading,
      orders,
      hasNext,
      onLoadNext: () =>
        setPaging((prev) => ({
          ...prev,
          offset: (prev.offset ?? pagingDefaults.offset) + (prev.limit ?? pagingDefaults.limit),
        })),
    };
  }, [hasNext, isLoading, orders]);
}

export function useTabs() {
  const { pathname } = useLocation();

  return useMemo(() => {
    return [
      {
        label: 'Active Orders',
        route: appRoutes.orderHistory('active'),
        selected: !!matchPath(appRoutes.orderHistory('active'), pathname),
      },
      {
        label: 'Past Orders',
        route: appRoutes.orderHistory('past'),
        selected: !!matchPath(appRoutes.orderHistory('past'), pathname),
      },
    ];
  }, [pathname]);
}
