import { Icon } from './Icon';

export type SettingsMenuItemProps = {
  className?: string;
  title: string;
  selected?: boolean;
  onClick: () => void;
};

export function SettingsMenuItem({ className = '', title, selected, onClick }: SettingsMenuItemProps) {
  return (
    <button
      className={`flex w-full flex-row items-center justify-between space-x-4 rounded-md ${
        selected ? 'bg-dark-gray' : 'bg-black'
      } hover:bg-light-gray px-4 py-4 ${className}`}
      onClick={onClick}
    >
      <span className="cursor-pointer text-white">{title}</span>
      <Icon name="ChevronRight" className="h-3 w-3 text-white" />
    </button>
  );
}
