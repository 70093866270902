type Props = {
    className?: string;
    onClick?: () => void;
};

export function ArrowLeft(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11 7C11.5523 7 12 6.55228 12 6C12 5.44771 11.5523 5 11 5H1C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7L11 7Z"
                fill="currentColor"
            />
            <path
                d="M0.30119 6.72345L5.13455 11.6853C5.53992 12.1017 6.20078 12.1051 6.61011 11.6941C7.0201 11.2824 7.0234 10.6112 6.61803 10.1954L2.5142 5.98185L6.66293 1.8132C7.07226 1.4015 7.07622 0.7303 6.67019 0.314575C6.26549 -0.101822 5.60462 -0.105174 5.19529 0.306529L0.315054 5.21008C0.209421 5.31535 0.131517 5.43873 0.0800209 5.57015C-0.0764479 5.95302 -0.00382531 6.41032 0.30119 6.72345Z"
                fill="currentColor"
            />
        </svg>
    );
}
