import { CampaignType } from '@/graphql/generated';

export type OfferAdSize = '300x600' | '300x250' | '728x90';

export type OfferListItemServerType = {
  id: string;
  type: string;
  name: string;
  appleId?: number;
  androidId?: string;
  pcLink?: string;
  shop?: { id: string; name: string; thumbnail?: string };
  product?: {
    id: string;
    name: string;
    category: string;
    price: number;
    rewards: { scutis: number };
    scutisExclusive: boolean;
  };
  media?: {
    tile?: string;
    vertical?: string;
    videoUrl?: string;
    image: string;
    banner?: { tallUrl?: string; smallUrl?: string };
  };
};

export type OfferListItem = {
  id: string;
  type: CampaignType;
  image?: string;
  adSize?: OfferAdSize;
  title: string;
  price: number;
  scutisExclusive: boolean;
  scutiRewards: number;
  shopThumbnail?: string;
  isCampaignOffer: boolean;
  androidId?: string | null;
  appleId?: number | null;
  pcLink?: string | null;
};

export type ProductOfferOptionKey = 'option1' | 'option2' | 'option3';
export type ProductVariantOptions = { option1?: string | null; option2?: string | null; option3?: string | null };

export type ProductOffer = {
  id: string;
  productId: string;
  name: string;
  description?: string | null;
  brand?: {
    name?: string;
  } | null;
  shop: {
    name?: string | null;
    thumbnail?: string | null;
  };
  price: {
    current: number;
    old?: number;
  };
  optionNames: Record<ProductOfferOptionKey, string | null | undefined>;
  option1?: string | null;
  option2?: string | null;
  option3?: string | null;
  images: string[];
  reward?: number | null;
  inStock: number;
  scutisExclusive: boolean;
};

export const productOfferDefaults: ProductOffer = {
  id: '',
  productId: '',
  name: '',
  description: null,
  shop: {
    name: null,
    thumbnail: null,
  },
  price: {
    current: 0,
    old: undefined,
  },
  option1: undefined,
  option2: undefined,
  option3: undefined,
  optionNames: {
    option1: undefined,
    option2: undefined,
    option3: undefined,
  },
  images: [],
  reward: null,
  inStock: 0,
  scutisExclusive: false,
};

export type HeaderBannerOfferType = {
  id: string;
  imageUrl?: string | null;
  productId?: string | null;
  videoUrl?: string | null;
};
