import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

type ScrollLoaderProps = {
  isLoading?: boolean;
  onLoad: () => void;
};

export function ScrollLoader({ isLoading, onLoad }: ScrollLoaderProps) {
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && !isLoading) onLoad();
  }, [inView, isLoading, onLoad]);

  return (
    <div className="flex w-full flex-col items-center p-4">
      {isLoading ? <div /> : <div className="w-full pt-8" ref={ref} />}
    </div>
  );
}
