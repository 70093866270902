type Props = {
    className?: string;
    onClick?: () => void;
};

export function Menu(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.6748 16.4705C21.407 16.4705 22 17.037 22 17.7356C22 18.4334 21.407 19 20.6748 19H3.32609C2.59385 19 2 18.4334 2 17.7356C2 17.037 2.59385 16.4705 3.32609 16.4705H20.6748ZM20.6748 10.7358C21.407 10.7358 22 11.3016 22 12.0002C22 12.6988 21.407 13.2645 20.6748 13.2645H3.32609C2.59385 13.2645 2 12.6988 2 12.0002C2 11.3016 2.59385 10.7358 3.32609 10.7358H20.6748ZM20.6751 5C21.4073 5 22.0003 5.56656 22.0003 6.26516C22.0003 6.96297 21.4073 7.52953 20.6751 7.52953H3.32643C2.59418 7.52953 2.00034 6.96297 2.00034 6.26516C2.00034 5.56656 2.59418 5 3.32643 5H20.6751Z"
                fill="currentColor"
            />
        </svg>
    );
}
