import { Icon } from '@/components/Common/Icon';
import { SettingsMenuItem } from '@/components/Common/SettingsMenuItem';
import { SettingsLayout } from '@/components/Layout/SettingsLayout';
import { Outlet } from 'react-router-dom';
import { useLegalMenu, useSideStyles } from './LegalPage.hooks';

export function LegalPage() {
  const legalMenu = useLegalMenu();
  const { left, right } = useSideStyles();

  return (
    <SettingsLayout
      leftClassName={left}
      left={
        <div className="z-10 flex h-full flex-col px-0 pb-8 lg:px-6">
          <Icon name="ScutiLogo" className="z-10 mt-4 hidden h-[125px] self-center p-6 md:block" />
          <div className="mt-20 flex h-full flex-1 flex-col justify-between md:mt-40 xl:mx-16">
            <div>
              <h2 className="mx-4 mb-4 text-2xl font-semibold md:mx-0 md:text-base">Legal</h2>
              {legalMenu.map((item) => (
                <SettingsMenuItem key={item.title} className="mt-1" {...item} />
              ))}
            </div>
            <div className="text-light-gray mx-4 mb-4 text-sm font-semibold md:mx-0 md:mb-16">
              © {new Date().getFullYear()} Scuti. All Rights Reserved.
            </div>
          </div>
        </div>
      }
      rightClassName={right}
      right={<Outlet />}
    />
  );
}
