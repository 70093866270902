type Props = {
    className?: string;
    onClick?: () => void;
};

export function RadioCheck(props: Props) {
    return (
        <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" fill="#7ED321" r="12" />
            <path d="M5.46484 13.0088L9.23787 16.6788L17.9995 8.15634" stroke="white" strokeLinecap="square" strokeMiterlimit="10" strokeWidth="3" />
        </svg>
    );
}
