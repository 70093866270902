import { formatPrice, formatScore } from '@/utils/number.utils';
import { CheckoutDetails as CheckoutDetailsType } from '@/types/CheckoutTypes';

export type CheckoutDetailsProps = {
  className?: string;
  details: CheckoutDetailsType;
};

export function CheckoutDetails({ className, details }: CheckoutDetailsProps) {
  return (
    <div className={`flex w-full flex-col space-y-4 bg-darker-gray p-8 md:rounded-2xl ${className}`}>
      <div className="flex flex-row justify-between">
        <p className="text-sm text-light-gray">Subtotal:</p>
        <p className="text-sm text-white">${formatPrice(details.subtotal)}</p>
      </div>
      <hr className="border border-b-0 border-dark-gray" />
      <div className="flex flex-row justify-between">
        <p className="text-sm text-light-gray">Shipping Fee:</p>
        <p className="text-sm text-white">${formatPrice(details.shippingFee)}</p>
      </div>
      <div className="flex flex-row justify-between">
        <p className="text-sm text-light-gray">Sales Tax:</p>
        <p className="text-sm text-white">${details.salesTax}</p>
      </div>
      <div className="flex flex-row justify-between">
        <p className="text-sm text-light-gray">Scuti$ Used:</p>
        <p className="text-sm text-white">{formatScore(details.scutisUsed)}</p>
      </div>
      {details.discounts.cart > 0 && (
        <div className="flex flex-row justify-between">
          <p className="text-sm text-light-gray">Promo Code Discounts:</p>
          <p className="text-sm text-white">-${formatPrice(details.discounts.cart)}</p>
        </div>
      )}
      <hr className="border border-b-0 border-dark-gray" />
      <div className="flex flex-row justify-between">
        <p className="font-semibold">Total:</p>
        <p className="font-semibold text-white">${formatPrice(details.total)}</p>
      </div>
      <div className="flex flex-row justify-between">
        <p className="text-sm text-light-gray">Scuti$ Earned:</p>
        <p className="text-sm text-white">{formatScore(details.scutisEarned)}</p>
      </div>
      {details.extraScutis > 0 && (
        <>
          <div className="flex flex-row justify-between">
            <p className="text-sm text-light-gray">Additional Scuti$ Earned (Promo Code Rewards):</p>
            <p className="text-sm text-white">{formatScore(details.extraScutis)}</p>
          </div>
          <div className="flex flex-row justify-between">
            <p className="text-sm text-light-gray">Total Scuti$ Earned:</p>
            <p className="text-sm text-white">{formatScore(details.scutisEarned + details.extraScutis)}</p>
          </div>
        </>
      )}
    </div>
  );
}
