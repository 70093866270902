type Props = {
    className?: string;
    onClick?: () => void;
};

export function Pin(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.5 1.20175e-08C9.99682 -0.000126467 7.59474 0.998108 5.81585 2.77774C4.03696 4.55737 3.02503 6.97457 3 9.50397C3 16.0799 11.3719 23.3999 11.7281 23.7119C11.9432 23.8978 12.217 24 12.5 24C12.783 24 13.0568 23.8978 13.2719 23.7119C13.6875 23.3999 22 16.0799 22 9.50397C21.975 6.97457 20.963 4.55737 19.1841 2.77774C17.4053 0.998108 15.0032 -0.000126467 12.5 1.20175e-08ZM12.5 13.2C11.678 13.2 10.8744 12.9536 10.1909 12.4921C9.50742 12.0306 8.9747 11.3747 8.66013 10.6072C8.34555 9.83979 8.26324 8.99531 8.42361 8.18059C8.58398 7.36588 8.97983 6.61751 9.56109 6.03013C10.1423 5.44275 10.8829 5.04274 11.6892 4.88069C12.4954 4.71863 13.3311 4.8018 14.0905 5.11969C14.85 5.43758 15.4991 5.9759 15.9558 6.66658C16.4125 7.35727 16.6562 8.16929 16.6562 8.99997C16.6562 10.1139 16.2184 11.1822 15.4389 11.9698C14.6595 12.7575 13.6023 13.2 12.5 13.2Z"
                fill="currentColor"
            />
        </svg>
    );
}
