import { GuidedTour } from '@/components/Common/GuidedTour';
import { Back } from '../Back';
import { Header } from './Header';
import { Outlet } from 'react-router-dom';

interface MainLayoutProps {
  hideHeader?: boolean;
}

export function MainLayout({ hideHeader }: MainLayoutProps) {
  return (
    <div className="relative flex h-full w-screen flex-col bg-dark-gray">
      <div className="border-b-solid flex h-12 shrink-0 items-center border-b border-b-dark-gray bg-darker-gray px-4 md:h-14 md:py-4 landscape:hidden lg:landscape:flex">
        <Back />
      </div>
      {!hideHeader && <Header />}
      <div className="flex-1 overflow-y-auto px-2 pb-4">
        <Outlet />
      </div>
      <GuidedTour />
    </div>
  );
}
