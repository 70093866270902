import React from 'react';

type Props = {
    className?: string;
    onClick?: () => void;
};

export function ScutiCoin(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 157 157" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M78.4997 151.284C118.697 151.284 151.283 118.697 151.283 78.4999C151.283 38.3026 118.697 5.71626 78.4997 5.71626C38.3024 5.71626 5.716 38.3026 5.716 78.4999C5.716 118.697 38.3024 151.284 78.4997 151.284Z"
                fill="url(#paint0_linear_1727_3345)"
            />
            <path
                d="M78.5 157C121.854 157 157 121.854 157 78.5C157 35.1456 121.854 0 78.5 0C35.1456 0 0 35.1456 0 78.5C0 121.854 35.1456 157 78.5 157Z"
                fill="#D9E3E8"
            />
            <path
                clipRule="evenodd"
                d="M78.5001 4.40154C63.8448 4.40154 49.5186 8.74733 37.3332 16.8894C25.1477 25.0314 15.6503 36.604 10.042 50.1438C4.43366 63.6835 2.96626 78.5823 5.82536 92.956C8.68447 107.33 15.7417 120.533 26.1046 130.896C36.4674 141.258 49.6705 148.316 64.0442 151.175C78.4179 154.034 93.3166 152.566 106.856 146.958C120.396 141.35 131.969 131.852 140.111 119.667C148.253 107.482 152.599 93.1553 152.599 78.5C152.598 68.77 150.68 59.1356 146.956 50.1467C143.232 41.1577 137.774 32.9904 130.893 26.1109C124.012 19.2315 115.844 13.7746 106.854 10.052C98.2678 6.49625 89.0928 4.58917 79.8084 4.42603V4.40154H78.5001ZM52.1555 14.8931C60.5057 11.4348 69.4587 9.66644 78.4967 9.69017C94.4129 9.69177 109.839 15.212 122.142 25.3102C134.445 35.4084 142.865 49.4599 145.969 65.0705C149.073 80.6812 146.668 96.8851 139.164 110.921C131.661 124.958 119.522 135.958 104.817 142.048C90.1122 148.138 73.7505 148.941 58.5198 144.321C43.2891 139.7 30.1317 129.941 21.2895 116.707C12.4473 103.473 8.46735 87.5821 10.0277 71.7426C11.588 55.903 18.5921 41.0945 29.8466 29.8401C36.2213 23.4331 43.8053 18.3513 52.1555 14.8931Z"
                fill="#92B0BF"
                fillRule="evenodd"
            />
            <path
                d="M78.5026 32.5279C82.9653 32.5279 86.7336 35.257 88.1124 39.4788L91.2917 49.2392L93.467 55.9207C93.5883 56.2838 93.8206 56.5996 94.131 56.8236C94.4414 57.0476 94.8144 57.1684 95.1972 57.169H112.536C116.994 57.169 120.762 59.8965 122.141 64.1199C123.52 68.3433 122.077 72.7558 118.473 75.3662L104.076 85.7926C103.876 85.9347 103.727 86.1368 103.651 86.3694C103.574 86.6021 103.573 86.8531 103.649 87.086L107.202 97.9826L107.645 99.3413L109.151 103.976C110.53 108.203 109.091 112.617 105.481 115.228C103.761 116.492 101.684 117.178 99.5495 117.185C97.4113 117.18 95.3304 116.494 93.6076 115.228L83.2046 107.698C81.8381 106.707 80.1938 106.173 78.506 106.172C76.8185 106.172 75.1743 106.706 73.809 107.698L63.406 115.228C61.6828 116.493 59.6021 117.179 57.4641 117.185C55.3277 117.178 53.2489 116.493 51.5272 115.228C47.9229 112.617 46.4822 108.2 47.8594 103.976L49.3653 99.3413L49.8071 97.9826L53.3645 87.086C53.4371 86.8529 53.435 86.6028 53.3585 86.3709C53.282 86.139 53.1349 85.9368 52.9378 85.7926L38.5273 75.3612C34.9213 72.7508 33.4823 68.3333 34.8578 64.1149C36.2332 59.8965 40.0065 57.164 44.4642 57.164H61.798C62.181 57.1641 62.5543 57.0436 62.8649 56.8195C63.1755 56.5954 63.4076 56.2792 63.5282 55.9157L65.7035 49.2342L68.8828 39.4738C70.275 35.2587 74.0449 32.5279 78.5026 32.5279Z"
                fill="#00C0E9"
            />
            <defs>
                <linearGradient gradientUnits="userSpaceOnUse" id="paint0_linear_1727_3345" x1="49.0687" x2="159.286" y1="49.069" y2="159.286">
                    <stop stopColor="#E5E5E5" />
                    <stop offset="0.53" stopColor="#92B0BF" />
                    <stop offset="1" stopColor="#E5E5E5" />
                </linearGradient>
            </defs>
        </svg>
    );
}
