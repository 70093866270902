import * as stripeJs from '@stripe/stripe-js';

const colors = {
  blue: '#1d35ee',
  'dark-blue': '#1024a3',
  'light-blue': '#00c0e9',
  'darker-gray': '#101015',
  'dark-gray': '#1b1b22',
  gray: '#6a6b70',
  'light-gray': '#aeaeae',
  red: '#ff1463',
  orange: '#ff7500',
  yellow: '#edea00',
  green: '#7ed321',
  white: '#ffffff',
  black: '#000000',
};

export const getStripeOptions = (clientSecret: string) => {
  let result: stripeJs.StripeElementsOptions = {
    clientSecret,
    appearance: {
      variables: {
        fontFamily: `'Industry'`,
        colorText: colors['light-gray'],
        colorBackground: colors['dark-gray'],
        colorTextPlaceholder: colors['gray'],
      },
      rules: {
        '.Block': {
          borderColor: colors['dark-gray'],
          paddingLeft: '0',
          paddingRight: '0',
        },
        '.SecondaryLink': {
          color: colors['light-blue'],
        },
        '.Label': {
          fontSize: '12px',
          fontWeight: '500',
        },
        '.Input': {
          backgroundColor: 'transparent',
          border: 'none',
          boxShadow: 'none',
          color: 'white',
          fontSize: '16px',
          borderBottom: '1px solid black',
          borderRadius: '0',
          padding: '16px 0 16px 0',
        },
        '.Input--invalid': {
          backgroundColor: 'transparent',
          border: 'none',
          boxShadow: 'none',
          borderBottom: `1px solid ${colors.red}`,
        },
        '.Error': {
          color: colors.red,
        },
        '.Input:focus': {
          backgroundColor: 'transparent',
          border: 'none',
          boxShadow: 'none',
          borderBottom: `1px solid ${colors.blue}`,
        },
      },
    },
  };

  return result;
};
