type Props = {
    className?: string;
    onClick?: () => void;
};

export function CheckedStar(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 75 72" xmlns="http://www.w3.org/2000/svg">
            <path
                clipRule="evenodd"
                d="M74.4461 26.8653C73.279 23.2744 70.0855 20.9545 66.3085 20.9545H51.6228C50.9533 20.9545 50.3626 20.5249 50.1549 19.8912L45.6082 5.91079C44.4411 2.31992 41.2512 0 37.4741 0H37.467C33.6899 0 30.5 2.31992 29.3293 5.91079L26.6371 14.2095L24.7861 19.8912C24.5749 20.5249 23.9878 20.9545 23.3219 20.9545H8.63259C4.85913 20.9545 1.66207 23.2744 0.494952 26.8653C-0.672171 30.4562 0.548653 34.2117 3.60608 36.4314L15.8107 45.2994C16.158 45.5536 16.3012 45.9939 16.1759 46.4021L13.165 55.671L12.7855 56.8274L11.5074 60.7691C10.3403 64.36 11.5611 68.1156 14.6114 70.3352C16.1437 71.4451 17.8908 72 19.6415 72C21.3958 72 23.15 71.4487 24.6787 70.3352L33.493 63.934C34.6816 63.0676 36.0743 62.638 37.4705 62.6344C38.8668 62.638 40.2595 63.0676 41.4481 63.934L50.2659 70.3352C51.7911 71.4487 53.5453 72 55.2924 72C57.0467 72 58.801 71.4451 60.3297 70.3352C63.3835 68.1156 64.6008 64.36 63.4372 60.7691L62.152 56.8274L61.7796 55.671L58.7687 46.4021C58.6434 45.9939 58.7831 45.5536 59.1339 45.2994L71.3386 36.4314C74.3924 34.2117 75.6132 30.4562 74.4461 26.8653Z"
                fill="url(#paint0_linear_2094_5233)"
                fillRule="evenodd"
            />
            <path
                clipRule="evenodd"
                d="M52 30.5978L31.207 50.5043L20.2001 39.9666L23.7557 36.3688L31.207 43.5024L48.4444 27L52 30.5978Z"
                fill="white"
                fillRule="evenodd"
            />
            <defs>
                <linearGradient gradientUnits="userSpaceOnUse" id="paint0_linear_2094_5233" x1="37.4706" x2="37.4706" y1="0" y2="72">
                    <stop stopColor="#7ED321" />
                    <stop offset="1" stopColor="#548F12" />
                </linearGradient>
            </defs>
        </svg>
    );
}
