import { wrapperStore } from '@/store/wrapper.store';
import { messageListener } from './messageListener';
import { sendMessage } from './wrapper-sender';
import { waitForUnity, waitForVuplex } from './utils';
import { sendMetric } from '../metrics/metics-sender';
import { watch } from 'valtio/utils';
import ReactDOM, { Root } from 'react-dom/client';
import { appSessionStorage } from '../storage.helper';



export const initializeSDK = async (App: JSX.Element, container: HTMLElement) => {
  const platform = appSessionStorage.getItem('platform');
  if (!platform) return;

  let root: Root | null = ReactDOM.createRoot(container);

  wrapperStore.actions.toggleVisibility(false);

  if (platform === 'Unity') {
    await waitForUnity();
    await import('./methods');
  }
  if (platform === 'Vuplex') {
    await waitForVuplex();
  }

  messageListener(platform);

  if (['WebGL', 'iFrame'].includes(platform)) {
    sendMessage({ message: 'STORE_IS_READY' });

    if (platform === 'WebGL') sendMetric({ name: 'SDK_INIT' });

    return root.render(App);
  }

  sendMessage({ message: 'STORE_IS_READY' });
  sendMetric({ name: 'SDK_INIT' });

  watch((get) => {
    const { visible } = get(wrapperStore.state.ui);

    if (!root) root = ReactDOM.createRoot(container);

    if (visible) {
      root.render(App);
    } else {
      root.unmount();
      root = null;
    }
  });
};
