import { useState } from 'react';
import { Button } from './Button';
import { appRoutes } from '@/helpers/routes.helper';
import { appSessionStorage } from '@/helpers/storage.helper';
import { useMe } from '@/hooks/useMe';

const VIDEO_SRC =
  'https://storage.googleapis.com/imageserviceprod.scuti.store/8644540757796367046new_update_landscape_video_01_1%20(1).MP4';

export const WelcomeVideo = () => {
  const [skipEnabled, setSkipEnabled] = useState(false);

  const logged = useMe() ? true : false;
  const viewed = appSessionStorage.getItem('welcomeVideoViewed') || false;

  const [modalVisible, setModalVisible] = useState(!logged && !viewed);

  const hideModal = (e: React.MouseEvent) => {
    e.preventDefault();
    setModalVisible(false);
  };

  const startTimer = () => {
    window.setTimeout(() => {
      setSkipEnabled(true);
      appSessionStorage.setItem('welcomeVideoViewed', true);
    }, 5000);
  };

  return modalVisible ? (
    <div
      className="absolute left-0 top-0 z-50 h-full w-full"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed bottom-0 left-0 right-0 top-0 flex overflow-y-auto backdrop-brightness-0 backdrop-opacity-90">
        <div className="flex w-full items-center justify-center">
          <div className={`rounded-2x relative flex-col py-8`}>
            <div className="w-full max-w-md lg:max-w-5xl landscape:max-w-sm landscape:lg:max-w-5xl">
              <video autoPlay muted playsInline onCanPlay={startTimer}>
                <source src={VIDEO_SRC} type="video/mp4" />
              </video>
            </div>
            <div className="w-full max-w-md lg:max-w-5xl">
              <div className="mt-4 animate-fadeIn flex-col">
                <Button className="mx-auto mb-4 w-1/2" href={appRoutes.registration}>
                  Register Now
                </Button>

                <div className={`mt-2 animate-fadeIn text-center ${skipEnabled ? 'block' : 'hidden'}`}>
                  <a aria-current="page" onClick={(e) => hideModal(e)} className="active" href="/">
                    Continue to the Store
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};
