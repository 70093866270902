import { Blurred } from '@/components/Common/Blurred';
import { ProgressBar } from '@/components/Common/ProgressBar';
import useAnimation from '@/hooks/useAnimation';

export function PaymentWithScutisFormProgress() {
  const loading = useAnimation('linear', 2000);

  return (
    <Blurred>
      <div className="flex flex-col space-y-8">
        <div className="relative flex justify-center">
          <div className="flex flex-row items-center space-x-10">
            <img alt="" className="h-20 w-20" src="/images/ScutiCoin.png" />
          </div>
        </div>
        <ProgressBar className="w-full max-w-lg" value={loading * 100} />
        <h2 className="text-center">Applying Your Scuti$ to your purchase</h2>
        <p className="text-paragraph-sm text-secondary-400 text-center">
          Your transaction is in process, this action might take a few seconds...
        </p>
      </div>
    </Blurred>
  );
}
