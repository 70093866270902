import { RefObject, useEffect } from "react";

/**
 * Scroll to a ref when {dep} changes.
 * @param ref of component that would need to be scrolled into view.
 * @param dep one dependency, or else would need to handle passing around and adding ref to it array for use effect.
 * @note Only works for components that change (ref is changed).
 */
export const useScrollIntoView = (ref: RefObject<HTMLElement>, dep: Readonly<unknown>) => {
    useEffect(() => {
        ref.current?.scrollIntoView({behavior: 'smooth'});
    }, [ref, dep])
};
