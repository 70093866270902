import React from 'react';

type Props = {
  className?: string;
  value: number;
};

export function ProgressBar({ className, value }: Props) {
  return (
    <div className={`bg-gray h-0.5 ${className}`}>
      <div className="h-0.5 bg-white" style={{ width: `${value}%` }} />
    </div>
  );
}
