import { NavLink } from 'react-router-dom';
import { Button } from '@/components/Common/Button';
import { Modal } from '@/components/Common/Modal';
import { Welcome } from '@/components/Common/Welcome';
import { WelcomeLayout } from '@/components/Layout/WelcomeLayout';
import { appRoutes } from '@/helpers/routes.helper';
import { useSize, useWindowSize } from 'react-use';

export function WelcomePage() {
  const windowSize = useWindowSize();

  /**
   * in smaller height screens the content overflow but is not visible setting it to flex-start in those scenario helps
   * without major changes to other pages that use Modal.
   */
  const [welcome] = useSize(({ height }) => (
    <Modal
      itemAlignStart={height > windowSize.height}
      className="w-full flex-col items-start justify-center bg-transparent pt-3.5 md:w-[60%] md:bg-darker-gray/60"
    >
      <Welcome />
      <div className="w-full">
        <Button className="mb-4 w-full" href={appRoutes.registration}>
          Create Account
        </Button>
        <Button variant="secondary" className="w-full" href={appRoutes.login}>
          Login
        </Button>
        <div className="mt-8 text-center">
          <NavLink to={appRoutes.store}>Continue to the Store</NavLink>
        </div>
      </div>
    </Modal>
  ));

  return <WelcomeLayout>{welcome}</WelcomeLayout>;
}
