import { ScrollLoader } from '@/components/Common/ScrollLoader';
import { useMyScutiTransactions } from './ScutiTransactions.hooks';
import { ScutiTransactionItem } from '@/components/Common/ScutiTransactionItem';
import { ScutiTransactionItem as ScutiTransactionItemType } from '@/types/ScutiTransactionType';
import { useMemo } from 'react';
import { getPeriodLabel } from '@/utils/date.utils';

export function ScutiTransactionsPage() {
  const { scutiTransactions, hasNext, onLoadNext, isLoading } = useMyScutiTransactions();

  const sections = useMemo(() => {
    return scutiTransactions.reduce<Record<string, ScutiTransactionItemType[]>>((acc, next) => {
      const label = getPeriodLabel(next.createdAt);
      if (acc[label]) acc[label].push(next);
      else acc[label] = [next];

      return acc;
    }, {});
  }, [scutiTransactions]);

  return (
    <div className="flex h-full flex-col items-center pt-4 md:pt-8">
      <div>
        <h1 className="mb-4 text-center font-semibold">Transactions</h1>
      </div>
      <div className="flex w-full grow flex-col overflow-y-auto pr-4 md:w-[80%] lg:w-[60%]">
        {Object.entries(sections).map(([label, ts]) => {
          return (
            <div key={label}>
              <div className="sticky  top-0 bg-dark-gray">
                <h3 className="mx-4 pt-8 text-light-gray">{label}</h3>
                <hr className="my-4 flex w-full border-b-0 border-darker-gray" />
              </div>
              {ts.map((transaction) => (
                <div className="my-4 border-0 border-b border-darker-gray px-4 pb-4" key={transaction.id}>
                  <ScutiTransactionItem scutiTransaction={transaction} />
                </div>
              ))}
            </div>
          );
        })}
      </div>
      {hasNext && <ScrollLoader isLoading={isLoading} onLoad={onLoadNext} />}
    </div>
  );
}
