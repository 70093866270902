import { Button } from '@/components/Common/Button';
import { PaymentElement } from '@stripe/react-stripe-js';
import { usePaymentForm } from './PaymentForm.hooks';
import { PaymentTransactionProcess } from './PaymentTransactionProcess';
import { useState } from 'react';
import { Icon } from '@/components/Common/Icon';

export type AddressFormProps = {
  className?: string;
};

export function PaymentForm({ className }: AddressFormProps) {
  const { handleSubmit, paymentComplete, isSubmitting, errorMessage } = usePaymentForm();
  const [isReady, setIsReady] = useState(false);

  return (
    <form noValidate className={`mt-12 w-full md:w-2/3 lg:w-1/3 ${className}`} onSubmit={handleSubmit}>
      {isSubmitting && <PaymentTransactionProcess />}
      {paymentComplete && (
        <div className="flex h-full items-center justify-center">
          <div className="flex flex-col items-center text-center">
            <Icon name="CheckedStar" className="my-4 h-20 w-20" />
            <h1 className="my-4 font-semibold text-white">Your Order Has Been Placed</h1>
            <div className="text-light-grey mb-4 text-base font-medium">Thank you for purchasing with Scuti</div>
            <div className="text-light-grey mb-4 text-base font-medium">Please back to the App</div>
          </div>
        </div>
      )}
      {!paymentComplete && (
        <div className={isSubmitting ? 'invisible' : 'visible'}>
          <div>
            <h1 className="mb-6 text-center font-semibold">Payment Information</h1>
          </div>
          <PaymentElement onReady={() => setIsReady(true)} />
          {errorMessage && <div className="text-system-red-400 mt-4 text-center">{errorMessage}</div>}
          <Button type="submit" className={`mt-12 w-full ${isReady ? 'visible' : 'invisible'}`} disabled={isSubmitting}>
            Checkout
          </Button>
        </div>
      )}
    </form>
  );
}
