type Props = {
    className?: string;
    onClick?: () => void;
};

export function ShareB(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.17847 14.9664C8.82992 14.9664 10.1687 13.6276 10.1687 11.9762C10.1687 10.3247 8.82992 8.98596 7.17847 8.98596"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.7941"
            />
            <path
                d="M17.3453 14.3019C15.6939 14.3019 14.3551 15.6406 14.3551 17.2921C14.3551 18.0339 14.6252 18.7127 15.0725 19.2353"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.7941"
            />
            <path
                d="M17.3453 9.6505C15.6939 9.6505 14.3551 8.31174 14.3551 6.66029C14.3551 5.81004 14.71 5.04268 15.2797 4.49823"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.7941"
            />
            <path
                d="M10.1688 13.438L14.8203 15.6973M10.1688 10.6472L14.8203 8.255"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.7941"
            />
            <path
                d="M22.9999 3.22856C22.7688 3.33099 22.5205 3.4002 22.2594 3.43148C22.5288 3.27031 22.7303 3.01664 22.8264 2.71781C22.5733 2.86813 22.2963 2.97393 22.0075 3.03063C21.8133 2.82324 21.556 2.68579 21.2757 2.6396C20.9953 2.59341 20.7075 2.64107 20.457 2.77518C20.2066 2.9093 20.0073 3.12236 19.8904 3.38129C19.7734 3.64022 19.7451 3.93054 19.81 4.20716C19.2973 4.18141 18.7956 4.04813 18.3377 3.81597C17.8798 3.58381 17.4758 3.25795 17.1519 2.85955C17.0412 3.05056 16.9775 3.27202 16.9775 3.50788C16.9774 3.72021 17.0297 3.92929 17.1297 4.11656C17.2298 4.30383 17.3745 4.46351 17.5511 4.58143C17.3463 4.57492 17.1461 4.51958 16.967 4.42004V4.43665C16.967 4.73445 17.07 5.02308 17.2585 5.25358C17.4471 5.48407 17.7096 5.64223 18.0015 5.70121C17.8115 5.75262 17.6124 5.7602 17.419 5.72336C17.5014 5.9796 17.6618 6.20367 17.8779 6.3642C18.0939 6.52473 18.3547 6.6137 18.6238 6.61863C18.167 6.97724 17.6028 7.17176 17.0221 7.17091C16.9192 7.17093 16.8164 7.16493 16.7142 7.15291C17.3037 7.53194 17.99 7.7331 18.6908 7.73232C21.0632 7.73232 22.3602 5.76737 22.3602 4.06321C22.3602 4.00784 22.3588 3.95192 22.3563 3.89655C22.6086 3.71412 22.8263 3.4882 22.9994 3.22939L22.9999 3.22856Z"
                fill="white"
            />
            <path clipRule="evenodd" d="M1 10.5796H2.22222V14.0718H1V10.5796Z" fill="white" fillRule="evenodd" />
            <path
                clipRule="evenodd"
                d="M1.61112 10.0558C1.94828 10.0558 2.22223 9.7818 2.22223 9.44445C2.22223 9.1071 1.94828 8.83331 1.61112 8.83331C1.27327 8.83331 1.00001 9.1071 1.00001 9.44445C1.00001 9.7818 1.27327 10.0558 1.61112 10.0558Z"
                fill="white"
                fillRule="evenodd"
            />
            <path
                clipRule="evenodd"
                d="M6.2381 12.1525C6.2381 11.2103 6.03486 10.5796 4.93643 10.5796C4.40843 10.5796 4.05416 10.7757 3.90924 11.0508H3.89423V10.5796H2.92064V14.0716H3.93823V12.3407C3.93823 11.8844 4.02448 11.4423 4.58915 11.4423C5.14543 11.4423 5.19048 11.9644 5.19048 12.3702V14.0716H6.2381V12.1525Z"
                fill="white"
                fillRule="evenodd"
            />
            <path
                d="M19.8034 19.375L19.959 18.2891H18.9863V17.5845C18.9863 17.2874 19.1222 16.9979 19.5578 16.9979H20V16.0734C20 16.0734 19.5987 16 19.215 16C18.414 16 17.8904 16.5201 17.8904 17.4616V18.2891H17V19.375H17.8904V22H18.9863V19.375H19.8034Z"
                fill="white"
            />
        </svg>
    );
}
