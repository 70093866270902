import { FormEvent, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { appRoutes } from '@/helpers/routes.helper';
import { toast } from 'react-toastify';
import { getErrorMessage } from '@/utils/error.utils';

type Return = {
  isSubmitting: boolean;
  errorMessage?: string;
  handleSubmit: (event: FormEvent<unknown>) => Promise<void>;
};

export function usePaymentForm(): Return {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = useCallback(
    async (event: FormEvent<unknown>) => {
      event.preventDefault();

      if (!stripe || !elements) return;
      setIsSubmitting(true);

      try {
        const { error } = await stripe.confirmPayment({ elements, redirect: 'if_required' });

        if (error) {
          setErrorMessage(error.message);
        } else {
          navigate(appRoutes.paymentComplete);
        }
        setIsSubmitting(false);
      } catch (error) {
        setIsSubmitting(false);
        toast.error(getErrorMessage(error), { autoClose: 8000 });
      }
    },
    [elements, navigate, stripe],
  );

  return useMemo(() => {
    return {
      isSubmitting,
      errorMessage,
      handleSubmit,
    };
  }, [errorMessage, handleSubmit, isSubmitting]);
}
