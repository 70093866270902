import { NavLink } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { appRoutes } from '@/helpers/routes.helper';
import { cartStore } from '@/store/cart.store';
import { Icon } from '@/components/Common/Icon';
import { UserMenuPopup, UserMenuDrawer } from '@/components/Common/UserMenu';
import { useState } from 'react';
import { useScreenSize } from '@/hooks/useScreenSize';
import { BannerAds } from '@/components/Common/BannerAds';
import { useMyWalletQuery } from '@/graphql/generated';
import { useMe } from '@/hooks/useMe';
import { Back } from '../../Back';
import clsx from 'clsx';

const AD_SELECTOR_MOBILE = 'scuti-header-banner-mobile';
const AD_SELECTOR = 'scuti-header-banner';
const AD_SELECTOR_CLASSES = 'mx-auto h-[50px] xl:h-[90px] w-[320px] xl:w-[728px] cursor-pointer';

export function Header() {
  const { itemsInCart } = useSnapshot(cartStore.state);
  const screenSize = useScreenSize();

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen((s) => !s);
  const handleClose = () => setMenuOpen(false);

  const isMobile = ['xs', 'sm', 'md'].includes(screenSize!);
  const showMobileBanner = screenSize! === 'xs';

  const me = useMe();
  const { data } = useMyWalletQuery(
    {},
    { enabled: !!me, refetchOnWindowFocus: 'always', refetchOnMount: 'always', staleTime: 0, cacheTime: 0 },
  );

  const scutiBalance = data ? data.myWallet.promotional + data.myWallet.purchase : 0;

  return (
    <div>
      <div className="flex w-full items-center justify-between bg-darker-gray px-4 py-[9px] lg:p-4">
        <div className="hidden landscape:inline lg:landscape:hidden">
          <Back />
        </div>
        <NavLink to={appRoutes.store} className={`landscape:ml-4 lg:landscape:ml-0`}>
          <Icon name="ScutiLogo" className="h-5 w-auto shrink-0 cursor-pointer lg:h-8" />
        </NavLink>
        <div className="flex w-full flex-row items-center justify-center">
          {!showMobileBanner && <div className={clsx(AD_SELECTOR, AD_SELECTOR_CLASSES)}></div>}
        </div>
        <div className="align-center flex shrink-0 space-x-4 lg:space-x-6 landscape:space-x-4 lg:landscape:space-x-6">
          <div className="relative" id="joyride-wallet">
            <NavLink to={appRoutes.wallet}>
              <img
                src="/images/ScutiCoin.png"
                className="relative z-0 inline-block h-7 w-7 shrink-0 cursor-pointer lg:h-10 lg:w-10"
                alt=""
              />
              {!!scutiBalance && (
                <div className="left-0 z-0 -ml-6 inline-block h-6 rounded-full border border-white px-3 py-1 pl-8 text-xs lg:h-8 lg:border-2 lg:pl-7">
                  <span className="self-center text-xs text-white lg:text-sm">{scutiBalance}</span>
                </div>
              )}
            </NavLink>
          </div>

          <NavLink to={appRoutes.cart}>
            <div className="relative cursor-pointer text-white">
              {itemsInCart ? (
                <div className="absolute -right-3 -top-3 flex h-7 w-7 items-center justify-center rounded-full border border-black bg-red text-[9px] lg:-right-4 lg:-top-4 lg:h-9 lg:w-9">
                  <span className="font-semibold lg:text-[14px]">{itemsInCart}</span>
                </div>
              ) : undefined}
              <Icon name="Cart" className="h-7 shrink-0 lg:h-10" hover={!itemsInCart} />
            </div>
          </NavLink>
          <Icon name="Menu" className="h-7 shrink-0 cursor-pointer lg:h-10" hover onClick={toggleMenu} />
        </div>
      </div>
      {showMobileBanner && (
        <div className="flex w-full flex-1 items-center justify-center bg-darker-gray">
          <div className={clsx(AD_SELECTOR_MOBILE, AD_SELECTOR_CLASSES)}></div>
        </div>
      )}
      {isMobile && menuOpen && <UserMenuDrawer className="block lg:hidden" onClose={handleClose} />}
      {!isMobile && menuOpen && (
        <UserMenuPopup className="absolute right-4 top-36 z-10 hidden lg:block" onClose={handleClose} />
      )}
      <BannerAds selector={showMobileBanner ? AD_SELECTOR_MOBILE : AD_SELECTOR} />
    </div>
  );
}
