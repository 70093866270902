import NoImage from './NoImage.svg';
import clsx from 'clsx';

export type ImageProps = {
  className?: string;
  src?: string | null;
};

export function Image({ className, src }: ImageProps) {
  return (
    <div className={`relative ${className}`}>
      <img
        loading="lazy"
        src={src || ''}
        alt=""
        className={clsx(`object-cover`, className)}
        onError={({ target }: any) => (target.src = NoImage)}
      />
    </div>
  );
}
