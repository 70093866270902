import * as Sentry from '@sentry/react';
import { useNavigate, useRouteError } from 'react-router-dom';
import { Button } from './Button';
import { useCallback, useEffect } from 'react';
import { sendMessage } from '@/helpers/sdk-wrapper';
import { appRoutes } from '@/helpers/routes.helper';
import { appSessionStorage } from '@/helpers/storage.helper';
import { toast } from 'react-toastify';
import { getErrorMessage } from '@/utils/error.utils';

export function Splash() {
  const platform = appSessionStorage.getItem('platform');
  const gameId = appSessionStorage.getItem('gameId');

  const navigate = useNavigate();
  const error = useRouteError();

  const handleBack = useCallback(() => {
    if (platform) return sendMessage({ message: 'BACK_TO_THE_GAME' });
    else navigate(appRoutes.store);
  }, [navigate, platform]);

  if (error) console.error(error);

  useEffect(() => {
    Sentry.captureException(error);
    toast.error(getErrorMessage(error), { autoClose: 5000 });
  }, [error]);

  return (
    <div className="flex h-full w-screen items-center justify-center">
      <div className="flex flex-col items-center">
        <img alt="" src="/images/Logo.svg" className="h-40 w-40 md:h-80 md:w-40" />
        {!!error && !!gameId && (
          <>
            <Button className="mt-4 w-full" onClick={handleBack}>
              {platform ? 'BACK TO GAME' : 'BACK TO MAIN SCREEN'}
            </Button>
          </>
        )}
        {!!error && <p className="mt-2 text-2xl text-red">Oooops... something went wrong</p>}
      </div>
    </div>
  );
}
