import { AddressCheckoutForm } from '@/components/Form/AddressCheckoutForm';
import { useMe } from '@/hooks/useMe';
import { checkoutStore } from '@/store/checkout.store';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/Common/Button';

export function CheckoutAddressPage() {
  const me = useMe()!;
  const shippingInfo = checkoutStore.state.shippingAddress || me.shippingInfo;
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center px-2 pt-4 md:pt-8">
      <div>
        <h1 className="mb-6 text-center font-semibold">Edit Address</h1>
      </div>
      <div className="grow-1 w-full overflow-y-auto pb-2 pr-4 md:w-[80%] lg:w-[40%]">
        <AddressCheckoutForm shippingInfo={shippingInfo} />
        <div className="mt-4">
          <Button className="w-full" onClick={() => navigate(-1)} variant="secondary">
            Back
          </Button>
        </div>
      </div>
    </div>
  );
}
