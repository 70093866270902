import { Button } from '@/components/Common/Button';
import { Icon } from '@/components/Common/Icon';
import { Modal } from '@/components/Common/Modal';
import { appRoutes } from '@/helpers/routes.helper';

export function PasswordReset() {
  return (
    <Modal>
      <div className="flex flex-col items-center justify-between p-3 px-8 md:p-6 md:px-16">
        <Icon name="Password" className="mb-4 h-16 w-16" />
        <h1 className="mb-8 font-semibold">Password Reset Successful</h1>
        <p className="text-paragraph-sm text-secondary-200 mb-10 text-center md:text-left">
          Please check your email for instructions.
        </p>
        <Button className="w-full" href={appRoutes.login}>
          Continue to Login
        </Button>
      </div>
    </Modal>
  );
}
