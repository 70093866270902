type Props = {
    className?: string;
    onClick?: () => void;
};

export function Exchange(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 12 13" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.02398 1.80569C5.02419 1.58847 4.95367 1.37708 4.82308 1.20349C4.69248 1.02991 4.50892 0.903566 4.30014 0.843577C4.09136 0.783587 3.86873 0.793211 3.66591 0.870993C3.46308 0.948775 3.2911 1.09048 3.17598 1.27469L0.487975 3.96269C0.39513 4.0556 0.321496 4.16589 0.271273 4.28726C0.221051 4.40862 0.195227 4.5387 0.195273 4.67004C0.19532 4.80139 0.221236 4.93145 0.271544 5.05278C0.321852 5.17411 0.395565 5.28435 0.488475 5.37719C0.581384 5.47003 0.691673 5.54367 0.813041 5.59389C0.934408 5.64412 1.06448 5.66994 1.19583 5.66989C1.32718 5.66985 1.45723 5.64393 1.57856 5.59362C1.69989 5.54331 1.81013 5.4696 1.90298 5.37669L3.02298 4.25569L3.02298 7.80569C3.02298 8.07091 3.12833 8.32526 3.31587 8.5128C3.5034 8.70033 3.75776 8.80569 4.02298 8.80569C4.28819 8.80569 4.54255 8.70033 4.73008 8.5128C4.91762 8.32526 5.02298 8.07091 5.02298 7.80569L5.02398 1.80569ZM6.99198 11.8197C6.99176 12.0369 7.06228 12.2483 7.19287 12.4219C7.32347 12.5955 7.50703 12.7218 7.71581 12.7818C7.92459 12.8418 8.14722 12.8322 8.35005 12.7544C8.55287 12.6766 8.72485 12.5349 8.83998 12.3507L11.528 9.66269C11.7155 9.47505 11.8208 9.22061 11.8207 8.95534C11.8206 8.69007 11.7151 8.4357 11.5275 8.24819C11.3398 8.06068 11.0854 7.9554 10.8201 7.95549C10.5549 7.95558 10.3005 8.06105 10.113 8.24869L8.99298 9.36969L8.99298 5.81969C8.99298 5.55448 8.88762 5.30012 8.70008 5.11258C8.51255 4.92505 8.25819 4.81969 7.99298 4.81969C7.72776 4.81969 7.47341 4.92505 7.28587 5.11258C7.09833 5.30012 6.99298 5.55448 6.99298 5.81969L6.99198 11.8197Z"
                fill="currentColor"
            />
        </svg>
    );
}
