import { FilterInput, Filter_Type, OrderStatus } from '@/graphql/generated';
import { OrderDetails, OrderItem, OrderListItem } from '@/types/OrderTypes';

export const mapOrderTypeToFilter = (type: 'active' | 'past'): FilterInput => {
  if (type === `active`) {
    return { name: 'status', operator: Filter_Type.In, value: [OrderStatus.Pending, OrderStatus.Shipping] };
  }
  return { name: 'status', operator: Filter_Type.In, value: [OrderStatus.Cancelled, OrderStatus.Completed] };
};

export const getFirstOrderItemFromOrder = (orderListItem: OrderListItem): OrderItem => {
  const firstItem = orderListItem.items[0];
  return { ...firstItem, createdAt: orderListItem.createdAt, id: orderListItem.id };
};

export const mapOrderDetailsToOrderItems = (orderDetails: OrderDetails): OrderItem[] => {
  return orderDetails.items.map((orderListItem) => ({
    ...orderListItem,
    createdAt: orderDetails.createdAt,
    id: orderListItem.id,
  }));
};
