import { useOrderDetails } from './OrderDetailsPage.hooks';
import { OrderAddress } from '@/components/Common/OrderAddress';
import { OrderTracking } from '@/components/Common/OrderTracking';
import { OrderCard } from '@/components/Common/OrderCard';

export function OrderDetailsPage() {
  const { orderTracking, orderAddress, orderItems = [] } = useOrderDetails();

  return (
    <div className="flex flex-col pt-4 md:pt-8">
      <div>
        <h1 className="mb-8 text-center font-semibold">Order Details</h1>
      </div>
      <div className="flex  grow flex-col items-center overflow-y-auto">
        <div className="w-full flex-col space-y-4 lg:w-[50%]">
          {orderTracking && <OrderTracking {...orderTracking} />}
          <div className="pt-4">Shipping Information</div>
          {orderAddress && <OrderAddress {...orderAddress} />}
          <div className="pt-4">Order Items</div>
          {orderItems.map((orderItem) => (
            <OrderCard orderItem={orderItem} key={orderItem.id} hideDetails />
          ))}
        </div>
      </div>
    </div>
  );
}
