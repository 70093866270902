import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Input } from '@/components/Common/Input';
import { Button } from '@/components/Common/Button';
import { ForgotPasswordFormData, schema } from './ForgotPasswordForm.schema';
import { useForgotPassword } from './ForgotPasswordForm.hooks';

interface ForgotPasswordFormProps {
  className?: string;
  onForgot: () => void;
}

export function ForgotPasswordForm({ className, onForgot }: ForgotPasswordFormProps) {
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<ForgotPasswordFormData>({
    resolver: yupResolver(schema),
    defaultValues: { email: '' },
    mode: 'all',
  });

  const onSubmit = useForgotPassword();

  const handleForgot = async (data: ForgotPasswordFormData) => {
    onSubmit(data);
    onForgot();
  };

  return (
    <form noValidate className={className} onSubmit={handleSubmit(handleForgot)}>
      <div className="flex flex-col space-x-4">
        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <Input
              error={errors.email?.message}
              label="Email"
              placeholder="Your Email"
              required
              type="email"
              {...field}
            />
          )}
        />
      </div>
      <Button disabled={!isValid} type="submit" className="mt-6 w-full">
        Reset Password
      </Button>
    </form>
  );
}
