import { useEffect } from 'react';

import { sendMetric } from './metics-sender';

export const useOpenStoreMetric = () => {
  useEffect(() => {
    sendMetric({ name: 'OPEN' });

    const onVisible = () => {
      if (window.document.visibilityState === 'visible') {
        sendMetric({ name: 'OPEN' });
      }
    };

    window.addEventListener('visibilitychange', onVisible);

    return () => {
      window.removeEventListener('visibilitychange', onVisible);
    };
  }, []);
};
