import { WrapperPlatform } from '@/types/App';

type LocalStorageSchema = {
  refreshToken: string;
};

export const appLocalStorage = {
  getItem: <T extends keyof LocalStorageSchema>(key: T): LocalStorageSchema[T] | null => {
    const item = window.localStorage.getItem(key);
    return item ? (JSON.parse(item) as LocalStorageSchema[T]) : null;
  },
  setItem: <T extends keyof LocalStorageSchema>(key: T, value: LocalStorageSchema[T]): void => {
    window.localStorage.setItem(key, JSON.stringify(value));
  },
  clear: () => localStorage.clear(),
};

type SessionStorageSchema = {
  gameId: string;
  sessionId: string;
  gameUserId: string;
  platform?: WrapperPlatform;
  hideBackToTheGame: boolean;
  stripeKey: string;
  storeIsReady?: boolean;
  welcomeVideoViewed?: boolean;
  exchangeSettings?: {
    gameSourceId: string;
    gameCurrency: string;
    gameExchangeRate: number;
    gameCurrencyImg: string;
  };
};

export const appSessionStorage = {
  getItem: <T extends keyof SessionStorageSchema>(key: T): SessionStorageSchema[T] | null => {
    const item = window.sessionStorage.getItem(key);
    const value = item ? (JSON.parse(item) as SessionStorageSchema[T]) : null;

    if (value) {
      return value;
    }

    const fallbackValue = htmlStorageFallback.getItem(key);
    fallbackValue && appSessionStorage.setItem(key, fallbackValue);
    return fallbackValue;
  },
  setItem: <T extends keyof SessionStorageSchema>(key: T, value: SessionStorageSchema[T]): void => {
    htmlStorageFallback.setItem(key, value);
    window.sessionStorage.setItem(key, JSON.stringify(value));
  },
  clear: () => sessionStorage.clear(),
};

export const htmlStorageFallback = {
  getItem: <T extends keyof SessionStorageSchema>(key: T): SessionStorageSchema[T] | null => {
    const store = document.querySelector('div[data-scuti]')?.getAttribute('data-scuti');
    return store ? JSON.parse(store)[key] : null;
  },
  setItem: <T extends keyof SessionStorageSchema>(key: T, value: SessionStorageSchema[T]): void => {
    const el = document.querySelector('div[data-scuti]');
    let store = el?.getAttribute('data-scuti');
    if (store) {
      const data = JSON.parse(store);
      data[key] = value;
      el?.setAttribute('data-scuti', JSON.stringify(data));
    } else {
      const el = document.createElement('div');
      el.setAttribute('data-scuti', JSON.stringify({ [key]: value }));
      document.body.appendChild(el);
    }
  },
};
