import { unescape } from 'html-escaper';

const removeInlineStyles = (html: string) => {
  const div = document.createElement('div');
  div.innerHTML = html;
  Array.from(div?.querySelectorAll('*')).map((el) => el.removeAttribute('style'));

  return div.innerHTML;
};

export const cleanHtml = (html: string = '') => removeInlineStyles(unescape(html));
