import { useQueryClient } from '@tanstack/react-query';
import { NameAgeFormData } from './NameAgeForm.schema';
import { useMeQuery, useMyNameAndAgeMutation } from '@/graphql/generated';
import { toast } from 'react-toastify';
import { getErrorMessage } from '@/utils/error.utils';

export function useNameAgeForm() {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMyNameAndAgeMutation({
    onSuccess: () => {
      queryClient.refetchQueries(useMeQuery.getKey());
      toast.success('Updated successfully');
    },
    onError: (error) => toast.error(getErrorMessage(error), { autoClose: 8000 }),
  });

  return (data: NameAgeFormData) => {
    return mutateAsync({
      input: {
        fullName: data.fullName,
        gender: data.gender,
        birthDate: `${data.birthYear}-${data.birthDate?.split('/').join('-') || '01-01'}`,
        dateTruncated: !data.birthDate,
        phone: '',
      },
    });
  };
}
