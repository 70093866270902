import { Blurred } from '@/components/Common/Blurred';
import { Button } from '@/components/Common/Button';
import { appRoutes } from '@/helpers/routes.helper';
import { useNavigate } from 'react-router-dom';

export type ConvertScutiFormSuccessProps = {
  convertedCoins: number;
  coinName: string;
};

export function ConvertScutiFormSuccess({ coinName, convertedCoins }: ConvertScutiFormSuccessProps) {
  const navigate = useNavigate();

  return (
    <Blurred>
      <div className="lg:2/5 relative md:w-1/2">
        <div className="flex flex-col items-center space-y-10">
          <img alt="" className="h-20 w-20" src="/images/ScutiCoin.png" />
          <div className="flex flex-col items-center space-y-4">
            <h2 className="text-center font-semibold">
              Your Scuti$ have been successfully converted to {convertedCoins} {coinName}
            </h2>
            <p className="text-secondary-200 mb-7 text-center">
              Your {coinName} will be in your account when you return to your game
            </p>
            <Button variant="primary" className="w-full" onClick={() => navigate(appRoutes.store)}>
              Continue Shopping
            </Button>
          </div>
        </div>
      </div>
    </Blurred>
  );
}
