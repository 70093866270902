import { ProductOffer, ProductOfferOptionKey, ProductVariantOptions } from '@/types/OfferTypes';
import { ProductVariantOptionsMatrix, SelectedOptions } from '@/utils/offer.utils';
import { DropDown } from './DropDown';

export type ProductVariantsProps = {
  className?: string;
  productOffer: ProductOffer;
  selectedOptions: ProductVariantOptions;
  optionsMatrix: ProductVariantOptionsMatrix;
  onSelect: (selectedOptions: SelectedOptions) => void;
};

const options: ProductOfferOptionKey[] = ['option1', 'option2', 'option3'];

export function ProductVariants({ productOffer, selectedOptions, optionsMatrix, onSelect }: ProductVariantsProps) {
  return (
    <div className={`flex w-full space-x-4`}>
      {options
        .filter((v) => !!productOffer[v])
        .map((optionKey) => (
          <div key={optionKey} className="w-1/3">
            <label className="mb-4 truncate text-sm font-semibold text-white">
              {productOffer.optionNames[optionKey]}:
            </label>
            <DropDown
              className="mt-4"
              buttonClassName="w-full"
              options={optionsMatrix[optionKey].map((o) => {
                return {
                  label: o.name!,
                  value: o.name!,
                  disabled: !o.inStock,
                };
              })}
              value={{ label: selectedOptions[optionKey]!, value: selectedOptions[optionKey]! }}
              onSelect={({ value }) =>
                onSelect({ optionKey, options: { ...selectedOptions, [optionKey]: `${value}` } })
              }
            />
          </div>
        ))}
    </div>
  );
}
