import { NavLink } from 'react-router-dom';
import { Welcome } from '@/components/Common/Welcome';
import { WelcomeLayout } from '@/components/Layout/WelcomeLayout';
import { RegistrationForm } from '@/components/Form/RegistrationForm';
import { Icon } from '@/components/Common/Icon';
import { appRoutes } from '@/helpers/routes.helper';
import { Credentials } from '@/types/UserTypes';
import { useState } from 'react';
import { VerifyEmail } from './VerifyEmail';

export function RegistrationPage() {
  const [credentials, setCredentials] = useState<Credentials | undefined>();

  return (
    <WelcomeLayout
      split
      left={
        <>
          <div
            className={`relative flex min-h-full w-full items-center justify-center landscape:items-start md:landscape:items-center`}
          >
            <div className="z-10 flex w-full flex-col justify-center rounded-2xl bg-transparent p-4 md:w-[60%] md:bg-dark-gray/80 md:p-16 md:backdrop-blur-sm">
              <NavLink to={appRoutes.welcome}>
                <div className="flex items-center">
                  <Icon name="ArrowLeft" className="mr-1 h-3" />
                  Back
                </div>
              </NavLink>
              <h1 className="mt-4 font-bold">Welcome to Scuti</h1>
              <p className="mt-4 text-base">Log In to the Shopping Experience</p>
              <div className="-mr- mt-14 pr-6">
                <RegistrationForm onRegistration={setCredentials} />
                <p className="mb-2 mt-8 text-white">
                  Creating an account indicates you agree with the terms of use of the Privacy Policy.
                </p>
                <NavLink to={appRoutes.legalTermsAndConditions}>View Policy</NavLink>
              </div>
            </div>
          </div>
          {credentials && <VerifyEmail credentials={credentials} />}
        </>
      }
      right={
        <div className="flex h-full w-full flex-col items-center justify-center backdrop-brightness-35">
          <Welcome />
        </div>
      }
    />
  );
}
