type Props = {
    className?: string;
    onClick?: () => void;
};

export function Star(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                clipRule="evenodd"
                d="M22.0307 8.25333C21.759 7.4175 21.0157 6.8775 20.1365 6.8775H16.7182C16.5624 6.8775 16.4249 6.7775 16.3765 6.63L15.3182 3.37583C15.0465 2.54 14.304 2 13.4249 2H13.4232C12.544 2 11.8015 2.54 11.529 3.37583L10.9024 5.3075L10.4715 6.63C10.4224 6.7775 10.2857 6.8775 10.1307 6.8775H6.71155C5.83321 6.8775 5.08905 7.4175 4.81738 8.25333C4.54571 9.08917 4.82988 9.96333 5.54155 10.48L8.38238 12.5442C8.46321 12.6033 8.49655 12.7058 8.46738 12.8008L7.76655 14.9583L7.67822 15.2275L7.38071 16.145C7.10905 16.9808 7.39322 17.855 8.10322 18.3717C8.45988 18.63 8.86655 18.7592 9.27405 18.7592C9.68238 18.7592 10.0907 18.6308 10.4465 18.3717L12.4982 16.8817C12.7749 16.68 13.099 16.58 13.424 16.5792C13.749 16.58 14.0732 16.68 14.3499 16.8817L16.4024 18.3717C16.7574 18.6308 17.1657 18.7592 17.5724 18.7592C17.9807 18.7592 18.389 18.63 18.7449 18.3717C19.4557 17.855 19.739 16.9808 19.4682 16.145L19.1691 15.2275L19.0824 14.9583L18.3815 12.8008C18.3524 12.7058 18.3849 12.6033 18.4665 12.5442L21.3074 10.48C22.0182 9.96333 22.3024 9.08917 22.0307 8.25333Z"
                fill="currentColor"
                fillRule="evenodd"
            />
            <path
                clipRule="evenodd"
                d="M3.6671 15.7325C3.72913 15.7325 3.79056 15.7527 3.84408 15.7932L4.23696 16.0889C4.30507 16.1407 4.38292 16.1666 4.46077 16.1666C4.53922 16.1666 4.61707 16.1407 4.68518 16.0889C4.8208 15.9866 4.87493 15.8128 4.82324 15.646L4.76668 15.464L4.74965 15.4102L4.61585 14.9818C4.61038 14.9622 4.61646 14.942 4.63166 14.93L5.17537 14.5199C5.3116 14.4169 5.36573 14.2437 5.31403 14.0769C5.26234 13.9107 5.12003 13.8033 4.95095 13.8033H4.29717C4.26676 13.8033 4.24061 13.7837 4.23148 13.754L4.02957 13.1069C3.97727 12.9407 3.83496 12.8333 3.6671 12.8333C3.49924 12.8333 3.35571 12.9407 3.30402 13.1069L3.1015 13.754C3.09238 13.7837 3.06622 13.8033 3.03642 13.8033H2.38203C2.21356 13.8033 2.07125 13.9107 2.01956 14.0769C1.96725 14.2437 2.02199 14.4169 2.15822 14.5199L2.70132 14.93C2.71713 14.942 2.72321 14.9622 2.71774 14.9818L2.58394 15.4102L2.56691 15.464L2.51035 15.646C2.45805 15.8128 2.51279 15.9866 2.64841 16.0889C2.71713 16.1407 2.79437 16.1666 2.87222 16.1666C2.95067 16.1666 3.02852 16.1407 3.09724 16.0889L3.48951 15.7932C3.54242 15.7527 3.60446 15.7325 3.6671 15.7325Z"
                fill="currentColor"
                fillRule="evenodd"
            />
            <path
                clipRule="evenodd"
                d="M6.78451 19.6364H6.12974C6.10029 19.6364 6.07366 19.6175 6.06524 19.5876L5.98182 19.3246L5.86264 18.9406C5.81006 18.7738 5.66845 18.6667 5.5002 18.6667C5.33265 18.6667 5.18964 18.7738 5.13706 18.9406L5.01719 19.3246L4.93446 19.5876C4.92535 19.6175 4.89941 19.6364 4.86997 19.6364H4.21519C4.04765 19.6364 3.90533 19.7435 3.85276 19.9104C3.80088 20.0765 3.85486 20.2506 3.99156 20.3526L4.53487 20.7636C4.55029 20.7752 4.5566 20.7956 4.55099 20.8146L4.41709 21.2437L4.40097 21.2969L4.34348 21.4798C4.29091 21.6466 4.34559 21.82 4.48159 21.9228C4.54959 21.9738 4.62811 22 4.70592 22C4.78374 22 4.86226 21.9745 4.92956 21.9228L5.32214 21.6262C5.37542 21.5862 5.43781 21.5658 5.5002 21.5658C5.5619 21.5658 5.62429 21.5862 5.67687 21.6262L6.06945 21.9228C6.13745 21.9745 6.21597 22 6.29378 22C6.3716 22 6.44941 21.9738 6.51812 21.9228C6.65412 21.82 6.7088 21.6466 6.65622 21.4798L6.59944 21.2969L6.58261 21.2437L6.44871 20.8146C6.4431 20.7956 6.44871 20.7752 6.46484 20.7636L7.00814 20.3526C7.14485 20.2506 7.19883 20.0765 7.14765 19.9104C7.09507 19.7435 6.95276 19.6364 6.78451 19.6364Z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </svg>
    );
}
