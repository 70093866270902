type Props = {
    className?: string;
    onClick?: () => void;
};

export function Cross(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.49895 6L11.6897 1.80935C12.1034 1.39498 12.1034 0.724479 11.6897 0.310778C11.276 -0.103593 10.6055 -0.103593 10.1918 0.310778L6.00033 4.50142L1.80957 0.310778C1.39519 -0.103593 0.723996 -0.103593 0.310284 0.310778C-0.103428 0.724479 -0.103428 1.39498 0.310284 1.80935L4.50105 6L0.310284 10.1906C-0.103428 10.6043 -0.103428 11.2755 0.310284 11.6892C0.723996 12.1036 1.39519 12.1036 1.80957 11.6892L6.00033 7.4979L10.1918 11.6892C10.6055 12.1036 11.276 12.1036 11.6897 11.6892C12.1034 11.2755 12.1034 10.6043 11.6897 10.1906L7.49895 6Z"
                fill="currentColor"
            />
        </svg>
    );
}
