import { PreferencesForm } from '@/components/Form/PreferencesForm';

export function Preferences() {
  return (
    <div className="flex h-full flex-col pb-8">
      <h2 className="mb-8 font-semibold">Preferences</h2>
      <PreferencesForm className="grow overflow-y-auto pr-4" />
    </div>
  );
}
