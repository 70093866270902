import { Icon } from './Icon';

export type Props = {
  className?: string;
  category: string;
  onLeft: () => void;
  onRight: () => void;
};

export function CategorySwitcher({ className, category, onLeft, onRight }: Props) {
  return (
    <div
      className={`${className} flex flex-row items-center justify-between rounded-full bg-white p-1 shadow-md md:max-w-sm`}
    >
      <button className="p-4" onClick={onLeft}>
        <Icon name="ChevronLeft" className="h-3 w-3 text-black" />
      </button>
      <p className="button w-[200px] text-center font-bold text-black">{category}</p>
      <button className="p-4" onClick={onRight}>
        <Icon name="ChevronRight" className="h-3 w-3 text-black" />
      </button>
    </div>
  );
}
