type Props = {
    className?: string;
    onClick?: () => void;
};

export function Facebook(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                clipRule="evenodd"
                d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12ZM12 6C15.3 6 18 8.7 18 12C18 15 15.825 17.55 12.825 18V13.725H14.25L14.55 12H12.9V10.875C12.9 10.425 13.125 9.975 13.875 9.975H14.625V8.475C14.625 8.475 13.95 8.325 13.275 8.325C11.925 8.325 11.025 9.15 11.025 10.65V12H9.525V13.725H11.025V17.925C8.175 17.475 6 15 6 12C6 8.7 8.7 6 12 6Z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </svg>
    );
}
