type Props = {
    className?: string;
    onClick?: () => void;
};

export function Pause(props: Props) {
    return (
        <svg {...props} fill="none" stroke="currentColor" strokeWidth={3} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.75 5.25v13.5m-7.5-13.5v13.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}
