import { toast } from 'react-toastify';
import { AddressCheckoutFormData } from './AddressCheckoutForm.schema';
import { checkoutStore } from '@/store/checkout.store';
import { appRoutes } from '@/helpers/routes.helper';
import { useNavigate } from 'react-router-dom';

export function useAddressCheckoutForm() {
  const navigate = useNavigate();

  return (shippingAddress: AddressCheckoutFormData) => {
    checkoutStore.actions.setState({ shippingAddress });
    navigate(appRoutes.checkout);
    toast.success('Updated successfully');
  };
}
