import React from 'react';

type Props = {
    className?: string;
    onClick?: () => void;
};

export function Referral(props: Props) {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 12C10.625 12 9.44792 11.5104 8.46875 10.5312C7.48958 9.55208 7 8.375 7 7C7 5.625 7.48958 4.44792 8.46875 3.46875C9.44792 2.48958 10.625 2 12 2C13.375 2 14.5521 2.48958 15.5312 3.46875C16.5104 4.44792 17 5.625 17 7C17 8.375 16.5104 9.55208 15.5312 10.5312C14.5521 11.5104 13.375 12 12 12ZM2 22V18.5C2 17.7917 2.1825 17.1404 2.5475 16.5463C2.91167 15.9529 3.39583 15.5 4 15.1875C5.29167 14.5417 6.60417 14.0571 7.9375 13.7338C9.27083 13.4113 10.625 13.25 12 13.25C13.375 13.25 14.7292 13.4113 16.0625 13.7338C17.3958 14.0571 18.7083 14.5417 20 15.1875C20.6042 15.5 21.0883 15.9529 21.4525 16.5463C21.8175 17.1404 22 17.7917 22 18.5V22H2Z"
                fill="white"
            />
            <path
                d="M21 0.5C21 0.223858 20.7761 0 20.5 0C20.2239 0 20 0.223858 20 0.5V3H17.5C17.2239 3 17 3.22386 17 3.5C17 3.77614 17.2239 4 17.5 4H20V6.5C20 6.77614 20.2239 7 20.5 7C20.7761 7 21 6.77614 21 6.5V4H23.5C23.7761 4 24 3.77614 24 3.5C24 3.22386 23.7761 3 23.5 3L21 3V0.5Z"
                fill="white"
            />
        </svg>
    );
}
