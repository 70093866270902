import { appRoutes } from '@/helpers/routes.helper';
import { useScreenSize } from '@/hooks/useScreenSize';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { matchPath, useLocation } from 'react-router-dom';

export function useAccountSettingsMenu() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return useMemo(() => {
    return [
      {
        title: 'Edit Name & Age',
        selected: !!matchPath(appRoutes.accountSettingsNameAndAge, pathname),
        onClick: () => navigate(appRoutes.accountSettingsNameAndAge),
      },
      {
        title: 'Edit Password',
        selected: !!matchPath(appRoutes.accountSettingsPassword, pathname),
        onClick: () => navigate(appRoutes.accountSettingsPassword),
      },
      {
        title: 'Edit Address',
        selected: !!matchPath(appRoutes.accountSettingsAddress, pathname),
        onClick: () => navigate(appRoutes.accountSettingsAddress),
      },
      {
        title: 'Manage Preferences',
        selected: !!matchPath(appRoutes.accountSettingsPreferences, pathname),
        onClick: () => navigate(appRoutes.accountSettingsPreferences),
      },
    ];
  }, [navigate, pathname]);
}

export function useSideStyles() {
  const { pathname } = useLocation();

  const screenSize = useScreenSize();

  const isRoot = !!matchPath(appRoutes.accountSettings, pathname);
  const isMobile = ['xs', 'sm'].some((size) => size === screenSize);

  return useMemo(() => {
    return {
      left: isMobile ? (isRoot ? 'relative w-full' : 'hidden') : undefined,
      right: isMobile ? (isRoot ? 'hidden' : 'block') : undefined,
    };
  }, [isMobile, isRoot]);
}
