type Props = {
    className?: string;
    onClick?: () => void;
};

export function ScutiCoinRecessed(props: Props) {
    return (
        <svg {...props} fill="none" viewBox="0 0 138 138" xmlns="http://www.w3.org/2000/svg">
            <circle cx="69" cy="69" fill="#1B1B22" r="68.6406" stroke="#101015" strokeWidth="0.71875" />
            <circle cx="69.0259" cy="69.2602" fill="url(#paint0_linear_2701_9300)" r="66.0259" />
            <path
                d="M69 109C91.0914 109 109 91.0914 109 69C109 46.9086 91.0914 29 69 29C46.9086 29 29 46.9086 29 69C29 91.0914 46.9086 109 69 109Z"
                fill="#D9E3E8"
            />
            <path
                d="M69 109C91.0914 109 109 91.0914 109 69C109 46.9086 91.0914 29 69 29C46.9086 29 29 46.9086 29 69C29 91.0914 46.9086 109 69 109Z"
                fill="url(#paint1_linear_2701_9300)"
            />
            <path
                clipRule="evenodd"
                d="M68.9994 31.2428C61.5317 31.2428 54.2318 33.4572 48.0226 37.6061C41.8135 41.7549 36.9741 47.6517 34.1163 54.551C31.2586 61.4502 30.5108 69.0419 31.9677 76.3661C33.4246 83.6903 37.0206 90.418 42.301 95.6984C47.5815 100.979 54.3092 104.575 61.6333 106.032C68.9575 107.489 76.5492 106.741 83.4485 103.883C90.3477 101.025 96.2446 96.186 100.393 89.9768C104.542 83.7677 106.757 76.4677 106.757 69C106.756 64.0421 105.779 59.1328 103.881 54.5524C101.984 49.9721 99.2025 45.8104 95.6964 42.3049C92.1903 38.7995 88.0281 36.0189 83.4474 34.122C79.0721 32.3102 74.397 31.3384 69.6661 31.2553V31.2428H68.9994ZM55.5754 36.5888C59.8303 34.8267 64.3923 33.9256 68.9977 33.9377C77.1078 33.9385 84.9685 36.7513 91.2373 41.8969C97.5061 47.0425 101.797 54.2025 103.378 62.157C104.96 70.1114 103.735 78.3682 99.9111 85.5205C96.0876 92.6728 89.9024 98.2781 82.4094 101.381C74.9164 104.485 66.5792 104.894 58.8184 102.539C51.0575 100.185 44.3531 95.2119 39.8475 88.4684C35.3419 81.7249 33.3139 73.6278 34.109 65.5567C34.9041 57.4856 38.473 49.9399 44.2078 44.2051C47.4561 40.9405 51.3206 38.351 55.5754 36.5888Z"
                fill="#92B0BF"
                fillRule="evenodd"
            />
            <path
                d="M69.0013 45.5747C71.2753 45.5747 73.1955 46.9654 73.8981 49.1166L75.5181 54.09L76.6265 57.4946C76.6883 57.6796 76.8067 57.8406 76.9648 57.9547C77.123 58.0688 77.3131 58.1304 77.5081 58.1307H86.3432C88.6146 58.1307 90.5348 59.5205 91.2373 61.6726C91.9399 63.8246 91.205 66.073 89.3684 67.4032L82.0322 72.716C81.9306 72.7884 81.8547 72.8914 81.8156 73.0099C81.7765 73.1284 81.7762 73.2564 81.8148 73.3751L83.625 78.9274L83.8509 79.6198L84.6183 81.9816C85.3209 84.1354 84.5876 86.3846 82.7485 87.7148C81.8721 88.3592 80.8136 88.7085 79.7258 88.7124C78.6363 88.7094 77.576 88.36 76.6981 87.7148L71.3973 83.8779C70.7009 83.3731 69.8631 83.101 69.003 83.1003C68.1432 83.1003 67.3054 83.3725 66.6097 83.8779L61.3088 87.7148C60.4307 88.3596 59.3705 88.7089 58.2811 88.7124C57.1925 88.7086 56.1332 88.3593 55.2559 87.7148C53.4193 86.3846 52.6852 84.1337 53.3869 81.9816L54.1543 79.6198L54.3794 78.9274L56.1921 73.3751C56.2291 73.2562 56.228 73.1288 56.1891 73.0107C56.1501 72.8925 56.0751 72.7894 55.9747 72.716L48.6318 67.4006C46.7943 66.0705 46.061 63.8195 46.7619 61.67C47.4628 59.5205 49.3855 58.1281 51.6569 58.1281H60.4894C60.6846 58.1282 60.8748 58.0668 61.0331 57.9526C61.1913 57.8384 61.3096 57.6773 61.371 57.4921L62.4795 54.0875L64.0995 49.114C64.8089 46.9662 66.7299 45.5747 69.0013 45.5747Z"
                fill="#00C0E9"
            />
            <defs>
                <linearGradient gradientUnits="userSpaceOnUse" id="paint0_linear_2701_9300" x1="69.0259" x2="69.0259" y1="3.23437" y2="121.605">
                    <stop offset="0.447917" stopColor="#101015" />
                    <stop offset="1" stopColor="#101015" stopOpacity="0" />
                </linearGradient>
                <linearGradient gradientUnits="userSpaceOnUse" id="paint1_linear_2701_9300" x1="98.2994" x2="41.2293" y1="96.2611" y2="40.2102">
                    <stop stopColor="#D9E3E8" />
                    <stop offset="0.594915" stopColor="white" />
                </linearGradient>
            </defs>
        </svg>
    );
}
