import * as yup from 'yup';

export const schema = yup
  .object({
    address1: yup.string().required('Field is required'),
    address2: yup.string().nullable(),
    phone: yup
      .string()
      // .when('country', {
      //   is: 'US',
      //   then: (phone) =>
      //     phone.test('phone', 'Phone number is not correct', (phone) => (phone ? isMobilePhone(phone, 'en-US') : true)),
      //   otherwise: (phone) =>
      //     phone.test('phone', 'Phone number is not correct', (phone) => (phone ? isMobilePhone(phone) : true)),
      // })
      .required('Field is required'),
    country: yup.string().required('Field is required'),
    city: yup.string().required('Field is required'),
    state: yup.string().nullable(),
    zipCode: yup.string().required('Field is required'),
  })
  .required('Field is required');

export type AddressCheckoutFormData = yup.InferType<typeof schema>;
