import { useStoreInfoQuery } from '@/graphql/generated';
import { appSessionStorage } from '@/helpers/storage.helper';

export type StoreInfo = {
  game: {
    id: string;
    currency?: { name: string; thumbnail?: string | null; exchangeRate: number; scutiExchangeRate: number } | null;
  };
  platform: {
    scutiExchangeRate: number;
    stripeKey: string;
  };
};

const defaults: StoreInfo = {
  game: {
    id: '',
    currency: null,
  },
  platform: {
    scutiExchangeRate: 0,
    stripeKey: '',
  },
};

export function useStoreInfo(): StoreInfo {
  const id = appSessionStorage.getItem('gameId')!;
  const { data } = useStoreInfoQuery({ id }, { cacheTime: Infinity });

  if (!data) return defaults;

  const { gameInfo, platformSettings, stripeSettings } = data;

  return {
    game: {
      id: gameInfo?.id || '',
      currency: gameInfo?.currency
        ? {
            name: gameInfo.currency.name,
            thumbnail: gameInfo.currency.thumbnail,
            exchangeRate: gameInfo.currency.exchangeRate,
            scutiExchangeRate: gameInfo.currency.scutiExchangeRate,
          }
        : null,
    },
    platform: {
      scutiExchangeRate: platformSettings.scutiExchangeRate,
      stripeKey: stripeSettings.publicKey,
    },
  };
}
