type Props = {
  className?: string;
  onClick?: () => void;
};

export function History(props: Props) {
  return (
    <svg {...props} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M19.664 5.635C20.4862 6.45501 21.141 7.42705 21.592 8.497C22.0617 9.60502 22.3026 10.7966 22.3 12C22.3023 13.7937 21.7665 15.5469 20.762 17.033C19.7861 18.4803 18.4107 19.6128 16.803 20.293C15.694 20.7592 14.5031 20.9995 13.3 21C12.1182 21.0007 10.9478 20.7682 9.85595 20.3159C8.76408 19.8636 7.77217 19.2003 6.93705 18.364L8.35105 16.95C8.66876 17.2673 9.016 17.5536 9.38805 17.805C10.1445 18.3168 10.9943 18.6746 11.889 18.858C12.8206 19.0465 13.7805 19.0465 14.712 18.858C15.1621 18.7655 15.6019 18.6289 16.025 18.45C16.8559 18.0966 17.6112 17.587 18.25 16.949C18.567 16.633 18.854 16.284 19.104 15.913C19.6169 15.1563 19.9751 14.3056 20.158 13.41C20.3467 12.4781 20.3467 11.5179 20.158 10.586C19.783 8.7677 18.7014 7.17271 17.1508 6.15167C15.6002 5.13063 13.7076 4.7671 11.889 5.141C10.9942 5.3264 10.144 5.68441 9.38605 6.195C8.641 6.69952 7.99917 7.34169 7.49505 8.087C6.71351 9.24207 6.29717 10.6054 6.30005 12L6.30105 12.025H8.30005L5.30005 16L2.30005 12.025H4.30105L4.30005 12C4.29837 10.7963 4.53916 9.60461 5.00805 8.496C5.46138 7.42786 6.11594 6.45689 6.93605 5.636C8.17952 4.389 9.76272 3.53584 11.488 3.183C12.6845 2.93949 13.9176 2.93949 15.114 3.183C16.8378 3.5381 18.4196 4.39057 19.664 5.635ZM12.3 13V8H14.3V11H17.3V13H12.3Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
