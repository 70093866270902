import { useQueryClient } from '@tanstack/react-query';
import { Navigate } from 'react-router-dom';
import { MeQuery, useMeQuery } from '@/graphql/generated';
import { appRoutes } from '@/helpers/routes.helper';

interface ProtectedRouteProps {
  component: JSX.Element;
  needAuthorization?: boolean;
}

export function ProtectedRoute({ component: Component, needAuthorization }: ProtectedRouteProps) {
  const client = useQueryClient();
  const me = client.getQueryData<MeQuery>(useMeQuery.getKey())?.me;

  if (needAuthorization) return me ? Component : <Navigate to={appRoutes.welcome} />;
  return !me ? Component : <Navigate to={appRoutes.store} />;
}
