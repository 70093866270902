import { useParams } from 'react-router-dom';
import { useCampaignOffer } from './CampaignOfferPage.hooks';
import { useEngagementProductMetric } from '@/helpers/metrics/useEngagementProductMetric';

export function CampaignOfferPage() {
  const { id } = useParams<{ id: string }>();

  useEngagementProductMetric(id!);

  const campaign = useCampaignOffer(id!);

  return campaign && <iframe className="h-full w-full pt-4 md:pt-8" src={campaign.videoUrl} title={campaign.name} />;
}
