import { FilterInput, Paging, PagingInput } from '@/graphql/generated';
import { fetcherRest } from '@/helpers/fetch.helper';
import { OfferListItemServerType } from '@/types/OfferTypes';
import { useQuery } from '@tanstack/react-query';

type InputType = {
  seed?: number;
  filters?: FilterInput[];
  paging?: PagingInput;
};

export const fetchOffers = async (input?: InputType): Promise<{ paging: Paging; nodes: OfferListItemServerType[] }> =>
  fetcherRest({ url: `/offers/search`, method: 'POST' }, input);

export function useOffers(input?: InputType) {
  return useQuery({ queryKey: ['offers', input], queryFn: () => fetchOffers(input) });
}
