import { PaymentForm } from './PaymentForm';
import { useStoreInfo } from '@/hooks/useStoreInfo';
import { getStripeOptions } from '@/utils/stipe.utls';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useMemo } from 'react';

type VuplexPaymentPageProps = {
  stripeKey: string;
};

export function VuplexPaymentPage({ stripeKey }: VuplexPaymentPageProps) {
  const { platform } = useStoreInfo();
  const stripe = useMemo(() => {
    if (platform.stripeKey) return loadStripe(platform.stripeKey);
    else return null;
  }, [platform.stripeKey]);

  const options = useMemo(() => getStripeOptions(stripeKey), [stripeKey]);

  return stripe ? (
    <Elements stripe={stripe} options={options}>
      <div className="flex h-full w-screen flex-col bg-dark-gray">
        <div className="flex h-full flex-col items-center px-2 pt-4 md:pt-8">
          <div className="flex-grow-1 flex h-full w-full flex-col items-center">
            <PaymentForm />
          </div>
          <div className="h-4 lg:h-8" />
        </div>
      </div>
    </Elements>
  ) : null;
}
