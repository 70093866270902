import { CheckoutAddress } from '@/components/Common/CheckoutAddress';
import { CheckoutDetails } from '@/components/Common/CheckoutDetails';
import { useCheckout } from './CheckoutPage.hooks';
import { Button } from '@/components/Common/Button';
import { BlockReason } from './BlockReason';
import { Disclosure } from '@/components/Common/Disclosure';
import { CartType, cartStore } from '@/store/cart.store';
import { CartItem } from '@/components/Common/CartItem';
import { useSnapshot } from 'valtio';
import { useNavigate } from 'react-router-dom';

export function CheckoutPage() {
  const { products } = useSnapshot(cartStore.state) as CartType;
  const { details, shippingAddress, blockReason, handleCheckout } = useCheckout();
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center pt-4 md:pt-8">
      <div>
        <h1 className="mb-6 text-center font-semibold">Checkout</h1>
      </div>
      <div className="-mr-4 w-full space-y-4 self-center pb-24 pr-4 md:w-[100%] lg:flex lg:space-x-4 lg:pb-0">
        <div className="w-12/12 -mr-4 overflow-y-auto pr-4 lg:w-9/12">
          <div className="mb-4 flex w-full items-center justify-between">
            <div className="flex items-center space-x-2">
              <div className="text-light-gray">Review Order</div>
            </div>
          </div>
          <div className="flex flex-col space-y-2">
            <CheckoutDetails details={details} />
            <CheckoutAddress shippingInfo={shippingAddress} />
            <div className="hidden lg:block">
              <Disclosure className="border-b-0" title="Order Details" defaultOpen>
                <div className="flex flex-col space-y-2">
                  {Object.values(products).map((cartItem) => (
                    <CartItem cartItem={cartItem} key={cartItem.product.productId} viewOnly />
                  ))}
                </div>
              </Disclosure>
            </div>
          </div>
        </div>
        <div className="w-12/12 lg:w-3/12">
          <div className="lg:h-6"></div>
          {blockReason && <BlockReason className="mb-4 block lg:hidden" reason={blockReason} />}
          <div className="flex flex-col space-y-4 bg-darker-gray p-8 md:rounded-2xl">
            <Button className="w-full" disabled={!!blockReason} onClick={handleCheckout}>
              Complete Order
            </Button>
            <Button className="w-full" onClick={() => navigate(-1)} variant="secondary">
              Back
            </Button>
          </div>
          {blockReason && <BlockReason className="mt-4 hidden lg:block" reason={blockReason} />}
          <div className="mt-4 block lg:hidden">
            <Disclosure className="border-b-0" title="Order Details" defaultOpen>
              <div className="flex flex-col space-y-2">
                {Object.values(products).map((cartItem) => (
                  <CartItem cartItem={cartItem} key={cartItem.product.productId} viewOnly />
                ))}
              </div>
            </Disclosure>
          </div>
        </div>
      </div>
    </div>
  );
}
