import { useStoreInfo } from '@/hooks/useStoreInfo';
import { useExchangeMutation, useMyWalletQuery } from '@/graphql/generated';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getErrorMessage } from '@/utils/error.utils';
import { sendMessage } from '@/helpers/sdk-wrapper';
import { appSessionStorage } from '@/helpers/storage.helper';
import { useQueryClient } from '@tanstack/react-query';

export function useConvertScuti() {
  const queryClient = useQueryClient();
  const { game, platform } = useStoreInfo();
  const { data } = useMyWalletQuery(
    {},
    { refetchOnWindowFocus: 'always', refetchOnMount: 'always', staleTime: 0, cacheTime: 0 },
  );
  const exchangeSettings = appSessionStorage.getItem('exchangeSettings');

  const coinImage = exchangeSettings?.gameCurrencyImg || game.currency?.thumbnail;
  const exchangeRate = exchangeSettings?.gameExchangeRate || game.currency?.exchangeRate || 0;
  const coinName = exchangeSettings?.gameCurrency || game.currency?.name || '';

  const gameCoinScutiEquivalent = (exchangeRate ?? 0) / (platform.scutiExchangeRate ?? 1);
  const [transactionStatus, setTransactionStatus] = useState<'inProgress' | 'Success'>();

  const [usedScutis, setUsedScuties] = useState(0);
  const [gameCoinsValue, setGameCoinsValue] = useState(0);

  useEffect(() => {
    setGameCoinsValue(Math.floor(usedScutis * gameCoinScutiEquivalent));
  }, [gameCoinScutiEquivalent, usedScutis]);

  const baseExchangeRate = exchangeRate;
  const exchangeRateFactor = Math.floor(baseExchangeRate / gameCoinScutiEquivalent);

  const useExchange = useExchangeMutation();

  return {
    coinImage,
    coinName,
    usedScutis,
    gameCoinsValue,
    transactionStatus,
    scutiBalance: data ? data.myWallet.promotional + data.myWallet.purchase : 0,
    scutisPerOneGameCoin: Math.ceil(exchangeRateFactor / baseExchangeRate),
    onChange: setUsedScuties,
    onExchange: async () => {
      const gameUserId = appSessionStorage.getItem('gameUserId');
      if (!gameUserId) return;

      setTransactionStatus('inProgress');

      return await useExchange.mutateAsync(
        {
          gameId: game.id,
          amount: usedScutis,
          gameUserId: `${gameUserId}`,
        },
        {
          onSuccess: async ({ exchange }) => {
            setTransactionStatus('Success');

            await queryClient.invalidateQueries({
              queryKey: useMyWalletQuery.getKey(),
            });

            sendMessage({ message: 'SCUTI_EXCHANGE', payload: exchange });
          },
          onError: (error) => {
            toast.error(getErrorMessage(error), { autoClose: 8000 });
            setTransactionStatus(undefined);
          },
        },
      );
    },
  };
}
