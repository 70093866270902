import { QueryClient } from '@tanstack/react-query';
import { NonIndexRouteObject } from 'react-router-dom';
import { useAppDownloadOfferQuery } from '@/graphql/generated';
import { fetcherGraphql } from '@/helpers/fetch.helper';

export const appDownloadLoader =
  (queryClient: QueryClient): NonIndexRouteObject['loader'] =>
  async ({ params }) => {
    const id = params.id!;
    return await queryClient.ensureQueryData({
      queryFn: fetcherGraphql(useAppDownloadOfferQuery.document, { id }),
      queryKey: useAppDownloadOfferQuery.getKey({ id }),
    });
  };
