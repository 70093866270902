import { SDKReceiveMessage, SDKSendMessage } from './types';
import * as Sentry from '@sentry/react';
import { appSessionStorage } from '../storage.helper';
import { WrapperPlatform } from '@/types/App';

export const messageSender = (message: SDKReceiveMessage | SDKSendMessage) => {
  const platform = appSessionStorage.getItem('platform') as WrapperPlatform;

  if (platform === 'Unity') {
    window.Unity.call(JSON.stringify(message));
  }
  if (platform === 'Vuplex') {
    window.vuplex.postMessage(message, '*');
  }
  if ((['WebGL', 'iFrame', 'Unreal'] as WrapperPlatform[]).includes(platform)) {
    window.parent.postMessage(message, '*');
  }
  if (platform === 'Unreal') console.log('S4UE', JSON.stringify(message));
};

export const sendMessage = (message: SDKSendMessage) => {
  try {
    messageSender(message);
  } catch (e) {
    Sentry.captureException(e);
  }
};
