import { forwardRef, ReactNode } from 'react';
import clsx from 'clsx';

interface ModalProps {
  className?: string;
  children: ReactNode;
  itemAlignStart?: boolean;
}

export const Modal = forwardRef<HTMLDivElement, ModalProps>((props, ref) => {
  const { className, children, itemAlignStart } = props;

  return (
    <div ref={ref} className="relative z-10 h-full" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="fixed bottom-0 left-0 right-0 top-0 flex overflow-y-auto backdrop-brightness-50 md:backdrop-brightness-50">
        <div className={clsx('flex w-full justify-center', itemAlignStart ? 'items-start' : 'items-center')}>
          <div className={clsx(`relative flex rounded-2xl bg-dark-gray/95 p-4 md:backdrop-blur-sm`, className)}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
});
