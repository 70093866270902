import { QueryClient } from '@tanstack/react-query';
import { NonIndexRouteObject, redirect } from 'react-router-dom';
import { MyCartQuery, useMyCartQuery } from '@/graphql/generated';
import { fetcherGraphql } from '@/helpers/fetch.helper';
import { cartStore } from '@/store/cart.store';
import { appLocalStorage } from '@/helpers/storage.helper';
import { appRoutes } from '@/helpers/routes.helper';

const queryFn = fetcherGraphql<MyCartQuery, {}>(useMyCartQuery.document);
const queryKey = useMyCartQuery.getKey();

export const cartLoader =
  (queryClient: QueryClient): NonIndexRouteObject['loader'] =>
  async () => {
    if (!appLocalStorage.getItem('refreshToken')) {
      return redirect(appRoutes.welcome);
    }

    await queryClient.refetchQueries({ queryKey });
    const data = await queryClient.ensureQueryData({
      queryFn,
      queryKey,
    });
    cartStore.actions.setCart(data?.myCart.products);
    return data;
  };
