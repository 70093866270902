import { AddressForm } from '@/components/Form/AddressForm';

export function Address() {
  return (
    <div className="-mr-4 flex h-full flex-col overflow-y-auto pb-8 pr-4">
      <h2 className="font-semibold">Edit Address</h2>
      <AddressForm />
    </div>
  );
}
