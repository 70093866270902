import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAppDownloadOffer } from './AppDownloadPage.hooks';
import { useEngagementProductMetric } from '@/helpers/metrics/useEngagementProductMetric';
import { useEffect } from 'react';
import { Platform, getPlatform } from '@/utils/platform.utils';
import { appRoutes } from '@/helpers/routes.helper';

export function AppDownloadPage() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const platform = getPlatform();
  useEngagementProductMetric(id!);

  const campaign = useAppDownloadOffer(id!);

  useEffect(() => {
    if (!campaign?.videoUrl) return;

    if ([Platform.Android, Platform.Ios].includes(platform)) {
      window.location.href = campaign.videoUrl + window.location.search;
    } else {
      navigate(appRoutes.campaignOffer(id));
    }
  }, [campaign, navigate, id, platform]);

  return (
    <div className="grid h-full place-items-center">
      <Link to={campaign?.videoUrl || '#'}>{campaign?.name}</Link>
    </div>
  );
}
