import { freshToken } from '@/helpers/freshToken.helper';
import { queryClient } from '@/helpers/query.helper';
import { messageSender } from '@/helpers/sdk-wrapper/wrapper-sender';
import { appLocalStorage, appSessionStorage } from '@/helpers/storage.helper';
import { cartStore } from '@/store/cart.store';

export const clearSession = () => {
  const platform = appSessionStorage.getItem('platform');
  if (platform) messageSender({ message: 'LOG_OUT' });

  cartStore.actions.resetState();
  appLocalStorage.clear();
  queryClient.clear();
  freshToken.setTokens({});
};
