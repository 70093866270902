type Props = {
    className?: string;
    onClick?: () => void;
};

export function Discord(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                clipRule="evenodd"
                d="M13.308 14.1C13.488 14.328 13.704 14.586 13.704 14.586C15.03 14.544 15.54 13.674 15.54 13.674C15.54 11.742 14.676 10.176 14.676 10.176C13.812 9.528 12.99 9.546 12.99 9.546L12.906 9.642C13.926 9.954 14.4 10.404 14.4 10.404C13.776 10.062 13.164 9.894 12.594 9.828C12.162 9.78 11.748 9.792 11.382 9.84C11.3506 9.84 11.3237 9.84457 11.2934 9.84973L11.28 9.852C11.07 9.87 10.56 9.948 9.918 10.23C9.696 10.332 9.564 10.404 9.564 10.404C9.564 10.404 10.062 9.93 11.142 9.618L11.082 9.546C11.082 9.546 10.26 9.528 9.396 10.176C9.396 10.176 8.532 11.742 8.532 13.674C8.532 13.674 9.036 14.544 10.362 14.586C10.362 14.586 10.584 14.316 10.764 14.088C10.002 13.86 9.714 13.38 9.714 13.38C9.714 13.38 9.774 13.422 9.882 13.482C9.888 13.488 9.894 13.494 9.906 13.5C9.915 13.506 9.924 13.5105 9.933 13.515C9.942 13.5195 9.951 13.524 9.96 13.53C10.11 13.614 10.26 13.68 10.398 13.734C10.644 13.83 10.938 13.926 11.28 13.992C11.73 14.076 12.258 14.106 12.834 13.998C13.116 13.95 13.404 13.866 13.704 13.74C13.914 13.662 14.148 13.548 14.394 13.386C14.394 13.386 14.094 13.878 13.308 14.1ZM10.302 12.45C10.302 12.084 10.572 11.784 10.914 11.784C11.256 11.784 11.532 12.084 11.526 12.45C11.526 12.816 11.256 13.116 10.914 13.116C10.578 13.116 10.302 12.816 10.302 12.45ZM12.492 12.45C12.492 12.084 12.762 11.784 13.104 11.784C13.446 11.784 13.716 12.084 13.716 12.45C13.716 12.816 13.446 13.116 13.104 13.116C12.768 13.116 12.492 12.816 12.492 12.45Z"
                fill="currentColor"
                fillRule="evenodd"
            />
            <path
                clipRule="evenodd"
                d="M12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0ZM6.75 7.986C6.75 7.302 7.302 6.75 7.98 6.75H16.02C16.698 6.75 17.25 7.302 17.25 7.986V18.75L15.96 17.61L15.234 16.938L14.466 16.224L14.784 17.334H7.98C7.302 17.334 6.75 16.782 6.75 16.098V7.986Z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </svg>
    );
}
