type Props = {
    className?: string;
    onClick?: () => void;
};

export function Information(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                clipRule="evenodd"
                d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12ZM10.6135 18V10.1281L13.3705 10.1281L13.3705 18H10.6135ZM13.4689 6.9526C13.5564 7.15316 13.6002 7.36486 13.6002 7.5877C13.6002 7.7994 13.5564 7.99996 13.4689 8.18937C13.3923 8.38993 13.2775 8.55706 13.1243 8.69077C12.9821 8.83561 12.8125 8.94703 12.6156 9.02503C12.4187 9.11416 12.2108 9.15873 11.992 9.15873C11.7732 9.15873 11.5653 9.11416 11.3684 9.02503C11.1715 8.94703 11.0019 8.83561 10.8597 8.69077C10.7175 8.54592 10.6026 8.37322 10.5151 8.17266C10.4385 7.98325 10.4002 7.78269 10.4002 7.57099C10.4002 7.34815 10.444 7.14202 10.5315 6.9526C10.619 6.76319 10.7339 6.59606 10.8761 6.45121C11.0183 6.30637 11.1824 6.19494 11.3684 6.11695C11.5653 6.03895 11.7732 5.99996 11.992 5.99996C12.2108 5.99996 12.4187 6.04453 12.6156 6.13366C12.8125 6.21166 12.9821 6.31751 13.1243 6.45121C13.2775 6.59606 13.3923 6.76319 13.4689 6.9526Z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </svg>
    );
}
