import { FilterInput, Filter_Type, OrderStatus } from '@/graphql/generated';
import { UserCartItem } from '@/types/CartTypes';
import { ProductOffer } from '@/types/OfferTypes';
import { OrderItem, OrderListItem } from '@/types/OrderTypes';

export const mapOrderTypeToFilter = (type: 'active' | 'past'): FilterInput => {
  if (type === `active`) {
    return { name: 'status', operator: Filter_Type.In, value: [OrderStatus.Pending, OrderStatus.Shipping] };
  }
  return { name: 'status', operator: Filter_Type.In, value: [OrderStatus.Cancelled, OrderStatus.Completed] };
};

export const getFirstOrderItemFromOrder = (orderListItem: OrderListItem): OrderItem => {
  const firstItem = orderListItem.items[0];
  return { ...firstItem, createdAt: orderListItem.createdAt, id: orderListItem.id };
};

export const mapProductOfferToUserCartProduct = (
  productOffer: ProductOffer,
  quantity: number = 1,
  scutiExchangeRate: number = 1000,
): UserCartItem['product'] => {
  return {
    images: productOffer.images,
    option1: productOffer.optionNames.option1,
    option2: productOffer.optionNames.option2,
    option3: productOffer.optionNames.option3,
    productId: productOffer.id,
    productName: productOffer.name,
    reward: {
      scutis: (productOffer.reward || 0) * scutiExchangeRate,
    },
    variant: {
      variantId: productOffer.productId,
      image: productOffer.images[0] || productOffer.images[1],
      offerId: productOffer.id,
      inStock: productOffer.inStock,
      option1: productOffer.option1,
      option2: productOffer.option2,
      option3: productOffer.option3,
      price: productOffer.price.current,
      quantity,
    },
  };
};
