type Props = {
    className?: string;
    onClick?: () => void;
};

export function Trash(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.2002 6.00138H16.2002V4.33138C16.1767 3.6912 15.9004 3.08644 15.4318 2.64967C14.9631 2.2129 14.3404 1.97977 13.7002 2.00138H10.7002C10.0599 1.97977 9.43726 2.2129 8.96862 2.64967C8.49999 3.08644 8.22366 3.6912 8.2002 4.33138V6.00138H3.2002C2.93498 6.00138 2.68062 6.10674 2.49309 6.29427C2.30555 6.48181 2.2002 6.73616 2.2002 7.00138C2.2002 7.26659 2.30555 7.52095 2.49309 7.70849C2.68062 7.89602 2.93498 8.00138 3.2002 8.00138H4.2002V19.0014C4.2002 19.797 4.51627 20.5601 5.07888 21.1227C5.64148 21.6853 6.40455 22.0014 7.2002 22.0014H17.2002C17.9958 22.0014 18.7589 21.6853 19.3215 21.1227C19.8841 20.5601 20.2002 19.797 20.2002 19.0014V8.00138H21.2002C21.4654 8.00138 21.7198 7.89602 21.9073 7.70849C22.0948 7.52095 22.2002 7.26659 22.2002 7.00138C22.2002 6.73616 22.0948 6.48181 21.9073 6.29427C21.7198 6.10674 21.4654 6.00138 21.2002 6.00138ZM10.2002 16.0014C10.2002 16.2666 10.0948 16.5209 9.9073 16.7085C9.71977 16.896 9.46541 17.0014 9.2002 17.0014C8.93498 17.0014 8.68062 16.896 8.49309 16.7085C8.30555 16.5209 8.2002 16.2666 8.2002 16.0014V12.0014C8.2002 11.7362 8.30555 11.4818 8.49309 11.2943C8.68062 11.1067 8.93498 11.0014 9.2002 11.0014C9.46541 11.0014 9.71977 11.1067 9.9073 11.2943C10.0948 11.4818 10.2002 11.7362 10.2002 12.0014V16.0014ZM10.2002 4.33138C10.2002 4.17138 10.4102 4.00138 10.7002 4.00138H13.7002C13.9902 4.00138 14.2002 4.17138 14.2002 4.33138V6.00138H10.2002V4.33138ZM16.2002 16.0014C16.2002 16.2666 16.0948 16.5209 15.9073 16.7085C15.7198 16.896 15.4654 17.0014 15.2002 17.0014C14.935 17.0014 14.6806 16.896 14.4931 16.7085C14.3056 16.5209 14.2002 16.2666 14.2002 16.0014V12.0014C14.2002 11.7362 14.3056 11.4818 14.4931 11.2943C14.6806 11.1067 14.935 11.0014 15.2002 11.0014C15.4654 11.0014 15.7198 11.1067 15.9073 11.2943C16.0948 11.4818 16.2002 11.7362 16.2002 12.0014V16.0014Z"
                fill="currentColor"
            />
        </svg>
    );
}
