import { Tabs } from '@/components/Common/Tabs';
import { useMyOrders, useTabs } from './OrdersHistoryPage.hooks';
import { OrderCard } from '@/components/Common/OrderCard';
import { ScrollLoader } from '@/components/Common/ScrollLoader';
import { EmptyOrders } from './EmptyOrders';
import { getFirstOrderItemFromOrder } from '@/utils/order.utils';

export function OrdersHistoryPage() {
  const tabs = useTabs();
  const { orders, hasNext, onLoadNext, isLoading } = useMyOrders();

  return (
    <div className="flex flex-col pt-4 md:pt-8">
      <div>
        <h1 className="mb-8 text-center font-semibold">My Orders</h1>
        <Tabs items={tabs} />
        <div className="h-[1px] bg-black"></div>
      </div>
      <div className="flex grow flex-col overflow-y-auto">
        {!orders.length && <EmptyOrders />}
        {!!orders.length && (
          <div>
            <div className="mt-4 flex flex-col items-center space-y-2">
              {orders.map((order) => (
                <OrderCard key={order.id} orderItem={getFirstOrderItemFromOrder(order)} className="w-full lg:w-[50%]" />
              ))}
            </div>
            {hasNext && <ScrollLoader isLoading={isLoading} onLoad={onLoadNext} />}
          </div>
        )}
      </div>
    </div>
  );
}
