import { Disclosure as DisclosureBase } from '@headlessui/react';
import { ReactNode } from 'react';
import { Icon } from '../Icon';

export type DisclosureNoBordersProps = {
  title: string;
  children: ReactNode;
  defaultOpen?: boolean;
};

export function DisclosureNoBorders({ title, children, defaultOpen }: DisclosureNoBordersProps) {
  return (
    <DisclosureBase defaultOpen={defaultOpen}>
      <DisclosureBase.Button className=" w-full">
        {({ open }) => (
          <div className="flex flex-row items-center justify-between py-4">
            <h3 className="text-left font-bold">{title}</h3>
            <Icon name={open ? 'Minus' : 'Plus'} className="h-3" />
          </div>
        )}
      </DisclosureBase.Button>
      <DisclosureBase.Panel className="pb-4">{children}</DisclosureBase.Panel>
    </DisclosureBase>
  );
}
