import { useMemo } from 'react';
import { useLoaderData } from 'react-router-dom';
import { ProductOfferQuery, useProductOfferQuery } from '@/graphql/generated';
import { ProductOffer, productOfferDefaults, ProductVariantOptions } from '@/types/OfferTypes';
import {
  getDefaultVariantOptions,
  mapOfferToOptionsMatrix,
  mapProductOffer,
  ProductVariantOptionsMatrix,
  SelectedOptions,
} from '@/utils/offer.utils';

type Return = {
  productOffer: ProductOffer;
  selectedOptions: ProductVariantOptions;
  optionsMatrix: ProductVariantOptionsMatrix;
};

export function useProductOffer(id: string, selected?: SelectedOptions): Return {
  const initialData = useLoaderData() as ProductOfferQuery;
  const { data } = useProductOfferQuery({ id }, { initialData });

  return useMemo(() => {
    if (!data?.offer)
      return {
        productOffer: productOfferDefaults,
        selectedOptions: {},
        optionsMatrix: { option1: [], option2: [], option3: [] },
      };

    const selectedOptions = getDefaultVariantOptions(data.offer, selected);

    const optionsMatrix = mapOfferToOptionsMatrix(data.offer, selectedOptions);

    return { productOffer: mapProductOffer(data.offer, selectedOptions), selectedOptions, optionsMatrix };
  }, [data, selected]);
}
