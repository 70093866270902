type Props = {
    className?: string;
    onClick?: () => void;
};

export function ArrowRight(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1 7C0.447716 7 0 6.55228 0 6C0 5.44771 0.447715 5 1 5H11C11.5523 5 12 5.44772 12 6C12 6.55228 11.5523 7 11 7L1 7Z"
                fill="currentColor"
            />
            <path
                d="M11.6988 6.72345L6.86545 11.6853C6.46008 12.1017 5.79922 12.1051 5.38989 11.6941C4.9799 11.2824 4.9766 10.6112 5.38197 10.1954L9.4858 5.98185L5.33707 1.8132C4.92774 1.4015 4.92378 0.7303 5.32981 0.314575C5.73451 -0.101822 6.39538 -0.105174 6.80471 0.306529L11.6849 5.21008C11.7906 5.31535 11.8685 5.43873 11.92 5.57015C12.0764 5.95302 12.0038 6.41032 11.6988 6.72345Z"
                fill="currentColor"
            />
        </svg>
    );
}
