import { useNavigate } from 'react-router-dom';
import { tourStore } from '@/store/tour.store';
import { appRoutes } from '@/helpers/routes.helper';

type Props = {
  onClose: () => void;
};
export function useRestartWelcomeTour({ onClose }: Props) {
  const navigate = useNavigate();

  return () => {
    onClose();
    window.setTimeout(() => {
      tourStore.actions.setForceRun(true);
    }, 500);

    navigate(appRoutes.store);
  };
}
