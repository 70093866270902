type Props = {
    className?: string;
    onClick?: () => void;
};

export function Avatar(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.0906 18.3809C21.1172 17.1466 21.8311 15.6834 22.1721 14.1151C22.5131 12.5468 22.4711 10.9195 22.0497 9.3708C21.6282 7.82213 20.8397 6.39768 19.7509 5.21795C18.662 4.03822 17.3049 3.13791 15.7942 2.5932C14.2835 2.04848 12.6638 1.87538 11.072 2.08853C9.48026 2.30168 7.96328 2.89482 6.64941 3.81777C5.33554 4.74071 4.26344 5.96632 3.5238 7.3909C2.78416 8.81548 2.39874 10.3971 2.40015 12.002C2.40069 14.3351 3.22341 16.5935 4.72395 18.3809L4.70966 18.393C4.75969 18.453 4.81688 18.5045 4.86834 18.5638C4.93267 18.6373 5.00201 18.7066 5.06848 18.7781C5.26863 18.9952 5.47449 19.2038 5.69036 19.3995C5.75612 19.4595 5.82402 19.5152 5.8905 19.5724C6.11924 19.7695 6.3544 19.9567 6.59815 20.131C6.6296 20.1524 6.65819 20.1803 6.68964 20.2024V20.1938C8.36377 21.3711 10.3609 22.003 12.408 22.003C14.4551 22.003 16.4523 21.3711 18.1264 20.1938V20.2024C18.1578 20.1803 18.1857 20.1524 18.2179 20.131C18.4609 19.956 18.6968 19.7695 18.9255 19.5724C18.992 19.5152 19.0599 19.4588 19.1257 19.3995C19.3415 19.2031 19.5474 18.9952 19.7475 18.7781C19.814 18.7066 19.8826 18.6373 19.9477 18.5638C19.9984 18.5045 20.0563 18.453 20.1064 18.3923L20.0906 18.3809ZM12.4073 6.28751C13.0435 6.28751 13.6654 6.47603 14.1943 6.82924C14.7233 7.18245 15.1356 7.68447 15.379 8.27183C15.6225 8.85919 15.6862 9.5055 15.5621 10.129C15.438 10.7526 15.1316 11.3253 14.6818 11.7749C14.2319 12.2244 13.6588 12.5306 13.0348 12.6546C12.4109 12.7786 11.7641 12.715 11.1764 12.4717C10.5886 12.2284 10.0862 11.8164 9.73281 11.2878C9.37936 10.7592 9.19071 10.1377 9.19071 9.50194C9.19071 8.64942 9.5296 7.83182 10.1328 7.229C10.7361 6.62617 11.5542 6.28751 12.4073 6.28751ZM6.69393 18.3809C6.70633 17.443 7.08775 16.5476 7.75566 15.8886C8.42357 15.2296 9.32428 14.8598 10.2629 14.8593H14.5517C15.4903 14.8598 16.391 15.2296 17.0589 15.8886C17.7268 16.5476 18.1083 17.443 18.1207 18.3809C16.553 19.7926 14.5176 20.5739 12.4073 20.5739C10.297 20.5739 8.26159 19.7926 6.69393 18.3809Z"
                fill="currentColor"
            />
        </svg>
    );
}
