import { Icon } from '@/components/Common/Icon';
import { SettingsMenuItem } from '@/components/Common/SettingsMenuItem';
import { SettingsLayout } from '@/components/Layout/SettingsLayout';
import { Outlet } from 'react-router-dom';
import { useAccountSettingsMenu, useSideStyles } from './AccountSettingsPage.hooks';
import { useMe } from '@/hooks/useMe';
import { useLogOut } from '@/hooks/useLogout';
import { useState } from 'react';
import { DeleteAccountModal } from './DeleteAccountModal';

export function AccountSettingsPage() {
  const me = useMe();

  const accountSettingsMenu = useAccountSettingsMenu();
  const { left, right } = useSideStyles();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleLogOut = useLogOut();

  return (
    <SettingsLayout
      leftClassName={left}
      left={
        <div className="z-10 flex h-full flex-col px-0 pb-8 lg:px-6">
          {!!me && (
            <div className="z-10 mt-12 hidden p-6 md:block">
              <div className="flex w-full px-7 pt-4">
                <Icon name="Avatar" className="mr-4 h-12" />
                <div>
                  <div className="text-base  text-light-gray">Welcome back</div>
                  <div className="text-lg font-medium text-white">{me.fullName}</div>
                </div>
              </div>
            </div>
          )}
          <div className="mt-20 flex grow flex-col space-y-8 pb-4 xl:mx-16">
            <div className="grow">
              <h2 className="mx-4 mb-4 text-2xl font-semibold md:mx-0">Account Information</h2>
              {accountSettingsMenu.map((item) => (
                <SettingsMenuItem key={item.title} className="mt-0.5" {...item} />
              ))}
            </div>
            <div className="flex flex-col space-y-8">
              <div onClick={handleLogOut} className="flex cursor-pointer items-center">
                <Icon name="Logout" className="mr-5 h-6 pb-[2px]" />
                <div className="text-light-gray">Log Out</div>
              </div>
              <div className="cursor-pointer font-medium text-red" onClick={() => setShowDeleteModal(true)}>
                Delete Account
              </div>
              <div className="text-sm text-light-gray">App version: 1.0.1</div>
            </div>
          </div>
          {showDeleteModal && <DeleteAccountModal onDismiss={() => setShowDeleteModal(false)} />}
        </div>
      }
      rightClassName={right}
      right={
        <div className="flex min-h-full flex-col pb-4">
          <Outlet />
        </div>
      }
    />
  );
}
