import { CartType, cartStore } from '@/store/cart.store';
import { CartItem } from '@/components/Common/CartItem';
import { EmptyCart } from './EmptyCart';
import { Button } from '@/components/Common/Button';
import { Icon } from '@/components/Common/Icon';
import { NavLink } from 'react-router-dom';
import { appRoutes } from '@/helpers/routes.helper';
import { formatPrice } from '@/utils/number.utils';
import { useSnapshot } from 'valtio/react';
import { useCartMetric } from '@/helpers/metrics/useCartMetric';
import CheckoutPromoCodes from '@/components/Form/CheckoutPromoCodes/PromoCodes';

export function CartPage() {
  const { products, itemsInCart, cartSubtotal } = useSnapshot(cartStore.state) as CartType;

  const cartItems = Object.values(products);

  useCartMetric();

  if (!cartItems.length) return <EmptyCart />;

  const isCheckoutDisabled = cartItems.some((cartItem) => {
    return cartItem.product.variant.inStock < cartItem.product.variant.quantity;
  });

  return (
    <div className="flex flex-col items-center pt-4">
      <div>
        <h1 className="mb-6 text-center font-semibold">Cart</h1>
      </div>
      <div className="-mr-4 w-full space-y-4 self-center pb-20 pr-4 md:w-[90%] lg:pb-0 xl:flex xl:space-x-4 xl:overflow-hidden">
        <div className="w-12/12  flex flex-col xl:w-9/12">
          <div className="mb-4 flex w-full items-center justify-between">
            <div className="flex items-center space-x-2">
              <div className="text-light-gray">My Cart</div>
              <div className="text-sm">
                {itemsInCart} {`Item${itemsInCart > 1 ? 's' : ''}`}
              </div>
            </div>
            <div className="font-semibold">{`Subtotal: $${formatPrice(cartSubtotal)}`}</div>
          </div>
          <div className="flex-grow-1 -mr-4 flex flex-col space-y-2 overflow-y-auto pr-4">
            {cartItems.map((cartItem) => (
              <CartItem cartItem={cartItem} key={cartItem.product.variant.variantId} />
            ))}
          </div>
          <div className="my-4 w-full">
            <div className="flex w-full flex-col gap-4 bg-darker-gray p-4 md:relative md:flex-row md:items-center md:rounded-2xl md:p-8">
              <CheckoutPromoCodes />
            </div>
          </div>
        </div>
        <div className="w-12/12 xl:w-3/12">
          <div className="xl:h-6"></div>
          <div className="flex min-w-[300px] flex-col space-y-4 bg-darker-gray p-8 md:rounded-2xl">
            <Button href={appRoutes.checkout} className="w-full" disabled={!!isCheckoutDisabled}>
              Proceed to Checkout
            </Button>
            <Button
              href={appRoutes.paymentWithScutis}
              className="w-full"
              variant="secondary"
              disabled={isCheckoutDisabled}
            >
              Pay with Scuti$ <Icon name="Cart" className="ml-2 h-5" />
            </Button>
            <NavLink to={appRoutes.store} className="flex w-full items-center justify-center py-2">
              <Icon name="Cart" className="mr-2 h-4" /> Continue Shopping
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
