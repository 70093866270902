import { QueryClient } from '@tanstack/react-query';
import { NonIndexRouteObject } from 'react-router-dom';
import { productListingsAndColumn6AdsFilter, mapClientCategoryToFilter } from '@/utils/category.utils';
import { pagingDefaults } from '@/utils/paging.utils';
import { seed } from '@/utils/seed.utils';
import { fetchOffers } from '@/hooks/useOffers';

export const categoryLoader =
  (queryClient: QueryClient): NonIndexRouteObject['loader'] =>
  async ({ params }) => {
    const category = params.category!;

    const variables = {
      seed,
      paging: pagingDefaults,
      filters: [productListingsAndColumn6AdsFilter, mapClientCategoryToFilter(category)],
    };

    return await queryClient.ensureQueryData({
      queryFn: () => fetchOffers(variables),
      queryKey: ['offers', variables],
    });
  };
