/**
 * Function to wait for predicates.
 * @param {function() : Boolean} predicate - A function that returns a bool
 * @param {Number} [timeout] - Optional maximum waiting time in ms after rejected
 */
export function waitFor(predicate: () => boolean, timeout?: number) {
  return new Promise<void>((resolve, reject) => {
    let interval: null | NodeJS.Timer = null;
    const check = () => {
      if (!predicate()) return;
      if (interval) clearInterval(interval);
      resolve();
    };
    interval = setInterval(check, 100);
    check();

    if (!timeout) return;
    setTimeout(() => {
      if (interval) clearInterval(interval);
      reject();
    }, timeout);
  });
}
