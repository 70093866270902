type Props = {
    className?: string;
    onClick?: () => void;
};

export function Legal(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                clipRule="evenodd"
                d="M19 2H5C4.20435 2 3.44129 2.31607 2.87868 2.87868C2.31607 3.44129 2 4.20435 2 5V19C2 19.7956 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22H19C19.7956 22 20.5587 21.6839 21.1213 21.1213C21.6839 20.5587 22 19.7956 22 19V5C22 4.20435 21.6839 3.44129 21.1213 2.87868C20.5587 2.31607 19.7956 2 19 2ZM19.9709 16.8141C19.8929 16.5741 19.6795 16.4191 19.4271 16.4191H18.4457C18.401 16.4191 18.3615 16.3904 18.3476 16.3481L18.0438 15.4138C17.9658 15.1739 17.7527 15.0189 17.5003 15.0189C17.2479 15.0189 17.0342 15.1739 16.956 15.4138L16.7761 15.9684L16.6524 16.3481C16.6383 16.3904 16.599 16.4191 16.5545 16.4191H15.5729C15.3208 16.4191 15.1071 16.5741 15.0292 16.8141C14.9512 17.0541 15.0327 17.305 15.2371 17.4534L16.0526 18.0459C16.0758 18.0629 16.0854 18.0924 16.077 18.1196L15.8758 18.739L15.8505 18.8163L15.7651 19.0797C15.6871 19.3197 15.7686 19.5706 15.9725 19.7189C16.0749 19.7931 16.1916 19.8302 16.3086 19.8302C16.4258 19.8302 16.5431 19.7934 16.6452 19.7189L17.2342 19.2912C17.3136 19.2333 17.4067 19.2046 17.5 19.2043C17.5933 19.2046 17.6864 19.2333 17.7658 19.2912L18.3551 19.7189C18.457 19.7934 18.5742 19.8302 18.6909 19.8302C18.8082 19.8302 18.9254 19.7931 19.0276 19.7189C19.2316 19.5706 19.313 19.3197 19.2352 19.0797L19.1493 18.8163L19.1245 18.739L18.9233 18.1196C18.9149 18.0924 18.9242 18.0629 18.9477 18.0459L19.7632 17.4534C19.9673 17.305 20.0489 17.0541 19.9709 16.8141ZM6 5C5.44772 5 5 5.44772 5 6C5 6.55228 5.44772 7 6 7H14C14.5523 7 15 6.55228 15 6C15 5.44772 14.5523 5 14 5H6ZM6 8C5.44772 8 5 8.44772 5 9C5 9.55228 5.44772 10 6 10H14C14.5523 10 15 9.55228 15 9C15 8.44772 14.5523 8 14 8H6ZM5 12C5 11.4477 5.44772 11 6 11H14C14.5523 11 15 11.4477 15 12C15 12.5523 14.5523 13 14 13H6C5.44772 13 5 12.5523 5 12ZM6 14C5.44772 14 5 14.4477 5 15C5 15.5523 5.44772 16 6 16H10C10.5523 16 11 15.5523 11 15C11 14.4477 10.5523 14 10 14H6Z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </svg>
    );
}
