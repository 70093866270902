import { ShippingInfo } from '@/graphql/generated';

export type OrderAddressProps = {
  fullName: string;
  shippingInfo?: ShippingInfo | null;
};

export function OrderAddress({ fullName, shippingInfo }: OrderAddressProps) {
  return (
    <div className="flex flex-col space-y-4 bg-darker-gray p-8 md:rounded-2xl">
      <div>
        <p className="text-lg font-semibold text-white">{fullName}</p>
        {shippingInfo && (
          <>
            <p className="mt-4">{shippingInfo.address1}</p>
            {shippingInfo.address2 && <p>{shippingInfo.address2}</p>}
            <p>
              {shippingInfo.city}, {shippingInfo.state}, {shippingInfo.zipCode}, {shippingInfo.country}
            </p>
          </>
        )}
      </div>
    </div>
  );
}
