/* from validator */
export const countryPhoneLocaleMap = {
  AD: 'ca-AD',
  AE: 'ar-AE',
  AF: 'fa-AF',
  AG: 'en-AG',
  AI: 'en-AI',
  AL: 'sq-AL',
  AM: 'am-AM',
  AO: 'pt-AO',
  AR: 'es-AR',
  AT: 'de-AT',
  AU: 'en-AU',
  AW: 'nl-AW',
  AZ: 'az-AZ',
  BA: 'bs-BA',
  BD: 'bn-BD',
  BE: 'nl-BE',
  BF: 'fr-BF',
  BG: 'bg-BG',
  BH: 'ar-BH',
  BJ: 'fr-BJ',
  BM: 'en-BM',
  BO: 'es-BO',
  BR: 'pt-BR',
  BS: 'bs-BA',
  BT: 'dz-BT',
  BW: 'en-BW',
  BY: 'be-BY',
  CA: 'en-CA' /* mapped to US */,
  CD: 'fr-CD',
  CF: 'fr-CF' /* version update might be required */,
  CH: 'de-CH',
  CL: 'es-CL',
  CM: 'fr-CM',
  CN: 'zh-CN',
  CO: 'es-CO',
  CR: 'es-CR',
  CU: 'es-CU',
  CY: 'el-CY',
  CZ: 'cs-CZ',
  DE: 'de-DE',
  DK: 'da-DK',
  DO: 'es-DO',
  DZ: 'ar-DZ',
  EC: 'es-EC',
  EE: 'et-EE',
  EG: 'ar-EG',
  EH: 'ar-EH',
  ES: 'es-ES',
  FI: 'fi-FI',
  FJ: 'fj-FJ',
  FO: 'fo-FO',
  FR: 'fr-FR',
  GB: 'en-GB',
  GE: 'ka-GE',
  GF: 'fr-GF',
  GG: 'en-GG',
  GH: 'en-GH',
  GL: 'kl-GL',
  GP: 'fr-GP',
  GR: 'el-GR',
  GY: 'en-GY',
  HK: 'en-HK',
  HN: 'es-HN',
  HU: 'hu-HU',
  ID: 'id-ID',
  IE: 'en-IE',
  IL: 'he-IL',
  IN: 'en-IN',
  IQ: 'ar-IQ',
  IR: 'ir-IR',
  IT: 'it-IT',
  JM: 'en-JM',
  JO: 'ar-JO',
  JP: 'ja-JP',
  KE: 'en-KE',
  KG: 'ky-KG',
  KI: 'en-KI',
  KN: 'en-KN',
  KR: 'ko-KR',
  KW: 'ar-KW',
  KZ: 'kk-KZ',
  LB: 'ar-LB',
  LK: 'si-LK',
  LS: 'en-LS',
  LT: 'lt-LT',
  LU: 'de-LU',
  LV: 'lv-LV',
  LY: 'ar-LY',
  MA: 'ar-MA',
  MD: 'ro-MD',
  MG: 'mg-MG',
  MM: 'my-MM',
  MN: 'mn-MN',
  MO: 'en-MO',
  MQ: 'fr-MQ',
  MT: 'en-MT',
  MU: 'en-MU',
  MV: 'dv-MV',
  MW: 'en-MW' /* version update might be required */,
  MX: 'es-MX',
  MY: 'ms-MY',
  MZ: 'mz-MZ',
  NA: 'en-NA',
  NG: 'en-NG',
  NI: 'es-NI',
  NL: 'nl-NL',
  NO: 'nb-NO',
  NP: 'ne-NP',
  NZ: 'en-NZ',
  OM: 'ar-OM',
  PA: 'es-PA',
  PE: 'es-PE',
  PF: 'fr-PF',
  PG: 'en-PG',
  PH: 'en-PH',
  PK: 'en-PK',
  PL: 'pl-PL',
  PS: 'ar-PS',
  PT: 'pt-PT',
  PY: 'es-PY',
  RE: 'fr-RE',
  RO: 'ro-RO',
  RS: 'sr-RS',
  RU: 'ru-RU',
  RW: 'en-RW',
  SA: 'ar-SA',
  SD: 'ar-SD',
  SE: 'sv-SE',
  SG: 'en-SG',
  SI: 'sl-SI',
  SK: 'sk-SK',
  SL: 'en-SL',
  SM: 'it-SM',
  SO: 'so-SO',
  SS: 'en-SS',
  SV: 'es-SV',
  SY: 'ar-SY',
  TH: 'th-TH',
  TJ: 'tg-TJ',
  TM: 'tk-TM',
  TN: 'ar-TN',
  TR: 'tr-TR',
  TW: 'zh-TW',
  TZ: 'en-TZ',
  UA: 'uk-UA',
  UG: 'en-UG',
  US: 'en-US',
  UY: 'es-UY',
  UZ: 'uz-UZ',
  VE: 'es-VE',
  VN: 'vi-VN',
  WF: 'fr-WF',
  YE: 'ar-YE',
  ZA: 'en-ZA',
  ZM: 'en-ZM',
  ZW: 'en-ZW',
};
