import { appRoutes } from '@/helpers/routes.helper';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

type AltitudeDigitalMessage = {
  message: string;
  type: 'productAd' | 'appDownload' | 'affiliateLink' | 'advertisementAd' | 'videoAd' | 'internal';
  id: string;
};

type NoParamRoute =
  | 'welcome'
  | 'login'
  | 'registration'
  | 'cart'
  | 'checkout'
  | 'about'
  | 'legal'
  | 'legalTermsAndConditions'
  | 'legalPrivacyPolicy'
  | 'accountSettings'
  | 'accountSettingsNameAndAge'
  | 'accountSettingsPassword'
  | 'accountSettingsAddress'
  | 'accountSettingsPreferences'
  | 'rewards'
  | 'wallet';

export function useAdMessageListener() {
  const navigate = useNavigate();

  useEffect(() => {
    const messageListener = (event: MessageEvent<AltitudeDigitalMessage>) => {
      const { origin, data } = event;
      const { message, type, id } = data;
      const self = document.location.origin;

      if (origin === self && message === 'C6_CLICKTHROUGH') {
        switch (type) {
          case 'productAd':
            navigate(appRoutes.productOffer(id));
            break;
          case 'appDownload':
            navigate(appRoutes.appDownload(id));
            break;
          case 'affiliateLink':
          case 'advertisementAd':
            navigate(appRoutes.campaignOffer(id));
            break;
          case 'videoAd':
            navigate(appRoutes.videoOffer(id));
            break;
          case 'internal': {
            if (Object.keys(appRoutes).includes(id) && appRoutes.hasOwnProperty(id)) {
              navigate(appRoutes[id as NoParamRoute]);
            }
            break;
          }
        }
      }
    };

    window.addEventListener('message', messageListener);

    return () => window.removeEventListener('message', messageListener);
  }, [navigate]);
}
