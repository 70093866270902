export type PillProps = {
  className?: string;
  label: string;
  selected?: boolean;
  onClick?: () => void;
};

export function Pill({ className, label, selected, onClick }: PillProps) {
  return (
    <button
      className={`w-full rounded-full border-2 p-4 ${selected ? 'border-blue bg-black' : 'border-black'} ${className}`}
      type="button"
      onClick={onClick}
    >
      <p className={`${selected ? 'text-white' : 'text-light-gray'} selector`}>{label}</p>
    </button>
  );
}
