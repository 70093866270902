import { PaymentForm } from '@/components/Form/PaymentForm';
import { ProceedToPayment } from '@/helpers/sdk-wrapper/VuplexPaymentPage/ProceedToPayment';
import { appSessionStorage } from '@/helpers/storage.helper';
import { useStoreInfo } from '@/hooks/useStoreInfo';
import { getStripeOptions } from '@/utils/stipe.utls';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useMemo } from 'react';

export function PaymentPage() {
  const stripeSecret = appSessionStorage.getItem('stripeKey')!;
  const { platform } = useStoreInfo();
  const stripe = useMemo(() => loadStripe(platform.stripeKey), [platform.stripeKey]);
  const options = useMemo(() => getStripeOptions(stripeSecret), [stripeSecret]);

  if (appSessionStorage.getItem('platform') === 'Vuplex') return <ProceedToPayment />;

  return (
    <Elements stripe={stripe} options={options}>
      <div className="flex min-h-full flex-col items-center px-2 pt-4 md:pt-8">
        <div className="flex-grow-1 flex min-h-full w-full flex-col items-center">
          <PaymentForm />
        </div>
        <div className="h-4 lg:h-8" />
      </div>
    </Elements>
  );
}
