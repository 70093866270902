import { Icon } from '@/components/Common/Icon';
import { useMemo, useRef, useState } from 'react';
import ReactPlayer, { Config } from 'react-player/lazy';
import { useNavigate, useParams } from 'react-router-dom';
import { useVideoOffer } from './VideoOfferPage.hooks';
import { Slider } from '@/components/Common/Slider';
import { debounce } from '@/utils/timing.utils';
import { useViewVideoMetric } from '@/helpers/metrics/useViewVideoMetric';

const config: Config = {
  youtube: {
    playerVars: {
      autoplay: 1,
      rel: 0,
      modestbranding: 1,
    },
  },
  vimeo: {
    playerOptions: {
      autoplay: 1,
      controls: 0,
    },
  },
};

export function VideoOfferPage() {
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();

  const [playing, setPlaying] = useState<boolean>(true);
  const [started, setStarted] = useState<boolean>(false);
  const [duration, setDuration] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);
  const [muted, setMuted] = useState<boolean>(false);

  const playerRef = useRef<ReactPlayer>(null);

  const isLandscape = window.innerHeight < window.innerWidth;

  const handleClose = () => navigate(-1);

  const handleVideoChange = useMemo(() => {
    return debounce((slider: number) => {
      playerRef.current?.seekTo(slider / 100);
    });
  }, []);

  const handleSliderChange = (value: number | number[]) => {
    const sliderValue = Array.isArray(value) ? value[0] : value;
    setProgress(sliderValue);
    handleVideoChange(sliderValue);
  };

  const { video, isMp4 } = useVideoOffer(id!);

  useViewVideoMetric(id!, playing);

  return (
    <div className="bg-secondary-500 flex h-full w-screen">
      <div className="absolute right-4 top-4 z-10" onClick={handleClose}>
        <Icon name="Cross" className="h-5 w-5 cursor-pointer text-white" />
      </div>
      <div className="w-full self-center">
        <div
          className="relative mx-auto"
          style={{
            // Theses numbers are derived from the most commonly used 16/9 ratio
            // to make sure the player is always in 16/9 no matter the resolution
            paddingTop: 'min(56.25%, 100vh)',
            maxWidth: 'calc(1.77 * 100vh)',
          }}
        >
          <ReactPlayer
            id={id}
            width="100%"
            height="100%"
            className="absolute left-0 top-0 max-h-full"
            config={config}
            controls={isMp4}
            muted={muted}
            playing={playing}
            ref={playerRef}
            url={video.media.videoUrl}
            volume={0.8}
            progressInterval={1000}
            playIcon={<Icon name="Play" className="h-1/2" />}
            onDuration={setDuration}
            onPause={() => setPlaying(false)}
            onPlay={() => setPlaying(true)}
            onStart={() => {
              setPlaying(true);
              setStarted(true);
            }}
            onProgress={(obj) => !isMp4 && setProgress(obj.played * 100)}
          />
          {!isMp4 && started && (
            <div
              className={`${
                isLandscape ? 'absolute bottom-0' : 'fixed mt-2'
              } flex w-full flex-row items-center justify-center space-x-8 px-3 pb-2 `}
            >
              <button className="cursor-pointer" onClick={() => setPlaying(!playing)}>
                {playing ? <Icon name="Pause" className="h-8 w-8" /> : <Icon name="Play" className="h-8 w-8" />}
              </button>
              <div className="w-full">
                <Slider
                  value={progress}
                  onChange={handleSliderChange}
                  colors={{ handler: '#FFFFFF', rail: '#AAAAAA', track: '#FFFFFF' }}
                />
              </div>
              <div className="text-white">
                {Math.floor(duration / 60)}:{Math.floor(duration % 60)}
              </div>
              <button className="cursor-pointer" onClick={() => setMuted(!muted)}>
                {muted ? <Icon name="SpeakerX" className="h-8 w-8" /> : <Icon name="SpeakerWave" className="h-8 w-8" />}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
