import { Button } from '@/components/Common/Button';
import { PaymentElement } from '@stripe/react-stripe-js';
import { usePaymentForm } from './PaymentForm.hooks';
import { PaymentTransactionProcess } from './PaymentTransactionProcess';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export type AddressFormProps = {
  className?: string;
};

export function PaymentForm({ className }: AddressFormProps) {
  const navigate = useNavigate();
  const { handleSubmit, isSubmitting, errorMessage } = usePaymentForm();
  const [isReady, setIsReady] = useState(false);

  return (
    <form
      noValidate
      className={`w-full overflow-y-auto md:w-2/3 lg:mt-12 lg:w-1/3 ${className} pb-20`}
      onSubmit={handleSubmit}
    >
      {isSubmitting && <PaymentTransactionProcess />}
      <div className={isSubmitting ? 'hidden' : 'block'}>
        <div>
          <h1 className="mb-6 text-center font-semibold">Payment Information</h1>
        </div>
        <PaymentElement onReady={() => setIsReady(true)} />
        {errorMessage && <div className="text-system-red-400 mt-4 text-center">{errorMessage}</div>}
        <Button type="submit" className={`mt-12 w-full ${isReady ? 'visible' : 'invisible'}`} disabled={isSubmitting}>
          Checkout
        </Button>
        <Button
          type="button"
          className={`mt-4 w-full ${isReady ? 'visible' : 'invisible'}`}
          onClick={() => navigate(-1)}
          variant="secondary"
        >
          Back
        </Button>
      </div>
    </form>
  );
}
