import { Icon } from './Icon';

interface WelcomeProps {
  className?: string;
}

export function Welcome({ className }: WelcomeProps) {
  return (
    <div className={className}>
      <Icon name="ScutiLogo" className="dark-blue mt-4 h-[120px] p-6 md:animate-none" />
      <h2 className="mt-[50px] px-12 text-lg font-bold uppercase lg:text-xl">Sign Up and Earn Rewards</h2>
      <div className="my-[50px] flex-col px-12">
        <div className="mb-3 flex items-center">
          <Icon name="Star" className="mr-3 h-5 shrink-0 text-blue" />
          <p className="font-bold text-white">Free Rewards Every Day</p>
        </div>
        <div className="mb-3 flex items-center">
          <Icon name="Star" className="mr-3 h-5 shrink-0  text-blue" />
          <p className="font-bold text-white">Discover Great Offers</p>
        </div>
        <div className="mb-3 flex items-center">
          <Icon name="Star" className="mr-3 h-5 shrink-0  text-blue" />
          <p className="font-bold text-white">Get Up to 5% on Every Purchase</p>
        </div>
        <div className="mb-3 flex items-center">
          <Icon name="Star" className="mr-3 h-5 shrink-0  text-blue" />
          <p className="font-bold text-white">Exchange Rewards for in-game Currency or to Buy More Products</p>
        </div>
      </div>
    </div>
  );
}
