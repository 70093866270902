import { DisclosureNoBorders } from '@/components/Common/Disclosure';

export function PrivacyAndPolicy() {
  return (
    <div className="flex h-full flex-col">
      <h2 className="mb-1 font-semibold">Privacy & Policy</h2>
      <p>Last updated: October 02, 2023.</p>

      <div className="grow-1 mb-8 mt-4 overflow-y-auto pr-4">
        <p className="text-white">
          SCUTI cares about your privacy and knows that you also care about how information about you is used, stored,
          and shared. We appreciate your trust, and we are committed to protecting your information carefully, sensibly
          and according to our privacy policy as set forth in this Privacy Notice. SCUTI always puts players first -
          always. That&apos;s why everything we do is focused on providing excellent customer service and delivering to
          you great products from our trusted vendors.
          <br />
          <br />
          The information you share with SCUTI allows us to provide the products and services you need and want while
          giving you the very best shopping experience, and of course participate in our rewards program. For example,
          when you shop through our SCUTI marketplace we use what we know about your shopping habits to show you the
          best products that may interest you. We collect information to deliver the products and services you request,
          to help improve your shopping experience, and to support our business functions. We collect information from
          you in a variety of ways when you interact with our website and mobile services.
          <br />
          <br />
          <b>By visiting SCUTI, you are accepting the practices described in this Privacy Notice.</b>
          <br />
          <br />
        </p>
        <ul>
          <li>
            <DisclosureNoBorders title="What Personal Information About Customers Does SCUTI Gather?">
              <div className="mr-2">
                <p className="text-white">
                  The information we learn from customers helps us personalize and continually improve your SCUTI
                  experience. Here are the types of information we gather. Personal information is information that
                  identifies you or reasonably can be linked to information that identifies you. For example, when you
                  place an order with SCUTI, to fulfill your order we collect personal information, such as name,
                  address, email, phone number, and credit or debit card information and stay in touch with you on the
                  status of the shipment. Some laws define “personal information” differently, and we use those
                  definitions when they are applicable. Rest assured, though, that no matter where you live, we treat
                  your personal information with respect, and we collect, use, and share it only as described in this
                  Policy.
                </p>
                <ul className="marker:text-white">
                  <li>
                    <p className="text-white">
                      <b>Information You Give Us:</b> We receive and store any information you enter on our Web site,
                      Scuti store, or give us in any other way. Scuti collects <b>identifiable</b> data you opt-in to
                      provide us with, as well as anonymous data such as location and the device you are accessing the
                      platform from. You can choose not to provide certain information, but then you might not be able
                      to take advantage of many of our features, including our rewards program and the specific products
                      and deals based on your preferences. We use the information that you provide for such purposes as
                      responding to your requests, customizing future shopping for you, improving the deals and offers
                      our vendors will offer, and communicating with you.
                    </p>
                  </li>

                  <li>
                    <p className="text-white">
                      <b>Automatic Information:</b> Unlike many Web sites and Apps, we do not use &quot;cookies&quot;.
                      Scuti does not track your cross-site web activity or use any third-party tracking software.
                    </p>
                  </li>

                  <li>
                    <p className="text-white">
                      <b>Mobile:</b> When you download the Scuti app, we may receive information about your location and
                      your mobile device, but we do not access the unique identifier for your device. We may use this
                      information to provide you with location-based services, such as advertising, search results, and
                      other personalized content. Most mobile devices allow you to turn off location services. Most
                      Scuti vendors are national distributors, so location-based services are necessary, in the event
                      you seek to purchase goods from a local vendor.
                    </p>
                  </li>

                  <li>
                    <p className="text-white">
                      <b>E-mail Communications:</b> To help us make e-mails more useful and interesting, we often
                      receive a confirmation when you open e-mail from Scuti if your computer supports such
                      capabilities. We also compare our customer list to lists received from other companies, in an
                      effort to avoid sending unnecessary messages to our customers.
                    </p>
                  </li>

                  <li>
                    <p className="text-white">
                      <b>Information from Other Sources:</b> We might receive information about you from other sources
                      and add it to our account information.
                    </p>
                  </li>
                </ul>
              </div>
            </DisclosureNoBorders>
          </li>

          <li>
            <DisclosureNoBorders title={'What About Advertising?'}>
              <div className="mr-2">
                <ul className="marker:text-white">
                  <li>
                    <p className="text-white">
                      <b>Third-Party Advertisers and Links to Other Websites:</b>
                      <br />
                      The SCUTI marketplace may include third-party advertising and links to download apps. Third-party
                      advertising partners may collect information about you when you interact with their content,
                      advertising, and services.
                    </p>
                  </li>

                  <br />

                  <li>
                    <p className="text-white">
                      <b>Use of Third-Party Advertising Services:</b>
                      <br />
                      We provide ad companies with information that allows them to serve you with more useful and
                      relevant SCUTI ads and to measure their effectiveness. We never share your name or other
                      information that directly identifies you when we do this. Instead, we use an advertising
                      identifier like a cookie, a device identifier, or a code derived from applying irreversible
                      cryptography to other information like an email address. We do not share your specific shopping
                      actions like purchases, product views, or searches with ad companies, we may share an advertising
                      identifier and an estimate of the value of the ads they show you on our behalf so they can serve
                      you with more effective SCUTI ads. Some ad companies also use this information to serve you
                      relevant ads from other advertisers.
                    </p>
                  </li>
                </ul>
                <br />
                <p className="text-white">
                  <b>Information You Give Us:</b>
                  <br />
                  We receive and store any information you enter on our Web site, SCUTI marketplace, or give us in any
                  other way. SCUTI collects identifiable data you opt-in to provide us with, as well as anonymous data
                  such as location and the device you are accessing the platform from. You can choose not to provide
                  certain information, but then you might not be able to take advantage of many of our features,
                  including our rewards program and the specific products and deals based on your preferences. We use
                  the information that you provide for such purposes as responding to your requests, customizing future
                  shopping for you, improving the deals and offers our vendors will offer, and communicating with you.
                  Information we collect when you enter SCUTI through a game or when you create a SCUTI Accounts
                  includes, without limitation: your IP Address, device ID, name, email address, alias, MSISDN.
                  <br />
                  <br />
                  <b>What About Cookies?:</b>
                  <br />
                  Unlike many Web sites and Apps, we <u>do not use "tracking cookies"</u>. SCUTI does not track your
                  cross-site web activity or use any third-party tracking software.
                  <br />
                  <br />
                  <b>Mobile:</b>
                  <br />
                  When you download the SCUTI app, we may receive information about your location and your mobile
                  device, but we do not access the unique identifier for your device. We may use this information to
                  provide you with location-based services, such as advertising, search results, and other personalized
                  content. Most mobile devices allow you to turn off location services. Most SCUTI vendors are national
                  distributors, so location-based services are necessary, in the event you seek to purchase goods from a
                  local vendor.
                  <br />
                  <br />
                  <b>E-mail Communications:</b>
                  <br />
                  To help us make e-mails more useful and interesting, we often receive a confirmation when you open
                  e-mail from SCUTI if your computer supports such capabilities. We also compare our customer list to
                  lists received from other companies, in an effort to avoid sending unnecessary messages to our
                  customers.
                  <br />
                  <br />
                  <b>Information from Other Sources:</b>
                  <br />
                  We might receive information about you from other sources and add it to our account information.
                </p>
              </div>
            </DisclosureNoBorders>
          </li>

          <li>
            <DisclosureNoBorders title="How Do We Use Your Personal Information?">
              <div className="mr-2">
                <p className="text-white">
                  We use your personal information to provide you products and services, such as to fulfill your
                  requests for products or to help us personalize our services and rewards program to you. We also use
                  your personal information to support our business functions, such as fraud prevention, marketing, and
                  legal functions.
                </p>

                <p className="mt-4">Some examples include:</p>

                <ul className="list-disc pl-4 marker:text-white">
                  <li>
                    <b>Purchase and delivery of products and services</b> We use your personal information to take and
                    handle orders, deliver products and services, process payments, and communicate with you about
                    orders, products and services, and promotional offers
                  </li>
                  <li>
                    <b>To create and maintain your account</b>
                  </li>

                  <li>
                    <b>To enable and maintain our SCUTI rewards program</b>
                  </li>

                  <li>
                    <b>To conduct auditing and monitoring of transactions and engagement</b>
                  </li>

                  <li>
                    <b>To protect the security and integrity of our websites, mobile services and our business,</b> and
                    help prevent fraud. We use personal information to prevent and detect fraud and abuse in order to
                    protect the security of our customers, SCUTI, and our vendors
                  </li>

                  <li>
                    <b>To update our operational and technical functionality</b>
                  </li>

                  <li>
                    <b>
                      To conduct business analysis, such as analytics, projections, identifying areas for operational
                      improvement
                    </b>
                  </li>

                  <li>
                    <b>To conduct research and development</b>
                  </li>

                  <li>
                    <b>To fulfill our legal function or obligations</b>
                  </li>

                  <li>
                    <b>Provide, troubleshoot, and improve the SCUTI marketplace.</b> We use your personal information to
                    provide functionality, analyze performance, fix errors, and improve the usability and effectiveness
                    of the SCUTI marketplace.
                  </li>

                  <li>
                    <b>Recommendations and personalization.</b> We use your personal information to recommend features,
                    products, and services that might be of interest to you, identify your preferences, and personalize
                    your experience with the SCUTI marketplace.
                  </li>

                  <li>
                    <b>Communicate with you.</b> We use your personal information to communicate with you in relation to
                    the SCUTI marketplace via different channels (e.g., by phone, email, chat).
                  </li>

                  <li>
                    <b>Advertising.</b> We use your personal information to display interest-based ads for features,
                    products, and services that might be of interest to you. We do not use information that personally
                    identifies you.
                  </li>
                </ul>
              </div>
            </DisclosureNoBorders>
          </li>

          <li>
            <DisclosureNoBorders title="Does SCUTI Share the Information It Receives?">
              <div className="mr-2">
                <p className="text-white">
                  Information about our customers is an important part of our business, and we are not in the business
                  of selling it to others. SCUTI shares aggregated data of all the activity on our mobile and online
                  marketplace with game publishers and vendors so they can offer our shoppers the best deals and
                  rewards. The only personally identifiable information we share is with our payments providers and with
                  vendors so they can ship your products, as stated below.
                </p>

                <ul className="list-disc pl-4 marker:text-white">
                  <li>
                    <b>Affiliated Businesses We Do Not Control:</b> We work closely with affiliated businesses. In some
                    cases, such as Marketplace vendors, these businesses sell offerings to you at SCUTI. SCUTI only
                    shares aggregated marketplace data with these third parties and never personally identifiable
                    information, other than the specifics needed to fulfill your orders, such as items purchased and
                    delivery address.
                  </li>

                  <li>
                    <b>Third-Party Service Providers:</b> We employ other companies and individuals to perform functions
                    on our behalf. Examples include e-mail, removing repetitive information from customer lists,
                    analyzing data, providing marketing assistance, processing credit card payments, and providing
                    customer service. They have access to personal information needed to perform their functions, but
                    may not use it for other purposes.
                  </li>

                  <li>
                    <b>Promotional Offers:</b> We send offers to selected groups of SCUTI customers on behalf of other
                    businesses. When we do this, we do not give that business your name and address.
                  </li>

                  <li>
                    <b>Protection of SCUTI and Others:</b> We release account and other personal information only when
                    we believe its release is appropriate and necessary to comply with the law; enforce or apply
                    our&nbsp;
                    <a href="https://www.scuti.store/terms">Conditions of Use</a> and other agreements; or to protect
                    the rights, property, or safety of SCUTI, our users, or others. This includes exchanging information
                    with applicable companies and organizations for fraud protection, credit risk reduction and
                    enforcement. For the avoidance of doubt, we will not sell, rent, share, or otherwise disclose
                    personally identifiable information from our customers for commercial purposes in any way that would
                    cause us to be in violation of the commitments set forth in this Privacy Notice.
                  </li>

                  <li>
                    <b>With Your Consent:</b> Other than as set out above, you will receive notice when information
                    about you might go to third parties, and you will have an opportunity to choose not to share the
                    information.
                  </li>
                </ul>
              </div>
            </DisclosureNoBorders>
          </li>

          <li>
            <DisclosureNoBorders title="How Secure Is Information About Me?">
              <div className="mr-2">
                <ul className="list-disc pl-4 marker:text-white">
                  <li>
                    We work to protect the security of your information during transmission by using Secure Sockets
                    Layer (SSL) software, which provides 256-bit encryption for the personally identifiable information
                    you input into our systems.
                  </li>

                  <li>
                    We reveal only the last four digits of your credit card numbers when confirming an order. Of course,
                    we transmit the entire credit card number to the appropriate credit card company during order
                    processing.
                  </li>

                  <li>
                    It is important for you to protect against unauthorized access to your password and to your
                    computer. Be sure to sign off when finished using a shared computer.
                  </li>
                </ul>
              </div>
            </DisclosureNoBorders>
          </li>

          <li>
            <DisclosureNoBorders title="Which Information Can I Access?">
              <div className="mr-2">
                <p className="text-white">
                  SCUTI gives you access to a broad range of information about your account and your interactions with
                  SCUTI for the limited purpose of viewing and, in certain cases, updating that information.
                </p>
              </div>
            </DisclosureNoBorders>
          </li>

          <li>
            <DisclosureNoBorders title="What Choices Do I Have?">
              <div className="mr-2">
                <ul className="list-disc pl-4 marker:text-white">
                  <li>
                    As described above, you can choose not to provide certain information, but then you might not be
                    able to take advantage of many of the SCUTI marketplace offerings.
                  </li>

                  <li>
                    If you do not want to receive email or other communications from us, please contact us at&nbsp;
                    <a href="mailto:support@scuti.store">support@scuti.store</a>. If you don't want to receive email
                    notifications from us, please adjust your opt-out directly from the email.
                  </li>

                  <li>
                    You can always choose not to provide information, even though it might be needed to make a purchase
                    or to take advantage of such SCUTI features as Your Profile and SCUTI Rewards.
                  </li>

                  <li>
                    You can add or update certain information on pages such as those referenced in the Account section.
                    When you update information, we usually keep a copy of the prior version for our records.
                  </li>
                </ul>
                <br />
                In addition, to the extent required by applicable law, you may have the right to request access to or
                delete your personal information. If you wish to do any of these things, you may email us at&nbsp;
                <a href="mailto:support@scuti.store">support@scuti.store</a> and request the deletion of all your data
                records.
              </div>
            </DisclosureNoBorders>
          </li>

          <li>
            <DisclosureNoBorders title="Are Children Allowed to Use SCUTI?">
              <div className="mr-2">
                <p className="text-white">
                  SCUTI strictly adheres to the Children’s Online Privacy Protection Act (“COPPA”). SCUTI does not sell
                  products for purchase by children. We sell children's products for purchase by adults. If you are
                  under 14, you may use SCUTI only with the involvement of a parent or guardian. We do not knowingly
                  collect or marketplace personal information from children under the age of 14 without the verifiable
                  consent of the child's parent or guardian. If parents believe that we have unintentionally collected
                  their children’s personal identifiable information, they may request the deletion of the information
                  by contacting us at SCUTI Privacy Policy, Attention: Privacy Policy Administrator, 7750 Okeechobee
                  Blvd, Ste 4 PMB 2057, West Palm Beach FL 33411. They may also e-mail us at{' '}
                  <a href="mailto:support@scuti.store">support@scuti.store</a>.
                </p>
              </div>
            </DisclosureNoBorders>
          </li>

          <li>
            <DisclosureNoBorders title="EU-US and Swiss-US Privacy Shield">
              <div className="mr-2">
                <p className="text-white">
                  SCUTI LLC. participates in the EU-US and Swiss-US Privacy Shield frameworks.
                </p>
              </div>
            </DisclosureNoBorders>
          </li>

          <li>
            <DisclosureNoBorders title="Conditions of Use, Notices, and Revisions">
              <div className="mr-2">
                If you choose to visit SCUTI, your visit and any dispute over privacy is subject to this Notice and our
                Conditions of Use, including limitations on damages, resolution of disputes, and application of the law
                of the State of Florida. If you have any concern about privacy at SCUTI, please contact us with a
                thorough description at <a href="mailto:support@scuti.store">support@scuti.store</a>, and we will use
                commercially reasonable efforts to try and resolve it. Our business changes constantly, and our Privacy
                Notice and the <a href="https://www.scuti.store/terms">Conditions of Use</a> will change accordingly on
                a periodic basis. We may e-mail periodic reminders of our notices and conditions, but you should check
                our Web site frequently to see recent changes. Unless stated otherwise, our current Privacy Notice
                applies to all information that we have about you and your account. We stand behind the promises we
                make, however, and will never materially change our policies and practices to make them less protective
                of customer information collected in the past without the consent of affected customers.
              </div>
            </DisclosureNoBorders>
          </li>

          <li>
            <DisclosureNoBorders title="Examples of Information Collected:">
              <div className="mr-2">
                <p className="text-white">
                  You provide most information when you search, buy, post a review, log in, participate in a contest or
                  questionnaire, or communicate with customer service. For example, you provide information when you
                  search for a product; place an order through SCUTI; provide information in Your Account (and you might
                  have more than one if you have used more than one e-mail address when shopping with us) or Your
                  Profile; communicate with us by phone, e- mail, or otherwise; complete a questionnaire or a contest
                  entry form; view videos which provide SCUTI Rewards; compile Wish Lists of brands; gift SCUTI Rewards;
                  participate in community features; and/or provide and rate Reviews. As a result of those actions, you
                  might supply us with such information as your name, address, and phone numbers; credit card
                  information; people to whom purchases have been shipped (including addresses and phone numbers);
                  content of reviews and e-mails to us; and personal profile information.
                  <br />
                  <br />
                  Upon placing an order and registering for a SCUTI rewards account, you may supply us with information
                  as:
                  <ul className="list-disc pl-4 marker:text-white">
                    <li>identifying information such as your name, address, and phone numbers;</li>
                    <li>payment information;</li>
                    <li>your age;</li>
                    <li>your location information;</li>
                    <li>your IP address;</li>
                    <li>people, addresses and phone numbers listed in your Addresses;</li>
                    <li>email addresses of your friends and other people if purchasing on behalf of others;</li>
                    <li>content of reviews and emails to us.</li>
                  </ul>
                  <br />
                  <ul className="marker:text-white">
                    <li>
                      <p className="text-white">
                        <b>Automatic Information</b>
                        <br />
                        Examples of the information we collect and analyze include the Internet protocol (IP) address
                        used to connect your computer to the Internet; login; e-mail address; password; computer and
                        connection information such as browser type, version, and time zone setting, browser plug-in
                        types and versions, operating system, and platform; purchase history, which we sometimes
                        aggregate with similar information from other customers to create features like Top Sellers; the
                        full Uniform Resource Locator (URL) clickstream to, through, and from our Web site, including
                        date and time; products you viewed or searched for. During some visits we may use software tools
                        to measure and collect session information, including page response times, download errors,
                        length of visits to certain pages, page interaction information (such as scrolling, clicks, and
                        mouse-overs), and methods used to browse away from the page. We may also collect technical
                        information to help us identify your device for fraud prevention and diagnostic purposes.
                      </p>
                    </li>
                    <br />
                    <li>
                      <p className="text-white">
                        <b>Mobile</b>
                        <br />
                        Examples of the information we collect and analyze include the Internet protocol (IP) address
                        Most mobile devices provide users with the ability to disable location services. Most likely,
                        these controls are located in the device's settings menu. If you have questions about how to
                        disable your device's location services, we recommend you contact your mobile service carrier or
                        your device manufacturer.
                      </p>
                    </li>
                    <br />
                    <li>
                      <p className="text-white">
                        <b>Information from Other Sources</b>
                        <br />
                        Examples of the information we collect and analyze include the Internet protocol (IP) address
                        Examples of information we receive from other sources include updated delivery and address
                        information from our vendors, which we use to correct our records and deliver your next purchase
                        or communication more easily; account information, purchase or redemption information, and
                        page-view information from some merchants with which we operate co-branded businesses or for
                        which we provide technical, fulfillment, advertising, or other services; search term and search
                        result information from some searches conducted through the Web search features.
                      </p>
                    </li>
                    <br />
                    <li>
                      <p className="text-white">
                        <b>Information You Can Access</b>
                        <br />
                        Examples of the information we collect and analyze include the Internet protocol (IP) address
                        Examples of information you can access easily at SCUTI include up-to-date information regarding
                        recent orders; personally identifiable information (including name, e-mail, password,
                        communications and personalized advertising preferences); payment settings (including credit
                        card information and promotional certificate and SCUTI Crypto balances); e-mail notification
                        settings (including Delivery status and newsletters); Recommendations (including Recommended for
                        You and Staff Recommendations); SCUTI rewards gifting; Vendor Accounts; and Your Profile
                        (including your product Reviews, Recommendations, personal profile, and Wish List).
                      </p>
                    </li>
                    <br />
                    <li>
                      <p className="text-white">
                        <b>How Long Do We Retain Data?</b>
                        <br />
                        Examples of the information we collect and analyze include the Internet protocol (IP) address In
                        general, we retain the Personal Data we collect for as long as it remains necessary for the
                        purposes set forth above, all under the applicable regulation, or until you express your
                        preference to optout, where applicable.
                      </p>
                      <p className="pt-2">The retention periods are determined according to the following criteria:</p>
                      <ol className="list list-inside list-decimal pl-4 marker:text-white">
                        <li>
                          For as long as it remains necessary in order to achieve the purpose for which the Personal
                          Data was initially processed. For example: if you contacted us, we will retain your contact
                          information at least until we will address your inquiry.
                        </li>
                        <li>
                          To comply with any regulatory obligations. For example: transactional data will be retained
                          for up to seven years (or even more under certain circumstances) for compliance with our
                          bookkeeping obligations purposes.
                        </li>
                        <li>
                          To resolve a claim or return we might have or a dispute with you, including any legal
                          proceeding between us, until such dispute will be resolved, and following, if we find it
                          necessary, in accordance with applicable statutory limitation periods.
                        </li>
                      </ol>
                      <br />
                      <p className="text-white">
                        Please note that except as required by applicable law, we will not be obligated to retain your
                        data for any particular period, and we may delete it for any reason and at any time, without
                        providing notification of our intention to do so.
                      </p>
                    </li>
                  </ul>
                </p>
              </div>
            </DisclosureNoBorders>
          </li>

          <li>
            <DisclosureNoBorders title="INTERNATIONAL DATA TRANSFER:">
              <div className="mr-2">
                <p className="text-white">
                  Our data servers in which we host and store the information are located in the US. The Company’s HQ
                  are based in the US as well. In the event that we need to transfer your Personal Data out of your
                  jurisdiction, we will take appropriate measures to ensure that your Personal Data receives an adequate
                  level of protection as required under applicable law. Furthermore, when Personal Data that is
                  collected within the European Economic Area ("EEA") is transferred outside of the EEA to a country
                  that has not received an adequacy decision from the European Commission, we will take necessary steps
                  in order to ensure that sufficient safeguards are provided during the transferring of such Personal
                  Data, in accordance with the provision of the standard contractual clauses approved by the European
                  Union. Thus, we will obtain contractual commitments or assurances from the data importer to protect
                  your Personal Data, using contractual protections that EEA and UK regulators have pre-approved to
                  ensure your data is protected (known as standard contract clauses), or rely on adequacy decisions
                  issued by the European Commission. Some of these assurances are well-recognized certification schemes.
                </p>
              </div>
            </DisclosureNoBorders>
          </li>

          <li>
            <DisclosureNoBorders title="How Do We Secure Your Personal Information?">
              <div className="mr-2">
                <p className="text-white">
                  We recognize the importance of maintaining the security of our customers’ personal information. We
                  work with professionals and third parties who are responsible for helping to protect the security of
                  your information. Whether you are shopping on our website or through our mobile services, we use
                  reasonable security measures and technical safeguards.
                  <br />
                  <br />
                  Please contact us at: <a href="mailto:support@scuti.store">support@scuti.store</a> if you feel that
                  your privacy was not dealt with properly, in a way that was in breach of our Privacy Policy, or if you
                  become aware of a third party's attempt to gain unauthorized access to any of your Personal Data. We
                  will make a reasonable effort to notify you and the appropriate authorities (if required by applicable
                  law) in the event that we discover a security incident related to your Personal Data.
                </p>
              </div>
            </DisclosureNoBorders>
          </li>
        </ul>
      </div>
    </div>
  );
}
