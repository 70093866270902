type Props = {
  onClose: () => void;
  children: any;
};

export const ImageModal = ({ onClose, children }: Props) => {
  return (
    <div className="fixed left-0 top-0 z-50 h-screen w-screen backdrop-brightness-[0.05]">
      <span className="absolute right-8 top-8 cursor-pointer text-4xl font-bold text-white" onClick={onClose}>
        &times;
      </span>
      <div className="m-auto flex h-full max-h-full w-[90%] items-center justify-center">{children}</div>
    </div>
  );
};
