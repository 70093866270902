type Props = {
    className?: string;
    onClick?: () => void;
};

export function Card(props: Props) {
    return (
        <svg {...props} fill="currentColor" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
            <path
                clipRule="evenodd"
                d="M61.3 13H10.7C8.39262 13 6.17974 13.9087 4.54817 15.5262C2.9166 17.1437 2 19.3375 2 21.625V50.375C2 52.6625 2.9166 54.8563 4.54817 56.4738C6.17974 58.0913 8.39262 59 10.7 59H61.3C63.6074 59 65.8203 58.0913 67.4518 56.4738C69.0834 54.8563 70 52.6625 70 50.375V21.625C70 19.3375 69.0834 17.1437 67.4518 15.5262C65.8203 13.9087 63.6074 13 61.3 13ZM35 32C35 30.8954 35.8954 30 37 30H63C64.1046 30 65 30.8954 65 32C65 33.1046 64.1046 34 63 34H37C35.8954 34 35 33.1046 35 32ZM35 39C35 37.8954 35.8954 37 37 37H63C64.1046 37 65 37.8954 65 39C65 40.1046 64.1046 41 63 41H37C35.8954 41 35 40.1046 35 39ZM26.48 29.16C28.45 29.16 30.16 30.4 30.77 32.28L30.76 32.27C31.37 34.15 30.72 36.16 29.12 37.32L26.53 39.2L27.03 40.75L27.14 41.09L27.52 42.25C28.13 44.13 27.48 46.14 25.88 47.3C25.09 47.87 24.15 48.17 23.22 48.17C22.29 48.17 21.36 47.88 20.57 47.3L17.99 45.42L15.41 47.3C14.62 47.87 13.69 48.17 12.75 48.17C11.81 48.17 10.88 47.87 10.1 47.3C8.5 46.14 7.85 44.13 8.46 42.25L9.45 39.21L6.87 37.33C5.27 36.17 4.62 34.16 5.23 32.28C5.84 30.4 7.54 29.16 9.52 29.16H12.72L13.71 26.12C14.32 24.24 16.02 23 18 23C19.97 23 21.68 24.24 22.29 26.12L23.28 29.16H26.48Z"
                fill="#101015"
                fillRule="evenodd"
            />
            <path
                clipRule="evenodd"
                d="M28.8717 32.8991C28.5286 31.8433 27.5896 31.1611 26.4791 31.1611H22.1611C21.9643 31.1611 21.7906 31.0348 21.7295 30.8485L20.3927 26.7379C20.0495 25.6821 19.1116 25 18.001 25H17.9989C16.8884 25 15.9505 25.6821 15.6063 26.7379L14.8147 29.178L14.2705 30.8485C14.2084 31.0348 14.0357 31.1611 13.8399 31.1611H9.52093C8.41144 31.1611 7.47143 31.8433 7.12826 32.8991C6.7851 33.9549 7.14405 35.0591 8.04301 35.7117L11.6315 38.3191C11.7336 38.3939 11.7757 38.5233 11.7389 38.6433L10.8536 41.3686L10.742 41.7086L10.3662 42.8676C10.023 43.9234 10.382 45.0276 11.2788 45.6803C11.7294 46.0066 12.2431 46.1698 12.7578 46.1698C13.2736 46.1698 13.7894 46.0077 14.2389 45.6803L16.8305 43.7982C17.18 43.5434 17.5895 43.4171 18 43.416C18.4105 43.4171 18.82 43.5434 19.1695 43.7982L21.7621 45.6803C22.2106 46.0077 22.7264 46.1698 23.2401 46.1698C23.7559 46.1698 24.2717 46.0066 24.7211 45.6803C25.619 45.0276 25.9769 43.9234 25.6348 42.8676L25.2569 41.7086L25.1475 41.3686L24.2622 38.6433C24.2253 38.5233 24.2664 38.3939 24.3696 38.3191L27.958 35.7117C28.8559 35.0591 29.2149 33.9549 28.8717 32.8991Z"
                fill="url(#paint0_linear_2577_14209)"
                fillRule="evenodd"
            />
            <defs>
                <linearGradient gradientUnits="userSpaceOnUse" id="paint0_linear_2577_14209" x1="18" x2="18" y1="20.2135" y2="43.1733">
                    <stop stopColor="#1D35EE" />
                    <stop offset="1" stopColor="#1024A3" />
                </linearGradient>
            </defs>
        </svg>
    );
}
