import { NavLink } from 'react-router-dom';
import { FreeMode, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { appRoutes } from '@/helpers/routes.helper';
import { OfferListItem } from '@/types/OfferTypes';
import { Icon } from './Icon';
import { OfferCard } from './OfferCard';

export interface OffersRowProps {
  id: string | number;
  category?: string;
  offers: OfferListItem[];
}

export function OffersRow({ category = '', id, offers }: OffersRowProps) {
  return (
    <div className={`category-row-${id}`}>
      {!!category && (
        <NavLink to={appRoutes.category(category)} className="flex items-center justify-between py-4 md:py-6 lg:px-12">
          <span id={`category-title-${id}`} className="text-sm uppercase text-white">
            {category}
          </span>
          <span>See All</span>
        </NavLink>
      )}
      <div className="flex w-full flex-row items-center space-x-2 overflow-x-auto">
        <div
          className="hidden h-10 w-10 shrink-0 cursor-pointer items-center rounded-full bg-darker-gray opacity-60 hover:opacity-100 lg:flex lg:items-center lg:justify-center"
          id={`swiper-button-prev-${id}`}
        >
          <Icon name="ChevronLeft" className="h-3" />
        </div>
        <Swiper
          freeMode
          modules={[FreeMode, Navigation]}
          navigation={{ prevEl: `#swiper-button-prev-${id}`, nextEl: `#swiper-button-next-${id}` }}
          slidesPerView="auto"
          spaceBetween={5}
        >
          {offers.map((offer) => (
            <SwiperSlide key={offer.id} className="scuti-masonry w-[300px]">
              <OfferCard offer={offer} fixedHeight className="overflow-hidden rounded-2xl" />
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          className="hidden h-10 w-10 shrink-0 cursor-pointer items-center rounded-full bg-darker-gray opacity-60 hover:opacity-100 lg:flex lg:items-center lg:justify-center"
          id={`swiper-button-next-${id}`}
        >
          <Icon name="ChevronRight" className="h-3" />
        </div>
      </div>
    </div>
  );
}
