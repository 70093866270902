import { ProductOffer } from '@/types/OfferTypes';
import { Icon } from './Icon';
import { Image } from './Image';
import { Price } from './Price';
import { ScutiEarn } from './ScutiEarn';
import { useStoreInfo } from '@/hooks/useStoreInfo';
import { useMemo } from 'react';

export type ProductDescriptionProps = {
  className?: string;
  productOffer: ProductOffer;
  onShare: () => void;
  onAddToCart: () => void;
};

export function ProductDescription({ className, productOffer, onShare, onAddToCart }: ProductDescriptionProps) {
  const { name, shop, reward, price } = productOffer;
  const { platform } = useStoreInfo();

  const calculatedReward = useMemo(
    () => (reward || 0) * platform.scutiExchangeRate,
    [reward, platform.scutiExchangeRate],
  );

  return (
    <div className={className}>
      {shop.thumbnail && <Image className="mr-2 h-11 w-11" src={shop.thumbnail} />}
      <div>
        <div className="flex items-center justify-between">
          <div>
            <p className="mt-1 text-white">{productOffer.brand?.name}</p>
            <h2 className="mb-10 mt-4">{name}</h2>
          </div>
          <div className="flex items-center justify-between">
            <div className="inline md:hidden" onClick={onShare}>
              <Icon name="ShareC" className="ml-2 h-8" />
            </div>
            <div className={`md:hidden ${productOffer.scutisExclusive ? 'hidden' : 'inline'}`} onClick={onAddToCart}>
              <Icon name="CartAdd" className="ml-2 h-8" />
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-between">
          {!productOffer.scutisExclusive && <Price price={price.current} oldPrice={price.old} />}
          <div className="flex flex-row items-center">
            {reward ? <ScutiEarn earn={calculatedReward} className="shrink-0 animate-glow" /> : null}
          </div>
        </div>
      </div>
    </div>
  );
}
