import { useMutation } from '@tanstack/react-query';
import { fetcherRest } from '@/helpers/fetch.helper';
import { RegistrationFormData } from './RegistrationForm.schema';
import { RegisterInfo } from '@/types/UserTypes';
import { toast } from 'react-toastify';
import { getErrorMessage } from '@/utils/error.utils';
import { appSessionStorage } from '@/helpers/storage.helper';

export function useRegistration() {
  const { mutateAsync } = useMutation({
    mutationFn: async (data: RegistrationFormData) => {
      await fetcherRest<RegisterInfo>(
        { url: '/user/register', method: 'POST' },
        {
          ...data,
          // server accept only ISO date
          birthDate: `${data.birthYear}-01-01`,
          email: data.email.toLowerCase(),
          gameId: appSessionStorage.getItem('gameId'),
          gameUserId: appSessionStorage.getItem('gameUserId'),
        },
      );
    },
    onError: (error) => {
      toast.error(getErrorMessage(error), { autoClose: 8000 });
    },
  });

  return mutateAsync;
}
